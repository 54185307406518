<template>
  <div>
    <AnDashboard ref="an_dashboard"
                 class="dashboard_vma"
                 :body="body"
                 :tab_items="Object.keys(data_tables_json)"
                 :tab_content="data_tables_json"
                 :search="$store.state.dashboard_filter.dashboard_search"
                 :show_inactive="show_inactive"
                 allow_mass_update
                 custom_filter
                 :custom_filter_function="custom_filter_vma"
                 :mass_update_actions="actions"
                 mass_update_key="matricule"
                 header_color="primary"
                 v-on:mass_action_click="manage_mass_action_click($event)"
                 v-on:move_to_dpa="move_to_dpa($event)"
                 v-on:tab_changed="tab_changed($event)">

      <template v-slot:append-tab>
        <v-tab>
          <span class="semibold--text">Tous</span>
        </v-tab>
      </template>
      <template v-slot:append-tab-item>
        <DoctorVMADashboardAdvancedSearchView :body="body" :data_tables_json="data_tables_json" v-on:move_to_dpa="move_to_dpa($event)" />
      </template>

      <template v-slot:header>
        <v-card-title>
          <PrPatientSearch ref="patient_search"
                         search_text="dashboard.filters.search_patient.filter"
                         :calendar_type="4"
                         calendar_text="Site de consultation"
                         :filter_list="['date', 'age', 'aptitude', 'rqth']"
                         v-on:show_inactive="show_inactive = $event"
          />
        </v-card-title>
      </template>
    </AnDashboard>
    <DialogSendEmail :dialog.sync="dialog_send_email" :ids_send_email="ids_send_email"/>
  </div>
</template>

<script>
import AnDashboard from "anam-library/src/components/an-dashboard/AnDashboard";
import BirthdateItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/BirthdateItem";
import DateTimeItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/DateTimeItem";
import NullBoleanItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/NullBoleanItem";
import SexItemLight from "anam-library/src/components/an-dashboard/an-dashboard-table-items/SexItemLight";
import DialogSendEmail from "anam-library/src/components/an-dialogs/DialogSendEmail";
import PrPatientSearch from "prevana-library/src/components/common/PrPatientSearch";
import VMAPlaceItem from "./items/VMAPlaceItem";
import DpaItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/DpaItem";
import VMAAmenagementItem from "@/components/doctor/vma/dashboard/items/VMAAmenagementItem.vue";
import DoctorVMADashboardAdvancedSearchView from "./DoctorVMADashboardAdvancedSearchView";
import {get_formatted_date} from "anam-library/src/js/dates";
import data_tables_json from "@/data/vma/dashboard_data_tables.json"
import moment from "moment-timezone";

export default {
  name: "DoctorVMADashboardView",
  components: {
    AnDashboard,
    DoctorVMADashboardAdvancedSearchView,
    DialogSendEmail,
    PrPatientSearch
  },
  data: () => ({
    data_tables_json: data_tables_json,
    show_inactive: false,
    body: {
      "dpa": DpaItem,
      "birthdate": BirthdateItem,
      "sex": SexItemLight,
      "next_rdv": DateTimeItem,
      "aptitude": VMAAmenagementItem,
      "last_visit": DateTimeItem,
      "place": VMAPlaceItem,
      "rqth": NullBoleanItem
    },
    actions: [
      {value: "send_email", text: "Envoyer un email"},
    ],
    dialog_send_email: false,
    ids_send_email: [],
  }),
  created() {
    this.$store.dispatch("axiosGetUserInfos", "vma");
  },
  methods: {
    move_to_dpa(item) {
      this.$router.push({name: "VMADoctorDpa", params: {id: item.patient_id}});
    },
    tab_changed(tab) {
      this.$router.push({name: 'VMADoctorDashboard', query: {tab: tab}});
    },
    manage_mass_action_click(data) {
      this.ids_send_email = [];
      if (data.action === "send_email") {
        for (let patient of data.selected) {
          this.ids_send_email.push(patient.user_id);
        }
        this.dialog_send_email = true;
      }
    },

    custom_filter_vma(list) {
      if (!this.$refs.patient_search) return list;
      if (this.$store.state.dashboard_filter.age.length) {
        list = list.filter(x => this.filter_by_age(moment.duration(moment().diff(x.birthdate)).years()));
      }
      if (this.$store.state.dashboard_filter.category) {
        list = list.filter(x => x.category === this.$store.state.dashboard_filter.category);
      }
      if (this.$store.state.dashboard_filter.date) {
        list = list.filter(x => this.filter_by_rdv(x.next_rdv));
      }
      if (this.$store.state.dashboard_filter.passed_consultation) {
        list = list.filter(x => x.next_rdv && moment(x.next_rdv, "YYYY-MM-DD HH:mm").isBefore(moment()));
      }
      if (this.$store.state.dashboard_filter.aptitude.length) {
        list = list.filter(x => this.$store.state.dashboard_filter.aptitude.includes(x.aptitude));
      }
      if (this.$store.state.dashboard_filter.rqth.length) {
        list = list.filter(x => this.$store.state.dashboard_filter.rqth.includes(x.rqth));
      }
      return list;
    },

    filter_by_age(age) {
      for (let filter of this.$store.state.dashboard_filter.age) {
        if (age >= filter[0] && age <= filter[1]) return true;
      }
      return false;
    },

    filter_by_rdv(date) {
      if (date === null) return false;
      let date_rdv = get_formatted_date(date, {input_format: "YYYY-MM-DD", output_format: "YYYY-MM-DD"});
      let date_filter = get_formatted_date(this.$store.state.dashboard_filter.date, {input_format: "DD/MM/YYYY", output_format: "YYYY-MM-DD"});
      return date_filter === date_rdv;
    },
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      document.title = "Tableau de bord - VMA - " + vm.$store.getters.getCurrentAgency.commercial_name;
    });
  },
  beforeRouteLeave: (to, from, next) => {
    document.title = "Prévana";
    next();
  },
}
</script>

<style>
.dashboard_vma tbody td:nth-child(9),
.dashboard_vma tbody td:nth-child(10) {
    background-color: var(--v-dashboard_highlight_column-base);
}

</style>
