import {theme_anamnese} from "anam-library/src/plugins/colors_themes";

const theme_patient = {
  theme: {
    themes: {
      light: {
        basic : '#1B2A49',
        score_green: '#00B24F',
        score_yellow: '#B1DD7D',
        score_orange: '#FDC202',
        score_red: '#E96B05',
        ...theme_anamnese.theme.themes.light
      }
    },
    options: {customProperties: true}
  },
};

const theme_doctor = theme_patient

const theme_hfmanager = theme_patient;

const theme_patient_ESCP = {
  theme: {
    themes: {
      light: {
        primary: '#240085',
        primary_2: '#240085',
        secondary: '#1B2A49',
        tertiary: '#74CEAA',
        soft_blue: '#240085',
        soft_red: '#E57373',
        soft_green: '#56AC8A',
        grey: '#F3F4F5',
        bg: '#ececec',
        bg_light: "#F7F7F7",
        bg_soft: "#EEF5F5",
        bg_grey: '#F0EFF4',
        error: '#F44336',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FFC107',
        pastel_green: '#81C784',
        score_green: '#7FC881',
        score_yellow: '#C7B400',
        score_orange: '#F9A825',
        score_red: '#E77271',
        bg_errors_layout: "#c5e1e3"
      }
    },
    options: {customProperties: true}
  },
};

const theme_doctor_ESCP = {
  theme: {
    themes: {
      light: {
        primary: '#240085',
        primary_2: '#240085',
        secondary: '#1B2A49',
        tertiary: '#b9b9b9',
        anchor: '#8c9eff',
        punchy: '#466B32',
        soft_green: '#56AC8A',
        soft_cyan: '#4db6ac',
        soft_red: '#E57373',
        soft_yellow: '#ffd54f',
        soft_blue: '#240085',
        pastel_green: '#81C784',
        bg: '#ececec',
        bg_light: "#F7F7F7",
        grey: '#F3F4F5',
        error: '#F44336',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FFC107',
        score_green: '#7FC881',
        score_yellow: '#C7B400',
        score_orange: '#F9A825',
        score_red: '#E77271',
        bg_errors_layout: "#c5e1e3",
        dashboard_highlight_column: "#eff3fd"
      },
    },
    options: {customProperties: true}
  },
};

export default {
  theme_doctor,
  theme_patient,
  theme_hfmanager,
  theme_patient_ESCP,
  theme_doctor_ESCP
}
