<template>
  <v-app>
    <DoctorHeader :navigation_buttons="navigation_buttons"/>
    <v-main class="bg">
      <v-container v-if="!loading" fluid>
        <router-view />
      </v-container>
    </v-main>
    <PrFooter app_name="prevanavaccination" />
  </v-app>
</template>

<script>
import DoctorHeader from "../common/DoctorHeader";
import PrFooter from "prevana-library/src/components/common/PrFooter";

export default {
  components: {
    DoctorHeader,
    PrFooter,
  },
  data() {
    return {
      loading: true,
      navigation_buttons: [
        {text: "Tableau de bord", icon: "mdi-view-dashboard", url: "/doctor/vaccination/dashboard", primary_action: true},
        {text: "Gestion des RDV", icon: "mdi-calendar", url: "/doctor/vaccination/appointments", primary_action: true},
        {text: "Statistiques", icon: "mdi-chart-bar", url: "/doctor/vaccination/statistics", primary_action: true},
        {text: "Paramètres", icon: "mdi-cog", url: "/doctor/vaccination/settings", primary_action: false},
      ],
    };
  },
  async created() {
    this.$store.state.is_mounted_app = false;
    await this.$http.post("/api/switch_app/prevanavaccination");
    this.loading = false;
    this.$store.state.is_mounted_app = true;
  },
};
</script>

<style>
</style>
