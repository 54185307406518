<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>

    <PatientAppointementComponent title_recap_place="Lieu du rendez-vous"
                                  title_recap_date="Date du rendez-vous"
                                  type_rdv="4"
                                  :redirection="get_redirection()"
                                  app_name="rps">
    </PatientAppointementComponent>
  </div>
</template>

<script>
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";
import PatientAppointementComponent from "@/components/patient/appointment/PatientAppointementComponent";

export default {
  name: "RPSAppointment",
  components: {PrBreadcrumbsComponent, PatientAppointementComponent},
  computed: {
    navigations() {
      if (this.$route.params.id) {
        return [
          {text: "Bien-Être", to: `/doctor/rps/patient/${this.$route.params.id}`},
          {text: "Prendre rendez-vous", to: `/doctor/rps/patient/${this.$route.params.id}/appointment`}
        ]
      }
      else {
        return [
          {text: "Bien-Être", to: '/patient/rps/dashboard'},
          {text: "Prendre rendez-vous", to: '/patient/rps/appointment'}
        ]
      }
    }
  },
  methods: {
    get_redirection() {
      if (this.$route.params.id) return {name: 'DoctorRPSDpa', params: {id: this.$route.params.id}};
      return {name: 'PatientRpsDashboard'};
    }
  }
}
</script>

