<template>
  <v-container fluid class="pa-6">
    <v-row class="mx-0">
      <v-col cols="12">
        <div class="d-flex">
          <img :src="logo" width="50" height="auto" class="rounded-lg mr-4" alt="Logo Visite Médicale"/>
          <span class="secondary--text bold--text font-size--24 align-self-center">{{$t("vma.visite_medicale")}}</span>
        </div>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import logo from "@/assets/picto-vma.png"
import colors_themes from "../../../plugins/colors_themes";

export default {
  name: "PatientVMALayout",
  data: () => ({
    logo: logo
  }),
  created() {
    if (Object.keys(colors_themes).includes(`theme_patient_${this.$store.getters.getAgencyName}`)) {
      this.$vuetify.theme.themes.light = colors_themes[`theme_patient_${this.$store.getters.getAgencyName}`]["theme"]["themes"]["light"];
    }
  },
};
</script>
