<template>
  <v-dialog v-model="_dialog" max-width="700">
    <v-card>
      <v-card-title class="primary--text">
        <v-icon color="primary" class="mr-4">mdi-help</v-icon>
        <span class="semibold--text font-size--20">Aide</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="_dialog=false" color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider inset></v-divider>
      <v-card-text>
        <v-row class="pt-10">
          <v-col cols="12" v-for="(item, index) in dico" :key="index">
            <span class="soft_blue--text font-weight-bold">{{ item.word }}</span>
            : {{ item.def }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "HelpDialogDecision",
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dico: [
        {
          word: "Surveillance",
          def: "Nécessite la surveillance d'un médecin",
        },
        {
          word: "Suivi",
          def: "Vigilance et suivi de la part d'un cadre de santé",
        },
        { word: "Autosuivi", def: "Pas de vigilance particulière" },
        { word: "Convalescence", def: "Ne présente plus de symptôme" },
      ],
    };
  },
  computed: {
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit("update:dialog", val);
      }
    }
  }
};
</script>

<style>
</style>
