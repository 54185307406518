export const LIST_SCORES_ECOS = [
    {text: "PHQ9", short_text: "PHQ9", name: "ecos_phq9", value: 0, q_a: []},
    {text: "GAD7", short_text: "GAD7", name: "ecos_gad7", value: 0, q_a: []},
    {text: "EVA Stress pré exercice", short_text: "EVA PRE", name: "eva_stress_pre", value: 0, q_a: []},
    {text: "EVA Stress post exercice", short_text: "EVA POST", name: "eva_stress_post", value: 0, q_a: []},
    {text: "Recommandation", short_text: "Reco", name: "ecos_reco_etudiant", value: 0, q_a: []},
]

export function get_score_name(name) {
  let index = LIST_SCORES_ECOS.findIndex(x => x.name === name);
  if (index > -1) return LIST_SCORES_ECOS[index].text;
  return name;
}

const DICT_PATIENT_DATA_ECOS = {
  items_marital_status: [
      {value: 1, text: "Célibataire, divorcé"},
      {value: 2, text: "Marié, Pacsé"},
      {value: 3, text: "J’ai un ou plusieurs enfants"},
      {value: 4, text: "J’attends mon premier enfant"},
      {value: 5, text: "Je n’ai pas d’enfant"},
  ],
  items_studies_funding: [
    {value: 1, text: "Je suis boursier"},
    {value: 2, text: "Je ne suis pas boursier"},
    {value: 3, text: "J’exerce un emploi régulier en plus de mes études moins de 10 heures par semaine"},
    {value: 4, text: "J’exerce un emploi régulier en plus de mes études plus de 10 heures par semaine"},
    {value: 5, text: "Je n’exerce pas un emploi régulier en plus de mes études"},
  ],
  items_travel_time: [
    {value: 1, text: "Moins de 30 min"},
    {value: 2, text: "Entre 30 min et 1 heure"},
    {value: 3, text: "Entre 1 heure et 2 heures"},
    {value: 4, text: "Plus de 2 heures"},
  ],
  items_stress: [
    {value: 1, text: "Pas du tout d’accord"},
    {value: 2, text: "Plutôt pas d’accord"},
    {value: 3, text: "Ni d’accord, ni pas d’accord"},
    {value: 4, text: "Plutôt d’accord"},
    {value: 5, text: "Tout à fait d’accord"},
  ],
  items_repeated_year: [
    {value: 1, text: "Oui"},
    {value: 2, text: "Non"},
  ],
  items_age: [
    {text: "Entre 18-25 ans", value: [18, 25]},
    {text: "Entre 26-30 ans", value: [26, 30]},
    {text: "Entre 31-35 ans", value: [31, 35]},
    {text: "Entre 36-40 ans", value: [36, 40]},
    {text: ">= 41 ans", value: [41, 150]},
  ]
}

export function get_patient_data_ecos(type) {
  if (Object.keys(DICT_PATIENT_DATA_ECOS).includes(type)) {
    return DICT_PATIENT_DATA_ECOS[type];
  }
  return [];
}

const DICT_THRESHOLD_SCORES = {
  "ecos_phq9": [4, 14],
  "ecos_gad7": [10, 15],
  "eva_stress_pre": [4, 6],
  "eva_stress_post": [4, 6],
  "ecos_reco_etudiant": [6, 8]
}

export function get_color_score(type, score) {
  if (score <= DICT_THRESHOLD_SCORES[type][0]) return type === "ecos_reco_etudiant" ? "red" : "green";
  else if (score <= DICT_THRESHOLD_SCORES[type][1]) return "orange";
  return type === "ecos_reco_etudiant" ? "green" : "red";
}

const QUESTIONNAIRE_NAMES = {
  "ecos-week1": {full_name: "Cohérence cardiaque - Séance 1", short_name: "CC-01"},
  "ecos-week2": {full_name: "Cohérence cardiaque - Séance 2", short_name: "CC-02"},
  "ecos-week3": {full_name: "Cohérence cardiaque - Séance 3", short_name: "CC-03"},
  "ecos-week4": {full_name: "Cohérence cardiaque - Séance 4", short_name: "CC-04"},
  "ecos-week5": {full_name: "Méditation pleine conscience - Séance 1", short_name: "MBCT-01"},
  "ecos-week6": {full_name: "Méditation pleine conscience - Séance 2", short_name: "MBCT-02"},
  "ecos-week7": {full_name: "Méditation pleine conscience - Séance 3", short_name: "MBCT-03"},
  "ecos-week8": {full_name: "Méditation pleine conscience - Séance 4", short_name: "MBCT-04"},
  "ecos-week9": {full_name: "Méditation pleine conscience - Séance 5", short_name: "MBCT-05"},
  "ecos-week10": {full_name: "Auto-hypnose - Séance 1", short_name: "AH-01"},
  "ecos-week11": {full_name: "Auto-hypnose - Séance 2", short_name: "AH-02"},
  "ecos-week12": {full_name: "Auto-hypnose - Séance 3", short_name: "AH-03"},
  //"ecos-week13": {full_name: "Auto-hypnose - Séance 4", short_name: "AH-04"},
  "ecos-week14": {full_name: "Auto-hypnose - Séance 4", short_name: "AH-04"},
  "ecos-week15": {full_name: "Yoga - Séance 1", short_name: "TOP-01"},
  "ecos-week16": {full_name: "Yoga - Séance 2", short_name: "TOP-02"},
  "ecos-week17": {full_name: "Yoga - Séance 3", short_name: "TOP-03"},
  "ecos-week18": {full_name: "Yoga - Séance 4", short_name: "TOP-04"},
  "ecos-week19": {full_name: "Fin de programme de gestion du stress", short_name: "FIN"},
}

export function get_questionnaire_names(questionnaire_type) {
  if (Object.keys(QUESTIONNAIRE_NAMES).includes(questionnaire_type)) return QUESTIONNAIRE_NAMES[questionnaire_type];
  return {full_name: questionnaire_type, short_name: questionnaire_type};
}
