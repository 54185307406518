<template>
  <AnSettings :component_map="component_map" :tabs="tabs"/>
</template>

<script>
  import AnSettings from "anam-library/src/components/an-settings/AnSettings";

  import AnSettingsInfos from "anam-library/src/components/an-settings/AnSettingsInfos";
  import AnSettingsNotifications from "anam-library/src/components/an-settings/AnSettingsNotifications";
  import SettingsHelp from "@/components/doctor/common/SettingsHelp";

  export default {
    name: "SettingsLayout",
    components: {
      AnSettings
    },

    computed: {
      is_rps_app() {
        return this.$route.path.includes("/rps/");
      },
      tabs() {
        let tabs = [{title: "Mes Paramètres", icon: "mdi-cog", tab: 0}];
        if (this.is_rps_app) tabs.push({title: "Alertes et Notifications", icon: "mdi-bell", tab: 1});
        tabs.push({title: "Aide", icon: "mdi-help-circle", tab: 2});
        return tabs;
      },

      component_map() {
        let map = [{
          name: AnSettingsInfos,
          child: {
            "Informations": [
              "AnSettingsUserCard",
              "AnSettingsPasswd",
            ],
          },
        }]
        if (this.is_rps_app) map.push({name: AnSettingsNotifications});
        map.push({name: SettingsHelp});
        return map;
      }
    }
  }
</script>
