<template>
  <PrCardComponent title="Mon évolution par domaine" icon="">
    <template v-slot:card-text>
      <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
      <v-row v-else>
        <v-col cols="12" sm="6">
          <PrEvolutionTab :questionnaires="questionnaires" />
        </v-col>
        <v-col cols="12" sm="6">
          <PrHistoryTab :questionnaires="questionnaires" :show_rdv="false" />
        </v-col>
      </v-row>
    </template>
  </PrCardComponent>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import PrEvolutionTab from "prevana-library/src/components/dpa/tabs/PrEvolutionTab";
import PrHistoryTab from "prevana-library/src/components/dpa/tabs/PrHistoryTab";

export default {
  name: "PatientRpsResultsCardEvolution",
  components: {PrCardComponent, PrEvolutionTab, PrHistoryTab},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    questionnaires: {
      type: Array,
      default: () => []
    }
  },
}
</script>

<style scoped>

</style>
