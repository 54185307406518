<template>
  <div>
    <div v-for="(authorisation, index) in data" :key="index">
      <p :class="`ma-0 medium--text`">{{get_questionnaire_name(authorisation.questionnaire_type)}}</p>
      <p class="ma-0 font-italic font-size--14">({{ authorisation.start_date | date }} - {{ authorisation.end_date | date }})</p>
    </div>
    <v-icon v-if="!data.length">mdi-minus</v-icon>
  </div>
</template>

<script>
import {mixin_dates} from "anam-library/src/js/dates";
import {get_questionnaire_name} from "anam-library/src/data/questionnaires";

export default {
  name: "EtudeAuthorisationItem",
  mixins: [mixin_dates],
  props: {
    item: {
      type: Object
    },
    variable: {
      type: String
    }
  },
  computed: {
    data() {
      return this.item[this.variable];
    }
  },
  methods: {
    get_questionnaire_name(questionnaire_type) {
      return get_questionnaire_name(questionnaire_type);
    }
  }
}
</script>

<style scoped>

</style>
