<template>
  <v-row class="mt-2">
    <v-col cols="1" class="py-0" offset="8">
      <v-btn fab dark elevation="2" :color="filter_selected ? 'red' : 'primary'" @click="show_filters = !show_filters">
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </v-col>

    <v-col cols="3" class="py-0">
      <v-card style="border-radius: 50px">
        <v-text-field rounded filled hide-details clearable prepend-inner-icon="mdi-magnify" label="Rechercher" v-model="$store.state.dashboard_filter.dashboard_search"/>
      </v-card>
    </v-col>

    <v-col cols="12" v-if="show_filters">
      <v-row class="white px-3 pb-3">
        <v-col cols="12" class="text-center">
          <v-btn text @click="reset_filter()" color="primary">
            <span class="font-weight-bold">Réinitialiser les filtres</span>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="4">
          <v-badge :value="_certified_military.length" color="red" left overlap style="width: 100%">
            <v-select v-model="_certified_military" :items="items_certified_military" multiple color="primary" filled item-color="primary" label="Militaire"/>
          </v-badge>
        </v-col>

        <v-col cols="4">
          <v-badge :value="_certified_military_diver.length" color="red" left overlap style="width: 100%">
            <v-select v-model="_certified_military_diver" :items="items_certified_military_diver" multiple color="primary" filled item-color="primary" label="Plongeur"/>
          </v-badge>
        </v-col>

        <v-col cols="4">
          <v-badge :value="_certified_older_18.length" color="red" left overlap style="width: 100%">
            <v-select v-model="_certified_older_18" :items="items_certified_older_18" multiple color="primary" filled item-color="primary" label="Majeur"/>
          </v-badge>
        </v-col>

        <v-col cols="4">
          <v-badge :value="_birth_year.length" color="red" left overlap style="width: 100%">
            <v-select v-model="_birth_year" :items="items_birth_year" multiple color="primary" item-color="primary" filled label="Age"/>
          </v-badge>
        </v-col>

        <v-col cols="4">
          <v-badge :value="_service.length" color="red" left overlap style="width: 100%">
            <v-select v-model="_service" :items="items_service" multiple color="primary" item-color="primary" filled label="Service"/>
          </v-badge>
        </v-col>

        <v-col cols="4">
          <v-badge :value="_diver_before_internship.length" color="red" left overlap style="width: 100%">
            <v-select v-model="_diver_before_internship" :items="items_diver_before_internship" multiple color="primary" item-color="primary" filled label="Plongée avant stage"/>
          </v-badge>
        </v-col>

        <v-col cols="4">
          <v-badge :value="_symptoms.length" color="red" left overlap style="width: 100%">
            <v-select v-model="_symptoms" :items="items_symptoms" multiple color="primary" item-color="primary" filled label="Symptômes"/>
          </v-badge>
        </v-col>

        <v-col cols="4">
          <v-switch v-model="show_inactive" @change="$emit('show_inactive', $event)" label="Afficher les utilisateurs désactivés" hide-details></v-switch>
        </v-col>

      </v-row>
    </v-col>

  </v-row>
</template>

<script>
import {get_patient_data_cephismer} from "../../../../data/etude/utils";

export default {
  name: "PatientSearch",
  props: {
    certified_military: {
      type: Array,
      default: () => []
    },
    certified_military_diver: {
      type: Array,
      default: () => []
    },
    certified_older_18: {
      type: Array,
      default: () => []
    },
    birth_year: {
      type: Array,
      default: () => []
    },
    marital_status: {
      type: Array,
      default: () => []
    },
    service: {
      type: Array,
      default: () => []
    },
    diver_before_internship: {
      type: Array,
      default: () => []
    },
    symptoms: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    show_filters: false,
    show_inactive : false,
    items_boolean: [
      {value: true, text: "Oui"},
      {value: false, text: "Non"},
    ]
  }),
  computed: {
    _certified_military: {
      get() {
        return this.certified_military;
      },
      set(value) {
        this.$emit("update:certified_military", value);
      },
    },
    _certified_military_diver: {
      get() {
        return this.certified_military_diver;
      },
      set(value) {
        this.$emit("update:certified_military_diver", value);
      },
    },
    _certified_older_18: {
      get() {
        return this.certified_older_18;
      },
      set(value) {
        this.$emit("update:certified_older_18", value);
      },
    },
    _birth_year: {
      get() {
        return this.birth_year;
      },
      set(value) {
        this.$emit("update:birth_year", value);
      },
    },
    _marital_status: {
      get() {
        return this.marital_status;
      },
      set(value) {
        this.$emit("update:marital_status", value);
      },
    },
    _service: {
      get() {
        return this.service;
      },
      set(value) {
        this.$emit("update:service", value);
      },
    },
    _diver_before_internship: {
      get() {
        return this.diver_before_internship;
      },
      set(value) {
        this.$emit("update:diver_before_internship", value);
      },
    },
    _symptoms: {
      get() {
        return this.symptoms;
      },
      set(value) {
        this.$emit("update:symptoms", value);
      },
    },
    filter_selected() {
      return this._certified_military.length
        || this._certified_military_diver.length
        || this._certified_older_18.length
        || this._birth_year.length
        || this._marital_status.length
        || this._service.length
        || this._diver_before_internship.length
        || this._symptoms.length
        || this.show_inactive
    },
    items_birth_year() {
      return get_patient_data_cephismer("items_birth_year");
    },
    items_certified_military() {
      return this.items_boolean;
    },
    items_certified_military_diver() {
      return this.items_boolean;
    },
    items_certified_older_18() {
      return this.items_boolean;
    },
    items_marital_status() {
      return get_patient_data_cephismer("items_marital_status");
    },
    items_service() {
      return get_patient_data_cephismer("items_service");
    },
    items_diver_before_internship() {
      return get_patient_data_cephismer("items_diver_before_internship");
    },
    items_symptoms() {
      return get_patient_data_cephismer("items_symptoms");
    }
  },
  methods: {
    reset_filter() {
      this._certified_military = [];
      this._certified_military_diver = [];
      this._certified_older_18 = [];
      this._birth_year = [];
      this._marital_status = [];
      this._service = [];
      this._diver_before_internship = [];
      this._symptoms = [];
      this.show_inactive = false;
    }
  }
}
</script>
