<template>
  <PrCardComponent :title="title" icon="mdi-tooltip-account">
    <template v-slot:card-text>
      <NoDataTxt v-if="contacts.length === 0" txt="Aucun contact n'a été renseigné"/>
      <v-row ref="contact_list" v-on:scroll="is_scrolled()">
        <v-col v-for="ind in nb_columns" cols="12" :sm="12 / nb_columns" :key="ind">
          <v-list>
            <v-list-item v-for="(item, key) in get_contacts(ind)" :key="key" class="px-0 contact_item">
              <v-list-item-icon class="align-self-start hidden-xs-only mr-2">
                <v-avatar>
                  <v-img v-if="item.avatar" :src="item.avatar" />
                  <v-icon v-else color="secondary">mdi-account</v-icon>
                </v-avatar>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="black--text semibold--text">{{ item.first_name }} {{ item.last_name }}</v-list-item-title>
                <v-list-item-subtitle class="primary--text normal--text" style="white-space: break-spaces">{{ $t(item.profession) }}</v-list-item-subtitle>
                <v-list-item-subtitle class="black--text normal--text" style="white-space: break-spaces"><a :href="`mailto:${item.email}`">{{ item.email }}</a></v-list-item-subtitle>
                <v-list-item-subtitle class="black--text normal--text">{{ item.phone }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <div v-if="has_scroll" class="text-center mt-2">
        <v-btn text @click="scroll_down()" color="primary" :disabled="!show_more_contacts">
          <span v-show="show_more_contacts" class="font-size--14 normal--text text-decoration-underline">Voir plus de contacts</span>
        </v-btn>
      </div>
    </template>
  </PrCardComponent>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import NoDataTxt from "@/components/patient/components/NoDataTxt";
import {add_scroll_if_multiple_elements} from "anam-library/src/js/utils";

export default {
  name: "PatientContactCardComponent",
  components: {NoDataTxt, PrCardComponent},
  data: () => ({
    has_scroll: false,
    nb_children_to_display: 3,
    show_more_contacts: true
  }),
  props: {
    contacts: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: "Mes contacts"
    },
    nb_columns: {
      type: Number,
      default: 1
    }
  },
  mounted() {
    this.has_scroll = add_scroll_if_multiple_elements(this.$refs.contact_list, "contact_item", this.contacts, this.nb_children_to_display);
  },
  methods: {
    is_scrolled() {
      this.show_more_contacts = (this.$refs.contact_list.offsetHeight / this.nb_children_to_display) * (this.contacts.length / this.nb_children_to_display) > this.$refs.contact_list.scrollTop;
    },
    scroll_down() {
      let height_element = this.$refs.contact_list.offsetHeight / this.nb_children_to_display;
      this.$refs.contact_list.scrollTop += height_element;
    },
    get_contacts(column_number) {
      let contacts = [];
      let display_per_columns = this.contacts.length / this.nb_columns;
      for (let index in this.contacts) {
        if (index >= display_per_columns * (column_number-1) && index < column_number * display_per_columns) contacts.push(this.contacts[index]);
      }
      return contacts;
    }
  }
}
</script>

<style scoped>

</style>
