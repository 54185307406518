<template>
  <PatientFAQCardComponent>
    <template v-slot:panels>
      <v-expansion-panels v-model="panel" hover flat multiple>
        <v-expansion-panel class="bg_light my-3">
          <v-expansion-panel-header>
            <span class="primary--text semibold--text">Protection des données</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="soft_blue--text">
            <p class="medium--text">
              Nous prenons la sécurité des données personnelles très au sérieux, que ce soit des données de santé ou
              des données personnelles. A ce titre, Anamnèse s’engage à assurer le meilleur niveau de protection de
              vos données en respectant la réglementation française et européenne relative à la protection des
              données personnelles. (la loi Informatique et Libertés LIL du 6 janvier 1978, la loi n ° 2018- 493 du
              20 juin 2018 et le Règlement général sur la protection des données personnelles du 27 avril 2016
              (par la suite RGPD)).
            </p>
            <p class="medium--text">
              Vos données sont hébergées sur la plateforme PREVANA qui est une suite logicielle de prévention en
              entreprise en SaaS. Elles sont protégées par le secret médical sur des serveurs sécurisés qui sont
              accessibles uniquement par des professionnels de santé habilités.
            </p>
            <a :href="get_politique_confidentialite_url('prevana', 'covidhelp')" target="_blank">En savoir plus</a>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </PatientFAQCardComponent>
</template>

<script>
import PatientFAQCardComponent from "@/components/patient/components/faq/PatientFAQCardComponent";

import {mixin_cgu} from "anam-library/src/js/utils";

export default {
  name: "CovidDashboardCardFAQ",
  components: {PatientFAQCardComponent},
  mixins: [mixin_cgu],
  props: {
    see_details: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    panel: [],
  })
}
</script>

<style scoped>
.v-expansion-panel{
  border-radius: 10px;
}
</style>
