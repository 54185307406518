<template>
<PatientFAQCardComponent>
  <template v-slot:panels>
    <v-expansion-panels v-model="panel" hover flat multiple>
      <v-expansion-panel class="bg_light my-3">
        <v-expansion-panel-header>
          <span class="primary--text semibold--text">La vaccination est-elle sans risque ?</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="soft_blue--text">
          <p class="medium--text">
            Le vaccin est un produit pharmaceutique soumis à un contrôle strict avant sa mise sur le marché.
            Dans le cadre des vaccins contre la Covid19 utilisés en France, ils ont été préalablement autorisés par
            l’Union Européenne puis en France par l’ANSM (L’Agence Nationale de Sécurité du Médicament).
            La HAS (Haute Autorité de Santé) définit quant à elle la stratégie de vaccination. Néanmoins, comme
            la majorité des produits pharmaceutiques, des effets indésirables ou secondaires ont été recensés durant
            la phase de tests cliniques. Ils ont été étudiés par les autorités compétentes avant d’autoriser la mise
            sur le marché du vaccin en Europe puis en France.
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="bg_light my-3">
        <v-expansion-panel-header>
          <span class="primary--text semibold--text">En quoi consiste la vaccination ?</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="soft_blue--text">
          <p class="medium--text">
            La vaccination consiste à faire une injection au niveau du bras ou de la cuisse (contenant un virus
            inactivé, une partie du virus, un adénovirus ou un ARN messager) pour simuler une contamination.
            L’organisme va alors produire des anticorps, qui vont combattre spécifiquement le virus pour lequel le
            vaccin a été administré et se familiariser avec ce virus. Ainsi, si une personne vaccinée est en contact
            avec le virus contre lequel elle a été vaccinée, son organisme va reconnaître ce virus et répondre
            rapidement en reproduisant les anticorps déjà produits lors de la vaccination. Le virus sera alors
            neutralisé avant même que la personne vaccinée ne tombe malade.
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="bg_light my-3">
        <v-expansion-panel-header>
          <span class="primary--text semibold--text">Combien d’injections sont nécessaires pour la vaccination contre la Covid19 ?</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="soft_blue--text">
          <p class="medium--text">
            La majorité des vaccins autorisés dans le cadre de cette campagne de vaccination (vaccin du laboratoire
            Pfizer-BioNTech, Moderna et AstraZeneca) nécessitent 2 injections à un intervalle de 21 jours à 6 semaines
            et dorénavant une 3ème dose de rappel 6 mois après la dernière injection (pour une partie de la population).
            <br/><br/> - Une première injection est injectée le jour J0.
            <br/> - Une seconde injection est injectée 21 à 84 jours (selon le vaccin administré).
            <br/> - Une dose de rappel à 3 mois après la 2ème injection (pour une partie de la population).
            <br/><br/>
            Le vaccin du laboratoire Johnson & Johnson ne nécessite quant à lui qu’une seule injection.
            Cependant, depuis le 01/09/2021, une 2ème injection d’un vaccin à ARN messager (au moins 4
            semaines après la 1ère injection) est nécessaire.
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </template>
</PatientFAQCardComponent>
</template>

<script>
import PatientFAQCardComponent from "@/components/patient/components/faq/PatientFAQCardComponent";

export default {
  name: "VaccinationDashboardCardFAQ",
  components: {PatientFAQCardComponent},
  data: () => ({
    panel: []
  })
}
</script>

<style scoped>

</style>
