<template>
  <div>
    <v-chip v-if="typeof value === 'number' && threshold_value && value >= threshold_value" class="red black--text font-weight-bold">{{ value }}</v-chip>
    <span v-else-if="typeof value === 'number' && value >= 0" :class="get_color_text(value)">{{ value }}</span>
    <v-icon v-else small>mdi-minus</v-icon>
  </div>
</template>

<script>
import mixin_tabs from "prevana-library/src/js/mixin_tabs";

export default {
  name: "ScoreRPSItem",
  props: ["item", "variable"],
  mixins: [mixin_tabs],

  computed: {
    value() {
      return this.get_value_to_display(this.item, this.variable);
    },
    threshold_value() {
      let pro = this.$store.getters.getUserInfos("professionnel");
      if (pro && pro.threshold_alert_for_scores) return pro.threshold_alert_for_scores;
      return null;
    }
  },

  methods: {
    get_value_to_display(item, variable) {
      return item[variable];
    }
  }
};
</script>
