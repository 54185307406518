<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>
    <PrCardComponent :title="$t('vma.results_1')" icon="">
      <template v-slot:card-text>
        <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>

        <NoDataTxt v-else-if="!questionnaire" :txt="$t('vma.results_2')"/>

        <AnRestitutionQuestionsAnswers v-else
                                       :panel="0"
                                       :title="title_questionnaire"
                                       :questions="questionnaire.questions_answers"
                                       :dense="true"
                                       :show_input_free_pre_text="false"
        />
      </template>
    </PrCardComponent>
  </div>
</template>

<script>
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import AnRestitutionQuestionsAnswers from "anam-library/src/components/an-questionnaire/AnRestitutionQuestionsAnswers";

import {mixin_dates} from "anam-library/src/js/dates";
import NoDataTxt from "@/components/patient/components/NoDataTxt";

export default {
  name: "PatientVmaResultsView",
  mixins: [mixin_dates],
  components: {
    NoDataTxt,
    PrCardComponent,
    PrBreadcrumbsComponent,
    AnRestitutionQuestionsAnswers
  },
  data() {
    return {
      navigations: [
        {
          text: this.$i18n.t('vma.visite_medicale'),
          to: '/patient/vma/dashboard',
        },
        {
          text: this.$i18n.t('vma.details_resultats'),
          to: '/patient/vma/details-results',
        },
      ],
      loading: false,
      questionnaire: {}
    }
  },
  computed: {
    title_questionnaire() {
      return `Complété le ${this.$options.filters.datetime_full_a_fr(this.questionnaire.creation_date)}`;
    },
  },
  mounted() {
    this.get_questionnaire();
  },
  methods: {
    get_questionnaire() {
      this.loading = true;
      this.$http.get(`/api/patients/0/questionnaires_vma`).then(response => {
        this.questionnaire = response.data.filter(x => x.questionnaire_is_completed && x.is_current_school_year)[0];
      }).finally(() => {
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>
