<template>
  <PrCardComponent title="Mes actions" icon="mdi-clipboard-text">
    <template v-slot:card-text>
      <v-list class="pt-0">
        <v-progress-linear v-if="_has_access_to_rps && loading_rps" color="secondary" indeterminate class="my-3"></v-progress-linear>
        <DashboardBlocActionsListItem v-if="_has_access_to_rps && !loading_rps"
                                      title="Bien-Être"
                                      icon="$vuetify.icons.rpsiconsecondary"
                                      :subtitle="text_rps">
          <template v-slot:btn_action>
            <v-btn v-if="rdv_rps" color="secondary" elevation="0" @click="$router.push({name: 'PatientRDV'})">
              <span class="medium--text font-size--12">Voir mes rendez-vous</span>
            </v-btn>
            <v-btn v-else-if="!questionnaire_rps" color="secondary" elevation="0" @click="start_questionnaire_rps()">
              <span class="medium--text font-size--12">Répondre au questionnaire</span>
            </v-btn>
            <v-btn v-else-if="!questionnaire_rps.questionnaire_is_completed" color="secondary" elevation="0" @click="continue_questionnaire_rps()">
              <span class="medium--text font-size--12">Continuer mon questionnaire</span>
            </v-btn>
            <v-btn v-else-if="is_eligible_rps" color="secondary" elevation="0" @click="$router.push({name: 'PatientRPSAppointement'})">
              <span class="medium--text font-size--12">Prendre rendez-vous</span>
            </v-btn>
            <v-btn v-else color="secondary" elevation="0" @click="$router.push({name: 'PatientRpsResults'})">
              <span class="medium--text font-size--12">Voir mes résultats</span>
            </v-btn>
          </template>
        </DashboardBlocActionsListItem>

        <v-divider></v-divider>

        <v-progress-linear v-if="_has_access_to_vma && loading_vma" color="secondary" indeterminate class="my-3"></v-progress-linear>
        <DashboardBlocActionsListItem v-if="_has_access_to_vma && !loading_vma"
                                      title="Visite médicale"
                                      icon="$vuetify.icons.vmaiconsecondary"
                                      :subtitle="text_vma">
          <template v-slot:btn_action>
            <v-btn v-if="!rdv_vma.length" color="secondary" elevation="0" @click="$router.push({name: 'PatientRDV'})">
              <span class="medium--text font-size--12">Voir mes rendez-vous</span>
            </v-btn>
            <v-btn v-else-if="has_questionnaire_vma" color="secondary" elevation="0" @click="dialog_rdv_vma = true">
              <span class="medium--text font-size--12">Prendre rendez-vous</span>
            </v-btn>
            <v-btn v-else color="secondary" elevation="0" @click="go_to_questionnaire_vma()">
              <span class="medium--text font-size--12">Répondre au questionnaire</span>
            </v-btn>
          </template>
        </DashboardBlocActionsListItem>

        <v-divider></v-divider>

        <DashboardBlocActionsListItem v-if="_has_access_to_covidapp"
                                      title="Suivi Covid"
                                      icon="$vuetify.icons.covidiconsecondary"
                                      subtitle="Répondez à votre questionnaire de suivi Covid.">
          <template v-slot:btn_action>
            <TooltipMissingInfo v-if="missing_info" />
            <CovidappButtonStartQuestionnaire v-else />
          </template>
        </DashboardBlocActionsListItem>

        <v-divider></v-divider>

        <v-progress-linear v-if="_has_access_to_vaccination && loading_vaccination" color="secondary" indeterminate class="my-3"></v-progress-linear>
        <DashboardBlocActionsListItem v-if="_has_access_to_vaccination && !loading_vaccination"
                                      title="Vaccination"
                                      icon="$vuetify.icons.vaccinationiconsecondary"
                                      :subtitle="text_vaccination">
          <template v-slot:btn_action>
            <v-btn v-if="rdv_vaccination.length" color="secondary" elevation="0" @click="$router.push({name: 'PatientRDV'})">
              <span class="medium--text font-size--12">Voir mes rendez-vous</span>
            </v-btn>
            <v-btn v-else-if="!questionnaire_vaccination" color="secondary" elevation="0" @click="vaccination_click()">
              <span class="medium--text font-size--12">Répondre au questionnaire</span>
            </v-btn>
            <v-btn v-else-if="is_eligible_vaccination" color="secondary" elevation="0" @click="$router.push({name: 'PatientVaccinationAppointement'})">
              <span class="medium--text font-size--12">Prendre rendez-vous</span>
            </v-btn>
            <v-btn v-else color="secondary" elevation="0" @click="vaccination_click()">
              <span class="medium--text font-size--12">Modifier mes réponses</span>
            </v-btn>
            <div :class="`${$vuetify.breakpoint.xs ? 'mt-3' : 'd-flex justify-end'}`" v-if="has_access_suivi_vaccination">
              <v-btn color="secondary" elevation="0" class="mt-3" @click="vaccination_suivi_click()">
                <span class="medium--text font-size--12">Suivi post vaccinal</span>
              </v-btn>
            </div>
          </template>
        </DashboardBlocActionsListItem>

      </v-list>
      <VmaDialogRdv :dialog.sync="dialog_rdv_vma" :consultations="rdv_vma"/>
    </template>
  </PrCardComponent>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import DashboardBlocActionsListItem from "@/components/patient/dashboard/actions/DashboardBlocActionsListItem";
import CovidappButtonStartQuestionnaire from "@/components/patient/covidhelp/components/CovidappButtonStartQuestionnaire";
import TooltipMissingInfo from "../../components/TooltipMissingInfo";
import VmaDialogRdv from "../../vma/dashboard/bilan/VmaDialogRdv";
import {get_formatted_date} from "anam-library/src/js/dates";
import axios from "axios";
import {mixin_vaccination} from "../../../../data/mixin_vaccination";


export default {
  name: "DashboardBlocActions",
  mixins: [mixin_vaccination],
  components: {
    PrCardComponent,
    DashboardBlocActionsListItem,
    CovidappButtonStartQuestionnaire,
    TooltipMissingInfo,
    VmaDialogRdv
  },
  props: {
    rdv: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    loading_rps: false,
    questionnaire_rps: null,
    rdv_rps: null,
    rdv_rps_date: null,
    is_eligible_rps: false,
    loading_vma: false,
    has_questionnaire_vma: false,
    dialog_rdv_vma: false,
    loading_vaccination: false,
    questionnaire_vaccination: null,
    is_eligible_vaccination: false,
    has_access_suivi_vaccination: false
  }),
  computed: {
    _has_access_to_rps: function () {
      return this.$store.getters.getPatientHasAccessRps;
    },
    _has_access_to_vaccination: function () {
      return this.$store.getters.getPatientHasAccessVaccination;
    },
    _has_access_to_covidapp: function () {
      return this.$store.getters.getPatientHasAccessCovidapp;
    },
    _has_access_to_vma: function () {
      return this.$store.getters.getPatientHasAccessVma;
    },
    missing_info() {
      return this.$store.getters.checkMissingInfoCovid;
    },
    text_rps() {
      if (this.rdv_rps) return `Vous avez un rendez-vous prévu le ${this.rdv_rps_date}.`;
      else if (!this.questionnaire_rps) return "Bienvenue sur Bien-Être, répondez au questionnaire pour connaître vos résultats.";
      else if (!this.questionnaire_rps.questionnaire_is_completed) return "Bienvenue sur Bien-Être, terminez votre questionnaire pour connaître vos résultats.";
      else if (this.is_eligible_rps) return "Vous êtes autorisé à prendre un rendez-vous.";
      return "Vos résultats d’évaluation sont désormais disponibles.";
    },
    rdv_vma() {
      return this.rdv.filter(x => x.app_name === "vma");
    },
    text_vma() {
      if (this.rdv_vma.length) return `Vous avez un rendez-vous prévu le ${get_formatted_date(this.rdv_vma[0].slot.start_slot, {output_format: "dddd DD MMM YYYY à HH:mm"}, true)}.`;
      if (this.has_questionnaire_vma) return "Vous êtes autorisé à prendre un rendez-vous.";
      return "Répondez à votre questionnaire pré-consultation pour préparer votre visite.";
    },
    rdv_vaccination() {
      return this.rdv.filter(x => x.app_name === "prevanavaccination");
    },
    text_vaccination() {
      if (this.rdv_vaccination.length) return `Vous avez un rendez-vous prévu le ${get_formatted_date(this.rdv_vaccination[0].slot.start_slot, {output_format: "dddd DD MMM YYYY à HH:mm"}, true)}.`;
      else if (this.is_eligible_vaccination) return "Vous êtes autorisés à prendre un rendez-vous.";
      else if (!this.questionnaire_vaccination) return "Prière de répondre au questionnaire suivant pour pouvoir prendre un rendez-vous.";
      return "Votre situation a changé ?";
    }
  },
  mounted() {
    if (this._has_access_to_rps) {
      this.get_data_rps();
    }
    if (this._has_access_to_vma) {
      this.get_data_vma();
    }
    if (this._has_access_to_vaccination) {
      this.get_data_vaccination();
    }
  },
  methods: {
    get_data_rps() {
      this.loading_rps = true;
      this.$http.get(`/api/patients/${this.$store.getters.getPatientId}/questionnaires_rps`).then(response => {
        if (response.data.length > 0) this.questionnaire_rps = response.data[0];
        this.$http.get("/api/rps_appointment_info/0").then(response_ => {
          if (response_.data.has_appointment) {
            this.rdv_rps = response_.data.id;
            this.rdv_rps_date = response_.data.date;
          }
          this.is_eligible_rps = response_.data.eligible_to_consultation;
        }).finally(() => {
          this.loading_rps = false;
        })
      }).catch(() => {
        this.loading_rps = false;
      })
    },

    get_data_vma() {
      this.loading_vma = true;
      this.$http.get(`/api/patients/0/questionnaires_vma`).then(response => {
        this.has_questionnaire_vma = response.data.filter(x => x.questionnaire_is_completed && x.is_current_school_year).length;
      }).finally(() => {
        this.loading_vma = false;
      })
    },

    get_data_vaccination() {
      this.loading_vaccination = true;
      this.$http.get("/api/vaccination_appointment_info").then(response => {
        this.response_api = response.data;
        this.is_eligible_vaccination = response.data.is_eligible;
        let questionnaires = response.data.questionnaires.filter(x => x.questionnaire_is_completed);
        if (questionnaires.length) this.questionnaire_vaccination = questionnaires[questionnaires.length - 1];
        axios.get("/api/has_access_to_suivi").then(response_ => {
          this.has_access_suivi_vaccination = true;
          if (response_.data.latest_questionnaire) {
            this.latest_suivi_questionnaire = response_.data.latest_questionnaire;
          }
        }).catch(() => {
          this.has_access_suivi_vaccination = false;
        }).finally(() => {
          this.loading_vaccination = false;
        })
      }).catch(() => {
        this.loading_vaccination = false;
      })
    },

    go_to_questionnaire_vma() {
      this.$router.push({
        name: "QuestionnaireView",
        query: {
          questionnaire_type: "vma-escp",
          app_name: "vma",
          patient_id: "0",
          nb_question: 5,
          redirection_end_anam: "/patient/vma/dashboard"
        }
      });
    },
    start_questionnaire_rps() {
      this.$router.push({name: 'PatientRPSStartQuestionnaire'});
    },
    continue_questionnaire_rps() {
      this.$router.push({
        name: "PatientRPSQuestionnaire",
        params: {questionnaire_uid: this.questionnaire_rps.uid, patient_id: 0},
        query: {redirection: "continue"}
      });
    }
  }
}
</script>

<style scoped>

</style>
