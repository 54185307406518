<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>
    <v-row class="mx-0 justify-center">
      <v-col cols="12" sm="8" class="mt-12">
        <PrCardComponent title="Évaluation, consignes et prochaines étapes" icon="mdi-clipboard-text">
          <template v-slot:card-text>
            <v-progress-linear v-if="loading_indications" class="my-5" indeterminate color="primary"></v-progress-linear>
            <div v-else-if="suspicious_depistage">
              <p class="black--text normal--text font-size--16 mt-6 mb-0">
                Merci
                <span class="semibold--text font-size--16 primary--text">{{patient_full_name}}</span>
                d'avoir répondu au  questionnaire !</p>
              <p class="black--text normal--text font-size--16">
                Durant ce questionnaire, vous avez indiqué
                <span class="semibold--text font-size--16 primary--text">{{number_indications}}</span>
                éléments qui méritent notre attention :
              </p>
              <ul class="pl-12 pt-2">
                <li v-for="(item, i) in list_indications" :key="i" class="black--text font-size--14 normal--text">{{item}}</li>
              </ul>
            </div>
            <div v-else>
              <p class="black--text normal--text font-size--16 mt-6 mb-0">
                Merci
                <span class="semibold--text font-size--16 primary--text">{{patient_full_name}}</span>
                d'avoir répondu au  questionnaire !</p>
              <p class="black--text normal--text font-size--16">
                Suite à  ce questionnaire, votre état est stationnaire,
                <span class="semibold--text font-size--16 primary--text">votre situation générale et vos symptômes ne présentent pas de risques apparents</span>.
              </p>
            </div>

            <v-alert v-if="!loading_indications" text color="primary" class="text-center font-size--14">
              <div v-if="priorisation===3">
                <v-icon small color="secondary" class="mr-2">mdi-information</v-icon>
                <span class="red--text font-weight-bold">ATTENTION !</span>
                Votre état de santé actuel présente des signes de gravité.
                <br><br>
                Composez le 18 (Secours) ou le 15 (Samu) ou le 112 ou le 114 (difficultés à entendre ou à parler) depuis votre téléphone, immédiatement pour obtenir du secours.
              </div>
              <div v-else>
                <v-icon color="primary" small>mdi-information</v-icon>
                Merci pour vos réponses qui seront analysées par le SSSM du <span class="semibold--text font-size--14">{{ agency_name }}</span>.
              </div>
              <p v-if="!suspicious_depistage" class="mb-0 pb-4 font-size--14">
                Maintenez votre vigilance sur les mesures barrières à votre domicile ou dans le cadre de vos activités au sein du {{ agency_name }}.
              </p>
              <p v-else class="mb-0 pb-4 font-size--14">
                Un infirmier ou un Médecin du SSSM est susceptible de vous recontacter si vous ne faites pas déjà l’objet d'un suivi personnalisé.
              </p>
            </v-alert>
            <div class="my-8 text-center">
              <v-btn color="secondary" elevation="0" class="px-8" @click="$router.push({name: 'PatientCovidDashboard'})">
                Retour à l'accueil
              </v-btn>
            </div>
          </template>
        </PrCardComponent>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";

export default {
  name: "PatientCovidEndQuestionnaireView",
  components: {PrCardComponent, PrBreadcrumbsComponent},
  data: function () {
    return {
      navigations: [
        {text: "Suivi Covid", to: '/patient/covidhelp/dashboard'},
        {text: "Questionnaire", to: `/patient/covidhelp/end-questionnaire/${this.$route.params.questionnaire_uid}`}
      ],
      list_indications: [],
      patient_full_name: null,
      number_indications: null,
      loading_indications: false,
      suspicious_depistage: null,
      priorisation: null,
      agency_name: ""
    }
  },
  mounted: function () {
    this.loading_indications = true;
    this.$http.get(`/api/ma/get_info_end_page/${this.$route.params.questionnaire_uid}`).then(response => {
      this.agency_name = response.data.agency_name;
      this.list_indications = response.data.indications;
      this.patient_full_name = response.data.infos_patient.firstname;
      this.suspicious_depistage = response.data.suspicious_depistage;
      this.priorisation = response.data.priorisation;
      this.number_indications = this.list_indications.length;
    }).finally(() => {
      this.loading_indications = false
    });

    this.$http.get("/api/prevana_related_data").then(response => {
      this.$store.commit("setPatientPrevanaData", response.data);
    })
  },
}
</script>
