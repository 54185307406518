export const LIST_VACCINE_CHOICES = [
  {value: "BNT162b2", text: "COMIRNATY (Pfizer-BioNTech)"},
  {value: "mRNA-1273", text: "MODERNA (Moderna)"},
  {value: "AZD1222", text: "VAXZEVRIA (AstraZeneca)"},
  {value: "Ad26COV2.S", text: "JANSSEN (Johnson & Johnson)"},
  {value: "Nuvaxovid", text: "NUVAXOVID (Novavax)"},
  {value: "Covid+", text: "COVID (Infection confirmée Covid+)"},
  {value: "Unknown", text: "INCONNU (Vaccin indéterminé)"},
];

export const LIST_INJECTION_CHOICES = [
  {value: 1, text: "Deltoïde Gauche (bras)"},
  {value: 2, text: "Deltoïde Droit (bras)"},
  {value: 3, text: "Vaste externe Gauche (jambe)"},
  {value: 4, text: "Vaste externe Droit (jambe)"},
];

export const LIST_INJECTION_CHOICES_PATIENT = [
  {value: 1, text: "Bras Gauche"},
  {value: 2, text: "Bras Droit"},
  {value: 3, text: "Jambe Gauche"},
  {value: 4, text: "Jambe Droite"},
];
