<template>
<v-tooltip bottom>
  <template v-slot:activator="{ on }">
    <v-btn v-on="on" elevation="0" class="align-self-center" @click="$router.push({name: 'PatientSettings'})">
      <span class="medium--text font-size--12">Répondre au questionnaire</span>
    </v-btn>
  </template>
  <span>Certaines informations nécessaires ne sont pas renseignées, veuillez compléter votre profil</span>
</v-tooltip>
</template>

<script>
export default {
  name: "TooltipMissingInfo",
}
</script>

<style scoped>

</style>
