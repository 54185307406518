<template>
  <div>
    <v-container class="bg_soft pb-0" fluid>
      <v-row>
        <v-col cols="12" sm="8" md="6" class="pb-0 pl-sm-10">
          <p class="primary--text bold--text font-size--30 mb-0">Prévana</p>
          <p class="soft_blue--text bold--text font-size--30 mb-0">Le portail de télésuivi et de prévention</p>
          <p class="primary--text medium--text font-size--18">{{ current_agency.commercial_name }} par Anamnèse</p>
          <p class="normal--text soft_blue--text mb-8">
            Il se présente sous la forme d’un téléquestionnaire que vous remplirez afin de déterminer si vous
            remplissez les critères pour vous faire vacciner.
            <br />
            L'ensemble des données traitées sur cette plateforme sont hébergées dans un Hébergeur certifié HDS
            (Hébergeur de Données de Santé) localisé en France.
            <br />
            L'accès aux données est sécurisé et seuls des professionnels de santé ont accès à vos données de
            santé. Ces données permettront aux professionnels de santé de personnaliser le suivi de votre état de santé.
          </p>
          <v-btn color="primary" class="white--text px-sm-6" elevation="0" @click="goTo('VaccinationConsentementView')">
            <span class="medium--text">Prendre RDV pour me faire vacciner</span>
          </v-btn>
          <p class="soft_blue--text medium--text font-size--12 mt-4">
            Vous avez déjà pris RDV ou vous avez déjà un compte ?
            <a class="text-decoration-underline primary--text semibold--text" @click="$emit('connexion')">Cliquez ici</a>
          </p>
        </v-col>
        <v-col cols="12" sm="4" md="6" class="text-center pb-0 align-self-center">
          <img class="illustration_vaccination" src="@/assets/vaccination/illustration_vaccination.png"/>
        </v-col>
      </v-row>
    </v-container>
    <img src="@/assets/bg1.png" width="100%">
    <v-container class="py-6">
      <p class="text-center soft_blue--text">
        Si vous ne trouvez pas la solution à votre problème contactez notre support de Niveau 1 par email :
        <a class="text-decoration-underline blue--text semibold--text" :href="'mailto:'+ current_agency.support_link">{{ current_agency.support_link }}</a>
      </p>
    </v-container>
  </div>

</template>

<script>
export default {
  name: "VaccinationHomeView",
  created() {
    this.$http.get('/api/deconnexion');
    this.$http.get(`/api/agency_data/${this.$route.params.agency_name}`).then(response => {
      this.$store.commit("setCurrentAgency", response.data);
    })
  },
  mounted() {
    this.$vuetify.goTo(0);
  },
  computed: {
    current_agency() {
      return this.$store.getters.getCurrentAgency;
    }
  }
}
</script>

<style scoped>
  img.illustration_vaccination{
    height: 350px;
  }

  @media (max-width: 768px) {
    img.illustration_vaccination{
      height: 200px;
    }
  }
</style>
