<template>
  <v-dialog v-model="_dialog_history_evenements" width="500">
    <v-card>
      <v-card-title
        class="primary--text semibold--text" style="position: relative">
        Historique des Évenements de l'Agent
        <v-spacer></v-spacer>
        <v-btn @click="_dialog_history_evenements = false" icon color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8">
        <v-timeline align-top dense v-if="evenements_history.length > 0">
          <v-timeline-item
            v-for="(item, index) in evenements_history"
            :key="index"
            color="primary"
            small
          >
            <v-row class="pt-1">
              <v-col cols="5">
                <strong>{{ item.appearance_date | moment_evenements }}</strong>
              </v-col>
              <v-col cols="7">
                <strong>{{ item.name | name_evenements }}</strong>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
        <p v-else class="ml-2">Aucun évenement renseigné</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  props: {
    dialog_history_evenements: {
      type: Boolean,
    },
  },
  computed: {
    _dialog_history_evenements: {
      get() {
        return this.dialog_history_evenements;
      },
      set(value) {
        this.$emit("update:dialog_history_evenements", value);
      },
    },
    evenements_history() {
      return this.$store.getters.getEventsHistory;
    }
  },
  filters: {
    moment_evenements: function (date) {
      return moment(date).locale("fr").format("DD MMMM YYYY");
    },
    name_evenements: function (event) {
      switch (event) {
        case "contact":
          return "Contact";
        case "isolement":
          return "Possible / Isolement";
        case "premierssymptomes":
          return  "Premiers symptômes";
        case "confirme":
          return  "Confirmé";
        case "gueri":
          return  "Guéri";
        case "endquarantaine":
          return  "Sortie d'isolement";
        case "hospitalisation":
          return  "Hospitalisé";
        case "arrettravail":
          return  "Arrêt de Travail";
        case "rappel_todo":
          return  "Rappel TODO";
        case "vaccination":
          return  "Vaccination";
        default:
          return event;
      }
    },
  },
};
</script>
