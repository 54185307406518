<template>
  <v-dialog v-model="_dialog" width="500">
    <v-card style="border-radius: 15px;">
      <v-card-title class="primary" style="height: 80px;">
        <span class="white--text normal--text font-size--25">Connexion</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="_dialog=false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0 pa-sm-4">
        <AnAuth :basic_auth="false" :flat="true" :agency_name="$route.params.agency_name" app_name="covidapp" card_class="" v-on:connected="redirect($event)"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import AnAuth from "anam-library/src/components/an-auth/AnAuth";

  export default {
    name: "DialogConnexion",
    components: {
      AnAuth
    },
    props: {
      dialog: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      _dialog: {
        get: function() {
          return this.dialog
        },
        set: function(value) {
          if(!value) this.$emit('update:dialog', value)
        }
      }
    },
    methods: {
      redirect: function(data) {
        this.$store.commit('ResetSnackBar');
        this.$router.push({name: "RedirectionView", query: data});
      }
    }
  }
</script>

<style scoped>

</style>
