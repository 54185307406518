<template>
  <v-card flat :class="(selected ? 'soft_blue' : 'bg_grey') + ' my-2'">
    <v-card-text class="py-5">
      <v-radio :value="value" color="white">
        <template v-slot:label>
          <span :class="(selected ? 'white--text' : 'soft_blue--text') + ' medium--text font-size--18 pl-4'">
            <span class="bold--text font-size--18">{{ content }}</span>
            {{ txt_optionnel }}
          </span>
        </template>
      </v-radio>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: "ConsentementItem",
    props: {
      value: {
        type: Number
      },
      content: {
        type: String
      },
      txt_optionnel: {
        type: String,
        default: null
      },
      selected: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style scoped>

</style>
