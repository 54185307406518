<template>
  <v-container>
    <v-tabs v-model="tab" color="primary" background-color="bg">
      <v-tab class="medium--text">Résumé</v-tab>
      <v-tab class="medium--text">Statistiques états vaccination</v-tab>
    </v-tabs>
    <v-divider></v-divider>

    <v-tabs-items v-model="tab" class="mt-8">
      <v-tab-item class="bg">
        <VaccinationStatisticsSummary class="py-8"/>
      </v-tab-item>
      <v-tab-item class="pa-0 bg">
        <VaccinationStatisticsStatus :option_export="true"/>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import VaccinationStatisticsSummary from "./components/VaccinationStatisticsSummary";
import VaccinationStatisticsStatus from "./components/VaccinationStatisticsStatus";

export default {
  name: "DoctorVaccinationStatisticsView",
  components: {VaccinationStatisticsStatus, VaccinationStatisticsSummary},
  data: () => ({
    tab: "",
  }),
}
</script>
