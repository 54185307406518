<template>
  <PrCardComponent title="Questions Fréquentes" icon="mdi-comment-question">
    <template v-slot:card-title-append>
      <v-spacer></v-spacer>
      <ButtonDetails v-if="see_details" :txt="`Voir toutes les questions sur ${name_details}`" :redirection="to_details"/>
    </template>
    <template v-slot:card-text>
      <slot name="panels">
        <p class="font-italic basic--text text-center mt-10 font-size--14">Aucune information</p>
      </slot>
    </template>
  </PrCardComponent>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import ButtonDetails from "@/components/patient/components/ButtonDetails";

export default {
  name: "PatientFAQCardComponent",
  components: {
    ButtonDetails,
    PrCardComponent
  },
  props: {
    see_details: {
      type: Boolean,
      default: false
    },
    to_details: {
      type: String,
      default: null
    },
    name_details: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
