<template>
  <div>
    <AnDashboard
      ref="an_dashboard"
      :body="body"
      :tab_items="Object.keys(dashboard_data_tables)"
      :tab_content="dashboard_data_tables"
      :search="$store.state.dashboard_search"
      :custom_filter="true"
      :custom_filter_function="custom_filter_function"
      :show_inactive="show_inactive"
      allow_export
      @move_to_dpa="open_dpa($event, false)"
      @open_dpa_window="open_dpa($event, true)"
      @tab_changed="tab_changed($event)"
    >
      <template v-slot:header>
        <v-card-title class="pb-0">
          <v-row align="end">
            <v-col cols="3" class="py-0 pr-0">
              <v-switch v-model="show_inactive" @change="$emit('show_inactive', $event)" label="Afficher les utilisateurs désactivés" hide-details></v-switch>
            </v-col>
            <v-col v-if="$store.state.user_doctor.data.has_access_to_open_vaccination" cols="1" offset="7" class="pa-0">
              <v-btn color="primary" fab dark @click="$refs.dialog_new_user.dialog = true">
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="1" :offset="$store.state.user_doctor.data.has_access_to_open_vaccination ? 0 : 8" class="py-0">
              <v-btn @click="$refs.dialog_dashboard_help.dialog = true" icon>
                <v-icon small color="primary">mdi-help</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-card style="border-radius: 50px">
                <v-select
                  v-model="filter_by_groupement"
                  @change="filter_by_affectation = ''"
                  class="mb-4"
                  :items="search_items_groupements"
                  label="Filtrer par groupement"
                  rounded
                  filled
                  hide-details
                ></v-select>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-card style="border-radius: 50px">
                <v-select
                  v-model="filter_by_affectation"
                  class="mb-4"
                  :items="search_items_affectations"
                  label="Filtrer par affectation"
                  rounded
                  filled
                  hide-details
                ></v-select>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field v-model="$store.state.dashboard_search"
                            label="Rechercher un patient"
                            rounded filled hide-details clearable
                            class="mb-4 elevation-2"
                            color="soft_blue" background-color="bg"
                            prepend-inner-icon="mdi-magnify">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
      </template>
    </AnDashboard>

    <CreatePatientDialog ref="dialog_new_user" v-on:action_after_success="$refs.an_dashboard.get_patients()" />

    <DialogDashboardHelp ref="dialog_dashboard_help" />
  </div>
</template>

<script>
import AnDashboard from "anam-library/src/components/an-dashboard/AnDashboard";
import DialogDashboardHelp from "./dialogs/HelpDashboard";
import PriorisationItem from "./dashboard_items/PriorisationItem";
import ScoreCovidItem from "./dashboard_items/ScoreCovidItem";
import ScoreAggravationItem from "./dashboard_items/ScoreAggravationItem.vue";
import BooleanItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/BooleanItem";
import CovidItem from "./dashboard_items/CovidItem";
import DateTimeItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/DateTimeItem";
import CreatePatientDialog from "@/components/doctor/common/CreatePatientDialog";

import dashboard_data_tables from "@/data/covidhelp/dashboard_data_tables";
import {get_affectations_text, get_compagnies_text} from "prevana-library/src/js/utils";

export default {
  components: {
    AnDashboard,
    DialogDashboardHelp,
    CreatePatientDialog
  },
  data() {
    return {
      body: {
        priorisation: PriorisationItem,
        score_covid: ScoreCovidItem,
        score_aggravation: ScoreAggravationItem,
        contact: BooleanItem,
        confirme: CovidItem,
        isolement: BooleanItem,
        hospitalisation: BooleanItem,
        arrettravail: BooleanItem,
        date_last_modification: DateTimeItem,
      },
      dashboard_data_tables: dashboard_data_tables,
      search: "",
      search_items_groupements: [{ text: "Tous", value: "all" }],
      affectations_items: [{ text: "Tous", value: "all" }],
      show_inactive: false
    };
  },
  methods: {
    filtered_affectations: function () {
      if (this.filter_by_groupement === "all" || !this.filter_by_groupement) {
        this.filter_by_affectation = "";
        this.search_items_affectations = this.affectations_items;
      } else {
        this.filter_by_affectation = "";
        this.search_items_affectations = [];
        for (let el in this.affectations_items) {
          if (
            this.affectations_items[el]["groupement"] ===
            this.filter_by_groupement
          ) {
            this.search_items_affectations.push(this.affectations_items[el]);
          }
        }
      }
    },
    custom_filter_function: function (list) {
      if (this.filter_by_affectation !== "all" && this.filter_by_affectation) {
        list = list.filter((value) =>
          value.affectations.includes(this.filter_by_affectation)
        );
      }

        if (this.filter_by_groupement !== "all" && this.filter_by_groupement) {
          list = list.filter(
            (value) => value.groupement === this.filter_by_groupement
          );
        }

      return list;
    },
    async open_dpa(patient, new_window) {
      const path = {name: 'CovidhelpDpa', params: {id: patient.patient_id }, query: {tab: this.$route.query.tab}}

        if(new_window){
          const routeData = this.$router.resolve(path);
          window.open(routeData.href, "_blank", "resizable");
        } else {
          this.$router.push(path);
        }
      },

    tab_changed(tab) {
      this.$router.push({name: 'CovidhelpDashboard', query: {tab: tab}});
    },
  },
  computed: {
    filter_by_groupement: {
      get() {
        return this.$store.getters.getDashboardFilter("covidhelp_grp");
      },
      set(value) {
        this.$store.commit("setDashboardFilter", {filter: "covidhelp_grp", value: value});
      }
    },
    filter_by_affectation: {
      get() {
        return this.$store.getters.getDashboardFilter("covidhelp_aff");
      },
      set(value) {
        this.$store.commit("setDashboardFilter", {filter: "covidhelp_aff", value: value});
      }
    },
    search_items_affectations() {
      if (this.filter_by_groupement === "all" || !this.filter_by_groupement) {
        return this.affectations_items;
      }
      return this.affectations_items.filter(value => value.groupement_id === this.filter_by_groupement || value.value === "all");
    },
  },
  mounted() {
    let data = this.$store.getters.getEntitiesData;
    this.search_items_groupements = this.search_items_groupements.concat(get_compagnies_text(data));
    this.affectations_items = this.affectations_items.concat(get_affectations_text(data));
    if (this.$route.query.tab) this.$refs.an_dashboard.tab = parseInt(this.$route.query.tab);
  },
};
</script>

<style>
</style>
