import { render, staticRenderFns } from "./DialogCardTitleTemplate.vue?vue&type=template&id=b0f48ad2&scoped=true&"
import script from "./DialogCardTitleTemplate.vue?vue&type=script&lang=js&"
export * from "./DialogCardTitleTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0f48ad2",
  null
  
)

export default component.exports