<template>
  <PrCardComponent title="Historique" icon="mdi-history">
    <template v-slot:card-text>
      <v-progress-linear v-if="loading_history" indeterminate color="secondary"></v-progress-linear>
      <v-row class="pa-4">
        <v-col cols="12" v-if="list_questionnaires.length > 0">
          <v-expansion-panels v-model="panel_history" multiple :accordion="true" flat>
            <v-expansion-panel v-for="(quest, key) in list_questionnaires" :key="key" class="expansion-item">
              <v-expansion-panel-header class="font-size--16" style="font-weight: bold">
                <v-row no-gutters>
                  <v-col cols="8">{{ quest.name }} - {{ quest.date | moment }}</v-col>
                  <v-col
                    v-if="(quest.priorisation != null) || (quest.score_covid != null)" cols="4" style="color: dimgrey">
                    <span v-if="quest.priorisation != null">Gravité
                      <v-icon v-if="quest.priorisation != -1" :color="get_color_priorisation(quest.priorisation)">mdi-checkbox-blank-circle</v-icon>
                    </span>
                    |
                    <span v-if="quest.score_covid != null">Proba. Covid+
                      <span :class="get_color_scores(quest.score_covid) +' font-weight-bold'">{{ quest.score_covid }}</span>
                    </span>
                    <span v-if="positive_test(quest)"> | Testé positif Covid19</span>
                    <span v-if="positive_serologie(quest)"> | Sérologie anti-SARS-CoV-2 Positive
                    </span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul v-if="quest.symptoms.length || quest.risk_factors.length">
                  <li v-for="(item, key) in quest.symptoms" :key="'sy_'+key">
                    {{ item.name }}
                  </li>
                  <li v-for="(item, key) in quest.risk_factors" :key="'rf_'+key">
                    {{ item }}
                  </li>
                </ul>
                <span v-else>Aucun Symptôme / Facteur de risque déclaré </span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col v-else cols="12">
          <p class="mt-2">Le patient n'a pas répondu au questionnaire</p>
        </v-col>
      </v-row>
    </template>
  </PrCardComponent>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import MixinColorScore from "./common/MixinColorScore"
import moment from "moment";

export default {
  components: {
    PrCardComponent,
  },
  props: {
    pds_uid: {
      type: String,
    },
  },
  mixins: [MixinColorScore],
  data: () => ({
    panel_history: [],
    list_questionnaires: [],
    loading_history: true,
  }),

  mounted: function () {
    this.$http.get("/api/history_parcours_de_soin/" + this.pds_uid).then((response) => {
      this.list_questionnaires = response.data;
    }).finally(() => {
      this.loading_history = false;
    });
  },
  filters: {
    moment: function (date) {
      let day = moment(date).locale("fr").format("DD MMMM YYYY");
      let time = moment(date).locale("fr").format("HH:mm");
      return "le " + day + " à " + time;
    },
  },

  methods: {
    positive_test(quest) {
      return quest.symptoms.findIndex(x => x.uid === "cf778ae6e70b4b01a1cf5e2052968ddd") > -1;
    },
    positive_serologie(quest) {
      return quest.symptoms.findIndex(x => x.uid === "ba404795567f489798777374ce89439f") > -1;
    }
  }
};
</script>

<style scoped>
.expansion-item {
  min-height: 56px;
  border-bottom: 1px solid rgb(204, 204, 204, 0.6);
}
</style>




