<template>
  <div>
    <v-list-item-group
      v-show="show_condition"
      v-for="(question, index) in questionnaire"
      :key="index"
      class="pl-5"
    >
      <v-list-item-content style="overflow: unset" class="pa-0">
        <v-list-item-title style="white-space: unset; word-break: break-word">
          <span v-html="question.name" class="normal--text black--text"></span>
        </v-list-item-title>
        <v-list-item-subtitle class="black--text font-size--14 medium--text">
          <p v-for="(answer, i) in question.answers" :key="i">
            <v-icon color="pastel" class="hidden-xs-only">mdi-chevron-right</v-icon>
            <span style="white-space: pre-wrap" class="medium--text primary--text">{{ answer.name }}</span>
          </p>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item-group>
  </div>
</template>

<script>
export default {
  name: "QuestionAnswers",
  props: {
    questionnaire: {
      required: true
    },

    show_condition: {
      type: Boolean,
      default: true
    }
  }
}
</script>
