<template>
  <v-row class="mx-0">
    <v-col cols="12" md="4" v-if="groupements_items.length > 1">
      <v-select v-model="groupement"
                @change="get_statistics('groupement')"
                :label="_label_niveau_3"
                color="primary" item-color="primary"
                :items="groupements_items"
                item-value="id"
                dense
                hide-details
                prepend-icon="mdi-magnify"/>
    </v-col>
    <v-col cols="12" md="4" v-if="affectations_items.length > 1">
      <v-select v-model="affectation"
                @change="get_statistics('affectation')"
                :label="_label_niveau_4"
                color="primary" item-color="primary"
                :items="filter_affectations_items"
                item-value="id"
                dense
                hide-details prepend-icon="mdi-magnify"/>
    </v-col>
    <v-col cols="12" md="4" class="text-right">
      <v-btn outlined color="secondary" large class="mr-4" @click="download_csv()" :disabled="loading_csv">
        <span class="medium--text font-size--14">Exporter CSV</span>
      </v-btn>
      <v-btn v-if="csv_anonymous" outlined color="secondary" large @click="download_csv(true)" :disabled="loading_csv">
        <span class="medium--text font-size--14">Exporter CSV Anonymisé</span>
      </v-btn>
    </v-col>

    <v-col cols="12" v-if="loading" class="mt-4">
      <v-progress-linear indeterminate color="secondary"></v-progress-linear>
    </v-col>

    <v-expand-transition>
      <v-col cols="12" v-if="!loading">
        <v-row>
          <v-col cols="12" sm="6">
            <PrCardComponent icon="mdi-clipboard-text" title="Répartition">
              <template v-slot:card-text>
                <PieTemplate :data="dataState" :colors="['#E94E1B', '#337477', '#000000', '#ECECEC']"/>
              </template>
            </PrCardComponent>

            <PrCardComponent icon="mdi-clipboard-text" title="Classification des cas" class="mt-6">
              <template v-slot:card-title-append>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="show_legend=!show_legend">
                  <span class="medium--text text-decoration-underline">Légende</span>
                  <v-icon class="ml-2">{{ show_legend ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                </v-btn>
              </template>
              <template v-slot:card-text>
                <v-expand-transition>
                  <div v-if="show_legend" class="pl-4">
                    <p v-for="(legend, index) in legends" :key="index" class="mb-0">
                      <span class="bold--text">{{ legend.word }}</span> = {{ legend.def }}
                    </p>
                  </div>
                </v-expand-transition>
                <BarTemplate :data="dataRecoMS" :colors="['#E94E1B']" column_width="45%"/>
              </template>
            </PrCardComponent>
          </v-col>
          <v-col cols="12" sm="6">
            <PrCardComponent icon="mdi-clipboard-text" title="Taux de participation">
              <template v-slot:card-text>
                <PieTemplate :data="{'Répondants': dataPatients.repondants, 'Non répondants': dataPatients.non_repondants}" :colors="[primary, secondary]" />
              </template>
            </PrCardComponent>

            <PrCardComponent icon="mdi-clipboard-text" title="Répartition des événements" class="mt-6">
              <template v-slot:card-text>
                <BarTemplate :data="dataInformations" :colors="['#E94E1B']" :horizontal="false" column_width="30%"/>
              </template>
            </PrCardComponent>
          </v-col>
        </v-row>
      </v-col>
    </v-expand-transition>
  </v-row>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";

import export_csv from "anam-library/src/js/export_file";
import {get_affectations_text, get_compagnies_text} from "prevana-library/src/js/utils";

import PieTemplate from "prevana-library/src/components/statistics/components/graphs/PieTemplate.vue";
import BarTemplate from "./BarTemplate.vue";
import {get_terminology} from "anam-library/src/data/dict_terminology_by_agency";

export default {
  name: "CovidStatisticsComponent",
  components: {
    PrCardComponent,
    PieTemplate,
    BarTemplate,
  },
  props: {
    csv_anonymous: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading_csv: false,
    show_legend: false,
    birthdate: null,
    dataState: {},
    dataInformations: {},
    dataRecoMS: {},
    dataPatients: {},
    nb_exclus: null,
    date: null,
    groupement: "",
    affectation: "",
    groupements_items: [],
    affectations_items: [],
    filter_affectations_items: [],
    user_has_role: null,
    help_recommendations: false,
    help_definitions: false,
    help_evenements: false,
    legends: [
      { word: "Confirmé", def: "Testé positif au Covid19" },
      { word: "Possible", def: "Non testé mais présence de symptômes" },
      { word: "Contact", def: "Aucun Symptôme mais contage covid19" },
      { word: "Guéri", def: "N'a plus de symptômes" },
      {
        word: "Sortie d'isolement",
        def: "Cas contact sans symptôme qui reprend l'activité",
      },
      { word: "Hospitalisé", def: "Personne hospitalisée" },
    ],
    loading: true,
  }),
  computed: {
    _label_niveau_3() {
      return `Filtrer par ${get_terminology("niveau_3", this.$store.getters.getCurrentAgency)}`;
    },
    _label_niveau_4() {
      return `Filtrer par ${get_terminology("niveau_4", this.$store.getters.getCurrentAgency)}`;
    },
    primary() {
      return this.$vuetify.theme.currentTheme.primary;
    },
    secondary() {
      return this.$vuetify.theme.currentTheme.secondary;
    }
  },
  created() {
    let data = this.$store.getters.getEntitiesData;
    this.user_has_role = data["user_role"];
    if (
      this.user_has_role === "PDS" ||
      this.user_has_role === "MANAGER_SDIS"
    ) {
      this.groupements_items = [{ text: "Tous", value: "all", id: "all" }];
    } else if (this.user_has_role === "MANAGER_GROUPEMENT") {
      this.affectations_items = [{ text: "Toutes", value: "all", id: "all" }];
    }
    this.groupements_items = this.groupements_items.concat(get_compagnies_text(data));
    this.affectations_items = this.affectations_items.concat(get_affectations_text(data));
    this.filter_affectations_items = this.affectations_items;
    this.get_statistics("all");

  },
  methods: {
    get_statistics: function (type) {
      this.loading = true;
      let api_url;
      if (type === "groupement" && this.groupement !== "all") {
        api_url = "/api/data_statistics_by_compagnie/" + this.groupement;
      } else if (type === "affectation" && this.affectation !== "all") {
        api_url = "/api/data_statistics_by_affectation/" + this.affectation;
      } else {
        api_url = "/api/data_statistics_all";
      }

      return this.$http.get(api_url).then((response) => {
        this.dataState = response.data["state"];
        this.dataInformations = response.data["informations"];
        this.dataRecoMS = response.data["reco-ms_datas"];
        this.dataPatients = response.data["ratio_patients"];
        this.nb_exclus = response.data["nb_exclus"];
        this.date = response.data["date"];
        this.dataPatients = {"repondants": response.data.ratio_patients.Participants, "non_repondants": response.data.ratio_patients.Tous - response.data.ratio_patients.Participants};

        if (type === "groupement") {
          this.affectation = "";
          if (this.groupement === "all") {
            this.filter_affectations_items = this.affectations_items;
          } else {
            this.filter_affectations_items = [];
            for (let el in this.affectations_items) {
              if (
                this.affectations_items[el]["groupement_id"] ===
                this.groupement
              ) {
                this.filter_affectations_items.push(
                  this.affectations_items[el]
                );
              }
            }
          }
        } else if (type === "all") {
          this.affectation = "";
          this.filter_affectations_items = this.affectations_items;
        }
      })
        .finally(() => {
          this.loading = false;
        });
    },
    download_csv: function (anonymous = false) {
      this.loading_csv = true;
      let api_url;
      if (this.affectation !== "all" && this.affectation !== "") {
        api_url = "/api/data_csv_by_affectation/" + this.affectation;
      } else if (this.groupement !== "all" && this.groupement !== "") {
        api_url = "/api/data_csv_by_compagnie/" + this.groupement;
      } else {
        api_url = "/api/data_csv_all";
      }

      let csv_name = "covid19Data-" + this.date + ".csv";
      this.$http.get(api_url, { params: { anonymous: anonymous } }).then((response) => {
        export_csv(csv_name, response);
      }).finally(() => {
        this.loading_csv = false
      });
    },
  },
};
</script>

<style>
</style>
