<template>
  <v-container fluid class="pa-sm-10">
    <v-row class="mx-0">
      <v-col cols="12" sm="10" offset-sm="1" md="6" offset-md="3" class="mb-10 text-center">
        <v-card rounded elevation="2" class="mt-sm-8">
          <v-card-title class="justify-center pt-sm-8">
            <p class="primary--text bold--text font-size--28 mb-0 text-break">Informations requises</p>
          </v-card-title>
          <v-card-text class="text-left pt-5 pt-sm-10 px-4 px-md-14">
            <PrSelectPatientProfile
              title="Quel est ton statut actuel ?"
              :value="marital_status"
              :items="items_marital_status"
              multiple
              v-on:update="marital_status = $event"
            />
            <PrSelectPatientProfile
              title="Concernant le financement de tes études"
              :value="studies_funding"
              :items="items_studies_funding"
              multiple
              v-on:update="studies_funding = $event"
            />
            <PrSelectPatientProfile
              title="Quel est ton temps de trajet moyen quotidien ?"
              :value="travel_time"
              :items="items_travel_time"
              v-on:update="travel_time = $event"
            />
            <PrSelectPatientProfile
              title="Tes études sont un facteur de stress pour toi ?"
              :value="stress"
              :items="items_stress"
              v-on:update="stress = $event"
            />
            <PrSelectPatientProfile
              title="As-tu redoublé ?"
              :value="repeated_year"
              :items="items_repeated_year"
              v-on:update="repeated_year = $event"
            />
          </v-card-text>
          <v-card-actions class="justify-center py-6">
            <v-btn class="px-10" elevation="0" large color="primary" @click="save_data_profile()" :loading="loading" :disabled="button_disabled">
              <span class="medium--text font-size--16">Continuer</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PrSelectPatientProfile from "prevana-library/src/components/common/PrSelectPatientProfile";
import {get_patient_data_ecos} from "../../../data/ecos/utils";

export default {
  name: "PatientProfileView",
  components: {PrSelectPatientProfile},
  data: () => ({
    loading: false,
    marital_status: null,
    studies_funding: null,
    travel_time: null,
    stress: null,
    repeated_year: null
  }),
  computed: {
    button_disabled() {
      return !this.marital_status || !this.studies_funding || !this.travel_time || !this.stress || !this.repeated_year;
    },
    items_marital_status() {
      return get_patient_data_ecos("items_marital_status");
    },
    items_studies_funding() {
      return get_patient_data_ecos("items_studies_funding");
    },
    items_travel_time() {
      return get_patient_data_ecos("items_travel_time");
    },
    items_stress() {
      return get_patient_data_ecos("items_stress");
    },
    items_repeated_year() {
      return get_patient_data_ecos("items_repeated_year");
    }
  },
  methods: {
    save_data_profile() {
      this.loading = true;
      let data = {
        age: this.age,
        marital_status: this.marital_status,
        studies_funding: this.studies_funding,
        travel_time: this.travel_time,
        stress: this.stress,
        repeated_year: this.repeated_year === 1,
      }
      this.$http.post(`/api/patients/0/patient-data-ecos`, data).then(() => {
        this.$router.push(this.$route.query.redirection);
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
