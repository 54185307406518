<template>
  <div v-if="value">
    <p class="ma-0 medium--text">{{value.name}}</p>
    <p class="ma-0 font-italic font-size--14">({{ value.date | datetime }})</p>
  </div>
  <v-icon v-else small>mdi-minus</v-icon>
</template>

<script>
import {mixin_dates} from "anam-library/src/js/dates";

export default {
  name: "QuestionnaireECOSItem",
  props: ['item', 'variable'],
  mixins: [mixin_dates],
  computed: {
    value() {
      return this.item.latest_questionnaire_completed;
    }
  }
}
</script>

<style scoped>

</style>
