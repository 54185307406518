<template>
  <AnHomeAgencies :agencies="data_agencies"/>
</template>

<script>
import AnHomeAgencies from "anam-library/src/components/an-auth/AnHomeAgencies";

export default {
  name: "DirectoryLayout",
  components: {AnHomeAgencies},
  data: () => ({
    data_agencies: [],
  }),
  created() {
    this.$vuetify.goTo(0);
    this.data_agencies = [];
    this.$http.get("/api/all_agencies", {params: {apps: ["covidapp", "prevanavaccination", "rps", "vma", "ECOS", "etude"]}}).then(response => {
      for (let el in response.data) {
        let tmp = {...response.data[el], path: response.data[el].name};
        this.data_agencies.push(tmp);
      }
    })
    this.$store.commit("setCurrentAgency", {
      name: "anamnese",
      commercial_name: "Anamnèse",
      logo_height: 100
    })
  }
};
</script>
