<!-- Structure globale de la page pour un patient de l'application RPS -->
<template>
  <v-container fluid class="pa-6">
    <v-row class="mx-0">
      <v-col cols="12">
        <div class="d-flex">
          <img :src="logo" width="50" height="auto" class="rounded-lg mr-4" alt="Logo Bien-Être"/>
          <span class="secondary--text bold--text font-size--24 align-self-center">Bien-Être</span>
        </div>
      </v-col>
    </v-row>
    <router-view></router-view>
    <component :is="welcome_component" :dialog.sync="dialog" :patient_name="patient_name" />
  </v-container>
</template>

<script>
import logo from "@/assets/picto-rps.png";
import {get_welcome_component} from "../../../data/rps/text_by_agency";
import moment from "moment-timezone";

export default {
  name: "PatienRPStLayout",
  data: () => ({
    logo: logo,
    dialog: false,
    welcome_component: null,
    patient_name: ""
  }),
  mounted() {
    this.welcome_component = get_welcome_component(this.$store.getters.getAgencyName);
    if (this.welcome_component) {
      let patient_data = this.$store.getters.getPatientData;
      if (patient_data.datetime_previous_login === null) {
        this.patient_name = patient_data.first_name;
        patient_data.datetime_previous_login = moment();
        this.$store.commit("setPatientData", patient_data);
        this.dialog = true;
      }
    }
  }
};
</script>
