<template>
  <AnContinueQuestionnaire
    show_questions_answers
    patient_id="0"
    :query_redirect="query_redirect" />
</template>

<script>
import AnContinueQuestionnaire from "anam-library/src/components/an-questionnaire/patient/AnContinueQuestionnaire";

export default {
  name: "ContinueQuestionnaireView",
  components: {AnContinueQuestionnaire},
  data: () => ({
    query_redirect: {
      nb_question: 7
    }
  })
}
</script>
