<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4" order="2" order-sm="1" class="pl-sm-10 text-center text-sm-left">
        <img src="@/assets/vaccination/illustration_vaccination_light.png" height="250"/>
      </v-col>
      <v-col cols="12" sm="8" order="1" order-sm="2" class="soft_blue--text">
        <p class="font-size--30 bold--text text-sm-right pr-5">En quoi consiste la vaccination ?</p>
        <p class="medium--text">
          La vaccination consiste à faire une injection au niveau du bras ou de la cuisse (contenant un virus
          inactivé, une partie du virus, un adénovirus ou un ARN messager) pour simuler une contamination.
          L’organisme va alors produire des anticorps, qui vont combattre spécifiquement le virus pour lequel le
          vaccin a été administré et se familiariser avec ce virus. Ainsi, si une personne vaccinée est en
          contact avec  le virus contre lequel elle a été vaccinée, son organisme va reconnaître ce virus et répondre
          rapidement en reproduisant les anticorps déjà produits lors de la vaccination. Le virus sera alors
          neutralisé avant même que la personne vaccinée ne tombe malade.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "BlocExplication"
  }
</script>

<style scoped>

</style>
