<template>
  <v-dialog v-model="missing_cgu_dialog" width="600" persistent>
    <v-card>
      <v-card-title class="primary primary-title white--text">{{ $t('vma.required_act_text') }}</v-card-title>
      <v-card-text>
        <p class="soft_blue--text medium--text font-size--18 mt-4">{{ $t('vma.you_text') }} :</p>
        <v-form v-model="valid_cgu">
          <CguCheckbox :cgu.sync="cgu"
                       :confidentiality.sync="confidentiality"
                       :agency="$store.getters.getAgencyName"
                       app_name="prevana"
                       :form_required="true"/>
        </v-form>

        <v-alert type="error" text v-if="error_cgu" class="mt-4">
          {{ $t('common.error_alert_text') }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="justify-center py-6">
        <v-btn @click="saved()" color="secondary" elevation="0" large class="px-10" :disabled="!valid_cgu" :loading="loading">
          <span class="medium--text">{{ $t('common.validate') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CguCheckbox from "../../common/CguCheckbox";
import axios from 'axios'
import {getCookie} from "../../../main"

export default {
    name: "MissingCGU",
    components: {
      CguCheckbox
    },
    props: {
      missing_cgu_dialog: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      valid_cgu: false,
      loading: false,
      error_cgu: false
    }),
    computed: {
      cgu: {
        get: function () {
          return this.$store.getters.getPatientCgu;
        },
        set: function(val) {
          this.$store.commit('setPatientCgu', val)
        }
      },
      confidentiality: {
        get: function () {
          return this.$store.getters.getPatientConfidentiality;
        },
        set: function(val) {
          this.$store.commit('setPatientConfidentiality', val)
        }
      }
    },
    methods: {
      saved() {
        this.loading = true;
        axios.patch("/api/save_cgu_and_confidentialite", {}, {headers: {"X-CSRFToken": getCookie("csrftoken")}}).then(() => {
          this.$emit("close_modal");
        }).catch(() => {
          this.error_cgu = true;
        }).finally(() => {
          this.loading = false;
        })
      }
    }
  }
</script>

<style scoped>

</style>
