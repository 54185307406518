<template>
  <v-row class="mx-0">
    <v-col v-if="option_export" cols="12" class="text-right">
      <v-btn :href="export_data_url()" color="primary" elevation="0" class="px-6">
        <v-icon class="mr-2">mdi-download</v-icon>
        Exporter les données sur cette période
      </v-btn>
    </v-col>
    <v-col cols="12">
      <PrCardComponent icon="mdi-clipboard-text" title="Répartition des états">
        <template v-slot:card-text>
          <v-row class="mx-0">
            <v-col cols="12" sm="4" offset-sm="8">
              <p class="mb-0 semibold--text primary--text">Filtrer au</p>
              <AnDate :date="end_date" :validate="false" :filled="false" solo bg_color="bg" hide_details :show_title="false" v-on:formatted_date="end_date=$event"/>
            </v-col>
            <v-col cols="12">
              <v-progress-linear v-if="loading_charts === 1" indeterminate color="secondary" class="mt-5"></v-progress-linear>
              <apex-chart v-else
                          type="bar"
                          height="400"
                          :options="chart_options"
                          :series="chart_series"/>
            </v-col>
          </v-row>
        </template>
      </PrCardComponent>
    </v-col>
  </v-row>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import AnDate from "anam-library/src/components/an-date/AnDate";

import {get_formatted_date} from "anam-library/src/js/dates";
import moment from "moment"


export default {
  name: "VaccinationStatisticsStatus",
  components: {
    AnDate,
    PrCardComponent
  },
  props: {
    option_export: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    end_date: moment().format('DD/MM/YYYY'),
    filtered_status_data: {},
    loading_charts: 0
  }),
  computed: {
    chart_data: function() {
      for (let value of Object.values(this.filtered_status_data)) {
        if (value !== 0) return this.filtered_status_data;
      }
      return {};
    },
    chart_series: function() {
      return [{'name': "", 'data': Object.values(this.chart_data)}];
    },
    chart_options: function() {
      return {
        chart: {
          zoom: {enabled: false},
          toolbar: {show: false}
        },
        xaxis: {
          categories: Object.keys(this.chart_data)
        },
        noData: {text: 'Aucune donnée'},
        colors: ['#E94E1B'],
        plotOptions: {
        bar: {
          columnWidth: "35%",
        },
      },
      }
    }
  },
  watch: {
    end_date() {
      this.filter_search();
    }
  },
  created() {
    this.filter_search();
  },
  methods: {
    filter_search() {
      if (this.loading_charts === 0) this.loading_charts = 1;
      let params = {};
      if (this.end_date) params["end_date"] = get_formatted_date(this.end_date, {input_format: 'DD/MM/YYYY', output_format: 'YYYY-MM-DD'});
      this.$http.get("/api/statistics/status", {params: params}).then(response => {
        this.filtered_status_data = response.data.status_dict;
      }).finally(() => {
        this.loading_charts = 2;
      })
    },
    export_data_url() {
      return "/api/export_data?&end_date=" + this.end_date;
    }
  }
}
</script>
