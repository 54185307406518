<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>
    <v-row class="mx-0 mt-0 justify-center">
      <v-col v-if="!display_end" cols="12" sm="8" class="mt-4">
        <PrCardComponent title="Votre situation" icon="mdi-clipboard-text">
          <template v-slot:card-text>
            <v-progress-linear v-if="loading_indications" class="my-5" indeterminate color="primary"></v-progress-linear>

            <p class="black--text normal--text">
              Votre dernière déclaration a été enregistrée le
              <span v-show="!loading_indications" class="semibold--text primary--text">{{ date_last_declaration|datetime_full_a_fr }}</span>.
              Vous avez répondu entièrement au questionnaire pour la dernière fois le
              <span v-show="!loading_indications" class="semibold--text primary--text">{{  date_questionnaire|datetime_full_a_fr }}</span>
            </p>

            <div v-if="!loading_indications">
              <p v-if="number_indications === 0" class="black--text normal--text">Vous n’avez déclaré aucun symptôme lors de votre précédent questionnaire.</p>
              <p v-else class="black--text normal--text">Vous aviez indiqué les éléments suivant :</p>
              <ul class="pl-sm-12 pt-2">
                <li v-for="(item, index) in list_indications" :key="index" class="black--text font-size--14 normal--text">
                  {{ item }}
                </li>
              </ul>
            </div>

            <v-divider class="primary mt-5 mb-10"></v-divider>
            <p class="text-center semibold--text font-size--16 primary--text">Votre état de santé a-t-il évolué depuis ?</p>
            <div class="text-center">
              <v-btn color="secondary" outlined class="mx-2 px-6"
                     :loading="loading_end"
                     @click="redirect_to_end()">
                Non
              </v-btn>
              <v-btn color="secondary" elevation="0" class="mx-2 px-8"
                     :disabled="loading_indications"
                     :loading="loading_redirection_questionnaire"
                     @click="redirect_to_questionnaire()">
                Oui
              </v-btn>
            </div>
            <div class="mt-6 font-italic font-size--10">
              <p class="mb-0">
                Si votre situation a evolué, cliquez sur 'Oui'. Cela démarrera un nouveau questionnaire de suivi afin de
                transmettre l'évolution de votre état de santé.
              </p>
              <p class="mb-0">
                Dans le cas où votre situation est stable, cliquez sur 'Non'. Vous ne répondrez pas à un nouveau
                questionnaire de suivi. Votre réponse sera tout de même prise en compte.
              </p>
            </div>
          </template>
        </PrCardComponent>
      </v-col>
      <v-col v-else cols="12" sm="8" class="mt-12">
        <PrCardComponent title="" icon="">
          <template v-slot:card-text>
            <p class="black--text text-center font-size--16">Merci, nous avons pris en compte votre réponse.</p>
            <p class="black--text text-center font-size--16">
              Maintenez votre vigilance sur les mesures barrières à votre domicile ou dans le cadre de vos activités au sein du {{ $route.params.agency_name }}.
            </p>
            <div class="text-center">
              <img width="60%" class="hidden-xs-only" src="@/assets/evolution/gestes_barieres.png"/>
              <img class="hidden-sm-and-up" src="@/assets/evolution/gestes_barieres_mobile.png"/>
            </div>
            <div class="text-center mt-10">
              <v-btn @click="$router.push({name: 'PatientCovidDashboard'})" class="px-8" color="secondary" elevation="0">
                Retour à l'accueil
              </v-btn>
            </div>
          </template>
        </PrCardComponent>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";

import axios from "axios";
import getCookie from "anam-library/src/js/common";
import {mixin_dates} from "anam-library/src/js/dates";

export default {
  name: "PatientCovidEvolutionView",
  components: {PrCardComponent, PrBreadcrumbsComponent},
  mixins: [mixin_dates],
  data: function () {
    return {
      list_indications: [],
      date_questionnaire: null,
      date_last_declaration: null,
      number_indications: null,
      loading_indications: true,
      display_end: false,
      loading_end: false,
      loading_redirection_questionnaire: false,
      evolution_choices: null,
      questionnaire_uid: null
    };
  },
  computed: {
    pds_uid: function() {
      return this.$route.params.pds_uid;
    },
    agency_name: function() {
      return this.$route.params.agency_name;
    },
    navigations: function() {
      return [
        {
          text: 'Suivi Covid',
          to: '/patient/covidhelp/dashboard',
        },
        {
          text: 'Questionnaire',
          to: `/patient/covidhelp/evolution/${this.agency_name}/${this.pds_uid}`,
        },
      ]
    }
  },
  mounted: function () {
    axios.get("/api/symptoms_risk_factors").then((response) => {
      this.list_indications = response.data["indications"];
      this.date_questionnaire = response.data["date_questionnaire"];
      this.date_last_declaration = response.data["date_last_declaration"];
      this.number_indications = this.list_indications.length;
      this.questionnaire_uid = response.data.questionnaire_uid;
    }).finally(() => {
      this.loading_indications = false;
    });
  },
  methods: {
    redirect_to_questionnaire() {
      this.loading_redirection_questionnaire = true;
      if (this.questionnaire_uid) {
        this.$router.push({
          name: "QuestionnaireView",
          query: {questionnaire_type: "SuiviCovid19", app_name: "covidapp", redirection: "continue"},
          params: {questionnaire_uid: this.questionnaire_uid}
        });
      }
      else {
        this.$router.push({
          name: "QuestionnaireView",
          query: {questionnaire_type: "SuiviCovid19", app_name: "covidapp"}
        });
      }
    },
    redirect_to_end() {
      this.loading_end = true;
      let formData = new FormData();
      formData.append("new_questionnaire", "false");

      axios.post("/api/demande_de_depistage/" + this.pds_uid + "/evolution", formData, { headers: { "X-CSRFToken": getCookie("csrftoken") } }).then(() => {
        this.display_end = true;
      }).catch(() => {
        this.snackbar_error = true;
        window.location.href = "/";
      }).finally(() => {
        this.loading_end = false;
      });
    },
  },
};
</script>

<style scoped>

</style>
