import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

import auth from "anam-library/src/store/modules/auth";
import manager from "prevana-library/src/modules/manager";
import pat_admin from "anam-library/src/store/modules/pat_admin"
import snackbar_and_errors from "anam-library/src/store/modules/snackbar_and_errors";
import user_doctor from "anam-library/src/store/modules/user_doctor"
import vaccination from "./modules/vaccination";
import visio from "anam-library/src/store/modules/visio";
import {defaultLocale} from "@/i18n";

import {DICT_STATUS_ACTE_VACCINATION, DICT_STATUS_STATUS_VACCINATION} from "prevana-library/src/js/dict_status";

import dpa from 'prevana-library/src/modules/dpa';

Vue.use(Vuex);

const paths = [
  'currentAgency',
  'questionnaire_follow_up_vaccination',
  'latest_form',
  'latest_questionnaire',
  'vaccination.pre_questionnaire',
  'user_doctor',
  'auth',
  'apps_allowed',
  'agency_commercial_name',
  'patient_data',
  'patient_account',
  'prevana_data',
  'manager',
  'langue',
  'motifs_consultation',
  'patient_list'
];

const store = new Vuex.Store({
  plugins: [createPersistedState({ paths })],
  state: {
    langue: defaultLocale,
    // Compte patient ?
    first_name: null,
    last_name: null,
    sex: null,
    birthdate: null,
    email: null,
    postcode: null,
    phone: null,
    weight: null,
    height: null,
    main_affectation: null,
    secondary_affectation: null,

    // Compte patient
    send_reminder_email: null,
    patient_account: {
      cgu: false,
      confidentiality: false
    },

    agency_commercial_name: null,
    missing_param: false,

    is_mounted_app: true,

    // Gestion de la home
    currentAgency: {
      name: "anamnese",
      commercial_name: "Anamnèse",
      logo_height: 100
    },

    // Vaccination ?
    latest_form: {},
    latest_questionnaire: {},
    questionnaire_first_vaccination: {},
    questionnaire_second_vaccination: {},
    questionnaire_follow_up_vaccination: {},
    prevana_data: {},
    dpa_data: {
      status_history: null,
      history_vaccination: [],
      is_eligible: false,
      status: "",
      status_vaccination_id: null,
      follow_up: 1,
      follow_up_datetime: null,
      history_consultations: [],
      next_rdv: null,
      vaccination_data: {},
      show_tabs: {
        "#tab_2_vaccination": false,
        "#tab_3_vaccination": false
      }
    },
    dashboard_filter: {
      dashboard_search: "",
      date: null,
      selected_place: null,
      selected_statuses: [],
      age: [],
      is_eligible: null,
      category: null,
      wants_consultation: null,
      service: [],
      passed_consultation: false,
      nb_of_injections: [],
      time_injection: [],
      covidhelp_grp: "",
      covidhelp_aff: "",
      aptitude: [],
      rqth: [],
    },
    stats_user_role: null,
    vaccinated_14_days: false,
    admin_name: {
      first_name: "",
      last_name: ""
    },

    location: null,
    apps_allowed: [],
    DICT_STATUS_STATUS_VACCINATION: DICT_STATUS_STATUS_VACCINATION,
    DICT_STATUS_ACTE_VACCINATION: DICT_STATUS_ACTE_VACCINATION,
    motifs_consultation: [],
    patient_list: []
  },

  getters: {
    GetStatusName: (state) => (status) => {
      if (status === null || status === undefined) return "Non évalué";
      if (Object.keys(DICT_STATUS_STATUS_VACCINATION).includes(status.toString())) {
        return DICT_STATUS_STATUS_VACCINATION[status];
      }
      return "Non évalué";
    },
    GetStatusNameActe: (state) => (status) => {
      if (status === null || status === undefined) return "Non évalué";
      if (Object.keys(DICT_STATUS_ACTE_VACCINATION).includes(status.toString())) {
        return DICT_STATUS_ACTE_VACCINATION[status];
      }
      return "Non évalué";
    },
    getAppsAllowed : function(state) {
      return state.apps_allowed
    },
    GetNbNote: (state) => {
      return state.pat_admin.data.nb_notes
    },
    getPatientCgu: function(state) {
      return state.patient_account.cgu
    },
    getPatientConfidentiality: function(state) {
      return state.patient_account.confidentiality
    },
    getPatientData(state) {
      return state.patient_data;
    },
    getPatientId(state) {
      return state.patient_data.patient.id;
    },
    getDashboardFilter: (state) => (filter) => {
      return state.dashboard_filter[filter];
    },
    getCurrentAgency(state) {
      return state.currentAgency;
    },
    getPatientHasAccessRps: function(state) {
      return state.prevana_data.has_access_to_rps;
    },
    getPatientHasAccessVaccination: function(state) {
      return state.prevana_data.has_access_to_vaccination;
    },
    getPatientHasAccessCovidapp: function(state) {
      return state.prevana_data.has_access_to_covidapp;
    },
    getPatientHasAccessVma: function(state) {
      return state.prevana_data.has_access_to_vma;
    },
    getPatientReminderEmail(state) {
      return state.prevana_data.send_reminder_email;
    },
    checkMissingInfoCovid(state) {
      let data = state.patient_data;
      return !data.first_name || !data.last_name || !data.personnephysique || !data.personnephysique.sexe ||
        !data.personnephysique.birthdate || !data.personnephysique.poidsCorporel || !data.personnephysique.tailleCorporelle;
    },
    checkMissingInfoVaccination(state) {
      let data = state.patient_data;
      return !data.personnephysique || !data.personnephysique.sexe || !data.personnephysique.birthdate;
    },
    getLangue: function(state) {
      return state.langue;
    },
    getPatientPrevanaData(state) {
      return state.prevana_data;
    },
    getMotifsConsultation(state) {
      return state.motifs_consultation;
    },
    getMotifData: (state) => (consultation_type) => {
      let index = state.motifs_consultation.findIndex(x => x.id === consultation_type);
      if (index > -1) return state.motifs_consultation[index];
      return null;
    },
    getMotifName: (state) => (consultation_type) => {
      let index = state.motifs_consultation.findIndex(x => x.id === consultation_type);
      if (index > -1) return state.motifs_consultation[index].commercial_name_fr;
      return null;
    },
    getPatientList: function (state) {
      return state.patient_list;
    },
  },

  mutations: {
    setAppsAllowed: function(state, apps) {
      state.apps_allowed = apps
    },
    setPatientCgu: function(state, val) {
      state.patient_account.cgu = val;
    },
    setPatientConfidentiality: function(state, val) {
      state.patient_account.confidentiality = val;
    },
    setPatientPrevanaData(state, val) {
      state.prevana_data.has_access_to_vaccination = val.has_access_to_vaccination;
      state.prevana_data.has_access_to_covidapp = val.has_access_to_covidapp;
      state.prevana_data.has_access_to_rps = val.has_access_to_rps;
      state.prevana_data.has_access_to_vma = val.has_access_to_vma;
      state.prevana_data.main_affectation = val.main_affectation;
      state.prevana_data.secondary_affectation = val.secondary_affectation;
      state.prevana_data.agency_commercial_name = val.agency_commercial_name;
      state.prevana_data.send_reminder_email = val.send_reminder_email;
      state.prevana_data.latest_date = val.latest_date;
    },
    setSpecificPatientPrevanaData(state, data) {
      state.prevana_data[data.key] = data.val;
    },
    is_mounted_app(state, payload) {
      state.is_mounted_app = payload;
    },
    resetState(state) {
      state.patient_list = []
      this.commit('cleanPatientAccountData')
    },
    cleanPatientAccountData(state){
      state.sex= null;
      state.birthdate= null;
      state.email= null;
      state.postcode= null;
      state.phone= null;
      state.weight= null;
      state.height= null;
      state.main_affectation= null;
      state.secondary_affectation= null;
      state.patient_data = null;
    },
    resetDashboardFilters(state){
      state.dashboard_filter = {
        dashboard_search: "",
        date: null,
        selected_place: null,
        selected_statuses: [],
        age: [],
        is_eligible: null,
        category: null,
        wants_consultation: null,
        service: [],
        passed_consultation: false,
        nb_of_injections: [],
        time_injection: [],
        aptitude: [],
        rqth: [],
      }
    },
    setPatientData(state, data) {
      return state.patient_data = data;
    },
    setDashboardFilter(state, {filter, value}) {
      return state.dashboard_filter[filter] = value;
    },
    setCurrentAgency(state, data) {
      state.currentAgency = data;
      this.commit('setAgencyName', data.name);
    },
    setLangue: function(state, value) {
      state.langue = value;
    },
    setMotifsConsultation(state, value) {
      state.motifs_consultation = value;
    },
    setPatientList(state, value) {
      state.patient_list = value;
    },
  },
  actions: {
  },
  modules: {
    auth: auth,
    errors: snackbar_and_errors,
    user_doctor: user_doctor,
    pat_admin: pat_admin,
    dpa: dpa,
    vaccination: vaccination,
    manager: manager,
    visio: visio
  }
});

export default store
