<template>
  <v-app>
    <DoctorHeader :navigation_buttons="navigation_buttons"/>
    <v-main class="bg">
      <v-container v-if="!loading" fluid>
        <router-view v-if="has_access_to_vma" />
        <iframe v-else src="https://www.prevana.care/module-prevana-visite-medicale" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" />
      </v-container>
    </v-main>
    <PrFooter app_name="vma" />
  </v-app>
</template>

<script>
import DoctorHeader from "../common/DoctorHeader";
import PrFooter from "prevana-library/src/components/common/PrFooter";

import colors_themes from "../../../plugins/colors_themes";

export default {
  name: "DoctorVMALayout",
  components: {
    DoctorHeader,
    PrFooter,
  },
  data: () => ({
    loading: true,
    navigation_buttons: [
      {text: "Tableau de bord", icon: "mdi-view-dashboard", url: "/doctor/vma/dashboard", primary_action: true},
      {text: "Gestion des RDV", icon: "mdi-calendar", url: "/doctor/vma/appointments", primary_action: true},
      {text: "Statistiques", icon: "mdi-chart-bar", url: "/doctor/vma/statistics", primary_action: true},
      {text: "Paramètres", icon: "mdi-cog", url: "/doctor/vma/settings", primary_action: false},
    ],
  }),
  computed: {
    has_access_to_vma() {
      return this.$store.getters.getAppsAllowed.includes("vma");
    }
  },
  async created() {
    this.$store.state.is_mounted_app = false;
    if (this.has_access_to_vma) await this.$http.post("/api/switch_app/vma");
    this.loading = false;
    if (Object.keys(colors_themes).includes(`theme_doctor_${this.$store.getters.getAgencyName}`)) {
      this.$vuetify.theme.themes.light = colors_themes[`theme_doctor_${this.$store.getters.getAgencyName}`]["theme"]["themes"]["light"];
    }
    this.$store.state.is_mounted_app = true;
  },
};
</script>

<style>
</style>
