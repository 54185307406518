<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>
    <PatientRpsResultsCardDetails :loading="loading" :questionnaires="questionnaires" />
    <PatientRpsResultsCardEvolution :loading="loading" :questionnaires="questionnaires" class="mt-10"/>
  </div>
</template>

<script>
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";
import PatientRpsResultsCardDetails from "@/components/patient/rps/results/PatientRpsResultsCardDetails";
import PatientRpsResultsCardEvolution from "@/components/patient/rps/results/PatientRpsResultsCardEvolution";

export default {
  name: "PatientRpsResultsView",
  components: {
    PrBreadcrumbsComponent,
    PatientRpsResultsCardDetails,
    PatientRpsResultsCardEvolution
  },
  data: () => ({
    loading: false,
    questionnaires: [],
    navigations: [
      {
        text: 'Bien-Être',
        to: '/patient/rps/dashboard',
      },
      {
        text: 'Détails de mes résultats',
        to: '/patient/rps/details-results',
      },
    ]
  }),
  mounted() {
    this.loading = true;
    this.$http.get(`/api/patients/${this.$store.getters.getPatientId}/questionnaires_rps`).then(response => {
      this.questionnaires = response.data.filter(x => x.questionnaire_is_completed === true);
    }).finally(() => {
      this.loading = false;
    })
  }
}
</script>

<style scoped>

</style>
