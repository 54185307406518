<template>
    <div>
      <v-checkbox v-model="_cgu" :rules="form_required ? [rules.required] : []" dense hide-details>
        <span slot="label">{{ $t("vma.consentement_1") }}
          <a :href="get_mentions_legales_url('prevana', app_name)" target="_blank" @click.stop class="soft_blue--text medium--text text-decoration-underline">
            {{ $t("vma.consentement_2") }}
          </a>
          {{$t('vma.consentement_3')}}
          <a :href="get_politique_confidentialite_url('prevana', app_name)" target="_blank" @click.stop class="soft_blue--text medium--text text-decoration-underline">
             {{$t("vma.consentement_4")}}
          </a>
        </span>
      </v-checkbox>
      <v-checkbox v-model="_confidentiality" :rules="form_required ? [rules.required] : []" dense hide-details>
        <span slot="label">
          {{ $t("vma.consentement_5") }}
          <a :href="get_politique_confidentialite_url('prevana', app_name)" target="_blank" @click.stop
             class="soft_blue--text medium--text text-decoration-underline">
            {{ $t("vma.consentement_4") }}
          </a>
        </span>
      </v-checkbox>
    </div>
</template>

<script>
import rules from "anam-library/src/js/rules";
import {mixin_cgu} from "anam-library/src/js/utils";

export default {
      name: "CguCheckbox",
      mixins: [mixin_cgu],
      props: {
        cgu: {
          type: Boolean,
          default: false
        },
        confidentiality: {
          type: Boolean,
          default: false
        },
        agency: {
          type: String,
          default: null
        },
        app_name: {
          type: String,
          default: null
        },
        form_required: {
          type: Boolean,
          default: false
        }
      },
      data: () => ({
        rules: rules
      }),
      computed: {
        _cgu: {
          get: function() {
            return this.cgu
          },
          set: function(val) {
            this.$emit('update:cgu', val)
          }
        },
        _confidentiality: {
          get: function() {
            return this.confidentiality
          },
          set: function(val) {
            this.$emit('update:confidentiality', val)
          }
        }
      }
    }
</script>

<style scoped>

</style>
