<template>
  <v-expansion-panels v-model="panels" multiple accordion>
    <v-expansion-panel v-for="(tab, index) in injections" :key="index" class="my-2">
      <v-expansion-panel-header color="soft_blue" class="white--text font-weight-bold">
        {{tab}} ème injection {{display_injection_status(tab)}}
        <template v-slot:actions>
          <v-icon color="white">
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pt-3">
        <DpaVaccination :vaccination_number="tab" v-on:success_vaccination_status="get_injections()"></DpaVaccination>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import DpaVaccination from "@/components/doctor/vaccination/dpa/tabs/DpaVaccination";
import {vaccination_mixin} from "@/components/doctor/vaccination/dpa/vaccination_mixin";
import {get_formatted_date} from "anam-library/src/js/dates";

export default {
  name: "AdditionalInjections",
  components: {DpaVaccination},
  mixins: [vaccination_mixin],
  data: () => ({
    panels: [0],
    injections: []
  }),

  mounted() {
    this.get_injections();
  },

  methods: {
    get_injections() {
      this.injections = [];
      let i;
      let injections = this.get_injections_list();
      for (i=1; i<=injections.length; i++) {
        if (i > 2) this.injections.unshift(i)
      }
      if (this.$store.state.dpa_data.vaccination_data[i-1] && this.$store.state.dpa_data.vaccination_data[i-1].status === 9 && this.$store.state.dpa_data.vaccination_data.nb_of_injections_needed === i) this.injections.unshift(i);
    },

    display_injection_status(tab) {
      let data_injection = this.$store.state.dpa_data.vaccination_data[tab];
      if (data_injection && data_injection.status && [2, 6, 7, 9].includes(data_injection.status)) {
        return ` - ${this.$store.getters.GetStatusNameActe(data_injection.status)} le ${get_formatted_date(data_injection.datetime)}`;
      }
      return "";
    }
  }
}
</script>
