<template>
  <v-row class="justify-center mt-3">
    <v-tooltip bottom v-if="back_history_is_enabled()">
      <template v-slot:activator="{ on }">
            <span v-on="on">
              <a @click="set_up_back_history()"><v-icon color="primary" small>mdi-undo</v-icon><span class="primary--text text-decoration-underline ml-2">Revenir à l'état précédent</span></a>
            </span>
      </template>
      <span>Cette action annulera l'état actuel</span>
    </v-tooltip>
    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on }">
            <span v-on="on">
              <a class="mt-3" disabled><v-icon color="primary" small>mdi-undo</v-icon><span class="primary--text text-decoration-underline ml-2">Revenir à l'état précédent</span></a>
            </span>
      </template>
      <span>Cette action n'est possible que pour les états renseignés par le professionnel de santé</span>
    </v-tooltip>

    <v-dialog v-model="dialog_confirm_back_history" width="500" persistent>
      <v-card>
        <v-toolbar color="primary" flat>
          <v-toolbar-title class="white--text medium--text"> Revenir à l'état précédent </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-5 text-center">
          <p>Vous êtes sur le point d'annuler l'état actuel
            <span class="secondary--text font-weight-bold">{{current_status}}</span>
            pour revenir à l'état précédent
            <span class="secondary--text font-weight-bold">{{previous_status}}</span>
          </p>
          <p>Souhaitez-vous continuer ?</p>

          <v-alert v-if="back_history_success" type="success" text>Action réalisée avec succès</v-alert>
          <v-alert v-if="back_history_error" type="error" text>{{back_history_error}}</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog_confirm_back_history = false" color="primary" large rounded outlined>Annuler</v-btn>
          <v-btn :disabled="back_history_success" :loading="loading_back_history" @click="back_history()" color="primary" large rounded>Valider</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import getCookie from "anam-library/src/js/common";
import {vaccination_mixin} from "../vaccination_mixin";

export default {
        name: "BackHistory",
        mixins: [vaccination_mixin],

        data: () => ({
            dialog_confirm_back_history: false,
            current_status: null,
            previous_status: null,
            loading_back_history: false,
            back_history_success: false,
            back_history_error: null
        }),

        computed: {
          _previous_state_is_programmed() {
            return this.previous_status === 4;
          }
        },

        methods: {
            set_up_back_history() {
                let nb_of_actes = this.get_injections_list().length;
                let history_vaccination_2 = this.$store.state.dpa_data.history_vaccination.filter(x => (x.is_correction === false && x.acte_number === nb_of_actes));
                let history_vaccination_1 = [];
                if (nb_of_actes >= 2) history_vaccination_1 = this.$store.state.dpa_data.history_vaccination.filter(x => (x.is_correction === false && x.acte_number === nb_of_actes-1));

                let history_vaccination_1_primary = history_vaccination_1.filter(x => x.is_primary === true);
                let history_vaccination_1_non_primary = history_vaccination_1.filter(x => x.is_primary === false);
                let history_vaccination_2_primary = history_vaccination_2.filter(x => x.is_primary === true);
                let history_vaccination_2_non_primary = history_vaccination_2.filter(x => x.is_primary === false);

                if (history_vaccination_2_primary.length) {
                    this.current_status = history_vaccination_2_primary[0].status;
                    if (history_vaccination_2_primary.length >= 2) this.previous_status = history_vaccination_2_primary[1].status;
                    else {
                        if (history_vaccination_2_non_primary.length) {
                          this.previous_status = history_vaccination_2_non_primary[0].status;
                          if (!this.$store.state.dpa_data.next_rdv && this._previous_state_is_programmed) this.previous_status = history_vaccination_2_non_primary[1].status;
                        }
                        else this.previous_status = history_vaccination_1_primary[0].status;
                    }
                }

                else if (history_vaccination_2_non_primary.length) {
                    this.current_status = history_vaccination_2_non_primary[0].status;
                    if (history_vaccination_2_non_primary.length >= 2) {
                      this.previous_status = history_vaccination_2_non_primary[1].status;
                      if (!this.$store.state.dpa_data.next_rdv && this._previous_state_is_programmed) this.previous_status = history_vaccination_2_non_primary[2].status;
                    }
                    else this.previous_status = history_vaccination_1_primary[0].status;
                }

                else if (history_vaccination_1_primary.length) {
                    this.current_status = history_vaccination_1_primary[0].status;
                    if (history_vaccination_1_primary.length >= 2) this.previous_status = history_vaccination_1_primary[1].status;
                    else {
                        if (history_vaccination_1_non_primary.length) {
                          this.previous_status = history_vaccination_1_non_primary[0].status;
                          if (!this.$store.state.dpa_data.next_rdv && this._previous_state_is_programmed) this.previous_status = history_vaccination_1_non_primary[1].status;
                        }
                        else this.previous_status = -1;
                    }
                }

                else if (history_vaccination_1_non_primary.length) {
                    this.current_status = history_vaccination_1_non_primary[0].status;
                    if (history_vaccination_1_non_primary.length >= 2) {
                      this.previous_status = history_vaccination_1_non_primary[1].status;
                      if (!this.$store.state.dpa_data.next_rdv && this._previous_state_is_programmed) this.previous_status = history_vaccination_1_non_primary[2].status;
                    }
                    else this.previous_status = -1;
                }
                this.current_status = this.$store.getters.GetStatusNameActe(this.current_status);
                this.previous_status = this.$store.getters.GetStatusNameActe(this.previous_status);
                this.back_history_success = false;
                this.back_history_error = null;
                this.dialog_confirm_back_history = true;
            },

            back_history() {
                this.loading_back_history = true;
                axios.post("/api/patients/" + this.$route.params.id + "/back_history", {}, {headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(() => {
                    this.get_patient_vaccination_data(false);
                    this.get_status_reason();
                    this.back_history_success = true;
                    setTimeout(() => {
                        this.dialog_confirm_back_history = false;
                        this.back_history_success = false;
                    }, 1500);
                }).catch(error => {
                    if (error.response.status === 403) this.back_history_error = error.response.data;
                    else this.back_history_error = "Une erreur est survenue, veuillez réessayer ou nous contacter si le problème persiste";
                }).finally(() => {
                    this.loading_back_history = false;
                })
            },

            back_history_is_enabled() {
                return [2, 6, 7, 9, 10].includes(this.$store.state.dpa_data.status);
            }
        }
    }
</script>
