<template>
  <v-container fluid>
    <div class="text-center mt-14 pt-14">
        <p class="letter-spacing--1 semibold--text grey--text font-size--24 mb-6">EN CONSTRUCTION</p>
        <v-icon x-large>mdi-account-hard-hat</v-icon>
        <v-icon x-large>mdi-hammer-wrench</v-icon>
    </div>
  </v-container>
</template>

<script>

export default {
  name: "DoctorVMAStatisticsView",
}
</script>

<style scoped>

</style>
