<template>
    <v-list-item dense>
      <v-list-item-avatar class="my-0">
        <v-icon color="error">mdi-close</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>{{ content }}</v-list-item-content>
    </v-list-item>
</template>

<script>
    export default {
      name: "ListItemError",
      props: {
        content: {
          type: String
        }
      }
    }
</script>

<style scoped>

</style>
