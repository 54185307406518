<template>
  <v-list-item-content>
    <v-list-item-title class="soft_blue--text semibold--text font-size--20 mb-1">{{title}}</v-list-item-title>
    <v-list-item-subtitle class="medium--text font-size--16" v-if="vaccination && vaccination.status !== null" :class="'my-1 ' + get_color_status()">
      {{DICT_STATUS_ACTE_VACCINATION[vaccination.status]}}
    </v-list-item-subtitle>
    <v-list-item-subtitle  v-if="vaccination && vaccination.status !== null" class="mb-1">
      <span class="soft_blue--text medium--text">Date : </span>{{get_date_and_time(vaccination.datetime)}}
    </v-list-item-subtitle>
    <v-list-item-subtitle v-if="get_condition()" class="mb-1">
      <span class="soft_blue--text medium--text">Lieu : </span>{{vaccination.vaccination_place}}
    </v-list-item-subtitle>
    <v-list-item-subtitle v-if="get_condition()" class="mb-1">
      <span class="soft_blue--text medium--text">Injection : </span>{{vaccination.vaccine_type || "Non renseigné"}}
    </v-list-item-subtitle>
    <v-list-item-subtitle v-if="get_condition()" class="mb-1">
     <span class="soft_blue--text medium--text"> Lot : </span> {{vaccination.batch_number ? "N° " + vaccination.batch_number : "Non renseigné"}}
    </v-list-item-subtitle>
    <v-list-item-subtitle v-if="get_condition()" class="mb-1">
      <span class="soft_blue--text medium--text">Expire le : </span> {{get_date(vaccination.expiration_date)}}
    </v-list-item-subtitle>
    <v-list-item-subtitle v-if="get_condition()" class="mb-1">
       <span class="soft_blue--text medium--text">Site d'injection : </span>{{vaccination.injection_site || "Non renseigné"}}
    </v-list-item-subtitle>
    <v-list-item-subtitle v-if="!vaccination || vaccination.status === null">Non renseigné</v-list-item-subtitle>
  </v-list-item-content>
</template>

<script>
import moment from "moment"
import {DICT_STATUS_ACTE_VACCINATION} from "prevana-library/src/js/dict_status";

export default {
        name: "listVaccination",
        props: ["title", "vaccination"],
        data() {
          return {
            DICT_STATUS_ACTE_VACCINATION
          }
        },
        methods: {
            get_date_and_time(date) {
                return moment(date).format("DD/MM/YYYY HH:mm");
            },

            get_date(date) {
                if (date) return moment(date).format("DD/MM/YYYY");
                return "Non renseigné";
            },

            get_condition() {
                return this.vaccination && ([7, 9].includes(this.vaccination.status));
            },

            get_color_status() {
                if (this.vaccination.status === 8) {
                    return this.$store.state.vaccinated_14_days ? "red--text": "secondary--text";
                }
                return "secondary--text";
            }
        }
    }
</script>
