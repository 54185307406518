<template>
  <div>
    <PrCardComponent :title="$t(title_recap_place)" icon="" class="pa-5" v-if="is_eligible">
      <template v-slot:card-text>
        <v-divider class="primary mb-5"></v-divider>
        <v-row class="mx-0">
          <v-col cols="12" sm="3">
            <v-select v-model="chosen_calendar"
                      :items="calendars"
                      :loading="loading_calendars"
                      color="secondary"
                      outlined dense
                      :label="$t('vma.select_place_text')"
                      :no-data-text="$t('prevana.no_center_available')">
              <template v-slot:append>
                <v-icon color="secondary">mdi-chevron-down</v-icon>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select v-if="pro_choices.length"
                      v-model="chosen_pro"
                      :items="pro_choices"
                      :loading="loading_calendars"
                      color="secondary"
                      outlined dense
                      :label="$t('prevana.select_pro')"
                      :no-data-text="$t('vma.no_pro_found_text')">
              <template v-slot:append>
                <v-icon color="secondary">mdi-chevron-down</v-icon>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="4" v-show="chosen_calendar && show_rdv_type">
            <p>{{$t("prevana.meet_type_text")}}</p>
            <v-radio-group v-model="type_rdv_consult" row>
              <v-radio :label="text_consult" :value="1" v-if="has_type_consult"></v-radio>
              <v-radio :label="text_telco" :value="2" v-if="has_type_telco"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <p class="primary--text bold--text font-size--22 align-self-center mt-6 mb-0">{{ $t('vma.appointment_date') }}</p>
        <v-divider class="primary mt-4 mb-5"></v-divider>
        <v-progress-linear v-if="loading_dates" indeterminate color="primary"></v-progress-linear>

        <p v-else-if="!calendars.length" class="medium--text">{{ $t('vma.no_niche_text') }}</p>
        <p v-else-if="no_slot_available" class="medium--text">
          {{ $t("vma.all_slots_reserved_text") }}
          <br/>
          {{ $t("vma.recommandation_text") }}
          <br/>
          {{ $t("vma.recommandation_text_2") }} ({{ $store.state.agency_commercial_name }}) {{ $t("vma.get_rdv_text") }}.
        </p>

        <div v-else class="pb-10" style="position: relative">
          <v-chip-group v-model="v_model_chip_group" active-class="primary" column>
            <div v-for="(item, key) in filtered_slots_available" :key="key" style="width: 100%" class="mb-5">
              <p class="mb-0 semibold--text font-size--18 secondary--text">{{ key }}</p>
              <v-chip v-for="(slot, key2) in item" :value="slot" :key="key2" @click="slot_click(slot)">
                {{ slot.start_slot|time }}
              </v-chip>
            </div>
          </v-chip-group>

          <v-overlay :value="disabled_slots" absolute opacity="0.7">
            <p :class="`white--text bold--text font-italic text-center my-10 ${$vuetify.breakpoint.xs ? 'font-size--16' : 'font-size--20'}`">
              {{!chosen_calendar ? $t('vma.overlay_text_1') : $t('vma.overlay_text_2')}}
            </p>
          </v-overlay>
        </div>
      </template>
    </PrCardComponent>

    <v-col cols="12" sm="8" offset-sm="2" class="mt-5" v-else>
      <PrCardComponent :title="$t('vma.no_eligible_text')" icon="" class="px-5" color="secondary">
        <template v-slot:card-text>
          <slot name="text_not_eligible"></slot>
        </template>
      </PrCardComponent>
    </v-col>

    <v-dialog v-model="dialog_confirm_rdv" width="700" persistent>
      <v-card>
        <DialogCardTitleTemplate :title="$t('prevana.appointment_confirm_text')" @click="dialog_confirm_rdv=false"/>
        <v-card-text class="pt-8">
          <p class="font-size--18 black--text">
            {{$t(title_recap_place)}} :
            <span class="font-size--18 semibold--text primary--text">{{ get_service_name() }}</span>
          </p>
          <p class="font-size--18 black--text">
            {{$t(title_recap_date)}} :
            <span class="font-size--18 semibold--text primary--text">{{ date_displayed }}</span>
          </p>
          <p class="font-size--18 black--text" v-if="show_rdv_type">
            {{$t("prevana.meet_type_text")}} :
            <span v-if="type_rdv_consult" class="font-size--18 semibold--text primary--text">{{ type_rdv_consult === 1 ? text_consult : text_telco }}</span>
            <span v-else class="font-size--18 semibold--text primary--text">{{$t("vma.much_choose_type_rdv")}}</span>
          </p>
          <p class="font-size--18 black--text" v-if="chosen_pro">
            Professionnel :
            <span class="font-size--18 semibold--text primary--text">{{chosen_pro_name}}</span>
          </p>
        </v-card-text>
        <v-card-actions class="justify-center py-6">
          <v-btn @click="dialog_confirm_rdv=false" color="secondary" class="px-6" text
                 :disabled="loading_confirm_rdv">
            <span class="text-decoration-underline">{{ $t("prevana.chose_other_date") }}</span>
          </v-btn>
          <v-btn @click="validate_appointement()" color="secondary" elevation="0" class="px-6"
                 :loading="loading_confirm_rdv" :disabled="show_rdv_type && !type_rdv_consult">
            {{ $t('prevana.confirmation_app_text') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar :value="is_eligible && (creneau_indisponible || error_message.length)" bottom color="error" timeout="-1">
      <div class="d-flex justify-space-between align-center">
        <v-icon>mdi-alert</v-icon>
        <span v-if="creneau_indisponible" class="medium--text font-size--16">{{ $t('vma.appointment_alert') }}</span>
        <span v-else class="medium--text font-size--16">{{error_message}}</span>
        <v-btn icon @click="creneau_indisponible = false; error_message = '';">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import DialogCardTitleTemplate from "@/components/patient/components/DialogCardTitleTemplate";

import {get_formatted_date} from "anam-library/src/js/dates";
import axios from "axios";
import getCookie from "anam-library/src/js/common";
import moment from "moment-timezone";

export default {
  name: "PatientAppointementComponent",
  components: {PrCardComponent, DialogCardTitleTemplate},
  props: {
    title_recap_place: {
      type: String,
      default: "prevana.meet_place_text"
    },
    title_recap_date: {
      type: String,
      default: "prevana.meet_date_text"
    },
    type_rdv: {
      type: String,
      required: true
    },
    redirection: {
      type: Object,
      required: true
    },
    is_eligible: {
      type: Boolean,
      default: true
    },
    app_name: {
      type: String,
      required: true
    },
    show_rdv_type: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    overlay: true,
    date_displayed: null,
    health_facility_id: null,
    creneau_indisponible: false,
    no_slot_available: false,
    calendars: [],
    chosen_calendar: null,
    dialog_confirm_rdv: false,
    loading_confirm_rdv: false,
    error_message: "",
    selected_slot: null,
    loading_calendars: false,
    loading_dates: false,
    pro_choices: [],
    chosen_pro: null,
    type_rdv_consult: null,
    slots_available: {},
    v_model_chip_group: null,
    unique_pro_already_chosen: null
  }),
  created() {
    this.get_calendars();
    this.get_agency_name();
  },
  mounted() {
    if (this.show_rdv_type === false) this.type_rdv_consult = 1;
    this.$vuetify.goTo(0);
  },
  computed: {
    disabled_slots: function() {
      return !!(this.pro_choices.length && !this.chosen_pro) || !this.chosen_calendar;
    },
    filtered_slots_available() {
      if (!this.chosen_pro) return this.slots_available;
      let response = {};
      for (let i in this.slots_available) {
        let data = this.slots_available[i].filter(x => x.professionnels && x.professionnels.some(y => y[0] === this.chosen_pro));
        if (data.length) response[i] = data;
      }
      return response;
    },

    chosen_calendar_data() {
      if (!this.chosen_calendar) return null;
      let index = this.calendars.findIndex(x => x.value === this.chosen_calendar);
      if (index === -1) return null;
      return this.calendars[index];
    },

    has_type_consult() {
      return this.chosen_calendar_data && this.chosen_calendar_data.type_rdv && this.chosen_calendar_data.type_rdv.includes(1);
    },
    has_type_telco() {
      return this.chosen_calendar_data && this.chosen_calendar_data.type_rdv && this.chosen_calendar_data.type_rdv.includes(2);
    },
    text_consult() {
      return this.$t("common.consult_physique");
    },
    text_telco() {
      return this.$t("common.consult_telco");
    },
    chosen_pro_name() {
      if (!this.chosen_pro) return "";
      let index = this.pro_choices.findIndex(x => x.value === this.chosen_pro);
      if (index === -1) return "";
      return this.pro_choices[index].text;
    }
  },

  watch: {
    chosen_calendar(val) {
      if (val) {
        this.chosen_pro = null;
        this.unique_pro_already_chosen = null;
        if (val.create_slots_automatically) this.pro_choices = [];
        let motif_data = this.$store.getters.getMotifData(this.chosen_calendar_data.consultation_type);
        if (motif_data && motif_data.unique_pro) {
          this.loading_dates = true;
          let url = "/api/patients/0/consultations";
          if (this.$route.params.id) url = `/api/patients/${this.$route.params.id}/consultations`;
          this.$http.get(url, {params: {app_name: this.app_name, get_pro: true}}).then(response => {
            let consults = response.data.filter(x => x.type_consultation === this.chosen_calendar_data.consultation_type && !x.cancelled);
            if (consults.length && consults[0].slot && consults[0].slot.professionnels && consults[0].slot.professionnels.length) {
              this.unique_pro_already_chosen = consults[0].slot.professionnels[0][0];
            }
            this.get_available_dates();
          }).catch(() => {
            this.loading_dates = false;
          })
        }
        else {
          this.get_available_dates();
        }
      }
    }
  },
  methods: {
    get_agency_name() {
      this.$http.get("/api/prevana_related_data").then(response => {
        this.$store.state.agency_commercial_name = response.data.agency_commercial_name;
      })
    },

    get_calendars() {
      this.loading_calendars = true;
      this.$http.get("/api/agencies/calendars", {params: {type_rdv: this.type_rdv, app_name: this.app_name, consultation_type: this.$route.query.consultation_reason}}).then(response => {
        for (let calendar of response.data) {
          if (calendar.is_active) {
            let name = calendar.name;
            if (!this.$route.query.consultation_reason) {
              let motif = this.$store.getters.getMotifName(calendar.consultation_type);
              if (motif) name = `${name} (${motif})`;
            }
            this.calendars.push({
              text: name,
              value: calendar.id,
              consultation_type: calendar.consultation_type,
              create_slots_automatically: calendar.create_slots_automatically,
              type_rdv: calendar.type_rdv
            })
          }
        }
      }).finally(() => {
        this.loading_calendars = false;
      })
    },

    get_available_dates() {
      this.loading_dates = true;
      this.error_message = "";
      this.$http.get(`/api/hf-calendars/${this.chosen_calendar}`, {params: {app_name: this.app_name, get_slots_available: true, get_pro: true}}).then(response => {
        this.health_facility_id = response.data.health_facility_id;
        this.pro_choices = [];
        let temp = {};
        this.no_slot_available = true;
        for(let slot of response.data.slots_available.sort((a, b) => moment(a.start_slot) - moment(b.start_slot))){
          let date_str = get_formatted_date(slot.start_slot, {output_format: "DD MMMM YYYY"}, true);
          if(!slot.link_to_consultation && slot.is_active) {
            this.no_slot_available = false;
            if (Object.keys(temp).includes(date_str)) temp[date_str].push(slot);
            else temp[date_str] = [slot];
            if (slot.professionnels) {
              for (let pro of slot.professionnels) {
                if (!this.unique_pro_already_chosen || this.unique_pro_already_chosen === pro[0]) {
                  let index = this.pro_choices.findIndex(x => x.value === pro[0]);
                  if (index === -1) this.pro_choices.push({value: pro[0], text: `${pro[1]} ${pro[2]}`});
                }
              }
            }
          }
        }
        this.slots_available = temp;
        if (this.pro_choices.length === 1) this.chosen_pro = this.pro_choices[0].value;
      }).finally(() => {
        this.loading_dates = false;
      })
    },

    slot_click(slot) {
      this.date_displayed = get_formatted_date(slot.start_slot);
      this.selected_slot = slot.id;
      this.dialog_confirm_rdv = true;
    },

    validate_appointement() {
      this.error_message = "";
      this.loading_confirm_rdv = true;
      let data = {
        slot_id: this.selected_slot,
        health_facility_id: this.health_facility_id,
        calendar_id: this.chosen_calendar,
        type_consultation: this.type_rdv_consult,
      };
      let index = this.calendars.findIndex(x => x.value === this.chosen_calendar);
      if (index > -1) {
        if (this.calendars[index].consultation_type) data["consultation_reason"] = this.calendars[index].consultation_type;
      }
      let url;
      if (this.$route.params.id) url = `/api/patients/${this.$route.params.id}/consultations`;
      else url = "/api/patients/0/consultations";
      axios.post(url, data, {params: {app_name: this.app_name}, headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(() => {
        this.$router.push(this.redirection);
      }).catch(e => {
        if (e.response.status === 401) {
          this.$router.push('/401');
        }
        else if (e.response.status === 403) {
          this.error_message = e.response.data;
        }
        else if (e.response.status === 422) {
          let errors_key = Object.keys(e.response.data);
          if (errors_key.includes('start_slot')) {
            this.creneau_indisponible = true;
            this.get_available_dates();
          }
          else if (errors_key.includes("is_current_school_year")) {
            this.error_message = this.$t(e.response.data.is_current_school_year);
          }
          else {
            try {
              this.error_message = e.response.data.non_field_errors[0];
            }
            catch (e) {
              this.error_message = "Une erreur est survenue, veuillez réessayer plus tard ou nous contacter si le problème persiste";
            }
          }
        }
        else if (e.response.status === 417) {
          this.creneau_indisponible = true;
          this.get_available_dates();
        }
        else {
          this.error_message = "Une erreur est survenue, veuillez réessayer plus tard ou nous contacter si le problème persiste";
        }
      }).finally(() => {
        this.loading_confirm_rdv = false;
        this.dialog_confirm_rdv = false;
      })
    },

    get_service_name() {
      for (let calendar of this.calendars) {
        if (calendar.value === this.chosen_calendar) return calendar.text;
      }
      return "";
    }
  },
  filters: {
    time: function(date) {
      if(date) return get_formatted_date(date, {output_format: 'HH:mm'})
      return date;
    }
  },
}
</script>

<style>
.table_rdv tr:hover {
  background-color: transparent !important;
}
</style>
