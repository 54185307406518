<template>
  <v-btn small text color="secondary" @click="go_to_details()">
    <span class="text-decoration-underline font-size--12 medium--text">{{ txt }}</span>
  </v-btn>
</template>

<script>
export default {
  name: "ButtonDetails",
  props: {
    txt: {
      type: String,
      required: true
    },
    redirection: {
      type: String,
      required: true
    }
  },
  methods: {
    go_to_details: function() {
      this.$router.push({name: this.redirection})
    }
  }
}
</script>

<style scoped>

</style>
