<template>
  <PatientFAQCardComponent :see_details="see_details" to_details="PatientRpsFAQ" name_details="Bien-Être">
    <template v-slot:panels>
      <v-expansion-panels v-model="panel" hover flat multiple>
        <v-expansion-panel class="bg_light my-3">
          <v-expansion-panel-header>
            <span class="primary--text semibold--text">Protection des données</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="soft_blue--text">
            <p class="medium--text">
              Nous prenons la sécurité des données personnelles très au sérieux, que ce soit des données de santé ou
              des données personnelles. A ce titre, Anamnèse s’engage à assurer le meilleur niveau de protection de
              vos données en respectant la réglementation française et européenne relative à la protection des
              données personnelles. (la loi Informatique et Libertés LIL du 6 janvier 1978, la loi n ° 2018- 493 du
              20 juin 2018 et le Règlement général sur la protection des données personnelles du 27 avril 2016
              (par la suite RGPD)).
            </p>
            <p class="medium--text">
              Vos données sont hébergées sur la plateforme PREVANA qui est une suite logicielle de prévention en
              entreprise en SaaS. Elles sont protégées par le secret médical sur des serveurs sécurisés qui sont
              accessibles uniquement par des professionnels de santé habilités.
            </p>
            <a :href="get_politique_confidentialite_url('prevana', 'rps')" target="_blank">En savoir plus</a>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel class="bg_light my-3">
          <v-expansion-panel-header>
            <span class="primary--text semibold--text">Qu'est ce que le COPSOQ ?</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="soft_blue--text">
            <p class="medium--text">
              Le COPSOQ ou le Copenhagen Psychosocial Questionnaire (COPSOQ), est une démarche d’évaluation validée
              scientifiquement dans 25 pays. Ce questionnaire évalue les facteurs de risques psychosociaux au travail.
              La version française actuelle est composée de 46 items regroupés en 24 échelles et rendant compte de
              six dimensions.
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel class="bg_light my-3">
          <v-expansion-panel-header>
            <span class="primary--text semibold--text">Qu'est ce que les risques psychosociaux ?</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="soft_blue--text">
            <p class="medium--text">
              Les risques psychosociaux sont définis comme un risque pour la santé physique et mentale des travailleurs.
              Leurs causes sont à rechercher à la fois dans les conditions d’emploi, les facteurs liés à l’organisation
              du travail et aux relations de travail. Ils peuvent concerner toutes les organisations quel que soient
              leur taille et leur secteur d’activité.
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel class="bg_light my-3">
          <v-expansion-panel-header>
            <span class="primary--text semibold--text">Interprétation des résultats</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="soft_blue--text">
            <p class="secondary--text font-weight-bold mb-0">Votre résultat est inférieur à 25</p>
            <p>Votre résultat reflète actuellement une bonne santé mentale sur cette dimension. N’hésitez pas néanmoins à consulter les ressources disponibles sur votre portail.</p>

            <p class="secondary--text font-weight-bold mb-0">Votre résultat est compris entre 26 et 50</p>
            <p>Votre état mental semble globalement stable sur cette dimension.</p>

            <p class="secondary--text font-weight-bold mb-0">Votre résultat est compris entre 51 et 75</p>
            <p>Cette tranche indique des signaux légers à plus importants de mal-être sur cette dimension.</p>

            <p class="secondary--text font-weight-bold mb-0">Votre résultat est supérieur à 75</p>
            <p>Vous semblez présenter des signes de mal-être plus ou moins importants.</p>

            <p v-html="txt_interpretation"></p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </PatientFAQCardComponent>
</template>

<script>
import PatientFAQCardComponent from "@/components/patient/components/faq/PatientFAQCardComponent";
import {get_faq_interpretation_text} from "anam-library/src/data/dict_terminology_by_agency";
import {LIST_SCORES_RPS} from "anam-library/src/data/scores_rps";
import {mixin_cgu} from "anam-library/src/js/utils";

export default {
  name: "RpsDashboardCardFAQ",
  components: {PatientFAQCardComponent},
  mixins: [mixin_cgu],
  props: {
    see_details: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    panel: [],
    list_scores_rps: LIST_SCORES_RPS
  }),
  computed: {
    txt_interpretation() {
      return get_faq_interpretation_text(this.$store.getters.getAgencyName);
    }
  }
}
</script>

<style scoped>
.v-expansion-panel{
  border-radius: 10px;
}
</style>
