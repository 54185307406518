<template>
  <v-container fluid class="pa-4">
    <AnBackButton v-on:back="$router.push({name: 'CovidhelpStatistics'})" color="primary" :x_large="false"/>
    <v-row class="mx-0">
      <v-col cols="auto">
        <h1 class="primary--text">Clusters</h1>
      </v-col>
    </v-row>
    <CovidClustersTable class="px-6 mt-6"/>
  </v-container>
</template>

<script>
import AnBackButton from "anam-library/src/components/an-ui/button/AnBackButton";
import CovidClustersTable from "@/components/common/covidhelp/clusters/CovidClustersTable";

export default {
  name: "DoctorCovidClustersView",
  components: {
    AnBackButton,
    CovidClustersTable
  }
}
</script>

<style scoped>

</style>
