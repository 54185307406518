<template>
  <v-app>
    <AnOverlayLoading :is_mounted="$store.state.is_mounted_app"/>
    <PatientHeader/>
    <v-main class="bg_primary">
      <router-view/>
      <MissingCGU :missing_cgu_dialog="missing_cgu_dialog" @close_modal="missing_cgu_dialog = false"></MissingCGU>
    </v-main>
    <v-footer class="bg_primary pt-10 justify-center">
      <a :href="get_mentions_legales_url('prevana', 'prevana')" target="_blank">{{ $t("footer.legals") }} - {{ current_agency.commercial_name }} </a>
    </v-footer>
  </v-app>
</template>

<script>
import colors_themes from "@/plugins/colors_themes";
import PatientHeader from "./header/PatientHeader";
import AnOverlayLoading from "anam-library/src/components/an-ui/loading/AnOverlayLoading";
import MissingCGU from "./dashboard/MissingCGU";


import {mixin_cgu} from "anam-library/src/js/utils";


export default {
  name: "PatientLayout",
  mixins: [mixin_cgu],
  components: {
    PatientHeader,
    AnOverlayLoading,
    MissingCGU
  },
  data: () => ({
    missing_cgu_dialog: false
  }),
  computed: {
    current_agency: function() {
      return this.$store.getters.getCurrentAgency;
    },
    agency_name: function() {
      return this.$store.getters.getAgencyName;
    }
  },
  mounted() {
    if (Object.keys(colors_themes).includes(`theme_patient_${this.agency_name}`)) {
      this.$vuetify.theme.themes.light = colors_themes[`theme_patient_${this.agency_name}`]["theme"]["themes"]["light"];
    }
    else {
      this.$vuetify.theme.themes.light = colors_themes.theme_patient["theme"]["themes"]["light"];
    }
    this.$store.state.is_mounted_app = true;
    if (!this.$store.getters.getPatientCgu || !this.$store.getters.getPatientConfidentiality) {
      this.$http.get("/api/user").then(response => {
        this.$store.commit('setPatientCgu', !!response.data.userutils.prevana_cgu_accord);
        this.$store.commit('setPatientConfidentiality', !!response.data.userutils.prevana_politique_confidentialite_accord);
        this.missing_cgu_dialog = !response.data.userutils.prevana_cgu_accord || !response.data.userutils.prevana_politique_confidentialite_accord;
      }).catch(() => {
        this.missing_cgu_dialog = true;
      })
    }
  }
};
</script>
