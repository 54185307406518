<template>
  <v-tab-item class="bg pt-4 pt-sm-8">
    <v-row class="mx-0 mb-3">
      <v-col cols="12" class="align-self-end py-0">
        <p class="font-italic font-size--14 mb-0">
          Vous pouvez effectuer une recherche des patients via le nom, le prénom, ou la date de naissance du patient.
          <br>
          <span class="font-size--14 semibold--text">Pensez à appuyer sur {{$vuetify.breakpoint.xsOnly? 'Rechercher': '"entrée"'}} une fois la saisie effectuée pour afficher les patients</span>.
        </p>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field v-model="search_last_name" label="Nom" dense solo hide-details clearable @keydown.enter="search_patients()"/>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field v-model="search_first_name" label="Prénom" dense solo hide-details clearable @keydown.enter="search_patients()"/>
      </v-col>
      <v-col cols="12" sm="2">
        <AnDate :date="search_birthdate"
                label="Date de naissance" icon=""
                :filled="false"
                hide_details :validate="false"
                reset_icon solo dense
                v-on:date_reset="search_birthdate=null"
                v-on:formatted_date="search_birthdate=$event; search_patients()"
        />
      </v-col>
      <v-col cols="12" sm="2">
        <v-select v-model="search_amenagement" :items="items_search_amenagement" label="Aménagement" dense solo hide-details @change="search_patients()"/>
      </v-col>
      <v-col cols="12" sm="2">
        <v-select v-model="search_rqth" :items="items_search_rqth" label="RQTH" dense solo hide-details @change="search_patients()"/>
      </v-col>

      <v-col cols="12">
        <AnDataTable :headers="all_patients_headers"
                     :items="all_patients"
                     :loading="loading"
                     :table_items_mapping="body"
                     :mobile_card_format="true"
                     :patient_table="true"
                     no_data_txt="Aucun résultat"
                     v-on:move_to_dpa="$emit('move_to_dpa', $event)"
        />
      </v-col>

      <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12" class="text-center align-self-end">
        <v-btn elevation="0" rounded color="primary" class="px-6" :loading="loading" :disabled="disabled_search" @click="search_patients()">
          <v-icon class="mr-1">mdi-magnify</v-icon>
          <span class="medium--text font-size--16">Rechercher</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-tab-item>
</template>

<script>
import AnDataTable from "anam-library/src/components/an-data-table/AnDataTable";
import AnDate from "anam-library/src/components/an-date/AnDate";
import {get_formatted_date} from "anam-library/src/js/dates";

export default {
  name: "DoctorVMADashboardAdvancedSearchView",
  components: {
    AnDataTable,
    AnDate,
  },
  props: {
    body: {
      type: Object,
      required: true
    },
    data_tables_json: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    all_patients: [],
    search_last_name: null,
    search_first_name: null,
    search_birthdate: null,
    search_amenagement: null,
    search_rqth: null,
    items_search_amenagement: [
      {text: "Aménagment : Tous", value: null},
      {text: "Aménagment : Oui", value: 1},
      {text: "Aménagment : Non", value: 2},
      {text: "Aménagment : En attente", value: 3},
    ],
    items_search_rqth: [
      {text: "RQTH : Tous", value: null},
      {text: "RQTH : Oui", value: "1"},
      {text: "RQTH : Non", value: "0"},
    ],
    loading: false
  }),
  computed: {
    disabled_search() {
      return !this.search_last_name && !this.search_first_name && !this.search_birthdate && this.search_amenagement === null && this.search_rqth === null;
    },
    all_patients_headers() {
      let key = Object.keys(this.data_tables_json)[0];
      let headers = JSON.parse(JSON.stringify(this.data_tables_json[key].headers));
      for (let header of headers) {
        header.class = "white--text py-4 primary";
      }
      return headers;
    }
  },
  methods: {
    search_patients() {
      if (this.disabled_search) {
        this.all_patients = [];
        return;
      }
      this.loading = true;
      let data = {
        "last_name": this.search_last_name,
        "first_name": this.search_first_name,
      };
      if (this.search_birthdate) data["birthdate"] = get_formatted_date(this.search_birthdate, {input_format: "DD/MM/YYYY", output_format: "YYYY-MM-DD"});
      if (this.search_amenagement !== null) data["amenagement"] = this.search_amenagement;
      if (this.search_rqth !== null) data["rqth"] = this.search_rqth;

      this.$http.get(`/api/vma/patients/advanced-search`, {params: data}).then(response => {
        let all_patients = response.data;
        if (this.search_amenagement !== null) all_patients = all_patients.filter(x => x.aptitude === this.search_amenagement);
        if (this.search_rqth === "1") all_patients = all_patients.filter(x => x.rqth === true);
        if (this.search_rqth === "0") all_patients = all_patients.filter(x => x.rqth === false);
        this.all_patients = all_patients;
      }).finally(() => {
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>
