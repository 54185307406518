<template>
  <v-container fluid class="pa-5">
    <v-row class="mx-0">
      <v-col cols="8">
        <h1 class="primary--text">CovidHELP - Statistiques</h1>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-btn color="primary" outlined elevation="0" @click="$router.push({name: 'CovidhelpTableSuivi'})" class="mr-4">
          <span class="medium--text">Tableau de suivi</span>
        </v-btn>
        <v-btn color="primary" outlined elevation="0" @click="$router.push({name: 'DoctorCovidClusters'})">
          <span class="medium--text">Clusters</span>
        </v-btn>
      </v-col>
    </v-row>
    <CovidStatisticsComponent/>
  </v-container>
</template>

<script>
import CovidStatisticsComponent from "@/components/common/covidhelp/statistics/CovidStatisticsComponent";

export default {
  name: 'StatisticsView',
  components: {
    CovidStatisticsComponent,
  },
};
</script>

<style>
</style>
