<template>
  <PrCardComponent :title="$t('vma.rdv_1')" icon="mdi-calendar-month-outline" max_height>
    <template v-slot:card-title-append>
      <v-spacer></v-spacer>
      <ButtonDetails v-if="see_details" :txt="$t('vma.rdv_2')" :redirection="to_details"/>
    </template>
    <template v-slot:card-text>
      <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
      <div ref="rdv_list" v-else>
        <NoDataTxt v-if="rdv.length === 0" :txt="`${$t('vma.rdv_3')} ${name_details} ${$t('vma.rdv_4')}`"/>

        <div v-for="(item, key) in rdv" :key="key" :class="`rdv_item ${is_passed_rdv(item) ? 'passed_rdv' : ''}`">
          <p class="primary--text font-size--16 semibold--text mb-2">
            <template v-if="item.slot">{{ item.slot.start_slot|date }}</template>
            <span v-else>{{$t('vma.rdv_5')}}</span>
            <br v-if="small_version">
            <template v-else-if="item.consultation_reason"> - </template>
            {{ get_consultation_reason(item.consultation_reason) }} {{get_type_consultation(item.type_consultation)}}
          </p>
          <div>
<!--            <v-btn small color="bg_light" elevation="0" class="primary&#45;&#45;text mx-1">-->
<!--              <v-icon color="primary" small class="mr-1">mdi-pencil</v-icon>-->
<!--              <span class="medium&#45;&#45;text">Modifier</span>-->
<!--            </v-btn>-->
            <ButtonCancelRDV v-if="!is_passed_rdv(item)" :rdv_id="item.id" v-on:rdv_cancelled="rdv_cancelled($event)"></ButtonCancelRDV>
          </div>
          <v-list dense>
            <v-list-item v-if="show_pro">
              <RDVInfoListItemContent :title="$t('vma.rdv_6')" :value="item.health_practitioner_name"/>
            </v-list-item>
            <v-list-item>
              <RDVInfoListItemContent :title="$t('vma.rdv_7')" :value="item.place"/>
              <RDVInfoListItemContent v-if="!small_version && show_pro" :title="$t('vma.rdv_8')" :value="item.health_practitioner_phone"/>
            </v-list-item>
            <v-list-item v-if="small_version && show_pro">
              <RDVInfoListItemContent :title="$t('vma.rdv_8')" :value="item.health_practitioner_phone"/>
            </v-list-item>
          </v-list>
          <v-divider v-if="(key+1) !== rdv.length" class="mt-3 mb-5"></v-divider>
        </div>
      </div>

      <slot name="append-action"></slot>

    </template>
  </PrCardComponent>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import NoDataTxt from "@/components/patient/components/NoDataTxt";
import ButtonDetails from "@/components/patient/components/ButtonDetails";
import RDVInfoListItemContent from "@/components/patient/components/rdv/RDVInfoListItemContent";

import {add_scroll_if_multiple_elements} from "anam-library/src/js/utils";
import {get_formatted_date} from "anam-library/src/js/dates";
import {get_formated_address} from "anam-library/src/js/address";
import moment from "moment-timezone";
import ButtonCancelRDV from "./ButtonCancelRDV";

export default {
  name: "PatientRDVCardComponent",
  components: {
    ButtonCancelRDV,
    NoDataTxt,
    PrCardComponent,
    ButtonDetails,
    RDVInfoListItemContent,
  },
  props: {
    small_version: {
      type: Boolean,
      default: false
    },
    show_pro: {
      type: Boolean,
      default: false
    },
    see_details: {
      type: Boolean,
      default: false
    },
    to_details: {
      type: String,
      default: null
    },
    name_details: {
      type: String,
      default: ""
    },
    app_name: {
      type: String,
      required: true
    },
    filter_out_passed_rdv: {
      type: Boolean,
      default: true
    },
    scroll_multiple_elements: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    rdv: [],
    loading: false,
    completed_api_calls: 0
  }),
  computed: {
    app_name_list() {
      return this.app_name.split(",");
    }
  },
  watch: {
    completed_api_calls(val) {
      if (val === this.app_name_list.length) {
        this.loading = false;
        if (this.filter_out_passed_rdv) {
          this.rdv = this.rdv.sort((a, b) => moment(a.slot.start_slot) - moment(b.slot.start_slot));
        }
        else {
          let upcoming_rdv = this.rdv.filter(x => x.slot && moment(x.slot.start_slot) > moment());
          upcoming_rdv = upcoming_rdv.sort((a, b) => moment(a.slot.start_slot) - moment(b.slot.start_slot));
          let passed_rdv = this.rdv.filter(x => x.slot && moment(x.slot.start_slot) < moment());
          passed_rdv = passed_rdv.sort((a, b) => moment(a.slot.start_slot) - moment(b.slot.start_slot));
          this.rdv = upcoming_rdv.concat(passed_rdv);
        }
        this.$emit("api_call_completed", this.rdv);
        if (this.scroll_multiple_elements) {
          setTimeout(() => {
            add_scroll_if_multiple_elements(this.$refs.rdv_list, "rdv_item", this.rdv, 2);
          })
        }
      }
    }
  },
  mounted() {
    this.loading = true;
    for (let app_name of this.app_name_list) {
      this.$http.get("/api/patients/0/consultations", {params: {app_name: app_name, skip_info_ressource: true}}).then(response => {
        let tmp = [];
        let new_rdv = response.data.filter(x => !x.cancelled);
        if (this.filter_out_passed_rdv) new_rdv = new_rdv.filter(x => x.slot && moment(x.slot.start_slot) > moment());
        for (let rdv of new_rdv) {
          rdv.app_name = app_name;
        }
        tmp = tmp.concat(new_rdv);
        this.$emit("rdv_loaded", tmp);
        this.rdv = tmp.filter(x => !x.closed);
      }).finally(() => {
        this.completed_api_calls += 1;
      })
    }
  },
  methods: {
    rdv_cancelled(rdv_id) {
      this.rdv = this.rdv.filter(x => x.id !== rdv_id);
    },
    is_passed_rdv(item) {
      return item.slot && moment(item.slot.start_slot).isBefore(moment(), "minute");
    },
    get_consultation_reason(consultation_reason) {
      let consult_types = this.$store.getters.getMotifsConsultation;
      let index = consult_types.findIndex(x => x.id === parseInt(consultation_reason));
      if (index > -1) return consult_types[index][`commercial_name_${this.$i18n.locale}`];
      return "";
    },
    get_type_consultation(type_consultation) {
      if (type_consultation === 1) return `(${this.$t("common.consult_physique")})`;
      else if (type_consultation === 2) return `(${this.$t("common.consult_telco")})`;
      return "";
    }
  },
  filters: {
    date: function(val) {
      return get_formatted_date(val);
    },
    address: function(val) {
      return get_formated_address(val);
    }
  }
}
</script>

<style scoped>
.passed_rdv {
  opacity: 0.4;
}
</style>
