<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>
    <v-row class="ma-2 ma-sm-5">
      <v-col cols="12" sm="8" offset-sm="2" class="mt-10">
        <PrCardComponent :title="title" icon="mdi-calendar-check" color="secondary">
          <template v-slot:card-title-append v-if="!status">
            <v-spacer></v-spacer>
            <ButtonCancelRDV
              :rdv_id="id"
              :not_allowed="is_within_24_hours"
              v-on:rdv_cancelled="$router.push('/patient/vaccination/dashboard')"
              v-on:not_allowed="dialog_is_within_24_hours = true"
              :small="false"
            ></ButtonCancelRDV>
          </template>
          <template v-slot:card-text>
            <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
            <v-row>
              <v-col v-if="status === 'programmed'" cols="12" class="font-size--16 px-5">
                <p class="text-center normal--text black--text font-size--14 mt-5">Vous aviez rendez-vous le
                  <span class="primary--text font-size--16 semibold--text">{{ date }}</span>
                  au <span class="primary--text font-size--16 semibold--text">{{ address }}</span>
                </p>
                <v-divider class="primary mt-5 mb-10"></v-divider>
                <p class="text-center semibold--text primary--text">Avez-vous été vacciné lors de ce rendez-vous ?</p>
                <div class="text-center mt-5">
                  <v-btn outlined color="secondary" elevation="0" class="medium--text px-6 mr-3" @click="start_questionnaire()">Non</v-btn>
                  <v-btn color="secondary" elevation="0" class="medium--text px-6 ml-3" @click="status = 'stopped'">Oui</v-btn>
                </div>
              </v-col>

              <v-col v-else-if="status === 'stopped'" cols="12" class="font-size--16 mt-5">
                <p class="normal--text black--text font-size--14">Afin que vous puissiez prendre rendez-vous pour votre vaccination, nous vous invitons à vous
                  rapprocher de votre centre de vaccination
                  <span class="primary--text font-size--16 semibold--text">{{ $store.state.agency_commercial_name }}</span>
                </p>
                <p class="normal--text black--text font-size--14">Si vous avez déjà été vacciné, merci de leur indiquer le jour et l'heure de votre dernière vaccination.</p>
              </v-col>

              <v-col v-else-if="status === 'finalised'" cols="12" class="font-size--16 mt-5">
                <p class="normal--text black--text font-size--14">
                  Nous comprenons que vous souhaitez recevoir une dose de rappel.
                  Malheureusement, votre centre de vaccination
                  <span class="primary--text font-size--16 semibold--text">{{ $store.state.agency_commercial_name }}</span>
                  n’a pas encore lancé sa campagne de rappel. En attendant, vous pouvez vous rapprocher de votre centre pour lui faire part de votre souhait de recevoir une injection de rappel.
                </p>
              </v-col>

              <v-col v-else cols="12" class="font-size--16 px-5">
                <p class="normal--text black--text font-size--14 my-5">Nous vous confirmons votre rendez-vous prévu le
                  <span class="primary--text font-size--16 semibold--text">{{ date }}</span>
                  au <span class="primary--text font-size--16 semibold--text">{{ address }}</span>
                </p>
                <v-alert text color="primary" class="text-center font-size--14 mt-8">
                  <div class="text-justify semibold--text">
                    <v-icon color="primary" class="mb-2" small>mdi-information</v-icon>
                    Nous vous prions de respecter le créneau réservé et de vous présenter, si possible, muni de :
                    <p class="mb-1 ml-5 mt-1 semibold--text"> - Votre carnet de vaccination</p>
                    <p class="mb-1 ml-5 semibold--text"> - Votre carte vitale</p>
                    <p class="mb-1 ml-5 semibold--text"> - Tous les documents ou examens complémentaires que vous souhaitez présenter avant votre vaccination</p>
                  </div>
                </v-alert>
              </v-col>

              <v-col cols="12" class="text-center">
                <v-btn v-if="answer_questionnaire_text" color="primary" class="mx-sm-2 px-sm-4" @click="start_questionnaire()">{{ answer_questionnaire_text }}</v-btn>
                <ButtonBackDashboard redirection="PatientVaccinationDashboard"/>
              </v-col>
            </v-row>
          </template>
        </PrCardComponent>
      </v-col>

      <v-dialog v-model="dialog_is_within_24_hours" width="500">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title class="white--text text-center">Impossible d'annuler le RDV</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="text-center pt-5">
            <p>Les doses de vaccins étant difficiles à se procurer, les annulations en ligne ne sont pas possibles
              durant les 24h qui précèdent la vaccination.</p>
            <p>Merci de vous rapprocher de votre centre de vaccination ({{$store.state.agency_commercial_name}}) afin
              qu’ils soient prévenus et qu’ils puissent trouver un
              remplaçant<span v-if="agency_phone_number"> ou de les contacter au {{agency_phone_number}}</span>.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="white--text px-sm-8 mx-sm-2" rounded large @click="dialog_is_within_24_hours = false">Fermer</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import ButtonCancelRDV from "../../components/rdv/ButtonCancelRDV";
import ButtonBackDashboard from "../../components/ButtonBackDashboard";

export default {
    name: "PatientVaccinationAppointmentSummaryView",
    components: {ButtonBackDashboard, ButtonCancelRDV, PrBreadcrumbsComponent, PrCardComponent},
    data: () => ({
      date: null,
      address: null,
      id: null,
      status: null,
      status_vaccination: null,
      acte_id: null,
      is_within_24_hours: false,
      dialog_is_within_24_hours: false,
      agency_phone_number: null,
      vaccination_number: null,
      answer_questionnaire_text: "",
      loading: false,
      navigations: [
        {
          text: 'Vaccination',
          to: '/patient/vaccination/dashboard',
        },
        {
          text: 'Rendez-vous',
          to: '/patient/vaccination/summary'
        },
      ]
    }),

    created() {
      this.loading = true;
      this.$http.get("/api/vaccination_appointment_info").then(response => {
        if (response.data) {
          this.status_vaccination = response.data.status;
          this.acte_id = response.data.acte_id;
          this.is_within_24_hours = response.data.is_within_24_hours;
          this.vaccination_number = response.data.vaccination_number;
          if (response.data.passed_consultation) {
            this.date = response.data.passed_consultation.date;
            this.address = response.data.passed_consultation.address;
          } else {
            this.id = response.data.id;
            this.date = response.data.date;
            this.address = response.data.address;
            this.get_questionnaires();
          }
        }
      }).finally(() => {
        this.loading = false;
      });

      this.$http.get("/api/prevana_related_data").then(response => {
        this.agency_phone_number = response.data.agency_phone_number;
        this.$store.state.agency_commercial_name = response.data.agency_commercial_name;
      })
    },

    mounted() {
      if (this.$route.query) this.status = this.$route.query.status;
      this.$vuetify.goTo(0);
    },

    computed: {
      title() {
        if (this.$route.query.status === 'programmed') return "Votre rendez-vous a déjà eu lieu";
        if (this.$route.query.status === 'stopped' || this.$route.query.status === 'finalised') return "Vous ne pouvez pas prendre rendez-vous";
        return "Rendez-vous confirmé !";
      }
    },

    methods: {
      get_questionnaires() {
        this.$http.get("/api/patients/0/questionnaire_vaccination").then(response => {
          let quest = response.data.filter(x => x.vaccination_number === this.vaccination_number);
          if (quest.length) {
            this.answer_questionnaire_text = "Mettre à jour mes réponses";
          }
          else {
            this.answer_questionnaire_text = "Répondre au questionnaire";
          }
        })
      },

      start_questionnaire() {
        let questionnaire_type = this.vaccination_number === 1 ? "FirstVaccinationPrevana" : "SecondVaccinationPrevana";
        this.$router.push({name: "QuestionnaireView", query: {questionnaire_type: questionnaire_type, app_name: "prevanavaccination",
            nb_question: 2, additional_data: {vaccination_number: this.vaccination_number}}});
      },
    }
  }
</script>
