<template>
  <v-container fluid class="pa-4">
    <AnBackButton v-on:back="$router.push({name: 'CovidhelpStatistics'})" color="primary" :x_large="false"/>
    <v-row class="mx-0">
      <v-col cols="auto">
        <h1 class="primary--text">Tableaux de Suivi</h1>
      </v-col>
    </v-row>
    <CovidMonitoringTable class="px-6 mt-6"/>
  </v-container>
</template>

<script>
import AnBackButton from "anam-library/src/components/an-ui/button/AnBackButton";
import CovidMonitoringTable from "@/components/common/covidhelp/monitoring/CovidMonitoringTable";

export default {
  name: "DoctorCovidTableMonitoringView",
    components: {CovidMonitoringTable, AnBackButton}
}
</script>

<style scoped>

</style>
