export default {
    methods: {
        get_color_scores: function (score) {
            if (score >= 20) {
                return "red--text";
            } else if (score >= 10) {
                return "orange--text";
            } else {
                return "green--text";
            }
        },
        get_color_priorisation: function (score) {
            if (score === 3) {
                return "red darken-4";
            } else if (score === 2) {
                return "red";
            } else if (score === 1) {
                return "orange";
            } else if (score === 0) {
                return "green";
            }
        },
    }
}