<template>
  <div class="mb-8">
    <v-alert v-if="error" type="error" class="mb-0" text>Une erreur est survenue lors de l'enregistrement de votre réponse.</v-alert>
    <BlocPresentation/>
    <img src="@/assets/bg2.png" width="100%" class="hidden-xs-only"/>
    <BlocExplication/>
    <BlocInjection/>
    <img src="@/assets/bg2.png" width="100%" class="hidden-xs-only">
    <BlocRisque/>
    <BlocLinks/>

    <v-container fluid>
      <p class="soft_blue--text bold--text font-size--30 text-center mt-4">Que souhaitez-vous faire ?</p>
      <v-row class="ma-2 ma-sm-5">
        <v-col cols="12">
          <v-radio-group v-model="consent" class="px-sm-12">
            <v-radio :value="1" color="soft_blue">
              <template v-slot:label>
                <p class="mb-0">
                  <span class="soft_blue--text font-size--16 medium--text">Je souhaite être vacciné</span> et reconnais
                  avoir été informé du bénéfice-risque de recevoir un des vaccins covid19 actuellement
                  autorisé par l'Agence Nationale de sécurité du médicament.
                </p>
              </template>
            </v-radio>
            <v-radio color="soft_blue" label="Je dois y réflechir" :value="0"></v-radio>
            <v-radio color="soft_blue" label="Je ne souhaite pas être vacciné" :value="3"></v-radio>
            <v-radio color="soft_blue" label="Je me suis déjà fait vacciner ailleurs OU je vais me faire vacciner à l’extérieur (je ne prends pas RDV)" :value="1.5"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn color="secondary" large class="px-8 white--text" :disabled="!consent && consent !== 0" @click="validate_consent_and_move_to_appointment()">
            <span v-if="consent === 1">Vers la prise de rendez-vous</span>
            <span v-else>Valider</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog_unaccept_vaccination" persistent width="400">
      <v-card>
        <v-card-title class="primary white--text">Vaccination</v-card-title>
        <v-card-text class="pt-7 font-size--16" v-if="external">
          <p>Merci, nous avons pris en compte votre réponse.</p>
          <p>Votre centre de vaccination ({{ agency_name }}) sera informé que vous ne souhaitez pas prendre rendez-vous car vous vous êtes déjà fait vacciné ailleurs ou que vous allez vous faire vacciner en externe (en dehors de votre organisation).</p>
          <p>Si vous n’allez plus vous faire vacciner en externe (en dehors de votre organisation), vous pourrez toujours revenir et prendre RDV pour vous faire vacciner dans le centre de votre organisation.</p>
          <p>Continuons à maintenir les gestes barrières.</p>
        </v-card-text>
        <v-card-text class="pt-7 font-size--16" v-else-if="temporisation">
          <p>Merci, nous avons pris en compte votre réponse.</p>
          <p>Si vous avez des questions, n’hésitez pas à revenir vers votre centre de vaccination ({{ agency_name }}) ou votre médecin traitant pour avoir plus d’informations.</p>
        </v-card-text>
        <v-card-text class="pt-7 font-size--16" v-else>
          <p>Nous avons pris en compte <span class="font-size--16 soft_blue--text semibold--text">votre souhait de ne pas être vacciné</span>. Merci de nous en avoir informé.</p>
          <p>Maintenez les gestes barrières, et n’hésitez pas à revenir vers votre centre de vaccination ({{ agency_name }}) ou votre médecin traitant pour échanger à ce sujet.</p>
        </v-card-text>
        <v-card-actions class="justify-center pb-4">
          <v-btn color="soft_blue" class="px-5" outlined @click="$router.push({name: 'PatientDashboard'}); loading_back=true" :loading="loading_back">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import BlocPresentation from "../../../vaccination/patient/consentement/BlocPresentation";
import BlocExplication from "../../../vaccination/patient/consentement/BlocExplication";
import BlocInjection from "../../../vaccination/patient/consentement/BlocInjection";
import BlocRisque from "../../../vaccination/patient/consentement/BlocRisque";
import BlocLinks from "../../../vaccination/patient/consentement/BlocLinks";

import moment from "moment-timezone"

export default {
    name: "PatientVaccinationConsentView",
    components: {
      BlocPresentation,
      BlocExplication,
      BlocInjection,
      BlocRisque,
      BlocLinks
    },
    data: () => ({
      consent: null,
      loading: false,
      error: false,
      dialog_unaccept_vaccination: false,
      temporisation: false,
      external: false,
      loading_back: false,
    }),
    mounted() {
      this.$vuetify.goTo(0);
    },
    computed: {
      agency_name() {
        return this.$store.getters.getAgencyName;
      }
    },
    methods: {
      validate_consent_and_move_to_appointment() {
        this.error = false;
        if(this.consent || this.consent === 0){
          this.loading = true;
          let data = {
            status_history:[{
              datetime: moment().utc().format("DD/MM/YYYY HH:mm"),
              status: this.consent
            }]
          };

          if (this.$route.query.vaccination) {
            this.$http.patch("/api/set_vaccination_status/" + this.$route.query.vaccination, data).then(() => {
              this.success_axios();
            }).catch(() => {
              this.error = true;
              this.$vuetify.goTo(0);
            }).finally(() => {
              this.loading = false;
            })
          }

          else {
            this.$http.post("/api/set_vaccination_status", data).then(() => {
              this.success_axios();
            }).catch(() => {
              this.error = true;
              this.$vuetify.goTo(0);
            }).finally(() => {
              this.loading = false;
            })
          }
        }
      },

          success_axios() {
              if(this.consent === 1){
                  let questionnaire = this.$route.query.vaccination_number === 1 ? "FirstVaccinationPrevana" : "SecondVaccinationPrevana";
                  this.$router.push({name: "QuestionnaireView", query: {questionnaire_type: questionnaire, app_name: "prevanavaccination", nb_question: 2, additional_data: {vaccination_number: this.$route.query.vaccination_number}}});
              } else if (this.consent === 0) {
                this.dialog_unaccept_vaccination = true;
                this.temporisation = true;
              } else if (this.consent === 3) {
                this.dialog_unaccept_vaccination = true;
              } else if (this.consent === 1.5) {
                  this.external = true;
                  this.dialog_unaccept_vaccination = true;
              }
          }
      }
  }
</script>
