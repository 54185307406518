import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.min.css'
import colors_themes from "./colors_themes";

Vue.use(Vuetify)

Vue.component("rps-icon-secondary", {template: `<svg xmlns="http://www.w3.org/2000/svg" width="35.72" height="38.7" viewBox="0 0 35.72 38.7">
  <g id="Calque_2" data-name="Calque 2" transform="translate(-9.36 -7.88)">
    <g id="Calque_1" data-name="Calque 1">
      <path id="Ellipse_974" data-name="Ellipse 974" d="M5.21-.75A5.96,5.96,0,1,1-.75,5.21,5.967,5.967,0,0,1,5.21-.75Zm0,10.42A4.46,4.46,0,1,0,.75,5.21,4.465,4.465,0,0,0,5.21,9.67Z" transform="translate(22.01 20.53)" fill="#e94e1b"/>
      <path id="Tracé_23654" data-name="Tracé 23654" d="M36.89,46.58a.75.75,0,0,1-.75-.75V37.24a2.974,2.974,0,0,0-1.915-2.778L31.13,33.3l-3.369,3.387A.75.75,0,0,1,26.7,35.631l3.71-3.73a.75.75,0,0,1,.795-.173l3.55,1.33a4.481,4.481,0,0,1,2.887,4.184V45.83A.75.75,0,0,1,36.89,46.58Z" fill="#e94e1b"/>
      <path id="Tracé_23655" data-name="Tracé 23655" d="M17.55,46.58a.75.75,0,0,1-.75-.75V37.24s0,0,0-.006a4.512,4.512,0,0,1,2.939-4.177l3.527-1.329a.75.75,0,0,1,.795.172l3.73,3.73A.75.75,0,0,1,26.73,36.69L23.342,33.3l-3.077,1.159-.006,0a3,3,0,0,0-1.959,2.78V45.83A.75.75,0,0,1,17.55,46.58Z" fill="#e94e1b"/>
      <path id="Ligne_111" data-name="Ligne 111" d="M0,7.44a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V6.69A.75.75,0,0,1,0,7.44Z" transform="translate(21.27 39.13)" fill="#e94e1b"/>
      <path id="Ligne_112" data-name="Ligne 112" d="M0,7.44a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V6.69A.75.75,0,0,1,0,7.44Z" transform="translate(33.17 39.13)" fill="#e94e1b"/>
      <path id="Ligne_113" data-name="Ligne 113" d="M0,5.91a.747.747,0,0,1-.374-.1A.75.75,0,0,1-.649,4.785l2.98-5.16A.75.75,0,0,1,3.355-.649.75.75,0,0,1,3.629.375L.649,5.535A.75.75,0,0,1,0,5.91Z" transform="translate(32.8 10.92)" fill="#e94e1b"/>
      <path id="Ligne_114" data-name="Ligne 114" d="M0,3.73a.75.75,0,0,1-.65-.374A.75.75,0,0,1-.376,2.331l5.14-2.98a.75.75,0,0,1,1.025.273A.75.75,0,0,1,5.516.649L.376,3.629A.747.747,0,0,1,0,3.73Z" transform="translate(12.41 31.32)" fill="#e94e1b"/>
      <path id="Ligne_115" data-name="Ligne 115" d="M0,3.73a.75.75,0,0,1-.65-.375A.75.75,0,0,1-.375,2.331l5.16-2.98a.75.75,0,0,1,1.025.274A.75.75,0,0,1,5.535.649L.375,3.629A.747.747,0,0,1,0,3.73Z" transform="translate(36.88 17.18)" fill="#e94e1b"/>
      <path id="Ligne_116" data-name="Ligne 116" d="M5.95.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H5.95A.75.75,0,0,1,6.7,0,.75.75,0,0,1,5.95.75Z" transform="translate(10.11 25.74)" fill="#e94e1b"/>
      <path id="Ligne_117" data-name="Ligne 117" d="M5.95.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H5.95A.75.75,0,0,1,6.7,0,.75.75,0,0,1,5.95.75Z" transform="translate(38.38 25.74)" fill="#e94e1b"/>
      <path id="Ligne_118" data-name="Ligne 118" d="M5.139,3.73a.747.747,0,0,1-.375-.1L-.376.649A.75.75,0,0,1-.649-.376.75.75,0,0,1,.376-.649l5.14,2.98a.75.75,0,0,1-.377,1.4Z" transform="translate(12.41 17.18)" fill="#e94e1b"/>
      <path id="Ligne_119" data-name="Ligne 119" d="M5.159,3.73a.747.747,0,0,1-.374-.1L-.375.649A.75.75,0,0,1-.649-.375.75.75,0,0,1,.375-.649l5.16,2.98a.75.75,0,0,1-.376,1.4Z" transform="translate(36.88 31.32)" fill="#e94e1b"/>
      <path id="Ligne_120" data-name="Ligne 120" d="M2.981,5.91a.75.75,0,0,1-.65-.375L-.649.375A.75.75,0,0,1-.375-.649.75.75,0,0,1,.649-.375l2.98,5.16A.75.75,0,0,1,2.981,5.91Z" transform="translate(18.67 10.92)" fill="#e94e1b"/>
      <path id="Ligne_121" data-name="Ligne 121" d="M0,6.7a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V5.95A.75.75,0,0,1,0,6.7Z" transform="translate(27.22 8.63)" fill="#e94e1b"/>
    </g>
  </g>
</svg>`});


Vue.component("rps-icon", {template: `<svg xmlns="http://www.w3.org/2000/svg" width="35.72" height="38.7" viewBox="0 0 35.72 38.7">
  <g id="Calque_2" data-name="Calque 2" transform="translate(-9.36 -7.88)">
    <g id="Calque_1" data-name="Calque 1">
      <path id="Ellipse_974" data-name="Ellipse 974" d="M5.21-.75A5.96,5.96,0,1,1-.75,5.21,5.967,5.967,0,0,1,5.21-.75Zm0,10.42A4.46,4.46,0,1,0,.75,5.21,4.465,4.465,0,0,0,5.21,9.67Z" transform="translate(22.01 20.53)" fill="#0000008a"/>
      <path id="Tracé_23654" data-name="Tracé 23654" d="M36.89,46.58a.75.75,0,0,1-.75-.75V37.24a2.974,2.974,0,0,0-1.915-2.778L31.13,33.3l-3.369,3.387A.75.75,0,0,1,26.7,35.631l3.71-3.73a.75.75,0,0,1,.795-.173l3.55,1.33a4.481,4.481,0,0,1,2.887,4.184V45.83A.75.75,0,0,1,36.89,46.58Z" fill="#0000008a"/>
      <path id="Tracé_23655" data-name="Tracé 23655" d="M17.55,46.58a.75.75,0,0,1-.75-.75V37.24s0,0,0-.006a4.512,4.512,0,0,1,2.939-4.177l3.527-1.329a.75.75,0,0,1,.795.172l3.73,3.73A.75.75,0,0,1,26.73,36.69L23.342,33.3l-3.077,1.159-.006,0a3,3,0,0,0-1.959,2.78V45.83A.75.75,0,0,1,17.55,46.58Z" fill="#0000008a"/>
      <path id="Ligne_111" data-name="Ligne 111" d="M0,7.44a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V6.69A.75.75,0,0,1,0,7.44Z" transform="translate(21.27 39.13)" fill="#0000008a"/>
      <path id="Ligne_112" data-name="Ligne 112" d="M0,7.44a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V6.69A.75.75,0,0,1,0,7.44Z" transform="translate(33.17 39.13)" fill="#0000008a"/>
      <path id="Ligne_113" data-name="Ligne 113" d="M0,5.91a.747.747,0,0,1-.374-.1A.75.75,0,0,1-.649,4.785l2.98-5.16A.75.75,0,0,1,3.355-.649.75.75,0,0,1,3.629.375L.649,5.535A.75.75,0,0,1,0,5.91Z" transform="translate(32.8 10.92)" fill="#0000008a"/>
      <path id="Ligne_114" data-name="Ligne 114" d="M0,3.73a.75.75,0,0,1-.65-.374A.75.75,0,0,1-.376,2.331l5.14-2.98a.75.75,0,0,1,1.025.273A.75.75,0,0,1,5.516.649L.376,3.629A.747.747,0,0,1,0,3.73Z" transform="translate(12.41 31.32)" fill="#0000008a"/>
      <path id="Ligne_115" data-name="Ligne 115" d="M0,3.73a.75.75,0,0,1-.65-.375A.75.75,0,0,1-.375,2.331l5.16-2.98a.75.75,0,0,1,1.025.274A.75.75,0,0,1,5.535.649L.375,3.629A.747.747,0,0,1,0,3.73Z" transform="translate(36.88 17.18)" fill="#0000008a"/>
      <path id="Ligne_116" data-name="Ligne 116" d="M5.95.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H5.95A.75.75,0,0,1,6.7,0,.75.75,0,0,1,5.95.75Z" transform="translate(10.11 25.74)" fill="#0000008a"/>
      <path id="Ligne_117" data-name="Ligne 117" d="M5.95.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H5.95A.75.75,0,0,1,6.7,0,.75.75,0,0,1,5.95.75Z" transform="translate(38.38 25.74)" fill="#0000008a"/>
      <path id="Ligne_118" data-name="Ligne 118" d="M5.139,3.73a.747.747,0,0,1-.375-.1L-.376.649A.75.75,0,0,1-.649-.376.75.75,0,0,1,.376-.649l5.14,2.98a.75.75,0,0,1-.377,1.4Z" transform="translate(12.41 17.18)" fill="#0000008a"/>
      <path id="Ligne_119" data-name="Ligne 119" d="M5.159,3.73a.747.747,0,0,1-.374-.1L-.375.649A.75.75,0,0,1-.649-.375.75.75,0,0,1,.375-.649l5.16,2.98a.75.75,0,0,1-.376,1.4Z" transform="translate(36.88 31.32)" fill="#0000008a"/>
      <path id="Ligne_120" data-name="Ligne 120" d="M2.981,5.91a.75.75,0,0,1-.65-.375L-.649.375A.75.75,0,0,1-.375-.649.75.75,0,0,1,.649-.375l2.98,5.16A.75.75,0,0,1,2.981,5.91Z" transform="translate(18.67 10.92)" fill="#0000008a"/>
      <path id="Ligne_121" data-name="Ligne 121" d="M0,6.7a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V5.95A.75.75,0,0,1,0,6.7Z" transform="translate(27.22 8.63)" fill="#0000008a"/>
    </g>
  </g>
</svg>`});


Vue.component("covid-icon", {template: `<svg xmlns="http://www.w3.org/2000/svg" width="38.41" height="34.67" viewBox="0 0 38.41 34.67">
  <g id="Calque_2" data-name="Calque 2" transform="translate(-8.14 -9.89)">
    <g id="Calque_1" data-name="Calque 1">
      <path id="Tracé_23656" data-name="Tracé 23656" d="M36.23,27.22A10.76,10.76,0,1,1,25.46,16.47h0A10.75,10.75,0,0,1,36.23,27.22Z" fill="none" stroke="#909090" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      <path id="Tracé_23673" data-name="Tracé 23673" d="M0,0H0" transform="translate(27.6 24.83)" fill="none" stroke="#909090" stroke-linecap="round" stroke-width="3"/>
      <line id="Ligne_123" data-name="Ligne 123" transform="translate(20.98 27.22)" fill="none" stroke="#909090" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <line id="Ligne_124" data-name="Ligne 124" transform="translate(25.47 31.91)" fill="none" stroke="#909090" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Tracé_23669" data-name="Tracé 23669" d="M3.14,3.16,0,0" transform="translate(13.75 15.49)" fill="none" stroke="#909090" stroke-linecap="round" stroke-width="1.5"/>
      <path id="Tracé_23668" data-name="Tracé 23668" d="M4.44,0H0" transform="translate(8.89 27.22)" fill="none" stroke="#909090" stroke-linecap="round" stroke-width="1.5"/>
      <path id="Tracé_23667" data-name="Tracé 23667" d="M4.12,0,0,4.13" transform="translate(13.75 34.82)" fill="none" stroke="#909090" stroke-linecap="round" stroke-width="1.5"/>
      <path id="Tracé_23666" data-name="Tracé 23666" d="M0,0V4.45" transform="translate(25.47 39.36)" fill="none" stroke="#909090" stroke-linecap="round" stroke-width="1.5"/>
      <path id="Tracé_23670" data-name="Tracé 23670" d="M0,0V5.6" transform="translate(25.47 10.64)" fill="none" stroke="#909090" stroke-linecap="round" stroke-width="1.5"/>
      <path id="Tracé_23665" data-name="Tracé 23665" d="M0,0,3.15,3.14" transform="translate(34.05 35.81)" fill="none" stroke="#909090" stroke-linecap="round" stroke-width="1.5"/>
      <path id="Tracé_23664" data-name="Tracé 23664" d="M0,0H4.44" transform="translate(37.61 27.23)" fill="none" stroke="#909090" stroke-linecap="round" stroke-width="1.5"/>
      <path id="Tracé_23671" data-name="Tracé 23671" d="M0,3.9,3.9,0" transform="translate(33.3 15.5)" fill="none" stroke="#909090" stroke-linecap="round" stroke-width="1.5"/>
      <path id="Tracé_23672" data-name="Tracé 23672" d="M0,0H0" transform="translate(43.28 15.27)" fill="none" stroke="#909090" stroke-linecap="round" stroke-width="2.5"/>
      <path id="Tracé_23663" data-name="Tracé 23663" d="M0,0H0" transform="translate(45.55 34.69)" fill="none" stroke="#909090" stroke-linecap="round" stroke-width="2"/>
    </g>
  </g>
</svg>
`});

Vue.component("covid-icon-secondary", {template: `<svg xmlns="http://www.w3.org/2000/svg" width="38.41" height="34.67" viewBox="0 0 38.41 34.67">
  <g id="Calque_2" data-name="Calque 2" transform="translate(-8.14 -9.89)">
    <g id="Calque_1" data-name="Calque 1">
      <path id="Tracé_23656" data-name="Tracé 23656" d="M36.23,27.22A10.76,10.76,0,1,1,25.46,16.47h0A10.75,10.75,0,0,1,36.23,27.22Z" fill="none" stroke="#e94e1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      <path id="Tracé_23673" data-name="Tracé 23673" d="M0,0H0" transform="translate(27.6 24.83)" fill="none" stroke="#e94e1b" stroke-linecap="round" stroke-width="3"/>
      <line id="Ligne_123" data-name="Ligne 123" transform="translate(20.98 27.22)" fill="none" stroke="#e94e1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <line id="Ligne_124" data-name="Ligne 124" transform="translate(25.47 31.91)" fill="none" stroke="#e94e1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Tracé_23669" data-name="Tracé 23669" d="M3.14,3.16,0,0" transform="translate(13.75 15.49)" fill="none" stroke="#e94e1b" stroke-linecap="round" stroke-width="1.5"/>
      <path id="Tracé_23668" data-name="Tracé 23668" d="M4.44,0H0" transform="translate(8.89 27.22)" fill="none" stroke="#e94e1b" stroke-linecap="round" stroke-width="1.5"/>
      <path id="Tracé_23667" data-name="Tracé 23667" d="M4.12,0,0,4.13" transform="translate(13.75 34.82)" fill="none" stroke="#e94e1b" stroke-linecap="round" stroke-width="1.5"/>
      <path id="Tracé_23666" data-name="Tracé 23666" d="M0,0V4.45" transform="translate(25.47 39.36)" fill="none" stroke="#e94e1b" stroke-linecap="round" stroke-width="1.5"/>
      <path id="Tracé_23670" data-name="Tracé 23670" d="M0,0V5.6" transform="translate(25.47 10.64)" fill="none" stroke="#e94e1b" stroke-linecap="round" stroke-width="1.5"/>
      <path id="Tracé_23665" data-name="Tracé 23665" d="M0,0,3.15,3.14" transform="translate(34.05 35.81)" fill="none" stroke="#e94e1b" stroke-linecap="round" stroke-width="1.5"/>
      <path id="Tracé_23664" data-name="Tracé 23664" d="M0,0H4.44" transform="translate(37.61 27.23)" fill="none" stroke="#e94e1b" stroke-linecap="round" stroke-width="1.5"/>
      <path id="Tracé_23671" data-name="Tracé 23671" d="M0,3.9,3.9,0" transform="translate(33.3 15.5)" fill="none" stroke="#e94e1b" stroke-linecap="round" stroke-width="1.5"/>
      <path id="Tracé_23672" data-name="Tracé 23672" d="M0,0H0" transform="translate(43.28 15.27)" fill="none" stroke="#e94e1b" stroke-linecap="round" stroke-width="2.5"/>
      <path id="Tracé_23663" data-name="Tracé 23663" d="M0,0H0" transform="translate(45.55 34.69)" fill="none" stroke="#e94e1b" stroke-linecap="round" stroke-width="2"/>
    </g>
  </g>
</svg>
`});

Vue.component("vaccination-icon", {template: `<svg xmlns="http://www.w3.org/2000/svg" width="32.45" height="32.45" viewBox="0 0 32.45 32.45">
  <g id="Calque_2" data-name="Calque 2" transform="translate(-11 -11)">
    <g id="Calque_1" data-name="Calque 1">
      <path id="Ligne_135" data-name="Ligne 135" d="M0,8.62A.748.748,0,0,1-.53,8.4a.75.75,0,0,1,0-1.061L7.34-.53A.75.75,0,0,1,8.4-.53.75.75,0,0,1,8.4.53L.53,8.4A.748.748,0,0,1,0,8.62Z" transform="translate(11.75 11.75)" fill="#909090"/>
      <path id="Ligne_136" data-name="Ligne 136" d="M0,13.58a.748.748,0,0,1-.53-.22.75.75,0,0,1,0-1.061L12.3-.53a.75.75,0,0,1,1.061,0,.75.75,0,0,1,0,1.061L.53,13.36A.748.748,0,0,1,0,13.58Z" transform="translate(15.34 15.34)" fill="#909090"/>
      <path id="Tracé_23657" data-name="Tracé 23657" d="M32.97,42.1a3.221,3.221,0,0,1-2.273-.947L16.56,27.01A.75.75,0,0,1,17.62,25.95L31.76,40.09a1.7,1.7,0,0,0,2.417,0L40,34.26a1.7,1.7,0,0,0,0-2.417L25.889,17.679l1.063-1.059,14.11,14.16a3.2,3.2,0,0,1,0,4.542L35.241,41.15A3.219,3.219,0,0,1,32.97,42.1Z" fill="#909090"/>
      <path id="Ligne_137" data-name="Ligne 137" d="M0,4.1a.748.748,0,0,1-.53-.22.75.75,0,0,1,0-1.061L2.82-.53a.75.75,0,0,1,1.061,0A.75.75,0,0,1,3.88.53L.53,3.88A.748.748,0,0,1,0,4.1Z" transform="translate(28.22 22.25)" fill="#909090"/>
      <path id="Ligne_138" data-name="Ligne 138" d="M0,4.11a.748.748,0,0,1-.53-.219.75.75,0,0,1,0-1.061L2.819-.53a.75.75,0,0,1,1.061,0,.75.75,0,0,1,0,1.061L.531,3.89A.748.748,0,0,1,0,4.11Z" transform="translate(33.73 27.75)" fill="#909090"/>
      <path id="Ligne_139" data-name="Ligne 139" d="M5.06,5.79a.748.748,0,0,1-.529-.219L-.529.531a.75.75,0,0,1,0-1.061.75.75,0,0,1,1.061,0l5.06,5.04A.75.75,0,0,1,5.06,5.79Z" transform="translate(37.64 37.66)" fill="#909090"/>
      <path id="Ligne_140" data-name="Ligne 140" d="M6.08,6.83a.748.748,0,0,1-.53-.22L-.53.53A.75.75,0,0,1-.53-.53.75.75,0,0,1,.53-.53L6.61,5.55a.75.75,0,0,1-.53,1.28Z" transform="translate(17.76 13.6)" fill="#909090"/>
      <path id="Ligne_141" data-name="Ligne 141" d="M6.07,6.83a.748.748,0,0,1-.531-.22L-.531.53a.75.75,0,0,1,0-1.061.75.75,0,0,1,1.061,0L6.6,5.55A.75.75,0,0,1,6.07,6.83Z" transform="translate(13.6 17.76)" fill="#909090"/>
    </g>
  </g>
</svg>
`});

Vue.component("vaccination-icon-secondary", {template: `<svg xmlns="http://www.w3.org/2000/svg" width="32.45" height="32.45" viewBox="0 0 32.45 32.45">
  <g id="Calque_2" data-name="Calque 2" transform="translate(-11 -11)">
    <g id="Calque_1" data-name="Calque 1">
      <path id="Ligne_135" data-name="Ligne 135" d="M0,8.62A.748.748,0,0,1-.53,8.4a.75.75,0,0,1,0-1.061L7.34-.53A.75.75,0,0,1,8.4-.53.75.75,0,0,1,8.4.53L.53,8.4A.748.748,0,0,1,0,8.62Z" transform="translate(11.75 11.75)" fill="#e94e1b"/>
      <path id="Ligne_136" data-name="Ligne 136" d="M0,13.58a.748.748,0,0,1-.53-.22.75.75,0,0,1,0-1.061L12.3-.53a.75.75,0,0,1,1.061,0,.75.75,0,0,1,0,1.061L.53,13.36A.748.748,0,0,1,0,13.58Z" transform="translate(15.34 15.34)" fill="#e94e1b"/>
      <path id="Tracé_23657" data-name="Tracé 23657" d="M32.97,42.1a3.221,3.221,0,0,1-2.273-.947L16.56,27.01A.75.75,0,0,1,17.62,25.95L31.76,40.09a1.7,1.7,0,0,0,2.417,0L40,34.26a1.7,1.7,0,0,0,0-2.417L25.889,17.679l1.063-1.059,14.11,14.16a3.2,3.2,0,0,1,0,4.542L35.241,41.15A3.219,3.219,0,0,1,32.97,42.1Z" fill="#e94e1b"/>
      <path id="Ligne_137" data-name="Ligne 137" d="M0,4.1a.748.748,0,0,1-.53-.22.75.75,0,0,1,0-1.061L2.82-.53a.75.75,0,0,1,1.061,0A.75.75,0,0,1,3.88.53L.53,3.88A.748.748,0,0,1,0,4.1Z" transform="translate(28.22 22.25)" fill="#e94e1b"/>
      <path id="Ligne_138" data-name="Ligne 138" d="M0,4.11a.748.748,0,0,1-.53-.219.75.75,0,0,1,0-1.061L2.819-.53a.75.75,0,0,1,1.061,0,.75.75,0,0,1,0,1.061L.531,3.89A.748.748,0,0,1,0,4.11Z" transform="translate(33.73 27.75)" fill="#e94e1b"/>
      <path id="Ligne_139" data-name="Ligne 139" d="M5.06,5.79a.748.748,0,0,1-.529-.219L-.529.531a.75.75,0,0,1,0-1.061.75.75,0,0,1,1.061,0l5.06,5.04A.75.75,0,0,1,5.06,5.79Z" transform="translate(37.64 37.66)" fill="#e94e1b"/>
      <path id="Ligne_140" data-name="Ligne 140" d="M6.08,6.83a.748.748,0,0,1-.53-.22L-.53.53A.75.75,0,0,1-.53-.53.75.75,0,0,1,.53-.53L6.61,5.55a.75.75,0,0,1-.53,1.28Z" transform="translate(17.76 13.6)" fill="#e94e1b"/>
      <path id="Ligne_141" data-name="Ligne 141" d="M6.07,6.83a.748.748,0,0,1-.531-.22L-.531.53a.75.75,0,0,1,0-1.061.75.75,0,0,1,1.061,0L6.6,5.55A.75.75,0,0,1,6.07,6.83Z" transform="translate(13.6 17.76)" fill="#e94e1b"/>
    </g>
  </g>
</svg>
`});

Vue.component("vma-icon", {template: `<svg xmlns="http://www.w3.org/2000/svg" width="35.62" height="34.641" viewBox="0 0 35.62 34.641">
  <g id="Calque_2" data-name="Calque 2" transform="translate(-9.41 -9.879)">
    <g id="Calque_1" data-name="Calque 1">
      <path id="Tracé_23658" data-name="Tracé 23658" d="M24.05,44.52a7.649,7.649,0,0,1-7.64-7.64V35a.75.75,0,0,1,1.5,0v1.88a6.14,6.14,0,1,0,12.28,0V35a.75.75,0,0,1,1.5,0v1.88A7.649,7.649,0,0,1,24.05,44.52Z" fill="#909090"/>
      <path id="Tracé_23659" data-name="Tracé 23659" d="M30.96,27.82h.049a3.99,3.99,0,0,1-.049,7.98h-.033a3.99,3.99,0,0,1,.033-7.98Zm0,6.48a2.49,2.49,0,0,0,.03-4.98H30.96a2.476,2.476,0,0,0-1.744.713A2.49,2.49,0,0,0,30.94,34.3Z" fill="#909090"/>
      <path id="Tracé_23660" data-name="Tracé 23660" d="M17.16,35.75A7.759,7.759,0,0,1,9.41,28V24.13s0,0,0-.005a3.54,3.54,0,0,1,3.6-3.515h.8a.75.75,0,0,1,0,1.5h-.859a2.04,2.04,0,0,0-2.041,2.023V28a6.257,6.257,0,0,0,6.25,6.25.75.75,0,0,1,0,1.5Z" fill="#909090"/>
      <path id="Ligne_142" data-name="Ligne 142" d="M0,3.13a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V2.38A.75.75,0,0,1,0,3.13Z" transform="translate(13.79 20.16)" fill="#909090"/>
      <path id="Tracé_23661" data-name="Tracé 23661" d="M17.16,35.75a.75.75,0,0,1,0-1.5A6.257,6.257,0,0,0,23.41,28V24.13a2.02,2.02,0,0,0-2.027-2.02H20.57a.75.75,0,0,1,0-1.5h.81a3.52,3.52,0,0,1,3.53,3.52V28A7.759,7.759,0,0,1,17.16,35.75Z" fill="#909090"/>
      <path id="Ligne_143" data-name="Ligne 143" d="M0,3.13a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V2.38A.75.75,0,0,1,0,3.13Z" transform="translate(20.52 20.16)" fill="#909090"/>
      <path id="Tracé_23662" data-name="Tracé 23662" d="M31.17,26.16a.75.75,0,0,1-.75-.75v-2.2l-.372,0H30.04a2.474,2.474,0,0,1-1.756-.724,2.507,2.507,0,0,1-.734-1.766V12.37a2.507,2.507,0,0,1,.734-1.766A2.474,2.474,0,0,1,30.04,9.88H42.651a2.319,2.319,0,0,1,2.378,2.257q0,.012,0,.023v8.56a2.5,2.5,0,0,1-2.5,2.5H35.416l-3.8,2.794A.75.75,0,0,1,31.17,26.16Zm-1.12-4.45h.007l1.12.01a.75.75,0,0,1,.743.75v1.458l2.806-2.062a.75.75,0,0,1,.444-.146h7.36a1,1,0,0,0,1-1V12.174a.826.826,0,0,0-.851-.794H30.04a.984.984,0,0,0-.7.288,1,1,0,0,0-.292.7v8.35a1,1,0,0,0,.292.7.984.984,0,0,0,.7.288h.01Z" fill="#909090"/>
      <path id="Ligne_144" data-name="Ligne 144" d="M8.85.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H8.85A.75.75,0,0,1,9.6,0,.75.75,0,0,1,8.85.75Z" transform="translate(31.97 15.05)" fill="#909090"/>
      <path id="Ligne_145" data-name="Ligne 145" d="M6.01.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H6.01A.75.75,0,0,1,6.76,0,.75.75,0,0,1,6.01.75Z" transform="translate(31.97 18.57)" fill="#909090"/>
    </g>
  </g>
</svg>
`});

Vue.component("vma-icon-secondary", {template: `<svg xmlns="http://www.w3.org/2000/svg" width="35.62" height="34.641" viewBox="0 0 35.62 34.641">
  <g id="Calque_2" data-name="Calque 2" transform="translate(-9.41 -9.879)">
    <g id="Calque_1" data-name="Calque 1">
      <path id="Tracé_23658" data-name="Tracé 23658" d="M24.05,44.52a7.649,7.649,0,0,1-7.64-7.64V35a.75.75,0,0,1,1.5,0v1.88a6.14,6.14,0,1,0,12.28,0V35a.75.75,0,0,1,1.5,0v1.88A7.649,7.649,0,0,1,24.05,44.52Z" fill="#e94e1b"/>
      <path id="Tracé_23659" data-name="Tracé 23659" d="M30.96,27.82h.049a3.99,3.99,0,0,1-.049,7.98h-.033a3.99,3.99,0,0,1,.033-7.98Zm0,6.48a2.49,2.49,0,0,0,.03-4.98H30.96a2.476,2.476,0,0,0-1.744.713A2.49,2.49,0,0,0,30.94,34.3Z" fill="#e94e1b"/>
      <path id="Tracé_23660" data-name="Tracé 23660" d="M17.16,35.75A7.759,7.759,0,0,1,9.41,28V24.13s0,0,0-.005a3.54,3.54,0,0,1,3.6-3.515h.8a.75.75,0,0,1,0,1.5h-.859a2.04,2.04,0,0,0-2.041,2.023V28a6.257,6.257,0,0,0,6.25,6.25.75.75,0,0,1,0,1.5Z" fill="#e94e1b"/>
      <path id="Ligne_142" data-name="Ligne 142" d="M0,3.13a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V2.38A.75.75,0,0,1,0,3.13Z" transform="translate(13.79 20.16)" fill="#e94e1b"/>
      <path id="Tracé_23661" data-name="Tracé 23661" d="M17.16,35.75a.75.75,0,0,1,0-1.5A6.257,6.257,0,0,0,23.41,28V24.13a2.02,2.02,0,0,0-2.027-2.02H20.57a.75.75,0,0,1,0-1.5h.81a3.52,3.52,0,0,1,3.53,3.52V28A7.759,7.759,0,0,1,17.16,35.75Z" fill="#e94e1b"/>
      <path id="Ligne_143" data-name="Ligne 143" d="M0,3.13a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V2.38A.75.75,0,0,1,0,3.13Z" transform="translate(20.52 20.16)" fill="#e94e1b"/>
      <path id="Tracé_23662" data-name="Tracé 23662" d="M31.17,26.16a.75.75,0,0,1-.75-.75v-2.2l-.372,0H30.04a2.474,2.474,0,0,1-1.756-.724,2.507,2.507,0,0,1-.734-1.766V12.37a2.507,2.507,0,0,1,.734-1.766A2.474,2.474,0,0,1,30.04,9.88H42.651a2.319,2.319,0,0,1,2.378,2.257q0,.012,0,.023v8.56a2.5,2.5,0,0,1-2.5,2.5H35.416l-3.8,2.794A.75.75,0,0,1,31.17,26.16Zm-1.12-4.45h.007l1.12.01a.75.75,0,0,1,.743.75v1.458l2.806-2.062a.75.75,0,0,1,.444-.146h7.36a1,1,0,0,0,1-1V12.174a.826.826,0,0,0-.851-.794H30.04a.984.984,0,0,0-.7.288,1,1,0,0,0-.292.7v8.35a1,1,0,0,0,.292.7.984.984,0,0,0,.7.288h.01Z" fill="#e94e1b"/>
      <path id="Ligne_144" data-name="Ligne 144" d="M8.85.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H8.85A.75.75,0,0,1,9.6,0,.75.75,0,0,1,8.85.75Z" transform="translate(31.97 15.05)" fill="#e94e1b"/>
      <path id="Ligne_145" data-name="Ligne 145" d="M6.01.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H6.01A.75.75,0,0,1,6.76,0,.75.75,0,0,1,6.01.75Z" transform="translate(31.97 18.57)" fill="#e94e1b"/>
    </g>
  </g>
</svg>
`});

const icons_options = {
  icons: {
    iconfont: 'mdi',
    values: {
      rpsicon: {
        component: "rps-icon"
      },
      rpsiconsecondary: {
        component: "rps-icon-secondary"
      },
      vmaicon: {
        component: "vma-icon"
      },
      vmaiconsecondary: {
        component: "vma-icon-secondary"
      },
      covidicon: {
        component: "covid-icon"
      },
      covidiconsecondary: {
        component: "covid-icon-secondary"
      },
      vaccinationicon: {
        component: "vaccination-icon"
      },
      vaccinationiconsecondary: {
        component: "vaccination-icon-secondary"
      }
    }
  }
}

export default new Vuetify({...colors_themes.theme_patient, ...icons_options});
