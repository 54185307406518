<template>
  <PrBaseDpaAppointment
    app_name="prevanavaccination"
    :consultations="$store.state.dpa_data.history_consultations"
    :rdv_is_disabled="rdv_is_disabled"
    :next_rdv="next_rdv"
    :can_cancel_appointment="can_cancel_appointment"
    :option_set_eligible="true"
    :option_answer_questionnaire="true"
    :data_set_eligible="data_set_eligible"
    :url_cancel_appointment="url_cancel_appointment"
    v-on:cancelled="get_patient_vaccination_data()"
    v-on:go_to_appointment="go_to_appointment()"
    v-on:answer_questionnaire="answer_questionnaire()"
    class="mb-4"
  >
  </PrBaseDpaAppointment>
</template>

<script>
import PrBaseDpaAppointment from "prevana-library/src/components/common/PrBaseDpaAppointment";
import moment from "moment";
import {vaccination_mixin} from "../vaccination_mixin";

export default {
  name: "DpaAppointment",
  components: {PrBaseDpaAppointment},
  mixins: [vaccination_mixin],

  computed: {
    rdv_is_disabled() {
      // Si la vaccination est terminée
      if (this.$store.state.dpa_data.status >= 10)
        return "La vaccination est Finalisée";
      if (!this.$store.state.dpa_data.is_eligible)
        return "Cet agent n'est pas éligible à la prise de rendez-vous";
      if (this.$store.state.dpa_data.next_rdv) {
        // Si le rendez-vous est dans le futur
        if (!moment(this.$store.state.dpa_data.next_rdv.date).isBefore(moment(), "day")) {
          // Dans le cas d'une première vaccination réalisée on rdv annulé pour la seconde, on autorise la prise de RDV
          if ([9, 5].includes(this.$store.state.dpa_data.status)) return false;
          return "Un rendez-vous est déjà prévu";
        }
        return false;
      }
      return false;
    },

    can_cancel_appointment() {
      if (this.$store.state.dpa_data.next_rdv) {
        if (!moment(this.$store.state.dpa_data.next_rdv.date).isBefore(moment(), "day")) return true;
      }
      return false;
    },

    url_cancel_appointment() {
      if (this.$store.state.dpa_data.next_rdv) return `/api/consultations/${this.$store.state.dpa_data.next_rdv.id}`;
      return "";
    }
  },

  methods: {
    go_to_appointment() {
      this.$router.push({name: 'VaccinationAppointement', params: {id: this.$route.params.id}});
    },

    answer_questionnaire() {
      let questionnaire_type = this.$store.state.dpa_data.vaccination_data.nb_of_injections_performed > 0 ? "SecondVaccinationPrevana" : "FirstVaccinationPrevana";
      this.$router.push({
        name: "DoctorQuestionnaireView", query: {
          questionnaire_type: questionnaire_type,
          app_name: "prevanavaccination",
          patient_id: this.$route.params.id,
          nb_question: 2,
          additional_data: {vaccination_number: this.$store.state.dpa_data.vaccination_data.nb_of_injections_performed+1},
          redirection_end_anam: `/doctor/vaccination/patient/${this.$route.params.id}/appointment`
        }
      });
    }
  },
};
</script>
