<template>
  <v-row class="mb-6">
    <v-col cols="6">
      <PrCardComponent title="Résumé" icon="mdi-information-outline">
        <template v-slot:card-title-append v-if="get_is_disabled() && !is_correcting">
          <v-spacer></v-spacer>
          <v-btn icon @click="is_correcting = true" text color="primary">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-slot:card-text>
           <InfoVaccination :title="get_title()"
                            :vaccination="$store.state.dpa_data.vaccination_data[vaccination_number]"/>
        </template>
      </PrCardComponent>

      <PrCardComponent title="Réponses du questionnaire" icon="mdi-comment-question-outline" class="mt-6">
        <template v-slot:card-text>
          <v-progress-linear v-if="!questionnaire_is_loaded" color="primary" indeterminate></v-progress-linear>
          <p v-else-if="questionnaire_not_completed()">Le questionnaire a été commencé mais n'a pas été sauvegardé</p>
          <p v-else-if="questionnaire_no_answers()">Ce patient n'a pas répondu au questionnaire pour cette injection</p>
          <QuestionAnswers v-else :questionnaire="get_questionnaire()"></QuestionAnswers>
          <div class="text-center" v-if="questionnaire_is_loaded">
            <v-btn rounded outlined color="secondary" @click="dialog_confirm_answer_questionnaire = true">
              {{ questionnaire_no_answers() ? "Répondre au questionnaire pour le patient" : "Mettre à jour les réponses" }}
            </v-btn>
          </div>
        </template>
      </PrCardComponent>
    </v-col>

    <v-col cols="6">
      <PrCardComponent title="Information sur la vaccination" icon="mdi-account-edit" :disabled="get_is_disabled() && !is_correcting" actions>
        <template v-slot:card-text>
          <v-form ref="vaccination_form" v-model="form_vaccination" class="mx-3">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-radio-group
                  v-model="vaccination_status"
                  :rules="[rules.required]"
                  label="Statut de la vaccination:">
                  <v-radio
                    :disabled="get_radio_disabled(9)"
                    label="Réalisée"
                    :value="9"
                    class="mt-3"
                  ></v-radio>
                  <v-radio
                    :disabled="get_radio_disabled(2)"
                    label="Consentement retiré"
                    :value="2"
                  ></v-radio>
                  <v-radio
                    :disabled="get_radio_disabled(6)"
                    label="Absent"
                    :value="6"
                  ></v-radio>
                  <v-radio
                    :disabled="get_radio_disabled(7)"
                    label="Stoppée"
                    :value="7"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="8">
                <AnDate :date="date"
                        label="Date de l'évènement"
                        required
                        :filled="false"
                        :validate="false"
                        icon_color="soft_blue"
                        v-on:formatted_date="date = $event"/>
              </v-col>
              <v-col col="4">
                <v-text-field
                  v-model="time"
                  label="Heure"
                  :rules="[rules.time]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="show_status_data">
                <v-select
                  v-model="vaccination_place"
                  :items="vaccination_place_choices"
                  label="Lieu de vaccination"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
              <v-col cols="12" v-if="show_status_data">
                <v-select
                  v-model="vaccine_type"
                  :items="vaccine_choices"
                  label="Vaccin utilisé"
                ></v-select>
              </v-col>
              <v-col cols="12" v-if="show_status_data">
                <v-text-field
                  v-model="batch_number"
                  label="Numéro de lot"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="show_status_data">
                <AnDate :date="expiration_date"
                        label="Date de péremption du vaccin (facultatif)"
                        dense
                        :filled="false"
                        :validate="false"
                        v-on:formatted_date="expiration_date=$event"/>
              </v-col>
              <v-col cols="12" v-if="show_status_data">
                <v-select
                  v-model="injection_site"
                  :items="injection_choices"
                  label="Site d'injection"
                ></v-select>
              </v-col>
              <v-col cols="12" v-if="vaccination_status === 9">
                <v-radio-group v-model="single_dose" row label="Injection(s) supplémentaire(s) nécessaire(s)">
                  <v-radio label="Oui" :value="false"></v-radio>
                  <v-radio label="Non" :value="true"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template v-slot:card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="get_is_disabled() && is_correcting" @click="is_correcting = false" class="justify-center">
            Annuler
          </v-btn>
          <v-btn :disabled="button_disabled()" :loading="loading" color="primary" @click="set_vaccination_status(vaccination_number)">
            Valider
          </v-btn>
          <v-spacer></v-spacer>
        </template>
      </PrCardComponent>
    </v-col>

    <v-dialog v-model="modal_confirm_cancel_injections" width="500">
      <v-card>
        <v-card-title class="primary white--text medium--text justify-center">Confirmation requise</v-card-title>
        <v-card-text class="pt-6 text-center">
          <p>Attention: des injections de rappel ultérieures ont déjà été effectuées pour ce patient</p>
          <p>Toutes les informations de ces injections seront annulées (mais toujours présentes dans l'historique)</p>
          <p>Souhaitez-vous continuer ?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close_modal_confirm_cancel_injections()" color="primary" large rounded outlined>Annuler</v-btn>
          <v-btn @click="confirm_modal_confirm_cancel_injections()" color="primary" large rounded>Valider</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_confirm_answer_questionnaire" width="500">
      <v-card>
        <v-card-title class="medium--text primary--text justify-center">
          Confirmation requise
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-10 text-center">
          <p class="font-size--22 medium--text primary--text">Souhaitez-vous mettre à jour les réponses pour ce questionnaire ?</p>
        </v-card-text>
        <v-card-actions class="justify-center pb-6">
          <v-btn color="secondary" class="px-5 mx-3" outlined @click="dialog_confirm_answer_questionnaire = false">
            <span class="semibold--text font-size--16">Annuler</span>
          </v-btn>
          <v-btn color="primary" class="white--text px-8 mx-3" elevation="0" @click="answer_questionnaire()" :loading="loading">
            <span class="semibold--text font-size--16">Confirmer</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import InfoVaccination from "../components/InfoVaccination";
import AnDate from "anam-library/src/components/an-date/AnDate";
import QuestionAnswers from "../components/QuestionAnswers";

import questionnaire_vaccination_json from "@/data/questionnaire_vaccination";
import {vaccination_mixin} from "../vaccination_mixin";
import {LIST_INJECTION_CHOICES, LIST_VACCINE_CHOICES} from "@/data/data_vaccination";
import rules from "anam-library/src/js/rules";
import {get_formatted_date} from "anam-library/src/js/dates";

import moment from "moment";

export default {
  name: "DpaVaccination",
  components: {
    PrCardComponent,
    InfoVaccination,
    AnDate,
    QuestionAnswers
  },
  mixins: [vaccination_mixin],
  props: ["vaccination_number"],

  data: () => ({
    rules: rules,
    is_correcting: false,
    form_vaccination: false,
    date: moment().format("DD/MM/YYYY"),
    expiration_date: null,
    time: moment().format("HH:mm"),
    vaccine_type: null,
    vaccination_place: null,
    vaccination_place_choices: [],
    injection_site: null,
    injection_choices: LIST_INJECTION_CHOICES,
    batch_number: null,
    vaccination_status: null,
    questionnaire_answers: {},
    questionnaire_is_loaded: false,
    single_dose: false,
    loading: false,
    modal_confirm_cancel_injections: false,
    pending_patch_data: {},
    dialog_confirm_answer_questionnaire: false
  }),

  computed: {
    show_status_data() {
      return ![2, 6].includes(this.vaccination_status);
    },
    vaccine_choices() {
      if (this.vaccination_place === "En dehors de l'organisation") return LIST_VACCINE_CHOICES;
      return LIST_VACCINE_CHOICES.filter(x => x.value !== "Unknown");
    }
  },

  watch: {
    is_correcting(val) {
      if (val) {
        let vaccination = this.$store.state.dpa_data.vaccination_data[this.vaccination_number];
        let index_type = this.vaccine_choices.findIndex((x) => x.text === vaccination.vaccine_type);
        let index_injection = this.injection_choices.findIndex((x) => x.text === vaccination.injection_site);
        this.vaccination_status = vaccination.status;
        this.date = get_formatted_date(vaccination.datetime, {output_format: 'DD/MM/YYYY'})
        this.time = get_formatted_date(vaccination.datetime, {output_format: 'HH:mm'})
        this.vaccination_place = vaccination.vaccination_place;
        this.vaccine_type = index_type > -1 ? this.vaccine_choices[index_type].value : null;
        this.batch_number = vaccination.batch_number;
        if (vaccination.expiration_date) this.expiration_date = get_formatted_date(vaccination.expiration_date, {output_format: 'DD/MM/YYYY'});
        this.injection_site = index_injection > -1 ? this.injection_choices[index_injection].value : null;
      } else {
        this.$refs.vaccination_form.reset();
        this.date = null;
        this.time = null;
        this.expiration_date = null;
      }
    },
  },

  mounted() {
    this.get_questionnaire_vaccination_answers();
    this.get_vaccination_place_choices();
  },

  methods: {
    set_vaccination_status() {
      this.loading = true;
      let data = {
        status_history: [{
          status: this.vaccination_status,
          datetime: get_formatted_date(`${this.date} ${this.time}:59`, {
            input_format: "DD/MM/YYYY HH:mm:ss",
            output_format: "DD/MM/YYYY HH:mm:ss",
            input_tz: "Europe/Paris",
            output_tz: "utc"
          })
        }],
        patient: this.$route.params.id,
      };
      if (this.show_status_data) {
        data.batch_number = this.batch_number;
        data.injection_site = this.injection_site;
        data.vaccine_type = this.vaccine_type;
        data.vaccination_place = this.vaccination_place;
        if (this.expiration_date) data.expiration_date = get_formatted_date(this.expiration_date, {
          input_format: "DD/MM/YYYY HH:mm",
          input_tz: "Europe/Paris",
          output_tz: "utc"
        });
      }
      data.more_injections_needed = !this.single_dose && this.vaccination_status === 9;
      let vaccination = this.$store.state.dpa_data.vaccination_data[this.vaccination_number];
      if ((!vaccination)) {
        data.patient_id = this.$route.params.id;
        this.$http.post("/api/set_vaccination_status", data).then(() => {
          this.success_vaccination_status();
        })
      } else {
        if (this.vaccination_status === 9 && this.single_dose) {
          if (this.$store.state.dpa_data.vaccination_data[this.vaccination_number + 1]) {
            this.pending_patch_data = {
              id_status_vacc: vaccination.id,
              data: data
            }
            this.modal_confirm_cancel_injections = true;
          }
          else {
            this.$http.patch("/api/set_vaccination_status/" + vaccination.id, data).then(() => {
              this.success_vaccination_status();
            })
          }
        }
        else {
          this.$http.patch("/api/set_vaccination_status/" + vaccination.id, data).then(() => {
            this.success_vaccination_status();
          })
        }
      }
    },

    confirm_modal_confirm_cancel_injections() {
      this.$http.patch("/api/set_vaccination_status/" + this.pending_patch_data.id_status_vacc, this.pending_patch_data.data).then(() => {
        this.close_modal_confirm_cancel_injections();
        this.success_vaccination_status();
      })
    },

    close_modal_confirm_cancel_injections() {
      this.loading = false;
      this.pending_patch_data = {};
      this.modal_confirm_cancel_injections = false;
    },

    async success_vaccination_status() {
      this.date = null;
      this.time = null;
      this.expiration_date = null;
      this.loading = false;
      this.$store.commit("PostSuccess", "Modifications enregistrées");
      this.is_correcting = false;
      this.$vuetify.goTo(0);
      this.$refs.vaccination_form.reset();
      await this.get_patient_vaccination_data();
      this.$emit("success_vaccination_status");
    },

    get_is_disabled() {
      if (!Object.keys(this.$store.state.dpa_data.vaccination_data).includes(this.vaccination_number.toString())) return false;
      return [7, 9].includes(this.$store.state.dpa_data.vaccination_data[this.vaccination_number].status);
    },

    get_radio_disabled(status) {
      if (!this.is_correcting) return false;
      return status !== this.$store.state.dpa_data.vaccination_data[this.vaccination_number].status;
    },

    get_questionnaire() {
      if (Object.keys(this.questionnaire_answers).includes(this.vaccination_number.toString())) {
        return this.questionnaire_answers[this.vaccination_number];
      }
      return null;
    },

    questionnaire_no_answers() {
      let questionnaire = this.get_questionnaire();
      return questionnaire === null;
    },

    questionnaire_not_completed() {
      let questionnaire = this.get_questionnaire();
      return questionnaire && !questionnaire.length;
    },

    answer_questionnaire() {
      let questionnaire_type = this.vaccination_number === 1 ? "FirstVaccinationPrevana" : "SecondVaccinationPrevana";
      this.$router.push({
        name: "DoctorQuestionnaireView",
        query: {
          questionnaire_type: questionnaire_type,
          app_name: "prevanavaccination",
          patient_id: this.$route.params.id,
          nb_question: 2,
          additional_data: {
            vaccination_number: this.vaccination_number
          }
        }
      });
    },

    get_title() {
      if (this.vaccination_number === 1) return "Première injection";
      return `${this.vaccination_number} ème injection`;
    },

    get_vaccination_place_choices() {
      this.$http.get("/api/agencies/calendars", {params: {type_rdv: 3}}).then((response) => {
        for (let place of response.data) {
          this.vaccination_place_choices.push({
            text: place.name,
            value: place.name,
          });
        }
        this.vaccination_place_choices.push({
          text: "En dehors de l'organisation",
          value: "En dehors de l'organisation",
        });
      })
        .catch((error) => {
          if (error.response.status === 401) this.$router.push("/401");
        });
    },

    get_questionnaire_vaccination_answers() {
      this.$http.get("/api/patients/" + this.$route.params.id + "/questionnaire_vaccination").then(response => {
        if (response.data.use_old_questionnaire) {
          let q_a = [];
          for (let key in response.data.use_old_questionnaire) {
            q_a.push({
              name: questionnaire_vaccination_json[key].txt,
              answers: [{name: response.data.use_old_questionnaire[key]}]
            })
          }
          this.questionnaire_answers["1"] = q_a;
        } else {
          for (let i=1; i <= this.$store.state.dpa_data.vaccination_data.nb_of_injections_needed; i++) {
            let q_i = response.data.questionnaires.filter(x => x.vaccination_number === i);
            if (q_i.length) this.questionnaire_answers[i] = q_i[0].questions_answers;
          }
        }
      }).finally(() => {
        this.questionnaire_is_loaded = true;
      })
    },

    button_disabled() {
      if (this.vaccination_status && this.date && this.time && this.vaccination_place &&
        this.vaccine_type && this.batch_number && this.injection_site) return false;
      if (!this.form_vaccination) return true;
      if (this.vaccination_status === 2 || this.vaccination_status === 6) return false;
      if (this.vaccination_place === "En dehors de l'organisation") return false;
      else {
        return (!this.date || !this.vaccine_type || !this.batch_number || !this.injection_site)
      }
    }
  }
  ,
}
;
</script>
