<template>
  <AnDialogComponent :dialog.sync="_dialog" width="650" title="Autoriser un questionnaire" :actions="true">
    <template v-slot:card-text>
      <v-form v-model="valid_form" ref="form_send_quest" autocomplete="off" class="px-sm-6">
        <div>
          <p class="mb-0 primary--text font-size--18 medium--text">
            Questionnaire à autoriser
            <v-icon color="red" size="8" class="mb-3">mdi-asterisk</v-icon>
          </p>
          <v-select v-model="questionnaire_selected"
                    :items="questionnaires"
                    item-value="id" item-text="name"
                    no-data-text="Erreur - questionnaires non disponible"
                    color="primary"
                    filled dense
                    :rules="[rules.required]">
          </v-select>
          <AnDate
            :date="start_date"
            title="Date de début"
            title_color="primary"
            :required="true"
            :show_title="true"
            icon=""
            dense
            v-on:formatted_date="start_date=$event"
          />
          <AnDate
            :date="end_date"
            title="Date de fin"
            title_color="primary"
            :required="true"
            :show_title="true"
            icon=""
            dense
            v-on:formatted_date="end_date=$event"
          />
        </div>
      </v-form>
    </template>
    <template v-slot:card-actions>
      <v-btn large text color="secondary" class="white--text px-sm-6" rounded @click="_dialog=false">Annuler</v-btn>
      <v-btn large color="secondary" class="white--text px-sm-6" elevation="0" rounded
             @click="authorise_questionnaire()"
             :loading="loading"
             :disabled="!valid_form">
        <span class="medium--text font-size--16">Envoyer</span>
      </v-btn>
    </template>
  </AnDialogComponent>
</template>

<script>
import AnDialogComponent from "anam-library/src/components/an-ui/an-dialog/AnDialogComponent";
import rules from "anam-library/src/js/rules";
import AnDate from "anam-library/src/components/an-date/AnDate";

	export default {
    name: "DialogAuthoriseQuestionnaire",
    components: {AnDialogComponent, AnDate},
    props: {
      dialog: {
        type: Boolean,
        default: false
      },
      questionnaires: {
        type: Array,
        default: () => []
      },
      ids_selected: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      loading: false,
      valid_form: false,
      questionnaire_selected: null,
      rules: rules,
      start_date: null,
      end_date: null
    }),
    computed: {
      _dialog: {
        get: function() {
          return this.dialog
        },
        set: function(val) {
          this.$emit('update:dialog', val)
        }
      }
    },
    watch: {
      dialog(val) {
        if (!val) {
          this.questionnaire_selected = null;
          this.loading = false;
        }
      }
    },
    methods: {
      authorise_questionnaire() {
        if (this.$refs.form_send_quest.validate()) {
          this.loading = true;
          let data = {
            "questionnaire": this.questionnaire_selected,
            "ids_selected": this.ids_selected,
            "start_date": this.start_date,
            "end_date": this.end_date,
          }
          this.$http.post(`/api/authorise_questionnaires`, data).then(() => {
            this.$store.commit("PostSuccess", "Questionnaire autorisé pour les patients sélectionnés");
            this._dialog = false;
          }).finally(() => {
            this.loading = false;
          })
      }
    }
  }
}
</script>
