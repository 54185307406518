<template>
  <v-app>
    <AnOverlayLoading :is_mounted="$store.state.is_mounted_app" />
    <router-view />
    <AnSnackbar
      :snackbar_model="$store.state.errors.snack_model"
      :snackbar_info="$store.state.errors.snack_info"
      :icon_class="$store.state.errors.snack_info.icon_class"
      v-on:closed="$store.state.errors.snack_model = false"
    />
  </v-app>
</template>

<script>
import colors_themes from "@/plugins/colors_themes";
import AnOverlayLoading from "anam-library/src/components/an-ui/loading/AnOverlayLoading";
import AnSnackbar from "anam-library/src/components/an-ui/snackbar/AnSnackbar.vue";

export default {
  name: "DoctorLayout",
  components: {
    AnOverlayLoading,
    AnSnackbar,
  },
  created() {
    if (Object.keys(colors_themes).includes(`theme_doctor_${this.$store.getters.getAgencyName}`)) {
      this.$vuetify.theme.themes.light = colors_themes[`theme_doctor_${this.$store.getters.getAgencyName}`]["theme"]["themes"]["light"];
    }
    else {
      this.$vuetify.theme.themes.light = colors_themes.theme_doctor["theme"]["themes"]["light"];
    }
    this.$store.state.is_mounted_app = true;
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.is_mounted_app = false;
    next();
  },
};
</script>
