<template>
  <v-container fluid class="pa-6">
    <v-row class="mx-0">
      <v-col cols="12">
        <p class="primary--text bold--text font-size--28 mb-0">Bonjour {{ patient_fullname }}</p>
      </v-col>
      <v-col cols="12" sm="6">
        <PrCardComponent title="Lancer un questionnaire" icon="mdi-file-document">
          <template v-slot:card-text>
            <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
            <v-list dense>
              <v-list-item v-for="(questionnaire, key) in questionnaire_choices" :key="key">
                <v-list-item-content class="my-3">
                  <div class="d-flex justify-space-between align-center">
                    <span class="primary--text font-size--18">Questionnaire - {{ questionnaire.commercial_name }}</span>
                    <v-btn :disabled="!questionnaire.is_available" @click="start_questionnaire(questionnaire)" class="px-sm-6" elevation="0" color="primary">
                      <span class="semibold--text white--text">Lancer</span>
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </PrCardComponent>
      </v-col>
      <v-col v-for="(item, title) in contacts" cols="12" sm="6" :key="title">
        <PatientContactCardComponent :contacts="item" :title="title" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import PatientContactCardComponent from "../components/contacts/PatientContactCardComponent";
import {get_contacts, get_ressources} from "../../../data/rps/text_by_agency";

export default {
  name: "PatientDashboardEtudeView",
  components: {
    PrCardComponent,
    PatientContactCardComponent,
  },
  data: () => ({
    loading: false,
    questionnaire_choices: [],
    redirect_to_socio_demo: true
  }),
  computed: {
    patient_fullname: function () {
      return `${this.$store.getters.getPatientData.first_name}`;
    },
    ressources() {
      return get_ressources(this.$store.getters.getAgencyName);
    },
    contacts() {
      return get_contacts(this.$store.getters.getAgencyName);
    },
  },
  created() {
    this.$http.get(`/api/patients/0/patient-data-etude`).then(response => {
      this.redirect_to_socio_demo = !response.data;
    })
    this.get_questionnaires();
  },
  methods: {
    get_questionnaires() {
      this.loading = true;
      this.$http.get(`/api/patients/0/questionnaires-available`).then(response => {
        this.questionnaire_choices = response.data;
        this.questionnaire_choices.push({
          name: "cephismer_journal_plongees",
          commercial_name: "Journal de bord des plongées",
          is_available: true
        })
      }).finally(() => {
        this.loading = false;
      })
    },
    start_questionnaire(questionnaire) {
      let data = new FormData();
      data.append('of_type', questionnaire.name);
      this.$http.post(`/api/patients/0/start_anamnese_etude`, data).then(response => {
        let res_split = response.data.split("/");
        let questionnaire_uid = res_split[res_split.length - 1];

        if (this.redirect_to_socio_demo) {
          this.$router.push({
            name: "PatientProfileEtude",
            query: {
              redirection: `/patient/${this.$store.getters.getPatientData.id}/questionnaire/${questionnaire_uid}?questionnaire_type=${questionnaire.name}`
            }
          })
        }
        else {
          this.$router.push({
            name: "QuestionnaireView",
            params: {questionnaire_uid: questionnaire_uid},
            query: {redirection: "continue"}
          })
        }

      })
    }
  }
}
</script>

<style scoped>

</style>
