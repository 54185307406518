<template>
  <v-app>
    <DoctorHeader :navigation_buttons="navigation_buttons"/>
    <v-main class="bg">
      <v-container fluid v-if="!loading">
        <router-view />
      </v-container>
    </v-main>
    <PrFooter app_name="etude" />
  </v-app>
</template>

<script>
import DoctorHeader from "../common/DoctorHeader";
import PrFooter from "prevana-library/src/components/common/PrFooter";

export default {
  name: "EtudeDoctorLayout",
  components: {
    DoctorHeader,
    PrFooter,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    navigation_buttons(){
      return [
        {text: "Tableau de bord", icon: "mdi-view-dashboard", url: "/doctor/etude/dashboard", primary_action: true},
        {text: "Paramètres", url: "/doctor/etude/settings", primary_action: false},
        {text: "Aide", href: "https://aide.anamnese.care", primary_action: false}
      ];
    },
    has_access_to_etude() {
      return this.$store.getters.getAppsAllowed.includes("etude");
    }
  },
  async created() {
    this.$store.state.is_mounted_app = false;
    if (this.$store.getters.getUserPermission === 2 && this.has_access_to_etude) {
      await this.$http.post("/api/switch_app/etude");
    }
    this.loading = false;
    this.$store.state.is_mounted_app = true;
  },
}
</script>

<style scoped>

</style>
