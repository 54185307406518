<template>
  <v-list-item-content>
    <v-list-item-title class="primary--text normal--text">{{ title }}</v-list-item-title>
    <v-list-item-subtitle class="text-break basic--text medium--text" style="white-space: normal">{{ value || 'Non renseigné' }}</v-list-item-subtitle>
  </v-list-item-content>
</template>

<script>
export default {
  name: "RDVInfoListItemContent",
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
