<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>
    <v-row class="ma-2 ma-sm-5">
      <v-col cols="12" sm="8" offset-sm="2" class="mt-10">
        <PrCardComponent title="Résultat du questionnaire" icon="mdi-calendar-check" color="secondary">
          <template v-slot:card-title-append>
            <p class="normal--text black--text font-size--14 mt-5 text-break">
              Votre situation <span class="font-size--16 bold--text primary--text">ne vous permet pas de prendre RDV</span>.
              <br/>
              Si vous avez été en contact à risque avec un cas confirmé de Covid dans les 7 derniers jours sans mesure de
              protection, ou avez des symptômes de la covid19, alors un test virologique est souhaitable.
              <br/>
              Si vous avez été covid+ il y a moins de 3 mois ou avez reçu un autre vaccin il y a moins de 14 jours,
              il convient de reporter la vaccination.
              <br/>
              N’hésitez pas à revenir vers votre centre de vaccination ({{ agency_name }}) ou
              votre médecin traitant pour échanger à ce sujet et trouver une autre solution.
            </p>
            <v-col cols="12" class="text-center">
              <ButtonBackDashboard redirection="PatientVaccinationDashboard" />
            </v-col>
          </template>
        </PrCardComponent>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import ButtonBackDashboard from "../../components/ButtonBackDashboard";

export default {
  name: "PatientVaccinationNotEligibleView",
  components: {ButtonBackDashboard, PrBreadcrumbsComponent, PrCardComponent},
  data: () => ({
    navigations: [
      {text: 'Vaccination', to: '/patient/vaccination/dashboard'},
      {text: 'Questionnaire', to: '/patient/vaccination/questionnaire'},
      {text: 'Résultats', to: '/patient/vaccination/not-eligible'},
    ]
  }),
  computed: {
    agency_name() {
      return this.$store.getters.getAgencyName;
    }
  },
  mounted() {
    this.$vuetify.goTo(0);
  }
}
</script>
