<template>
  <v-dialog v-model="_dialog" persistent fullscreen>
      <v-card>
        <v-card-title class="primary white--text medium--text" style="word-break: break-word">
          <v-icon color="white" class="mr-5">mdi-history</v-icon>
          Historique des visites médicales
          <v-spacer></v-spacer>
          <v-btn icon @click="_dialog = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="consultations"
            no-data-text="Aucune visite médicale"
          >
            <template v-slot:item.slot.start_slot="{ item }">
              {{item.slot.start_slot | date}}
            </template>
            <template v-slot:item.aptitude="{ item }">
              <BooleanItem variable="aptitude" :item="item"></BooleanItem>
            </template>
            <template v-slot:item.handicap_type="{ item }">
              <span style="white-space: pre;">{{get_handicap_type(item)}}</span>
            </template>
            <template v-slot:item.amenagement_type="{ item }">
              <span style="white-space: pre;">{{get_amenagement_type(item)}}</span>
            </template>
            <template v-slot:item.status="{ item }">
              <span v-if="item.status === 1">Absence excusée</span>
              <span v-else-if="item.status === 2">Absence non excusée</span>
              <span v-else-if="item.status === 3">Présence</span>
              <span v-else><v-icon class="soft_blue--text">mdi-minus</v-icon></span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import {get_formatted_date} from "anam-library/src/js/dates";
import BooleanItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/BooleanItem";
import {get_amenagement_type} from "../../../../../data/vma/dict_vma";

export default {
  name: "HistoryVisitVMA",
  components: {BooleanItem},
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    consultations: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    headers: [
      {text: "Date", value: "slot.start_slot", class: "white primary--text"},
      {text: "Type", value: "place", class: "white primary--text"},
      {text: "Taille (cm)", value: "constants.taille", class: "white primary--text"},
      {text: "Poids (kg)", value: "constants.poids", class: "white primary--text"},
      {text: "P.A Syst (mmHG)", value: "constants.pa_syst", class: "white primary--text"},
      {text: "P.A Diast (mmHG)", value: "constants.pa_diast", class: "white primary--text"},
      {text: "F Cardi (bat/min)", value: "constants.pulse", class: "white primary--text"},
      {text: "Acuité Visuelle avec correction OG", value: "constants.avisOG", class: "white primary--text"},
      {text: "Acuité Visuelle avec correction OD", value: "constants.avisOD", class: "white primary--text"},
      {text: "Aménagement", value: "aptitude", class: "white primary--text"},
      {text: "Type d'aménagement", value: "amenagement_type", class: "white primary--text"},
      {text: "Type de handicap", value: "handicap_type", class: "white primary--text"},
      {text: "Présence", value: "status", class: "white primary--text"},
      {text: "Auscultation", value: "med_obs", class: "white primary--text"},
    ]
  }),

  computed: {
    _dialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },
  },

  filters: {
    date(val) {
      return get_formatted_date(val);
    }
  },

  methods: {
    get_handicap_type(item) {
      let handicaps = "";
      for (let handicap of item.handicaps) {
        handicaps += `${handicap.handicap_type}: ${handicap.handicap_type_precision} (${handicap.duration})\n`;
      }
      return handicaps;
    },

    get_amenagement_type(item) {
      return get_amenagement_type(item);
    }
  }
}
</script>

<style scoped>

</style>
