<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-checkbox
        v-model="event.status"
        color="primary"
        :label="title"
        hide-details
        @change="set_status_evenements()"
      ></v-checkbox>
    </v-col>
    <v-col cols="11" offset="1" v-if="event.status">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="event.date"
            hide-details
            :label="title_date"
            prepend-icon="mdi-calendar-edit"
            readonly
            v-on="on"
            color="primary"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="event.date"
          @input="update_evenements()"
          color="primary"
          locale="fr"
        ></v-date-picker>
      </v-menu>
      <span v-if="!event.date" class="red--text ml-6 caption"
        >La date est nécessaire pour enregistrer l'information</span
      >
    </v-col>
  </v-row>
</template>

<script>

export default {
  data: () => ({
    menu: false,
  }),
  props: ["event", "title", "title_date", "type", "pds_uid"],
  methods: {
    update_evenements: function () {
      let form_data = new FormData();
      form_data.append("type_info", this.type);
      form_data.append("status", this.event.status);
      form_data.append("appearance_date", this.event.date);

      this.$http.post("/api/evenements_ddp/" + this.pds_uid, form_data).then(() => {
        this.$emit("enable_snackbar");
        this.$store.state.dpa.evenements[this.type] = {
          status: this.event.status,
          date: this.event.date,
        };

        if (this.event.status === true) {
          this.$store.commit("unshift_evenements_history", {
            name: this.type,
            appearance_date: this.event.date,
          });
        }
      }).finally(() => {
        this.menu = false;
      });
    },

    set_status_evenements: function () {
      if (!this.event.status) {
        this.event.date = "";
        this.update_evenements();
      }
    },
  },
};
</script>

<style>
</style>
