<template>
  <v-container>
    <AnResetPassword :redirection="'/'+$route.params.agency" color_btn="secondary"/>
  </v-container>
</template>

<script>
import AnResetPassword from "anam-library/src/components/an-auth/AnResetPassword";

export default {
  name: "ResetPasswordView",
  components: {
    AnResetPassword
  }
}
</script>

<style scoped>

</style>
