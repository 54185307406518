<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title
        class="primary primary-title white--text"
      >
        <v-icon color="white" class="mr-4">mdi-help</v-icon>
        Aide d'Utilisation
        <v-spacer></v-spacer>
        <v-btn
          @click="dialog = false"
          icon
          dark
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h2>
              Gravité = Gravité Instant T :
            </h2>
            <v-row no-gutters>
              <v-col cols="12" class="mt-2">
                <v-icon color="red darken-4">mdi-checkbox-blank-circle</v-icon>
                <span class="red--text darken-4--text">Alerte</span
                ><span>
                  = Vérifier le patient en priorité, présence de symptômes
                  alertants</span
                >
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-icon color="red">mdi-checkbox-blank-circle</v-icon>
                <span class="red--text">Levée de doute</span
                ><span>
                  = Évaluation de la gravité du patient recommandée en 2e
                  priorité (score >= 20)</span
                >
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-icon color="orange">mdi-checkbox-blank-circle</v-icon>
                <span class="orange--text">Contrôle jour J</span
                ><span> = Évaluation dans les 24h (score entre 10 et 19)</span>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-icon color="green">mdi-checkbox-blank-circle</v-icon>
                <span class="green--text">Abstention</span
                ><span
                  v-html="`= Pas d'action particulière (score <= 9)`"
                ></span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <h2>
             Proba. = Probabilité Covid+ :
            </h2>
            <v-row no-gutters>
              <v-col cols="12" class="mt-2">
                <span class="red--text">Très probable</span
                ><span> = score supérieur ou égal à 20</span>
              </v-col>
              <v-col cols="12" class="mt-2">
                <span class="orange--text">Probable</span
                ><span> = score entre 10 et 19</span>
              </v-col>
              <v-col cols="12" class="mt-2">
                <span class="green--text">Peu probable</span
                ><span> = score inférieur à 10</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <h2>
              Aggra. = Risque d'aggravation a posteriori :
            </h2>
            <v-row no-gutters>
              <v-col cols="12" class="mt-2">
                <span class="red--text">Très probable</span
                ><span> = score supérieur ou égal à 20</span>
              </v-col>
              <v-col cols="12" class="mt-2">
                <span class="orange--text">Probable</span
                ><span> = score entre 10 et 19</span>
              </v-col>
              <v-col cols="12" class="mt-2">
                <span class="green--text">Peu probable</span
                ><span> = score inférieur à 10</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <h2>
              Contact = Risque de Contage
            </h2>
          </v-col>
          <v-col cols="12">
            <h2>
              Covid+ = Testé positif au Covid
            </h2>
          </v-col>
          <v-col cols="12">
            <h2>Isol. = Isolement</h2>
          </v-col>
          <v-col cols="12">
            <h2>
              Hospi. = Hospitalisation
            </h2>
          </v-col>
          <v-col cols="12">
            <h2>AT = Arrêt de Travail</h2>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style>
</style>
