<template>
  <PrScoreStatistics class="mt-6"></PrScoreStatistics>
</template>

<script>
import PrScoreStatistics from "prevana-library/src/components/statistics/components/PrScoreStatistics";
export default {
  name: "HFManagerRPSStatisticsView",
  components: {
    PrScoreStatistics
  }
}
</script>

<style scoped>

</style>
