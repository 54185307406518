<template>
  <div>
    <v-btn color="secondary" elevation="0" class="align-self-center"  @click="dialog=true">
      <v-icon small class="mr-2">mdi-plus</v-icon>
      <span class="medium--text font-size--12">{{$t('common.importer_document')}}</span>
    </v-btn>

    <AnAttachmentAddDialog :dialog.sync="dialog"
                           :patient_id="patient_id"
                           color_label="basic"
                           :visible_by="visible_by"
                           :get_shared_with="true"
                           :app_name="app_name"
                           v-on:new_document="$emit('new_document', $event)">
      <template v-slot:card-title>
        <DialogCardTitleTemplate :title="$t('common.importer_document')" v-on:click="dialog=false"/>
      </template>
      <template v-slot:additional_data v-if="choose_pds">
        <v-select
          v-model="pds"
          :items="pds_items"
          :loading="loading_pds"
          :placeholder="$t('common.select_pro')"
          :rules="[rules.required]"
          :disabled="default_selected !== null"
          color="primary"
          filled
        />
      </template>
    </AnAttachmentAddDialog>
  </div>
</template>

<script>
import AnAttachmentAddDialog from "anam-library/src/components/an-attachment/AnAttachmentAddDialog";
import DialogCardTitleTemplate from "@/components/patient/components/DialogCardTitleTemplate";
import {get_profession_name} from "anam-library/src/data/professions";
import rules from "anam-library/src/js/rules";

export default {
  name: "DocumentsNewButton",
  components: {
    AnAttachmentAddDialog,
    DialogCardTitleTemplate
  },
  props: {
    patient_id: {
      type: String,
      required: true
    },
    choose_pds: {
      type: Boolean,
      default: false
    },
    app_name: {
      type: String,
      default: null
    },
    default_selected: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    dialog: false,
    pds: null,
    pds_items: [],
    loading_pds: false,
    rules: rules,
    visible_by: null
  }),
  mounted() {
    if (this.choose_pds) {
      this.loading_pds = true;
      this.pds_items = [
        {value: -1, text: "Médecin & Infirmier"},
        {value: -2, text: "Psychiatre"},
      ];
      this.$http.get(`/api/patients/0/professionals`).then(response => {
        for (let user of response.data) {
          if (user.professionnel__dmp_type === "93") {
            this.pds_items.push({
              value: user.id,
              text: `${user.first_name} ${user.last_name.toUpperCase()} (${get_profession_name(user.professionnel__dmp_type)})`
            });
          }
        }
      }).finally(() => {
        this.loading_pds = false;
        if (this.default_selected) this.pds = this.default_selected;
        else this.pds = null;
      })
    }
    else if (this.$store.getters.getUserPermission === 2) {
      let pro_data = this.$store.getters.getUserInfos("professionnel");
      if (pro_data.speciality_code === "SM42") this.visible_by = `{"speciality_code": "SM42"}`;
      else if (["10", "60"].includes(pro_data.dmp_type)) this.visible_by = `{"dmp_type": [10, 60]}`;
      else this.visible_by = `{"user_id": ${this.$store.getters.getUserInfos("id")}}`;
    }
  },
  watch: {
    pds(val) {
      if (val === -1) this.visible_by = `{"dmp_type": [10, 60]}`;
      else if (val === -2) this.visible_by = `{"speciality_code": "SM42"}`;
      else this.visible_by = `{"user_id": ${val}}`;
    }
  }
}
</script>

<style scoped>

</style>
