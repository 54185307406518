import Vue from 'vue'
import VueRouter from 'vue-router'

//Common
import PrRedirectionView from "prevana-library/src/components/common/PrRedirectionView";
import InscriptionView from "../components/common/inscription/InscriptionView";
import PatientVaccinationAppointement from "../components/patient/vaccination/appointement/PatientVaccinationAppointementView";

//Patient
import PatientLayout from "../components/patient/PatientLayout";
import PatientDashboard from "../components/patient/dashboard/PatientDashboardView";
import PatientDocumentsView from "@/components/patient/documents/PatientDocumentsView";
import PatientRDVView from "@/components/patient/rdv/PatientRDVView";
import PatientSettings from "../components/patient/settings/PatientSettingsView";
import PatientVaccinationQuestionnaire
  from "../components/patient/vaccination/questionnaire/PatientVaccinationQuestionnaireView";
import VaccinationNotEligible from "../components/patient/vaccination/not-eligible/PatientVaccinationNotEligibleView";
import PatientVaccinationConsent from "../components/patient/vaccination/consent/PatientVaccinationConsentView";
import PatientVaccinationAppointmentSummary
  from "../components/patient/vaccination/appointment-summary/PatientVaccinationAppointmentSummaryView";
import PatientVaccinationEvolution from "../components/patient/vaccination/evolution/PatientVaccinationEvolutionView";
import PatientVaccinationEndQuestionnaireSuivi
  from "../components/patient/vaccination/end-questionnaire-suivi/PatientVaccinationEndQuestionnaireSuiviView";
import QuestionnaireView from "@/components/patient/questionnaire/QuestionnaireView";
import ContinueQuestionnaireView from "@/components/patient/questionnaire/ContinueQuestionnaireView";
import EndQuestionnaireView from "@/components/patient/questionnaire/EndQuestionnaireView";

// Patient - Vaccination
import PatientVaccinationLayout from "../components/patient/vaccination/PatientVaccinationLayout";
import PatientVaccinationDashboardView from "@/components/patient/vaccination/dashboard/PatientVaccinationDashboardView";

// Patient -RPS
import PatientRPSLayout from "@/components/patient/rps/PatientRPSLayout";
import PatientRPSDashboardView from "../components/patient/rps/dashboard/PatientRPSDashboardView";
import PatientRpsResultsView from "@/components/patient/rps/results/PatientRpsResultsView";
import PatientRpsFAQView from "@/components/patient/rps/faq/PatientRpsFAQView";
import PatientRPSStartQuestionnaireView from "../components/patient/rps/questionnaire/PatientRPSStartQuestionnaireView";
import PatientRPSQuestionnaireView from "../components/patient/rps/questionnaire/PatientRPSQuestionnaireView";
import PatientRPSEndQuestionnaire from "../components/patient/rps/questionnaire/PatientRPSEndQuestionnaire";
import RPSAppointment from "@/components/patient/rps/appointment/RPSAppointment";
import PatientRPSAppointmentSummary from "@/components/patient/rps/appointment/PatientRPSAppointmentSummary";

// Patient - VMA
import PatientVMADashboardView from "../components/patient/vma/dashboard/PatientVMADashboardView";
import PatientVmaResultsView from "@/components/patient/vma/results/PatientVmaResultsView";
import PatientVMAAppointment from "../components/patient/vma/appointment/VMAAppointment";
import PatientVMALayout from "@/components/patient/vma/PatientVMALayout";

// Patient - Covidhelp
import PatientCovidLayout from "@/components/patient/covidhelp/PatientCovidLayout";
import PatientCovidDashboardView from "@/components/patient/covidhelp/dashboard/PatientCovidDashboardView";
import PatientCovidEvolutionView from "@/components/patient/covidhelp/evolution/PatientCovidEvolutionView";
import PatientCovidEndQuestionnaireView from "@/components/patient/covidhelp/questionnaire/PatientCovidEndQuestionnaireView";

// Patient - ECOS
import PrPatientConfirmIdentityView from "prevana-library/src/components/patient/PrPatientConfirmIdentityView";
import PatientProfileECOSView from "../components/patient/ecos/PatientProfileView";
import PatientECOSEndQuestionnaireView from "../components/patient/ecos/PatientECOSEndQuestionnaireView";

// Patient - Etude
import PatientProfileEtudeView from "../components/patient/etude/PatientProfileView";
import PatientEtudeEndQuestionnaireView from "../components/patient/etude/PatientEtudeEndQuestionnaireView";
import PatientDashboardEtudeView from "../components/patient/etude/PatientDashboardEtudeView";
import PatientEtudeLayout from "../components/patient/etude/PatientEtudeLayout";

//Doctor Common
import DoctorLayout from "../components/doctor/DoctorLayout";
import Settings from "@/components/doctor/common/SettingsView"
// Doctor Vaccination
import DoctorVaccinationLayout from "../components/doctor/vaccination/VaccinationLayout"
import DoctorVaccinationDashboard from "../components/doctor/vaccination/dashboard/DashboardLayout";
import DoctorVaccinationDpa from "../components/doctor/vaccination/dpa/DpaLayout";
import DoctorVaccinationStatisticsView from "../components/doctor/vaccination/statistics/DoctorVaccinationStatisticsView";
import DoctorVaccinationAppointmentsView from "@/components/doctor/vaccination/rdv/DoctorVaccinationAppointmentsView";
// Doctor Covidhelp
import DoctorCovidhelpLayout from "@/components/doctor/covidhelp/CovidhelpLayout"
import DoctorCovidhelpDashboard from "@/components/doctor/covidhelp/dashboard/DashboardView"
import DoctorCovidhelpStatistics from "@/components/doctor/covidhelp/statistics/StatisticsView"
import DoctorCovidTableMonitoringView from "@/components/doctor/covidhelp/statistics/DoctorCovidTableMonitoringView";
import DoctorCovidClustersView from "@/components/doctor/covidhelp/statistics/DoctorCovidClustersView";
import DoctorCovidhelpDpa from "@/components/doctor/covidhelp/dpa/DpaView"
// Doctor RPS
import DoctorRPSLayout from "@/components/doctor/rps/RPSLayout";
import DoctorRPSDashboard from "@/components/doctor/rps/dashboard/DashboardLayout"
import DoctorRPSDpa from "@/components/doctor/rps/dpa/DpaView"
import DoctorRPSStatistics from "@/components/doctor/rps/statistics/StatisticsLayout";
import DoctorQuestionnaireView from "@/components/doctor/common/QuestionnaireView"
import DoctorRPSAppointmentsView from "@/components/doctor/rps/rdv/DoctorRPSAppointmentsView";
// Doctor VMA
import DoctorVMALayout from "@/components/doctor/vma/DoctorVMALayout"
import DoctorVMADashboardView from "@/components/doctor/vma/dashboard/DoctorVMADashboardView";
import DoctorVMADpaView from "@/components/doctor/vma/dpa/DoctorVMADpaView";
import DoctorVMAStatisticsView from "../components/doctor/vma/statistics/DoctorVMAStatisticsView";
import VMAAppointment from "../components/doctor/vma/VMAAppointment";
import DoctorVMAAppointmentsView from "@/components/doctor/vma/rdv/DoctorVMAAppointmentsView";
// Doctor ECOS
import ECOSDoctorLayout from "../components/doctor/ecos/ECOSDoctorLayout";
import ECOSDoctorDashboardView from "../components/doctor/ecos/dashboard/ECOSDoctorDashboardView";
import ECOSDoctorHistoryQuestionnairesView from "../components/doctor/ecos/ECOSDoctorHistoryQuestionnairesView";
import DoctorECOSDpaView from "../components/doctor/ecos/dpa/DoctorECOSDpaView";
// Doctor Etude
import EtudeDoctorLayout from "../components/doctor/etude/EtudeDoctorLayout";
import EtudeDoctorDashboardView from "../components/doctor/etude/dashboard/EtudeDoctorDashboardView";
import DoctorEtudeDpaView from "../components/doctor/etude/dpa/DoctorEtudeDpaView";

// HF MANAGER
import HfManagerLayout from "@/components/hfmanager/HfManagerLayout";
import PrManagerSettings from "prevana-library/src/components/hfmanager/settings/PrManagerSettings";
// HF Manager - Utilisateurs & Administration
import PrHFManagerAdministrationLayout from "prevana-library/src/components/hfmanager/administration/PrHFManagerAdministrationLayout";
import PrHFManagerUsersView from "prevana-library/src/components/hfmanager/administration/users/PrHFManagerUsersView";
import PrHFManagerPDSView from "prevana-library/src/components/hfmanager/administration/users/PrHFManagerPDSView";
import PrHFManagerImportView from "prevana-library/src/components/hfmanager/administration/import/PrHFManagerImportView";
import PrHFManagerImportV2View from "prevana-library/src/components/hfmanager/administration/import/PrHFManagerImportV2View"; // TODO IMPORT: A supprimer lorsque la page de l'import des entités v1 sera supprimée et que la v2 seras le systeme principal d'import
import PrHFManagerAffectationsView from "prevana-library/src/components/hfmanager/administration/affectations/PrHFManagerAffectationsView";
import PrHFManagerAssociatedPatients from "prevana-library/src/components/hfmanager/administration/users/PrHFManagerAssociatedPatients";
import PrHFManagerImportEntityV2 from "prevana-library/src/components/hfmanager/administration/import/PrHFManagerImportEntityV2";
// HF Manager - Covid
import HFManagerCovidLayout from "@/components/hfmanager/covidhelp/HFManagerCovidLayout";
import HFManagerCovidStatisticsView from "@/components/hfmanager/covidhelp/statistics/HFManagerCovidStatisticsView";
import HFManagerCovidClustersView from "@/components/hfmanager/covidhelp/clusters/HFManagerCovidClustersView";
import HFManagerCovidTableMonitoringView from "@/components/hfmanager/covidhelp/monitoring/HFManagerCovidTableMonitoringView";
// HF Manager - Vaccination
import HFManagerVaccinationLayout from "@/components/hfmanager/vaccination/HFManagerVaccinationLayout";
import HFManagerVaccinationResumeView from "@/components/hfmanager/vaccination/resume/HFManagerVaccinationResumeView";
import HFManagerVaccinationStatisticsView from "@/components/hfmanager/vaccination/statistics/HFManagerVaccinationStatisticsView";
// HF Manager - RPS
import HFManagerRPSLayout from "@/components/hfmanager/rps/HFManagerRPSLayout";
import HFManagerRPSStatisticsView from "@/components/hfmanager/rps/statistics/HFManagerRPSStatisticsView";
// HF Manager - Appointments
import HFManagerAppointmentsLayout from "@/components/hfmanager/rdv/HFManagerAppointmentsLayout";
import HFManagerRPSAppointmentsView from "@/components/hfmanager/rdv/rps/HFManagerRPSAppointmentsView";
import HFManagerVaccinationAppointmentsView from "@/components/hfmanager/rdv/vaccination/HFManagerVaccinationAppointmentsView";
import HFManagerVMAAppointmentsView from "@/components/hfmanager/rdv/vma/HFManagerVMAAppointmentsView";
// HF Manager - VMA
import HFManagerVMALayout from "../components/hfmanager/vma/HFManagerVMALayout";
import HFManagerVMAResumeView from "../components/hfmanager/vma/resume/HFManagerVMAResumeView";

//Home
import HomeLayout from "@/components/home/HomeLayout";
import HomeDirectoryLayout from "@/components/home/directory/DirectoryLayout";
import ConnexionLayout from "../components/home/ConnexionView";
import HomeSignupLayout from "@/components/home/signup/SignupLayout"


import ResetPasswordLayout from "../components/home/ResetPasswordView";

// Vaccination ouverte
import VaccinationLayout from "../components/vaccination/VaccinationLayout";
import VaccinationHomeView from "../components/vaccination/home/VaccinationHomeView";
import VaccinationConsentementView from "../components/vaccination/patient/consentement/VaccinationConsentementView";
import VaccinationNotEligibleView from "../components/vaccination/patient/signup/VaccinationNotEligibleView";
import VaccinationSignupView from "../components/vaccination/patient/signup/VaccinationSignupView";
import VaccinationSignupConfirmedView from "../components/vaccination/patient/signup/VaccinationSignupConfirmedView";

import VisioLayout from "../components/visio/VisioLayout";

import router_error_pages from "anam-library/src/js/router_error_pages_new_charte";

import store from "../store";
import PatientVaccinationAppointementView
  from "@/components/patient/vaccination/appointement/PatientVaccinationAppointementView";

Vue.use(VueRouter);

function redirect_app_not_allowed(to, from, next) {
  if (to.path.includes("covidhelp") && !store.getters.getPatientHasAccessCovidapp) next("/patient/dashboard");
  else if (to.path.includes("rps") && !store.getters.getPatientHasAccessRps) next("/patient/dashboard");
  else if (to.path.includes("vaccination") && !store.getters.getPatientHasAccessVaccination) next("/patient/dashboard");
  else if (to.path.includes("vma") && !store.getters.getPatientHasAccessVma) next("/patient/dashboard");
  else next();
}

const routes = [
  router_error_pages.deconnexion_page,
  router_error_pages.forbidden_page,
  router_error_pages.not_found_page,
  router_error_pages.wildcard_page,
  router_error_pages.nginx_error_page,
  router_error_pages.error_server_page,
  router_error_pages.expiration_token_page,
  {
    path: '/patient',
    redirect: {name: 'PatientDashboard'},
    name: 'PatientLayout',
    component: PatientLayout,
    children: [
      {
        path: 'dashboard',
        name: 'PatientDashboard',
        component: PatientDashboard
      },
      {
        path: 'files',
        name: 'PatientDocuments',
        component: PatientDocumentsView
      },
      {
        path: 'rdv',
        name: 'PatientRDV',
        component: PatientRDVView
      },
      {
        path: 'settings',
        name: 'PatientSettings',
        component: PatientSettings
      },
      {
        path: 'rps',
        name: 'PatientRpsLayout',
        component: PatientRPSLayout,
        redirect: {name: 'PatientRpsDashboard'},
        beforeEnter: redirect_app_not_allowed,
        children: [
          {
            path: 'dashboard',
            name: 'PatientRpsDashboard',
            component: PatientRPSDashboardView
          },
          {
            path: 'details-results',
            name: 'PatientRpsResults',
            component: PatientRpsResultsView
          },
          {
            path: 'faq',
            name: 'PatientRpsFAQ',
            component: PatientRpsFAQView
          },
          {
            path: 'start-questionnaire',
            name: 'PatientRPSStartQuestionnaire',
            component: PatientRPSStartQuestionnaireView
          },
          {
            path: 'questionnaire',
            name: 'PatientRPSQuestionnaire',
            component: PatientRPSQuestionnaireView
          },
          {
            path: 'end-questionnaire/:questionnaire_uid',
            name: 'PatientRPSEndQuestionnaire',
            component: PatientRPSEndQuestionnaire
          },
          {
            path: 'appointment',
            name: 'PatientRPSAppointement',
            component: RPSAppointment,
          },
          {
            path: 'summary',
            name: 'PatientRPSAppointmentSummary',
            component: PatientRPSAppointmentSummary,
          },
        ]
      },
      {
        path: 'covidhelp',
        name: 'PatientCovidLayout',
        component: PatientCovidLayout,
        redirect: {name: 'PatientCovidDashboard'},
        beforeEnter: redirect_app_not_allowed,
        children: [
          {
            path: 'dashboard',
            name: 'PatientCovidDashboard',
            component: PatientCovidDashboardView
          },
          {
            path: 'evolution/:agency_name/:pds_uid',
            name: 'PatientCovidEvolution',
            component: PatientCovidEvolutionView,
          },
          {
            path: 'end-questionnaire/:questionnaire_uid',
            name: 'PatientCovidEndQuestionnaire',
            component: PatientCovidEndQuestionnaireView
          },
        ]
      },
      {
        path: 'vaccination',
        name: 'PatientVaccinationLayout',
        component: PatientVaccinationLayout,
        redirect: {name: 'PatientVaccinationDashboard'},
        beforeEnter: redirect_app_not_allowed,
        children: [
          {
            path: 'dashboard',
            name: 'PatientVaccinationDashboard',
            component: PatientVaccinationDashboardView
          },
          {
            path: 'consent',
            name: 'PatientVaccinationConsent',
            component: PatientVaccinationConsent
          },
          {
            path: 'appointment',
            name: 'PatientVaccinationAppointement',
            component: PatientVaccinationAppointementView,
          },
          {
            path: 'questionnaire',
            name: 'PatientVaccinationQuestionnaire',
            component: PatientVaccinationQuestionnaire,
          },
          {
            path: 'not-eligible',
            name: 'PatientVaccinationNotEligible',
            component: VaccinationNotEligible,
          },
          {
            path: 'summary',
            name: 'PatientVaccinationAppointmentSummary',
            component: PatientVaccinationAppointmentSummary,
          },
          {
            path: 'evolution',
            name: 'PatientVaccinationEvolution',
            component: PatientVaccinationEvolution,
          },
          {
            path: 'end_questionnaire_suivi',
            name: 'PatientVaccinationEndQuestionnaireSuivi',
            component: PatientVaccinationEndQuestionnaireSuivi,
          },
        ]
      },
      {
        path: 'vma',
        name: 'PatientVmaLayout',
        component: PatientVMALayout,
        redirect: {name: 'PatientVmaDashboard'},
        beforeEnter: redirect_app_not_allowed,
        children: [
          {
            path: 'dashboard',
            name: 'PatientVmaDashboard',
            component: PatientVMADashboardView
          },
          {
            path: 'details-results',
            name: 'PatientVmaResults',
            component: PatientVmaResultsView
          },
          {
            path: 'appointment',
            name: 'PatientVMAAppointement',
            component: PatientVMAAppointment,
          }
        ]
      },
      {
        path: 'etude',
        name: 'PatientEtudeLayout',
        component: PatientEtudeLayout,
        redirect: {name: 'PatientDashboardEtude'},
        beforeEnter: redirect_app_not_allowed,
        children: [
          {
            path: 'dashboard',
            name: 'PatientDashboardEtude',
            component: PatientDashboardEtudeView
          }
        ]
      },
      {
        path: ":patient_id/confirm-identity/ECOS",
        name: "ECOSPatientConfirmIdentity",
        component: PrPatientConfirmIdentityView,
        props: {
          app_name: "ECOS",
          agency: {name: "sorbonne", commercial_name: "ECOS - Sorbonne"},
          patient_data_url: "patient-data-ecos",
          component_redirection: "PatientProfileECOS"
        }
      },
      {
        path: ":patient_id/confirm-identity/etude",
        name: "EtudePatientConfirmIdentity",
        component: PrPatientConfirmIdentityView,
        props: {
          app_name: "etude",
          agency: {name: "cephismer", commercial_name: "IRBA - Cephismer"},
          patient_data_url: "patient-data-etude",
          component_redirection: "PatientProfileEtude"
        }
      },
      {
        path: ":patient_id/profile/ECOS",
        name: "PatientProfileECOS",
        component: PatientProfileECOSView
      },
      {
        path: ":patient_id/profile/etude",
        name: "PatientProfileEtude",
        component: PatientProfileEtudeView
      },
      {
        path: ":patient_id/end_questionnaire/ECOS",
        name: "PatientECOSEndQuestionnaire",
        component: PatientECOSEndQuestionnaireView
      },
      {
        path: "end_questionnaire/etude",
        name: "PatientEtudeEndQuestionnaire",
        component: PatientEtudeEndQuestionnaireView
      },
      {
        path: ':patient_id/questionnaire/:questionnaire_uid',
        name: 'QuestionnaireView',
        component: QuestionnaireView
      },
      {
        path: 'questionnaire',
        name: 'QuestionnaireView',
        component: QuestionnaireView
      },
      {
        path: ':patient_id/continue-questionnaire/:questionnaire_uid',
        name: 'ContinueQuestionnaireView',
        component: ContinueQuestionnaireView
      },
      {
        path: 'end-questionnaire/:questionnaire_uid',
        name: 'EndQuestionnaireView',
        component: EndQuestionnaireView
      },
    ]
  },


  // TODO: rps dans l'url temporaire, à retirer après factorisation avec les autres apps
  {
    path: '/rps/:agency/inscription/',
    name: 'InscriptionView',
    component: InscriptionView
  },
  {
    path: '/rps/:agency/inscription/:token',
    name: 'InscriptionView',
    component: InscriptionView
  },
  {
    path: '/doctor',
    redirect: {name: 'DoctorLayout'},
    name: 'DoctorLayout',
    component: DoctorLayout,
    children: [
      {
        path: 'vaccination',
        redirect: {name: 'VaccinationDashboard'},
        name: 'VaccinationLayout',
        component: DoctorVaccinationLayout,
        children: [
          {
            path: 'dashboard',
            name: 'VaccinationDashboard',
            component: DoctorVaccinationDashboard,
          },
          {
            path: 'appointments',
            name: 'DoctorVaccinationAppointments',
            component: DoctorVaccinationAppointmentsView,
            beforeEnter: (to, from, next) => {
              store.state.stats_user_role = "PDS";
              next();
            }
          },
          {
            path: 'statistics',
            name: 'VaccinationStatistics',
            component: DoctorVaccinationStatisticsView,
            beforeEnter: (to, from, next) => {
              store.state.stats_user_role = "PDS";
              next();
            }
          },
          {
            path: 'settings',
            name: 'VaccinationSettings',
            component: Settings
          },
          {
            path: 'patient/:id',
            name: 'VaccinationDpa',
            component: DoctorVaccinationDpa
          },
          {
            path: 'patient/:id/appointment',
            name: 'VaccinationAppointement',
            // TODO MKT : risque de poser problème, il ne faut pas que ce soit les mêmes composants....
            component: PatientVaccinationAppointementView,
          },
          {
            path: 'not-eligible',
            name: 'DoctorVaccinationNotEligible',
            component: VaccinationNotEligible,
          },
          {
            path: 'questionnaire',
            name: 'DoctorQuestionnaireView',
            component: DoctorQuestionnaireView
          },
        ]
      },
      {
        path: 'covidhelp',
        redirect: {name: 'CovidhelpDashboard'},
        name: 'CovidhelpLayout',
        component: DoctorCovidhelpLayout,
        children: [
          {
            path: 'dashboard',
            name: 'CovidhelpDashboard',
            component: DoctorCovidhelpDashboard,
          },
          {
            path: 'settings',
            name: 'CovidhelpSettings',
            component: Settings,
          },
          {
            path: 'statistics',
            name: 'CovidhelpStatistics',
            component: DoctorCovidhelpStatistics,
          },
          {
            path: 'appointments',
            redirect: {name: 'CovidhelpDashboard'},
          },
          {
            path: 'table_suivi',
            name: 'CovidhelpTableSuivi',
            component: DoctorCovidTableMonitoringView,
          },
          {
            path: 'clusters',
            name: 'DoctorCovidClusters',
            component: DoctorCovidClustersView,
          },
          {
            path: 'patient/:id',
            name: 'CovidhelpDpa',
            component: DoctorCovidhelpDpa,
          },
          {
            path: 'questionnaire',
            name: 'CovidHelpDoctorQuestionnaireView',
            component: DoctorQuestionnaireView
          },
        ]
      },
      {
        path: 'rps',
        redirect: {name: 'RPSDashboard'},
        name: 'RPSLayout',
        component: DoctorRPSLayout,
        children: [
          {
            path: 'dashboard',
            name: 'RPSDashboard',
            component: DoctorRPSDashboard,
          },
          {
            path: 'appointments',
            name: 'DoctorRPSAppointments',
            component: DoctorRPSAppointmentsView,
          },
          {
            path: 'statistics',
            name: 'RPSStatistics',
            component: DoctorRPSStatistics,
          },
          {
            path: 'settings',
            name: 'RPSSettings',
            component: Settings
          },
          {
            path: 'patient/:id',
            name: 'DoctorRPSDpa',
            component: DoctorRPSDpa,
          },
          {
            path: 'patient/:id/appointment',
            name: 'RPSAppointment',
            component: RPSAppointment,
          },
        ]
      },
      {
        path: 'vma',
        redirect: {name: 'VMADoctorDashboard'},
        name: 'VMADoctorLayout',
        component: DoctorVMALayout,
        children: [
          {
            path: 'dashboard',
            name: 'VMADoctorDashboard',
            component: DoctorVMADashboardView,
          },
          {
            path: 'patient/:id',
            name: 'VMADoctorDpa',
            component: DoctorVMADpaView,
          },
          {
            path: 'appointments',
            name: 'DoctorVMAAppointments',
            component: DoctorVMAAppointmentsView
          },
          {
            path: 'statistics',
            name: 'VMADoctorStatistics',
            component: DoctorVMAStatisticsView
          },
          {
            path: 'settings',
            name: 'VMADoctorSettings',
            component: Settings
          },
          {
            path: 'patient/:id/appointment',
            name: 'VMAAppointment',
            component: VMAAppointment,
          },
          {
            path: 'questionnaire',
            name: 'DoctorQuestionnaireViewVMA',
            component: DoctorQuestionnaireView
          },
        ]
      },
      {
        path: 'ecos',
        redirect: {name: 'ECOSDoctorDashboardView'},
        name: 'ECOSDoctorLayout',
        component: ECOSDoctorLayout,
        children: [
          {
            path: 'dashboard',
            name: 'ECOSDoctorDashboardView',
            component: ECOSDoctorDashboardView,
          },
          {
            path: 'history-questionnaires',
            name: 'ECOSDoctorHistoryQuestionnaires',
            component: ECOSDoctorHistoryQuestionnairesView,
          },
          {
            path: 'patient/:id',
            name: 'ECOSDoctorDpa',
            component: DoctorECOSDpaView,
          },
          {
            path: 'settings',
            name: 'ECOSDoctorSettings',
            component: Settings
          },
        ]
      },
      {
        path: 'etude',
        redirect: {name: 'EtudeDoctorDashboardView'},
        name: 'EtudeDoctorLayout',
        component: EtudeDoctorLayout,
        children: [
          {
            path: 'dashboard',
            name: 'EtudeDoctorDashboardView',
            component: EtudeDoctorDashboardView,
          },
          {
            path: 'patient/:id',
            name: 'EtudeDoctorDpa',
            component: DoctorEtudeDpaView,
          },
          {
            path: 'settings',
            name: 'EtudeDoctorSettings',
            component: Settings
          },
        ]
      }
    ]
  },
  {
    path: '/manager',
    name: 'HfManagerLayout',
    component: HfManagerLayout,
    redirect: {name: 'HfManagerHome'},
    children: [
      {
        path: 'settings',
        name: 'ManagerSettings',
        component: PrManagerSettings
      },
      {
        path: 'rps',
        name: 'HFManagerRPSLayout',
        component: HFManagerRPSLayout,
        redirect: {name: 'HFManagerRPSStatistics'},
        children: [
          {
            path: 'statistics',
            name: 'HFManagerRPSStatistics',
            component: HFManagerRPSStatisticsView,
          },
        ]
      },
      {
        path: 'covidhelp',
        name: 'HFManagerCovidLayout',
        component: HFManagerCovidLayout,
        redirect: {name: 'HFManagerCovidStatistics'},
        children: [
          {
            path: 'instant',
            name: 'HFManagerCovidStatistics',
            component: HFManagerCovidStatisticsView,
          },
          {
            path: 'clusters',
            name: 'HFManagerCovidClusters',
            component: HFManagerCovidClustersView,
          },
          {
            path: 'table_monitoring',
            name: 'HFManagerCovidTableMonitoring',
            component: HFManagerCovidTableMonitoringView,
          }
        ]
      },
      {
        path: 'vaccination',
        name: 'HFManagerVaccinationLayout',
        component: HFManagerVaccinationLayout,
        redirect: {name: 'HFManagerVaccinationResume'},
        beforeEnter: (to, from, next) => {
          store.state.stats_user_role = "MANAGER";
          next();
        },
        children: [
          {
            path: 'resume',
            name: 'HFManagerVaccinationResume',
            component: HFManagerVaccinationResumeView,
          },
          {
            path: 'statistics',
            name: 'HFManagerVaccinationStatistics',
            component: HFManagerVaccinationStatisticsView,
          }
        ]
      },
      {
        path: 'vma',
        name: 'HFManagerVMALayout',
        component: HFManagerVMALayout,
        redirect: {name: 'HFManagerVMAResume'},
        children: [
          {
            path: 'resume',
            name: 'HFManagerVMAResume',
            component: HFManagerVMAResumeView,
          },
        ]
      },
      {
        path: 'appointments',
        name: 'HFManagerAppointmentsLayout',
        component: HFManagerAppointmentsLayout,
        children: [
          {
            path: 'rps',
            name: 'HFManagerRPSAppointments',
            component: HFManagerRPSAppointmentsView
          },
          {
            path: 'vaccination',
            name: 'HFManagerVaccinationAppointments',
            component: HFManagerVaccinationAppointmentsView
          },
          {
            path: 'vma',
            name: 'HFManagerVMAAppointments',
            component: HFManagerVMAAppointmentsView
          },
        ],
      },
      {
        path: 'administration',
        name: 'HFManagerAdministration',
        component: PrHFManagerAdministrationLayout,
        redirect: {name: 'HFManagerUsers'},
        children: [
          {
            path: 'users',
            name: 'HFManagerUsers',
            component: PrHFManagerUsersView
          },
          {
            path: 'associated_patients/:patient_id',
            name: 'HFManagerAssociatedPatients',
            component: PrHFManagerAssociatedPatients
          },
          {
            path: 'health-practitioners',
            name: 'HFManagerPDS',
            component: PrHFManagerPDSView
          },
          {
            path: 'import',
            name: 'HFManagerImport',
            component: PrHFManagerImportView
          },
          { // TODO IMPORT: A supprimer lorsque la page de l'import des entités v1 sera supprimée et que la v2 seras le systeme principal d'import
            path: 'import-v2',
            name: 'HFManagerImportV2',
            component: PrHFManagerImportV2View
          },
          {
            path: 'import-entities-v2',
            name: 'HFManagerImportEntityV2',
            component: PrHFManagerImportEntityV2
          },
          {
            path: 'entities',
            name: 'HFManagerAffectations',
            component: PrHFManagerAffectationsView
          }
        ]
      },
    ],
  },
  {
    path: '/SDIS38',
    redirect: {path: '/SDIS38/vaccination'},
  },
  {
    path: '/PYRAMIDE',
    redirect: {path: '/PYRAMIDE/vaccination'},
  },
  {
    path: '/SOS92',
    redirect: {path: '/SOS92/vaccination'},
  },
  {
    path: '/:agency_name/vaccination',
    component: VaccinationLayout,
    redirect: {name: 'VaccinationHomeView'},
    children: [
      {
        path: '',
        name: 'VaccinationHomeView',
        component: VaccinationHomeView,
      },
      {
        path: 'consentement',
        name: 'VaccinationConsentementView',
        component: VaccinationConsentementView,
      },
      {
        path: 'not-eligible',
        name: 'PatientVaccinationNotEligible',
        component: VaccinationNotEligibleView,
      },
      {
        path: 'signup',
        name: 'VaccinationSignupView',
        component: VaccinationSignupView,
      },
      {
        path: 'signup-confirmed/:token',
        name: 'VaccinationSignupView',
        component: VaccinationSignupConfirmedView,
      },
    ]
  },
  {
    path: '/redirection',
    name: 'RedirectionView',
    component: PrRedirectionView
  },
  {
    path: '/visio_chat/visioroom/:room_uid/:agency_name',
    name: 'VisioLayout',
    component: VisioLayout,
  },
  {
    path: '/',
    name: 'HomeLayout',
    component: HomeLayout,
    redirect: {
      name: 'HomeDirectoryLayout'
    },
    children: [
      {
        path: '/:agency_name',
        name: 'ConnexionLayout',
        component: ConnexionLayout,
      },
      {
        path: '/',
        name: 'HomeDirectoryLayout',
        component: HomeDirectoryLayout,
      },
      {
        path: '/:agency/reset_password',
        name: 'ResetPasswordLayout',
        component: ResetPasswordLayout,
      },
      {
        path: '/:agency/inscription/:autolog_uid',
        name: 'SignupLayout',
        component: HomeSignupLayout,
      },
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router
