<template>
  <v-container fluid>
    <PrScoreStatistics></PrScoreStatistics>
  </v-container>
</template>

<script>
import PrScoreStatistics from "prevana-library/src/components/statistics/components/PrScoreStatistics";

export default {
  name: "StatisticsLayout",
  components: {
    PrScoreStatistics
  },
}
</script>
