<template>
  <AnVisioLayout logo="https://www.anamnese.care/hubfs/Logiciels/PREVENTION/Prevana%20(g%C3%A9n%C3%A9ral)/Logos/logo-2022-prevana-couleur-BL.png" />
</template>

<script>
import AnVisioLayout from "anam-library/src/components/an-visio/AnVisioLayout";

export default {
  name: "VisioLayout",
  components: {AnVisioLayout}
}
</script>

<style scoped>

</style>
