<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title class="secondary white--text">
          <v-icon color="white" class="mr-4">mdi-account-plus</v-icon>
          Créer un patient
        </v-card-title>
        <v-card-text class="pt-6">
          <v-form ref="form_create_patient" v-model="valid_create_patient" autocomplete="off">
            <v-row>
            <v-col cols="12" sm="6">
              <AnTextField :value.sync="last_name" label="Nom" class="required" dense
                           :required="true" :show_title="false" type_field="last_name"/>
            </v-col>
            <v-col cols="12" sm="6">
              <AnTextField :value.sync="first_name" label="Prénom" class="required" dense
                           :required="true" :show_title="false" type_field="text"/>
            </v-col>
            <v-col cols="12" sm="6">
              <AnTextField :value.sync="sex" label="Sexe" dense class="required"
                           :required="true" type_field="sexe" :show_title="false"/>
            </v-col>
            <v-col cols="12" sm="6">
              <AnDate :date="birthdate"
                      label="Date de naissance"
                      is_birthdate dense
                      v-on:formatted_date="birthdate=$event"/>
            </v-col>
            <v-col cols="12" sm="6">
              <AnTextField :value.sync="email" label="Email" class="required" dense
                           :required="true" type_field="email" :show_title="false"/>
            </v-col>
            <v-col cols="12" sm="6">
              <AnTextField :value.sync="phone_number" label="Téléphone" dense
                           type_field="phone" :show_title="false"/>
            </v-col>
            <v-col cols="12" sm="6" v-if="show_patient_category">
              <v-select
                v-model="category"
                :items="items_statut"
                color="primary"
                item-color="primary"
                class="required"
                label="Statut"
                :rules="[rules.required]"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <AnTextField :value.sync="health_care_number" label="Numéro de sécurité sociale" dense
                           type_field="healthcare_number" :show_title="false"/>
            </v-col>
            <v-col cols="12" v-if="errors.length > 0" class="text-center">
              <p class="red--text font-weight-bold" v-for="(item, index) in errors" :key="index">
                {{ item }}
              </p>
            </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" outlined @click="dialog = false">Annuler</v-btn>
          <v-btn color="secondary" class="white--text" @click="create_patient()"
            :loading="loading"
            :disabled="!valid_create_patient"
            id="button_create_new_user"
          >Enregistrer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar_success">
      Le patient a été créé avec succès
      <v-btn @click="snackbar_success = false" icon dark>
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import rules from "anam-library/src/js/rules";
import AnDate from "anam-library/src/components/an-date/AnDate";
import AnTextField from "anam-library/src/components/an-ui/textfield/AnTextField";
import {get_list_category_by_agency} from "anam-library/src/data/dict_categories_pro";
import axios from "axios"
import getCookie from "anam-library/src/js/common";
import {show_patient_category} from "prevana-library/src/js/dict_permissions";

export default {
  name: "CreatePatientDialog",
  components: {
    AnDate,
    AnTextField
  },

  data() {
    return {
      dialog: false,
      rules: rules,
      items_sexe: [
        {text: "Homme", value: "M"},
        {text: "Femme", value: "F"},
      ],
      items_statut: get_list_category_by_agency(this.$store.getters.getCurrentAgency),
      first_name: null,
      last_name: null,
      birthdate: null,
      sex: null,
      email: null,
      phone_number: null,
      category: null,
      health_care_number: "",
      valid_create_patient: null,
      loading: false,
      errors: [],
      snackbar_success: false
    }
  },

  computed: {
    show_patient_category() {
      return show_patient_category(this.$store.getters.getAgencyName);
    }
  },

  watch: {
    dialog(val) {
      if (val) {
        this.loading = false;
        this.last_name = null;
        this.first_name = null;
        this.sex = null;
        this.birthdate = null;
        this.email = null;
        this.phone_number = null;
        this.category = null;
        this.health_care_number = "";
      }
    }
  },

  methods: {
    create_patient() {
      let data = {
        last_name: this.last_name,
        first_name: this.first_name,
        email: this.email,
        personnephysique: {
          sexe: this.sex,
          personel_phone: this.phone_number,
          birthdate: {},
        },
        patient: {
          categorie: this.category,
          health_care_number: this.health_care_number
        }
      }
      this.dialog_confirm_delete_role = false;
      this.loading = true;
      this.errors = [];
      if (this.birthdate) {
        data.personnephysique.birthdate = {birthdate: this.birthdate};
      }
      axios.post("/api/create_patient", data, {headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(response => {
        this.snackbar_success = true;
        this.dialog = false;
        this.$emit("action_after_success");
      }).catch(error => {
        if (error.response.status === 422) {
          for (let i in error.response.data) {
            this.errors.push(error.response.data[i][0]);
          }
        }
        else this.errors.push("Une erreur est survenue, veuillez réessayer ou nous contacter si le problème persiste");
      }).finally(() => {
        this.loading = false;
      })
    }
  }

}
</script>

<style scoped>
.required label::after {
  content: " *";
  color: red;
}
</style>
