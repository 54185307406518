import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueTelInput from 'vue-tel-input'
import * as Sentry from '@sentry/vue';
import {Integrations} from "@sentry/tracing";
import VueI18n from "vue-i18n";
import {defaultLocale, languages} from "./i18n"
import VueApexCharts from 'vue-apexcharts'
import utils from "anam-library/src/js/utils";
import api from 'anam-library/src/services/api.js'
import {default_api_error, default_api_response} from 'anam-library/src/services/api'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';

Vue.config.productionTip = false;
Vue.component('apex-chart', VueApexCharts);
Vue.use(VueTelInput);
Vue.use(VueApexCharts);
Vue.use(VueI18n);
Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.mixin(utils.mixin_router)

const messages = Object.assign(languages);

Vue.prototype.$http = api;
api.interceptors.response.use(response => {
        return default_api_response(response);
    }, error => {
        return default_api_error(error, api, store.dispatch);
    }
);

export const i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: 'fr',
    messages,
    silentTranslationWarn: true
});


Sentry.init({
    Vue: Vue,
    logErrors: true,
    sampleRate: 0.90,
    autoSessionTracking: true,
    dsn: "https://db601b212ae7429dab84f51b531f7496@o458729.ingest.sentry.io/5563154",
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 0.25,

});
export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');
