<template>
  <v-container fluid class="pa-6">
    <v-row class="mx-0">
      <v-col cols="12">
        <div class="d-flex">
<!--          TODO MKT : chercher l'image-->
<!--          <img :src="logo" width="50" height="auto" class="rounded-lg mr-4" alt="Logo Bien-Être"/>-->
          <v-icon color="secondary" large class="mr-2">mdi-calendar-month-outline</v-icon>
          <span class="secondary--text bold--text font-size--24 align-self-center">{{ $t('vma.rdv_1') }}</span>
        </div>
      </v-col>
      <v-col cols="12">
        <PatientRDVCardComponent title="" icon="" name_details="" app_name="rps,prevanavaccination,vma" :filter_out_passed_rdv="false" :scroll_multiple_elements="false"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PatientRDVCardComponent from "@/components/patient/components/rdv/PatientRDVCardComponent";
export default {
  name: "PatientRDVView",
  components: {PatientRDVCardComponent}
}
</script>

<style scoped>

</style>
