<template>
  <v-dialog v-model="_dialog" width="800" persistent>
    <v-card>
      <DialogCardTitleTemplate :title="$t('vma.prendre_rdv')" v-on:click="_dialog=false"/>
      <v-card-text class="pt-6">
        <p class="basic--text font-size--16 medium--text mb-0">{{$t('vma.dialog_rdv_1')}}</p>
        <v-select v-model="consultation_reason" :items="items_consultations" filled :placeholder="$t('vma.dialog_rdv_2')"></v-select>

        <v-alert v-if="alert" type="info" text transition="scale-transition">
          {{ alert_txt }}
        </v-alert>

        <v-expand-transition>
          <div v-if="alert_absent">
            <v-alert type="warning" text transition="scale-transition">
              <span class="orange--text semibold--text font-size--16">{{$t('vma.dialog_rdv_3')}}</span>
              <br>
              <span class="black--text normal--text">
                {{$t('vma.dialog_rdv_4')}}
                <span class="semibold--text black--text">{{date_absent_rdv}}</span>
              </span>
              <br>
              <span class="black--text normal--text">
                {{$t('vma.dialog_rdv_5')}}
                <span class="semibold--text black--text">{{date_rdv_available}}</span>
              </span>
            </v-alert>
          </div>
        </v-expand-transition>

        <v-expand-transition>
          <div v-if="alert_document">
            <v-alert type="warning" text transition="scale-transition">
              <p class="normal--text">
                <span class="semibold--text font-size--16">{{$t('vma.alert_document_1')}}</span>
                {{$t('vma.alert_document_2')}}
              </p>
              <p class="normal--text">
                <span class="semibold--text font-size--16">{{$t('vma.alert_document_3')}}</span>
                {{$t('vma.alert_document_4')}}
              </p>
              <div class="text-center">
                <!-- la props default_selected à -1 permet de selectionner par defaut le médecin + infirmier pour le partage de document pour un rdv de type amenagement -->
                <DocumentsNewButton v-on:new_document="new_document($event)" patient_id="0" :choose_pds="import_attachment_choose_pds" :default_selected="-1" class="mt-5" app_name="vma" />
              </div>
            </v-alert>
          </div>
        </v-expand-transition>

      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="secondary" elevation="0" class="px-6" :disabled="loading || !consultation_reason || alert || alert_document" @click="go_to_questionnaire()">
          <span class="medium--text">{{$t('vma.prendre_rdv')}}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogCardTitleTemplate from "../../../components/DialogCardTitleTemplate";
import DocumentsNewButton from "../../../components/documents/DocumentsNewButton";

import moment from "moment-timezone";
import {get_formatted_date} from "anam-library/src/js/dates";
import {import_attachment_choose_pds} from "prevana-library/src/js/dict_permissions";

export default {
  name: "VmaDialogRdv",
  components: {DocumentsNewButton, DialogCardTitleTemplate},
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    consultations: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    loading: false,
    consultation_reason: null,
    questionnaire: null,
    alert_absent: false,
    alert: false,
    alert_txt: null,
    alert_document: false
  }),
  computed: {
    import_attachment_choose_pds() {
      return import_attachment_choose_pds(this.$store.getters.getAgencyName);
    },
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit("update:dialog", val);
      }
    },
    latest_absent_rdv() {
      let absent_rdv = this.consultations.filter(x => !x.cancelled && x.closed && x.status === 2);
      if (!absent_rdv.length) return null;
      absent_rdv.sort((a,b) => moment(b.slot.start_slot,  "DD/MM/YYYY HH:mm") - moment(a.slot.start_slot, "DD/MM/YYYY HH:mm"));
      return absent_rdv[0];
    },
    has_been_absent_to_rdv() {
      return this.latest_absent_rdv !== null && moment() < moment(this.latest_absent_rdv.slot.start_slot).add(1, "month");
    },
    date_absent_rdv() {
      if (!this.latest_absent_rdv) return null;
      return get_formatted_date(this.latest_absent_rdv.slot.start_slot, {output_format: "DD/MM/YYYY à HH:mm"});
    },
    date_rdv_available() {
      if (!this.latest_absent_rdv) return null;
      return moment(this.latest_absent_rdv.slot.start_slot).add(1, "month").format("DD/MM/YYYY");
    },
    items_consultations() {
      let consult_types = [];
      let entities = this.$store.getters.getPatientData.patient.entities_dict;
      for (let motif of this.$store.getters.getMotifsConsultation) {
        if (motif.hidden_for_groupements && motif.hidden_for_groupements.includes(entities.level_2)) continue;
        if (motif.hidden_for_healthfacilities && motif.hidden_for_healthfacilities.includes(entities.level_3)) continue;
        if (motif.hidden_for_services && motif.hidden_for_services.includes(entities.level_4)) continue;
        consult_types.push({value: motif.id, text: motif[`commercial_name_${this.$i18n.locale}`]});
      }
      return consult_types;
    }
  },
  watch: {
    consultation_reason: function(val) {
      this.alert_document = false;
      this.alert = false;
      this.alert_txt = null;
      let motifs = this.$store.getters.getMotifsConsultation;
      let index = motifs.findIndex(x => x.id === val);
      if (index === -1) return;
      let motif = motifs[index];
      if (motif.max_consultations_per_year) {
        if (this.consultations.filter(x => !x.cancelled && x.is_current_school_year && x.consultation_reason === val).length >= motif.max_consultations_per_year) {
          this.alert = true;
          this.alert_txt = this.$t('vma.dialog_rdv_7');
        }
      }
      if (motif.document_needed) {
        this.alert_document = true;
      }
    }
  },
  mounted() {
    this.loading = true;
    this.$http.get(`/api/patients/0/questionnaires_vma`).then(response => {
      this.questionnaire = response.data.filter(x => x.questionnaire_is_completed && x.is_current_school_year)[0];
    }).finally(() => {
      this.loading = false;
    })
  },
  methods: {
    go_to_questionnaire: function() {
      if (this.has_been_absent_to_rdv) this.alert_absent = true;
      else if (this.questionnaire) {
        this.$router.push({name: "PatientVMAAppointement", query: {consultation_reason: this.consultation_reason}});
      }
      else {
        this.$router.push({
          name: "QuestionnaireView",
          query: {
            questionnaire_type: "vma-escp",
            app_name: "vma",
            patient_id: "0",
            nb_question: 5,
            redirection_end_anam: `/patient/vma/appointment?consultation_reason=${this.consultation_reason}`
          }
        });
      }
    },
    new_document(document) {
      this.alert_document = false;
      this.$emit("new_document", document);
    }
  }
}
</script>

<style scoped>

</style>
