<template>
  <div class="px-5 mt-4">
    <v-data-table :loading="loading"
                  class="mt-4"
                  :items="clusters_items"
                  :headers="clusters_headers"
                  :items-per-page="300"
                  :disable-pagination="true"
                  no-data-text="Pas de clusters détecté dans votre domaine de visibilité"
                  hide-default-footer>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "CovidClustersTable",
  data: () => ({
    loading: false,
    clusters_items: [],
    clusters_headers: [
      {text: "Affectation", value: "name", class: "white--text"},
      {text: "Nombre de cas C19+", value: "cases", align: "center", class: "white--text"},
    ],
  }),
  created() {
    this.loading = true;
    this.$http.get("/api/get_clusters").then((response) => {
      this.clusters_items = response.data;
    }).finally(() => {
      this.loading = false;
    });
  }
}
</script>

<style scoped>

</style>
