<template>
  <PrCardComponent :title="$t('vma.bilan_1')" icon="mdi-clipboard-text">
    <template v-slot:card-title-append>
      <v-spacer></v-spacer>
      <ButtonDetails :txt="$t('vma.details_resultats')" redirection="PatientVmaResults"/>
    </template>
    <template v-slot:card-text>
      <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
      <div v-else :class="`${$vuetify.breakpoint.xs ? '' : 'd-flex'} mt-5`">
        <span class="normal--text basic--text align-self-center pr-2">
          {{$t('vma.bilan_2')}}
        </span>
        <v-spacer></v-spacer>
        <v-btn color="secondary" elevation="0" class="align-self-center mt-3 mt-sm-0" @click="dialog_take_rdv=true">
          <span class="medium--text font-size--12">{{$t('vma.prendre_rdv')}}</span>
        </v-btn>
      </div>
      <VmaDialogRdv :dialog.sync="dialog_take_rdv" :consultations="consultations" v-on:new_document="$emit('new_document', $event)" />
    </template>
  </PrCardComponent>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import ButtonDetails from "@/components/patient/components/ButtonDetails";
import VmaDialogRdv from "@/components/patient/vma/dashboard/bilan/VmaDialogRdv";


export default {
  name: "VmaPatientBilanCard",
  components: {VmaDialogRdv, ButtonDetails, PrCardComponent},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    consultations: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    dialog_take_rdv: false
  }),
}
</script>

<style scoped>

</style>
