<template>
  <v-btn color="secondary" elevation="0" @click="start_questionnaire_covidapp()">
    <span class="medium--text font-size--12">Répondre au questionnaire</span>
  </v-btn>
</template>

<script>
import axios from "axios";

export default {
  name: "CovidappButtonStartQuestionnaire",
  computed: {
    _agency_name: function() {
      return this.$store.getters.getAgencyName;
    }
  },
  methods: {
    start_questionnaire_covidapp: function() {
      axios.get(`/api/questionnaire/${this._agency_name}/covidapp`).then(response => {
        if (response.data.redirection) {
          this.$router.push({
            name: "PatientCovidEvolution",
            params: {
              "agency_name": response.data.agency_name,
              "pds_uid": response.data.pds_uid
            }
          })
        }
        else if (response.data.questionnaire_uid) {
          this.$router.push({
            name: "QuestionnaireView",
            query: {questionnaire_type: "Dpistagepatientcovid19v2", app_name: "covidapp", redirection: "continue"},
            params: {questionnaire_uid: response.data.questionnaire_uid}
          });
        }
        else {
          this.$router.push({
            name: "QuestionnaireView",
            query: {questionnaire_type: "Dpistagepatientcovid19v2", app_name: "covidapp"}
          });
        }
      }).catch(() => {
        this.$store.commit('PostCommonError');
      })
    },
  }
}
</script>

<style scoped>

</style>
