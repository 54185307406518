<template>
  <v-row class="mx-0">
    <v-col cols="12">
      <CovidClustersTable/>
    </v-col>
  </v-row>
</template>

<script>
import CovidClustersTable from "@/components/common/covidhelp/clusters/CovidClustersTable";

export default {
  name: "HFManagerCovidClustersView",
  components: {CovidClustersTable}
}
</script>

<style scoped>

</style>
