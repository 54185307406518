<template>
  <VaccinationStatisticsStatus/>
</template>

<script>
import VaccinationStatisticsStatus from "@/components/doctor/vaccination/statistics/components/VaccinationStatisticsStatus";
export default {
  name: "HFManagerVaccinationStatisticsView",
  components: {VaccinationStatisticsStatus}
}
</script>

<style scoped>

</style>
