<template>
  <PrCardComponent title=" Évolution du score de probabilité Covid+" icon="mdi-chart-line">
    <template v-slot:card-text>
      <v-progress-linear v-if="loading_stats" indeterminate color="secondary"></v-progress-linear>
      <VueApexCharts v-if="!loading_stats" height="350" :options="chartOptionsCovid" :series="series"></VueApexCharts>
    </template>
  </PrCardComponent>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import moment from "moment-timezone";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    PrCardComponent,
    VueApexCharts
  },
  props: {
    pds_uid: {
      type: String,
    },
  },
  data: () => ({
    loading_stats: false,
    dataScoreCovid: {},

    series: [{
        name: "Score Covid+",
        data: [],
    }],
    chartOptionsCovid: {
      colors: ["#3f51b5"],
      chart: {
        type: "scatter",
        zoom: { enabled: false },
        toolbar: { tools: { download: false } },
      },
      dataLabels: { enabled: false },
      grid: {
        row: { colors: ["#f3f3f3", "transparent"], opacity: 0.5 },
      },
      xaxis: { categories: [] },
      yaxis: { min: 0, max: 200 },
    },
  }),
  mounted: function () {
    this.loading_stats = true;
    this.$http.get("/api/all_scores/" + this.pds_uid).then((response) => {
      let datas_covid = response.data;
      for (let el in datas_covid) {
        let date = moment(datas_covid[el]["date"]).locale("fr").format("D MMMM YYYY");
        this.dataScoreCovid[date] = datas_covid[el]["value"];
      }
      this.chartOptionsCovid.xaxis.categories = Object.keys(this.dataScoreCovid);
      this.series[0].data = Object.values(this.dataScoreCovid);
    }).finally(() => {
      this.loading_stats = false;
    });
  },
};
</script>

<style>
</style>
