<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>

    <PatientAppointementComponent :title_recap_place="$t('vma.rdv_11')"
                                  :title_recap_date="$t('vma.rdv_12')"
                                  type_rdv="4"
                                  :show_rdv_type="true"
                                  :redirection="get_redirection()"
                                  app_name="vma">
    </PatientAppointementComponent>
  </div>
</template>

<script>
import PatientAppointementComponent from "@/components/patient/appointment/PatientAppointementComponent";
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";

export default {
  name: "VMAAppointment",
  components: {PrBreadcrumbsComponent, PatientAppointementComponent},
  data() {
    return {
      navigations: [
        {text: this.$t('vma.visite_medicale'), to: '/patient/vma/dashboard'},
        {text: this.$t('vma.prendre_rdv'), to: '/patient/vma/appointment'}
      ]
    }
  },
  methods: {
    get_redirection() {
      if (this.$route.params.id) return {name: 'VMADoctorDpa', params: {id: this.$route.params.id}};
      return {name: 'PatientVmaDashboard'};
    }
  }
}
</script>

