var render = function render(){var _vm=this,_c=_vm._self._c;return _c('apex-chart',{attrs:{"type":"bar","height":_vm.height,"series":[{name: '', data: Object.values(_vm.data)}],"options":{
              colors: _vm.colors,
              chart: {
                toolbar: {
                  show: false,
                },
              },
              xaxis: {
                categories: Object.keys(_vm.data),
              },
              plotOptions: {
                bar: {
                  columnWidth: _vm.column_width,
                  horizontal: _vm.horizontal,
                },
              },
            }}})
}
var staticRenderFns = []

export { render, staticRenderFns }