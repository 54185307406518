<template>
  <div>
    <span v-if="value === -1 || !value" class="medium--text">-</span>
    <span v-else :class="color_score + ' medium--text'">{{ value }}</span>
  </div>
</template>

<script>
export default {
  name: "ScoreAggravationItem",
  props: ["item", "variable"],
  computed: {
    value() {
      return this.item[this.variable];
    },
    color_score() {
      if (this.value >= 20) {
        return "red--text";
      } else if (this.value >= 10) {
        return "orange--text";
      } else {
        return "green--text";
      }
    },
  },

  methods: {
    get_value_to_display(data) {
      if (data === -1 || !data) return "-";
      return data;
    }
  }
};
</script>
