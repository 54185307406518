<template>
  <v-container>
    <v-row>
      <v-col cols="4" class="text-center hidden-xs-only">
        <img src="@/assets/vaccination/clipboard.png" height="250px">
      </v-col>
      <v-col cols="12" sm="8" class="soft_blue--text">
        <p class="bold--text font-size--30">La vaccination est-elle sans risque ?</p>
        <p class="medium--text">
          Le vaccin est un produit pharmaceutique soumis à un contrôle strict avant sa mise sur le marché. Dans le
          cadre des vaccins contre la Covid19 utilisés en France, ils ont été préalablement autorisés par l’Union
          Européenne puis en France par l’ANSM (L’Agence Nationale de Sécurité du Médicament). La HAS (Haute Autorité
          de Santé) définit quant à elle la stratégie de vaccination. Néanmoins, comme la majorité des produits
          pharmaceutiques, des effets indésirables ou secondaires ont été recensés durant la phase de tests cliniques.
          Ils ont été étudiés par les autorités compétentes avant d’autoriser la mise sur le marché du vaccin en
          Europe puis en France.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "BlocRisque"
  }
</script>

<style scoped>

</style>
