<template>
  <v-container fluid class="bg_soft pb-0">
    <v-row>
      <v-col cols="12" md="6" class="soft_blue--text pb-0 pl-10">
        <p class="bold--text font-size--30">Pour plus d'informations</p>
        <ul>
          <li v-for="(item, key) in links" :key="key">
            <a class="soft_blue--text text-decoration-underline medium--text" :href="item.link" target="_blank">
              {{ item.txt }}
            </a>
          </li>
        </ul>
      </v-col>
      <v-col cols="12" md="6" class="pb-0 text-center">
        <img src="@/assets/vaccination/picto_woman.png" class="picto_woman">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "BlocLinks",
    data: () => ({
      links: [
        {
          'txt': 'Calendrier français de la vaccination contre la covid19',
          'link': 'https://www.service-public.fr/particuliers/actualites/A14557'
        },
        {
          'txt': 'Site du ministère des solidarités et de la santé (Vaccin AstraZeneca)',
          'link': 'https://solidarites-sante.gouv.fr/actualites/presse/communiques-de-presse/article/vaccin-anti-covid-premiere-livraison-de-doses-du-vaccin-astrazeneca-ce-samedi-6'
        },
        {
          'txt': 'Liste des vaccins autorisés',
          'link': 'https://www.ansm.sante.fr/Dossiers/COVID-19-Vaccins/Vaccins-autorises/(offset)/3'
        },
        {
          'txt': 'Site du suivi des effets secondaires par l’ANSM',
          'link': 'https://pro.anamnese.care/info-vaccination'
        },
        {
          'txt': 'Site du ministère des solidarités et de la santé (Vaccins)',
          'link': 'https://www.gouvernement.fr/info-coronavirus/vaccins'
        },
        {
          'txt': 'Site l’Organisation mondiale de la santé (Vaccins)',
          'link': 'https://www.who.int/fr/news-room/q-a-detail/vaccines-and-immunization-what-is-vaccination?adgroupsurvey={adgroupsurvey}&gclid=CjwKCAiA_9r_BRBZEiwAHZ_v12oQCZt5gEO0kJ1iN9CQLLHALmbwQOKyRpueKADyLGlwOEH5UiJmbhoCp6wQAvD_BwE'
        }
      ]
    })
  }
</script>

<style scoped>
  img.picto_woman{
    height: 200px;
  }
  @media (max-width: 768px) {
    img.picto_woman {
      height: 150px;
    }
  }

  @media (max-width: 479px) {
    img.picto_woman {
      width: 100%;
      height: auto;
    }
  }
</style>
