import { render, staticRenderFns } from "./PatientRDVView.vue?vue&type=template&id=15bd20e8&scoped=true&"
import script from "./PatientRDVView.vue?vue&type=script&lang=js&"
export * from "./PatientRDVView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15bd20e8",
  null
  
)

export default component.exports