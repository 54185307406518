<template>
  <PrCardComponent :title="$t('vma.documents_1')" icon="mdi-folder">
    <template v-slot:card-title-append>
      <v-spacer></v-spacer>
      <ButtonDetails v-if="see_details" :txt="$t('vma.documents_2')" redirection="PatientDocuments"/>
    </template>
    <template v-slot:card-text>
      <div :class="`${$vuetify.breakpoint.xs ? '' : 'd-flex'} mt-5`">
        <span class="normal--text basic--text align-self-center pr-4">
          {{$t('vma.documents_3')}}
        </span>
        <v-spacer></v-spacer>
        <DocumentsNewButton :choose_pds="import_attachment_choose_pds" patient_id="0" v-on:new_document="new_document($event)" class="mt-3 mt-sm-0" :app_name="app_name" />
      </div>

      <v-divider class="my-5 mx-4"></v-divider>

      <AnAttachmentBloc :patient_id="$store.getters.getPatientId" :files.sync="documents" cols_file="12" cols_file_sm="4" cols_file_md="4" :max_display="max_display" :show_author="true" :get_shared_with="true" :app_name="app_name" delete_document_option />
    </template>
  </PrCardComponent>
</template>

<script>
import AnAttachmentBloc from "anam-library/src/components/an-attachment/AnAttachmentBloc";
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import ButtonDetails from "@/components/patient/components/ButtonDetails";
import DocumentsNewButton from "@/components/patient/components/documents/DocumentsNewButton";
import {import_attachment_choose_pds} from "prevana-library/src/js/dict_permissions";

export default {
  name: "PatientDocumentsCardComponent",
  components: {DocumentsNewButton, ButtonDetails, PrCardComponent, AnAttachmentBloc},
  props: {
    see_details: {
      type: Boolean,
      default: false
    },
    max_display: {
      type: Number,
      default: null
    },
    app_name: {
      type: String,
      default: null
    }
  },
  data: () => ({
    documents: []
  }),
  computed: {
    import_attachment_choose_pds() {
      return import_attachment_choose_pds(this.$store.getters.getAgencyName);
    }
  },
  methods: {
    // Attention, appelé également par PatientVMADashboardView
    new_document(document) {
      this.documents.unshift(document);
    }
  }
}
</script>

<style scoped>

</style>
