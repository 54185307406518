<template>
  <PrCardComponent title="Mon bilan Santé & Bien-Être" icon="mdi-clipboard-text">
    <template v-slot:card-title-append>
      <v-spacer></v-spacer>
      <ButtonDetails txt="Détails de mes résultats" redirection="PatientRpsResults"/>
    </template>
    <template v-slot:card-text>
      <v-progress-linear v-if="loading_questionnaires" indeterminate color="primary" class="mb-10"></v-progress-linear>
      <div :class="`${$vuetify.breakpoint.xs ? '' : 'd-flex'} mt-5`" v-else>
        <span class="normal--text basic--text align-self-center pr-2">{{ action_txt }}</span>
        <v-spacer></v-spacer>
        <v-btn color="secondary" elevation="0" class="align-self-center mt-3 mt-sm-0" :loading="loading_launch_quest" @click="launch_questionnaire()" :disabled="!has_active_study">
          <span class="medium--text font-size--12">{{ action_btn_txt }}</span>
        </v-btn>
      </div>

      <NoDataTxt v-if="!has_graph_data" txt="Nous ne pouvons pas afficher vos résultats tant que vous n'avez pas répondu à au moins 1 questionnaire" />
      <PrRadarGraph ref_graph="radar_graph"
                    class="mt-5"
                    :patient_id="$store.getters.getPatientId"
                    :use_short_text="true"
                    :padding="padding_graph"
                    :fontSize="15"
                    :font-size-yaxis="6"
                    :questionnaire_name="_questionnaire_name"
                    :bold_mean_line="has_graph_data"
                    :show_only_patient="true"
                    :no_data_text="['Nous ne pouvons pas afficher vos résultats tant', 'que vous n\'avez pas répondu à au moins 1 questionnaire']"
                    v-on:has_data="has_graph_data=true"/>

      <div class="text-right" v-if="has_graph_data">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" color="primary" @click="dialog_expand_graph=true">
              <v-icon>mdi-fullscreen</v-icon>
            </v-btn>
          </template>
          <span>Voir mes résultats en plein écran</span>
        </v-tooltip>
      </div>

      <v-dialog v-model="dialog_expand_graph" fullscreen>
        <v-card style="position: relative">
          <v-card-text>
            <PrRadarGraph ref_graph="radar_graph_dialog"
                          :patient_id="$store.getters.getPatientId"
                          :use_short_text="$vuetify.breakpoint.xs"
                          :padding="padding_graph"
                          :fontSize="15"
                          :font-size-yaxis="6"
                          :questionnaire_name="_questionnaire_name"
                          :bold_mean_line="true"
                          :show_only_patient="true"
                          height="750"
                          v-on:has_data="has_graph_data=true"/>
          </v-card-text>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="secondary" absolute top right fab class="mt-15 mr-5" @click="dialog_expand_graph=false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Quitter le mode plein écran</span>
          </v-tooltip>
        </v-card>
      </v-dialog>

      <div class="mt-6">
        <v-expansion-panels v-model="panel" hover flat multiple>
          <v-expansion-panel class="bg_light my-3" v-if="show_interpretation_in_bilan">
            <v-expansion-panel-header>
              <span class="primary--text semibold--text">Interprétation des résultats</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p class="secondary--text font-weight-bold mb-0">Votre résultat est inférieur à 25</p>
              <p>Votre résultat reflète actuellement une bonne santé mentale sur cette dimension. N’hésitez pas néanmoins à consulter les ressources disponibles sur votre portail.</p>

              <p class="secondary--text font-weight-bold mb-0">Votre résultat est compris entre 26 et 50</p>
              <p>Votre état mental semble globalement stable sur cette dimension.</p>

              <p class="secondary--text font-weight-bold mb-0">Votre résultat est compris entre 51 et 75</p>
              <p>Cette tranche indique des signaux légers à plus importants de mal-être sur cette dimension.</p>

              <p class="secondary--text font-weight-bold mb-0">Votre résultat est supérieur à 75</p>
              <p>Vous semblez présenter des signes de mal-être plus ou moins importants.</p>

              <p v-html="txt_interpretation"></p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="bg_light my-3" v-for="(item, i) in dimensions" :key="i">
            <v-expansion-panel-header>
              <span class="secondary--text semibold--text">{{$t(item.text)}}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <span class="medium--text">{{$t(item.legend)}}</span>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </template>
  </PrCardComponent>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import PrRadarGraph from "prevana-library/src/components/common/PrRadarGraph";

import {LIST_SCORES_RPS} from "anam-library/src/data/scores_rps";
import ButtonDetails from "@/components/patient/components/ButtonDetails";
import NoDataTxt from "../../../components/NoDataTxt";
import {can_manage_study, show_interpretation_in_bilan} from "prevana-library/src/js/dict_permissions";
import moment from "moment-timezone";
import {get_faq_interpretation_text} from "anam-library/src/data/dict_terminology_by_agency";

export default {
  name: "RpsDashboardCardBilan",
  components: {
    NoDataTxt,
    ButtonDetails,
    PrCardComponent,
    PrRadarGraph,
  },
  data: () => ({
    panel: [],
    loading_questionnaires: false,
    questionnaires: [],
    loading_launch_quest: false,
    dialog_expand_graph: false,
    has_graph_data: false,
    padding_graph: {
      top: -10,
      bottom: -25,
      left: 0,
    },
    studies: [],
    dimensions: []
  }),
  computed: {
    _questionnaire_name: function() {
      return this.$store.getters.getQuestionnaireName;
    },
    latest_questionnaire: function() {
      if (this.questionnaires.length > 0) return this.questionnaires[0];
      else return null;
    },
    latest_questionnaire_is_completed: function() {
      if(this.latest_questionnaire) return this.latest_questionnaire.questionnaire_is_completed;
      return true;
    },
    action_txt: function() {
      if(this.loading_questionnaires) return '';
      else if (!this.latest_questionnaire) return "Répondez au questionnaire afin d'obtenir vos résultats bien-être.";
      else if (!this.latest_questionnaire_is_completed) return "Vous n'avez pas terminé de répondre au questionnaire.";
      else return 'Répondez au questionnaire afin de mettre à jour vos résultats.';
    },
    action_btn_txt: function() {
      if(!this.latest_questionnaire_is_completed) return 'Continuer mon questionnaire';
      else return 'Répondre au questionnaire';
    },
    has_active_study() {
      if (can_manage_study(this.$store.getters.getAgencyName)) {
        return this.studies.filter(x => moment(x.start_date, "DD/MM/YYYY").isSameOrBefore(moment()) && (!x.end_date || moment(x.end_date, "DD/MM/YYYY").isSameOrAfter(moment()))).length;
      }
      return true;
    },
    txt_interpretation() {
      return get_faq_interpretation_text(this.$store.getters.getAgencyName);
    },
    show_interpretation_in_bilan() {
      return show_interpretation_in_bilan(this.$store.getters.getAgencyName);
    }
  },
  mounted() {
    this.loading_questionnaires = true;
    for (let el of LIST_SCORES_RPS.filter(x => x.questionnaire === this.$store.getters.getQuestionnaireName)) {
      this.dimensions.push({text: el.text, legend: el.legend});
    }
    if (this.show_interpretation_in_bilan) this.panel.push(0);
    for (let i in this.dimensions) {
      let ind = this.show_interpretation_in_bilan ? parseInt(i) + 1 : parseInt(i);
      this.panel.push(ind);
    }
    this.$http.get(`/api/patients/${this.$store.getters.getPatientId}/questionnaires_rps`, {params: {filter_questionnaire_completed: false}}).then(response => {
      this.questionnaires = response.data;
    }).finally(() => {
      this.loading_questionnaires = false;
    })
    if (can_manage_study(this.$store.getters.getAgencyName)) {
      this.$http.get("/api/rps_studies").then(response => {
        this.studies = response.data;
      })
    }
  },
  methods: {
    launch_questionnaire: function() {
      this.loading_launch_quest = true;
      if(!this.latest_questionnaire || this.latest_questionnaire_is_completed) {
        this.$router.push({
          name: 'PatientRPSStartQuestionnaire'
        });
      } else {
        this.$router.push({
          name: "PatientRPSQuestionnaire",
          params: {questionnaire_uid: this.latest_questionnaire.uid, patient_id: 0},
          query: {redirection: "continue"}
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
