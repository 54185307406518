<template>
  <div>
    <PrDrawerComponent :drawer_buttons="drawer_buttons" :drawer_value.sync="drawer_value" />
    <AnHeader :drawer="true"
              :elevation="1"
              color_app_bar="primary"
              :logo="logo"
              :additional_logo="additional_logo"
              :col_logo_sm="additional_logo ? 3 : 2"
              :col_logo_md="additional_logo ? 3 : 2"
              :height_logo="50"
              :display_col_logo="!$vuetify.breakpoint.xs"
              v-on:menu_icon_click="drawer_value = true"
    >
      <template v-slot:actions>
        <span v-if="show_associated_profiles" class="font-size--14 semibold--text white--text">Vous êtes connecté en tant que {{info_profile}}</span>
        <AnLangSelect v-if="has_trad_choices" style="max-width: fit-content;" :all_langs="false" v-on:no_choice="has_trad_choices=false" />
        <AnHeaderMenuButton :navigation_buttons="navigation_buttons" :name="full_name" :optional_info="optional_info" color="white"/>
        <PrButtonDeconnexion/>
      </template>
    </AnHeader>
  </div>
</template>

<script>
import PrDrawerComponent from "prevana-library/src/components/drawer/PrDrawerComponent";
import AnHeader from "anam-library/src/components/an-header/AnHeader";
import AnHeaderMenuButton from "anam-library/src/components/an-header/AnHeaderMenuButton";
import PrButtonDeconnexion from "prevana-library/src/components/common/PrButtonDeconnexion";
import AnLangSelect from "anam-library/src/components/an-ui/select/AnLangSelect";

import axios from "axios";
import moment from "moment";
import {get_categorie_name} from "anam-library/src/data/dict_categories_pro";
import {show_associated_profiles, show_documents} from "prevana-library/src/js/dict_permissions";
import {get_additional_logo_header} from "../../../data/rps/text_by_agency";

export default {
  name: "PatientHeader",
  components: {
    AnHeader,
    AnHeaderMenuButton,
    PrButtonDeconnexion,
    PrDrawerComponent,
    AnLangSelect
  },
  data: () => ({
    logo: "https://www.anamnese.care/hubfs/Logiciels/PREVENTION/Prevana%20(g%C3%A9n%C3%A9ral)/Logos/logo-2022-prevana-blanc-sans-BL.png",
    drawer: false,
    drawer_value: false,
    has_trad_choices: true,
  }),
  computed: {
    full_name: function() {
      let data = this.$store.getters.getPatientData;
      if (this.$vuetify.breakpoint.xs) return data.last_name.toUpperCase();
      return `${data["first_name"]} ${data["last_name"].toUpperCase()}`;
    },
    optional_info() {
      if (this.$vuetify.breakpoint.xs) return this.$store.getters.getPatientData.first_name;
      return null;
    },
    _has_access_to_rps: function () {
      return this.$store.getters.getPatientHasAccessRps;
    },
    _has_access_to_vaccination: function () {
      return this.$store.getters.getPatientHasAccessVaccination;
    },
    _has_access_to_covidapp: function () {
      return this.$store.getters.getPatientHasAccessCovidapp;
    },
    _has_access_to_vma: function () {
      return this.$store.getters.getPatientHasAccessVma;
    },
    number_access_apps: function() {
      let nb = 0;
      if(this._has_access_to_rps) nb += 1;
      if(this._has_access_to_vaccination) nb += 1;
      if (this._has_access_to_covidapp) nb += 1;
      if(this._has_access_to_vma) nb += 1;
      return nb;
    },
    navigation_buttons: function() {
      let tmp = [
        {text: this.$i18n.t("vma.settings_title"), url: "/patient/settings", primary_action: false}
      ];
      if(this.email_support_level1) {
        let btn_contact = {text: this.$i18n.t("vma.contact_text"), href: `mailto:${this.email_support_level1}`, primary_action: false};
        tmp.push(btn_contact);
      }

      let btn_help = {text: this.$i18n.t("vma.help_text"), href: "https://aide.anamnese.care", primary_action: false};
      tmp.push(btn_help);
      return tmp;
    },
    drawer_buttons: function() {
      let tmp = [];

      if(this.number_access_apps > 1) tmp.push({text: "Accueil", icon: "mdi-home-outline", to: "/patient/dashboard", primary_action: true});
      if(this._has_access_to_rps) tmp.push({text: "Bien-être", icon: "$vuetify.icons.rpsicon", active_icon: "$vuetify.icons.rpsiconsecondary", to: "/patient/rps", primary_action: true});
      if(this._has_access_to_covidapp) tmp.push({text: "Suivi Covid", icon: "$vuetify.icons.covidicon", active_icon: "$vuetify.icons.covidiconsecondary", to: "/patient/covidhelp", primary_action: true});
      if(this._has_access_to_vaccination) tmp.push({text: "Vaccination", icon: "$vuetify.icons.vaccinationicon", active_icon: "$vuetify.icons.vaccinationiconsecondary", to: "/patient/vaccination", primary_action: true});
      if(this._has_access_to_vma) tmp.push({text: this.$t('vma.visite_medicale'), icon: "$vuetify.icons.vmaicon", active_icon: "$vuetify.icons.vmaiconsecondary", to: "/patient/vma", primary_action: true});

      let global_buttons = [];
      if (show_documents(this.$store.getters.getAgencyName)) {
        global_buttons.push({text: this.$t('vma.documents'), icon: "mdi-folder-outline", to: "/patient/files", primary_action: false});
      }
      if (this.$store.getters.getCurrentAgency.patient_access_rdv) {
        global_buttons.unshift({text: this.$t('vma.rendez_vous'), icon: "mdi-calendar-month-outline", to: "/patient/rdv", primary_action: false})
      }
      tmp = tmp.concat(global_buttons);
      return tmp;
    },
    email_support_level1: function() {
        let email = this.$store.getters.getCurrentAgency.support_link;
        if(email) return email;
        else return null
    },
    info_profile() {
      let data = this.$store.getters.getPatientData;
      if (data.patient.main_affectation.name) return `${get_categorie_name(data.patient.categorie)}, ${data.patient.main_affectation.name}`;
      else if (data.patient.main_affectation) {
        let affectation_name_list = data.patient.main_affectation.split(" (");
        if (affectation_name_list.length) return `${get_categorie_name(data.patient.categorie)}, ${affectation_name_list[0]}`;
      }
      return `${get_categorie_name(data.patient.categorie)}`;
    },
    show_associated_profiles() {
      return show_associated_profiles(this.$store.getters.getAgencyName);
    },
    additional_logo() {
      return get_additional_logo_header(this.$store.getters.getAgencyName);
    }
  },
  created() {
    if (!this.$vuetify.breakpoint.xs) this.drawer_value = true;
    axios.get("/api/patient_info").then((response) => {
      this.$store.state.first_name = response.data.first_name;
      this.$store.state.last_name = response.data.last_name;
      this.$store.state.sex = response.data.personnephysique.sexe;
      if (response.data.personnephysique.birthdate) {
        this.$store.state.birthdate = moment(response.data.personnephysique.birthdate.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD");
      }
      this.$store.state.email = response.data.email;
      this.$store.state.postcode = response.data.personnephysique.adresse.codePostal;
      this.$store.state.phone = response.data.personnephysique.personel_phone;
      this.$store.state.weight = response.data.personnephysique.poidsCorporel;
      this.$store.state.height = response.data.personnephysique.tailleCorporelle;
      this.$store.state.apps = response.data.apps;
    });
  }
};
</script>
