<template>
  <v-container fluid class="pa-sm-10">
    <v-row class="mx-0">
      <v-col cols="12" sm="10" offset-sm="1" md="6" offset-md="3" class="mb-10 text-center">
        <v-card rounded elevation="2" class="mt-sm-8">
          <v-card-title class="justify-center pt-sm-8">
            <p class="primary--text bold--text font-size--28 mb-0 text-break">Questionnaire terminé !</p>
          </v-card-title>
          <v-card-text class="text-center pt-5 pt-sm-10 px-4 px-md-14">
            <p class="primary--text normal--text font-size--20 mb-0 text-break">Merci de votre participation, à bientôt !</p>
            <v-img class="ma-5" src="https://www.anamnese.care/hubfs/config/Prevana/IRBA/CEPHISMER/QUESTIONNAIRE-FIN.gif" />
          </v-card-text>
          <v-card-actions class="justify-center pb-10">
            <v-btn @click="back_to_home()" class="px-sm-6" elevation="0" color="primary">
              <span class="semibold--text white--text">Retour à l'accueil</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PatientEtudeEndQuestionnaireView",
  methods: {
    back_to_home() {
      this.$router.push({name: "PatientDashboardEtude"});
    }
  }
}
</script>

<style scoped>

</style>
