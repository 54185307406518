<template>
  <v-container>
    <h1 class="primary--text text-center mt-3">Votre questionnaire est terminé !</h1>
    <p class="mt-12 text-center">
      <v-btn color="soft_blue" class="white--text" @click="back_to_dashboard()">Revenir au portail Prévana</v-btn>
    </p>
    <p class="mt-12 text-center primary--text semibold--text">
      Merci pour vos réponses ! Si vous le souhaitez, vous pouvez prendre rendez-vous en cliquant ici:
    </p>
    <p class="text-center">
      <v-btn color="soft_blue" class="white--text" @click="go_to_appointment()">Prendre rendez-vous</v-btn>
    </p>
    <p v-if="loading">
      <span class="mr-3">Chargement de vos réponses...</span>
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </p>
    <AnRestitutionQuestionsAnswers
      v-else
      :questions="questions"
      :panel="0"
      :dense="false"
    ></AnRestitutionQuestionsAnswers>
  </v-container>
</template>

<script>
import AnRestitutionQuestionsAnswers from "anam-library/src/components/an-questionnaire/AnRestitutionQuestionsAnswers";

export default {
    name: "EndQuestionnaireView",
    components: {AnRestitutionQuestionsAnswers},

    data: () => ({
      questions: [],
      loading: true
    }),

    created() {
      this.$http.get(`/api/patient/questionnaire-restitution/${this.$route.params.questionnaire_uid}`).then(response => {
        this.questions = response.data;
      }).finally(() => {
        this.loading = false;
      })
    },

    methods: {
      go_to_appointment() {
        this.$http.get("/api/rps_appointment_info/0").then(response => {
          if (response.data.has_appointment || !response.data.eligible_to_consultation) this.$router.push({name: 'PatientRPSAppointmentSummary'});
          else this.$router.push({name: 'PatientRPSAppointement'});
        })
      },

      back_to_dashboard() {
        this.$http.get("/api/prevana_related_data").then(response => {
          if (response.data.has_access_to_rps && !response.data.has_access_to_covidapp && !response.data.has_access_to_vaccination && !response.data.has_access_to_vma) {
            this.$router.push({name: "PatientRpsDashboard"});
          } else if (response.data.has_access_to_vma && !response.data.has_access_to_covidapp && !response.data.has_access_to_vaccination && !response.data.has_access_to_rps) {
            this.$router.push({name: "PatientVmaDashboard"});
          } else {
            this.$router.push({name: 'PatientDashboard'});
          }
        })
      }
    }
  }
</script>
