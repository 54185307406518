<template>
  <div>
    <v-container fluid class="bg_soft">
      <v-row>
        <v-col cols="2" class="text-center">
          <img src="@/assets/vaccination/picto_pds.png" height="100px"/>
        </v-col>
        <v-col cols="10" class="align-self-center">
          <p class="soft_blue--text bold--text font-size--25 mb-0">Vos réponses vous permettent de prendre rendez-vous</p>
          <p class="soft_blue--text normal--text font-size--16">Merci de renseigner vos informations administratives pour prendre rendez-vous.</p>
        </v-col>
      </v-row>
    </v-container>
    <SignupForm/>
  </div>
</template>

<script>
  import SignupForm from "./SignupForm";

  export default {
    name: "VaccinationSignupView",
    components: {
      SignupForm
    }
  }
</script>

<style scoped>

</style>
