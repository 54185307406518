<template>
  <v-dialog v-model="dialog_inscription" width="800">
      <v-card>
          <v-card-title class="primary semibold--text primary-title white--text pr-0">
              {{$t("prevana.dialog_inscription_1")}}
          </v-card-title>

          <v-form ref="form_inscription" v-model="valid_inscription" autocomplete="off">
              <v-card-text>
                  <v-row>
                      <v-col cols="12">
                        <p class="font-size--12"><span class="primary--text medium--text">{{$t("prevana.dialog_inscription_4")}} {{_patient_text}} {{$t("prevana.dialog_inscription_5")}}</span> {{$t("prevana.dialog_inscription_6")}} {{type_email}}) <a href="https://aide.anamnese.care/inscription-patient" target="_blank" rel="noopener" class="text-decoration-underline primary--text">{{$t("prevana.more_infos")}}</a></p>
                      </v-col>
                      <v-col cols="4">
                          <v-text-field v-model="name_inscription" name="name" :label="$t('common.Nom')" color="primary" :rules="[rules.required]" outlined></v-text-field>
                      </v-col>
                      <v-col cols="4">
                          <v-text-field v-model="matricule_inscription" name="matricule" :label="_label_identifiant" color="primary" :rules="[rules.required, rules.matricule]" outlined></v-text-field>
                      </v-col>
                      <v-col cols="4">
                          <v-text-field v-model="email_inscription" name="email" :label="$t('common.email')" color="primary" :rules="[rules.required, rules.email]" outlined></v-text-field>
                      </v-col>
                      <v-col cols="12">
                          <p class="text-center primary--text medium--text">{{$t("prevana.dialog_inscription_7")}}</p>
                      </v-col>
                      <v-col cols="12">
                          <v-alert v-if="send_mail_success" dense text type="success">{{$t("prevana.dialog_inscription_8")}}</v-alert>
                          <v-alert v-if="send_mail_error" dense text color="orange">
                            <v-icon class="mr-4" color="orange">mdi-alert</v-icon>
                            <span class="medium--text font-size--16">{{ send_mail_error_message }}. </span>
                            <span v-if="additional_message" class="medium--text font-size--16">{{additional_message}}</span>
                          </v-alert>
                          <v-alert v-if="send_mail_other_error" dense text type="error">{{$t("common.error_persist_text")}}</v-alert>
                      </v-col>
                  </v-row>
              </v-card-text>

              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn outlined color="primary" class="px-6" @click="reset_dialog_inscription">{{$t("common.closed")}}</v-btn>
                  <v-btn @click="validate_inscription()" color="secondary" class="white--text px-6" elevation="0" :disabled="!valid_inscription" :loading="loading_inscription">{{$t("prevana.dialog_inscription_1")}}</v-btn>
                  <v-spacer></v-spacer>
              </v-card-actions>
          </v-form>
          <v-col v-if="matricule_helper" cols="12" class="text-right">
              <a @click="dialog_matricule = true">{{$t("prevana.dialog_inscription_9")}}</a>
          </v-col>
      </v-card>

      <v-dialog v-model="dialog_matricule" width="800">
        <v-card>
            <v-card-title class="primary primary-title white--text pr-0">
                {{$t("prevana.dialog_inscription_10")}}
                <v-spacer></v-spacer>
                <v-btn icon dark @click="dialog_matricule = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <img src="@/assets/divers/matricule_helper_SDIS78.png" />
            </v-card-text>
        </v-card>
    </v-dialog>
  </v-dialog>
</template>


<script>
import api from 'anam-library/src/services/api'
import rules from 'anam-library/src/js/rules'
import {get_terminology, get_additional_error_message_inscription} from "anam-library/src/data/dict_terminology_by_agency";
import {show_matricule_helper} from "prevana-library/src/js/dict_permissions";

export default {
    name: "InscriptionDialog",
    props: {
      model: {
        type: Boolean,
        default: false
      },
      agency_type: {
        type: String,
        default: null
      }
    },
    data: () => ({
      rules: {
        ...rules,
        matricule: v => /^[0-9]\d*$/.test(v) || "Matricule / Identifiant invalide"
      },
      dialog_matricule: false,
      dialog_inscription: false,
      valid_inscription: false,
      loading_inscription: false,
      name_inscription: null,
      matricule_inscription: null,
      email_inscription: null,
      send_mail_success: false,
      send_mail_error: false,
      send_mail_error_message: null,
      send_mail_other_error: false,
    }),
    computed: {
      matricule_helper () {
        return show_matricule_helper(this.$store.getters.getAgencyName)
      },
      _patient_text() {
        return this.$t(`prevana.${get_terminology("patient", this.current_agency)}`);
      },
      _label_identifiant() {
        return this.$t(`prevana.${get_terminology("identifiant", this.current_agency)}`);
      },
      current_agency() {
        return this.$store.getters.getCurrentAgency;
      },
      type_email() {
        return this.$t(`prevana.${get_terminology("type_email", this.current_agency)}`);
      },
      additional_message() {
        return get_additional_error_message_inscription(this.$store.getters.getAgencyName);
      }
    },
    watch: {
      dialog_inscription(val) {
        if (!val) this.$emit('closed');
      },
      model(val) {
        this.dialog_inscription = val;
      },
    },
    methods: {
        validate_inscription() {
            if (this.$refs.form_inscription.validate()) {
                this.loading_inscription = true;
                let data = {
                    last_name: this.name_inscription,
                    matricule: this.matricule_inscription,
                    email: this.email_inscription,
                    language: this.$i18n.locale
                }
                api.post(`/api/${this.current_agency.name}/inscription`, data).then(response => {
                    this.send_mail_success = true;
                    this.send_mail_error = false;
                    this.send_mail_other_error = false;
                    this.$refs.form_inscription.reset();
                }).catch((error) => {
                    if (error.response.status === 422){
                        this.send_mail_error_message = error.response.data.__all__[0]
                        this.send_mail_error = true;
                        this.send_mail_other_error = false;
                    }
                    else{
                        this.send_mail_error = false;
                        this.send_mail_other_error = true;
                    }
                    this.send_mail_success = false;
                }).finally(() => {
                    this.loading_inscription = false;
                })
            }
        },

        reset_dialog_inscription() {
            this.$refs.form_inscription.reset();
            this.send_mail_error = false;
            this.send_mail_success = false;
            this.dialog_inscription = false;
            this.loading_inscription = false;
        },
    }
  };
</script>
