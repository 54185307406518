<template>
  <v-row class="mb-6">
    <v-col cols="6">
      <PrCardComponent title="État" icon="mdi-information-outline">
        <template v-slot:card-text>
          <div v-if="$store.state.dpa_data.follow_up > 1 && $store.state.dpa_data.follow_up_datetime">
            <p>Ce patient est <span class="secondary--text font-weight-bold">suivi</span> (mis à jour le {{get_follow_up_datetime()}})</p>
          </div>
          <div v-else-if="$store.state.dpa_data.follow_up === 1 && $store.state.dpa_data.follow_up_datetime">
            <p>Ce patient n'est pas suivi (mis à jour le {{get_follow_up_datetime()}})</p>
          </div>
          <div v-else>
            <p>Ce patient n'est pas suivi</p>
          </div>
        </template>
      </PrCardComponent>

      <PrCardComponent title="Historique - Questionnaires de suivi" icon="mdi-history" class="mt-6">
        <template v-slot:card-text>
          <v-progress-linear v-if="loading_questionnaires" indeterminate color="primary"></v-progress-linear>
          <p v-else-if="!questionnaires.length">Ce patient n'a répondu à aucun questionnaire de suivi</p>
          <div v-else>
            <div v-for="(questionnaire, q_key) in questionnaires" :key="q_key">
              <h4 @click="show_questionnaire(questionnaire.uid)" class="my-2" style="cursor: pointer;">
                <v-row>
                  <v-col cols="8">
                    <span>Questionnaire du {{get_date(questionnaire.date)}}</span>
                  </v-col>
                  <v-col cols="2">
                    <v-icon class="mx-5" :color="questionnaire.follow_up ? 'error' : 'success'">mdi-circle</v-icon>
                  </v-col>
                  <v-col cols="2">
                    <v-icon class="mx-5">mdi-chevron-down</v-icon>
                  </v-col>
                </v-row>
              </h4>
              <QuestionAnswers :questionnaire="questionnaire.q_a" :show_condition="questionnaires_showed.includes(questionnaire.uid)"></QuestionAnswers>
              <v-divider></v-divider>
            </div>
          </div>
        </template>
      </PrCardComponent>
    </v-col>

    <v-col cols="6">
      <PrCardComponent title="Actions" icon="mdi-gesture-tap">
        <template v-slot:card-text>
          <v-list-item v-if="$store.state.dpa_data.follow_up === 1" class="mt-5 primary--text font-weight-bold" @click="set_follow_up(3)">
            Suivre
          </v-list-item>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-list-item disabled class="mt-5">Suivre</v-list-item>
              </span>
            </template>
            <span>Ce patient est déjà suivi</span>
          </v-tooltip>
          <v-list-item v-if="$store.state.dpa_data.follow_up > 1" class="mt-5 primary--text font-weight-bold" @click="set_follow_up(1)">
            Ne plus suivre
          </v-list-item>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-list-item disabled class="mt-5">Ne plus suivre</v-list-item>
              </span>
            </template>
            <span>Ce patient n'est pas suivi</span>
          </v-tooltip>
        </template>
      </PrCardComponent>
    </v-col>

  </v-row>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import api from "anam-library/src/services/api";
import moment from "moment-timezone"
import QuestionAnswers from "../components/QuestionAnswers";

export default {
        name: "DpaFollowUp",
        components: {
            QuestionAnswers,
            PrCardComponent
        },

        data: () => ({
            questionnaires: [],
            questionnaires_showed: [],
            loading_questionnaires: false
        }),

        mounted() {
            this.get_questionnaires();
        },

        methods: {
            get_questionnaires() {
                this.loading_questionnaires = true;
                api.get("/api/patients/" + this.$route.params.id + "/questionnaire_follow_up_vaccination").then(response => {
                    this.questionnaires = response.data;
                    this.loading_questionnaires = false;
                })
            },

            set_follow_up(value) {
                api.patch("/api/patients/" + this.$route.params.id + "/status_vaccination", {follow_up: value}).then(response => {
                    this.$store.state.dpa_data.follow_up = value;
                    this.$store.state.dpa_data.follow_up_datetime = response.data.follow_up_datetime;
                    this.$store.commit("PostSuccess", "Modifications enregistrées");
                })
            },

            get_follow_up_datetime() {
              return moment(this.$store.state.dpa_data.follow_up_datetime).format("DD/MM/YYYY HH:mm");
            },

            get_date(date) {
                return moment.tz(date, "Europe/Paris").format("DD/MM/YYYY HH:mm");
            },

            show_questionnaire(uid) {
                if (this.questionnaires_showed.includes(uid)) this.questionnaires_showed = this.questionnaires_showed.filter(x => x !== uid);
                else this.questionnaires_showed.push(uid);
            }
        }
    }
</script>
