<template>
  <v-container fluid class="pa-sm-10">
    <v-row class="mx-0">
      <v-col cols="12" sm="10" offset-sm="1" md="6" offset-md="3" class="mb-10 text-center">
        <v-card rounded elevation="2" class="mt-sm-8">
          <v-card-title class="justify-center pt-sm-8">
            <p class="primary--text bold--text font-size--28 mb-0 text-break">Informations requises</p>
          </v-card-title>
          <v-card-text class="text-left pt-5 pt-sm-10 px-4 px-md-14">
            <PrSelectPatientProfile
              title="Vous certifiez être militaire"
              :value="certified_military"
              :items="items_certified_military"
              v-on:update="certified_military = $event"
            />
            <PrSelectPatientProfile
              title="Vous certifiez être plongeur militaire (en formation ou formé)"
              :value="certified_military_diver"
              :items="items_certified_military_diver"
              v-on:update="certified_military_diver = $event"
            />
            <PrSelectPatientProfile
              title="Vous certifiez être âgé(e) de plus de 18 ans ?"
              :value="certified_older_18"
              :items="items_certified_older_18"
              v-on:update="certified_older_18 = $event"
            />
            <PrSelectPatientProfile
              title="En quelle année êtes-vous né(e)?"
              :value="birth_year"
              :items="items_birth_year"
              v-on:update="birth_year = $event"
            />
            <PrSelectPatientProfile
              title="Quelle est votre service ?"
              :value="service"
              :items="items_service"
              v-on:update="service = $event"
            />
            <v-expand-transition>
              <v-text-field v-if="service === 6" v-model="service_details" label="Précisez le service :" filled :rules="[rules.required]" />
            </v-expand-transition>
            <PrSelectPatientProfile
              title="Si vous êtes stagiaire à l'Ecoplong, plongiez-vous avant votre stage ?"
              :value="diver_before_internship"
              :items="items_diver_before_internship"
              v-on:update="diver_before_internship = $event"
            />
            <PrSelectPatientProfile
              title="Au cours de votre activité de plongée au cours des derniers mois, avez-vous déjà eu (cochez si vous êtes concernés) :"
              :value="symptoms"
              :items="items_symptoms"
              multiple
              v-on:update="symptoms = $event"
            />
          </v-card-text>
          <v-card-actions class="justify-center py-6">
            <v-btn class="px-10" elevation="0" large color="primary" @click="save_data_profile()" :loading="loading" :disabled="button_disabled">
              <span class="medium--text font-size--16">Continuer</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PrSelectPatientProfile from "prevana-library/src/components/common/PrSelectPatientProfile";
import {get_patient_data_cephismer} from "../../../data/etude/utils";
import rules from "anam-library/src/js/rules";

export default {
  name: "PatientProfileView",
  components: {PrSelectPatientProfile},
  data: () => ({
    loading: false,
    certified_military: null,
    certified_military_diver: null,
    certified_older_18: null,
    birth_year: null,
    service: null,
    diver_before_internship: null,
    symptoms: [],
    service_details: null,
    rules: rules
  }),
  computed: {
    button_disabled() {
      return !this.certified_military || !this.certified_military_diver || !this.certified_older_18 || !this.birth_year || !this.service || (this.service === 6 && !this.service_details) || !this.diver_before_internship || !this.symptoms.length;
    },
    items_certified_military() {
      return get_patient_data_cephismer("items_boolean");
    },
    items_certified_military_diver() {
      return get_patient_data_cephismer("items_boolean");
    },
    items_certified_older_18() {
      return get_patient_data_cephismer("items_boolean");
    },
    items_birth_year() {
      return get_patient_data_cephismer("items_birth_year");
    },
    items_service() {
      return get_patient_data_cephismer("items_service");
    },
    items_diver_before_internship() {
      return get_patient_data_cephismer("items_diver_before_internship");
    },
    items_symptoms() {
      return get_patient_data_cephismer("items_symptoms");
    }
  },
  methods: {
    save_data_profile() {
      this.loading = true;
      let data = {
        certified_military: this.certified_military === 1,
        certified_military_diver: this.certified_military_diver === 1,
        certified_older_18: this.certified_older_18 === 1,
        birth_year: this.birth_year,
        service: this.service,
        diver_before_internship: this.diver_before_internship,
        symptoms: this.symptoms
      }
      if (this.service === 6) data["service_details"] = this.service_details;
      this.$http.post(`/api/patients/0/patient-data-etude`, data).then(() => {
        this.$router.push(this.$route.query.redirection);
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
