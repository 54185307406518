<template>
  <v-row>
    <v-col cols="12" class="text-center my-5">
      <span class="secondary--text bold--text font-size--24 align-self-center ma-5">Historique des questionnaires envoyés</span>
    </v-col>
    <v-col cols="12" sm="10" offset-sm="1">
      <AnDataTable
        :headers="headers"
        :items="items"
        :loading="loading"
        :items_per_page="-1"
        :hide_footer="true"
        :table_items_mapping="mapping"
      />
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment-timezone";
import {get_questionnaire_names} from "../../../data/ecos/utils";
import DateItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/DateItem";
import AnDataTable from "anam-library/src/components/an-data-table/AnDataTable";

export default {
  name: "ECOSDoctorHistoryQuestionnairesView",
  components: {AnDataTable},
  data: () => ({
    loading: false,
    items: [],
    headers: [
      {text: "Date", value: "date"},
      {text: "Questionnaire", value: "name"},
      {text: "Nombre d'utilisateurs concernés", value: "number"}
    ],
    mapping: {date: DateItem}
  }),
  created() {
    this.loading = true;
    this.$http.get("/api/history-questionnaires").then(response => {
      let tmp = {};
      for (let questionnaire of response.data) {
        if (!Object.keys(tmp).includes(questionnaire.date)) {
          let dict = {};
          dict[questionnaire.name] = 1;
          tmp[questionnaire.date] = dict;
        }
        else {
          if (!Object.keys(tmp[questionnaire.date]).includes(questionnaire.name)) {
            tmp[questionnaire.date][questionnaire.name] = 1;
          }
          else {
            tmp[questionnaire.date][questionnaire.name] += 1;
          }
        }
      }
      for (let i in tmp) {
        for (let j in tmp[i]) {
          this.items.push({
            date: i,
            name: get_questionnaire_names(j).full_name,
            number: tmp[i][j]
          });
        }
      }
      this.items.sort((a, b) => moment(b.date) - moment(a.date));
    }).finally(() => {
      this.loading = false;
    })
  }
}
</script>

<style scoped>

</style>
