<template>
  <v-expansion-panels flat v-model="panel" multiple :accordion="true">
    <v-expansion-panel>
      <v-expansion-panel-header class="bold--text red--text"
        >Symptômes Alertants</v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <div id="panel_symptoms_alert">
          <ul v-if="indications.symptoms_alert.length > 0">
            <li
              v-for="(item, index) in indications.symptoms_alert"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
          <p v-else>Aucun Symptôme Alertant</p>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header class="bold--text"
        >Symptômes</v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <div id="panel_symptoms">
          <ul v-if="indications.other_symptoms.length > 0">
            <li
              v-for="(item, index) in indications.other_symptoms"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
          <p v-else>Aucun Symptôme</p>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header class="bold--text"
        >Facteurs de risques</v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <div id="panel_risk_factors">
          <ul v-if="indications.facteurs_de_risques.length > 0">
            <li
              v-for="(item, index) in indications.facteurs_de_risques"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
          <p v-else>Aucun Facteurs de risques</p>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header class="bold--text"
        >Comorbidités</v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <div id="panel_comorbidites">
          <ul v-if="indications.comorbidites.length > 0">
            <li v-for="(item, index) in indications.comorbidites" :key="index">
              {{ item }}
            </li>
          </ul>
          <p v-else>Aucune Comorbidité</p>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header class="bold--text"
        >Environnement</v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <div id="panel_environment">
          <ul v-if="indications.environment.length > 0">
            <li v-for="(item, index) in indications.environment" :key="index">
              {{ item }}
            </li>
          </ul>
          <p v-else>Aucune information</p>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  data() {
    return {
      panel: [0, 1],
    };
  },
  computed: {
    indications() {
      return this.$store.state.dpa.indications;
    },
  },
};
</script>

<style>
</style>