<template>
  <PrCardComponent title="Suivi de mes vaccinations" icon="mdi-clipboard-text">
    <template v-slot:card-text>
      <p class="semibold--text secondary--text">Suivi vaccinal Covid-19</p>
      <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
      <v-stepper v-else alt-labels flat>
        <v-stepper-header>
          <v-stepper-step color="white" complete :complete-icon="get_stepper_data(0).icon">
            <span :class="`${get_stepper_data(1).class}--text`">1ère dose</span>
            <v-icon v-if="get_stepper_data(1).check" color="secondary" class="ml-1" small>mdi-check</v-icon>
          </v-stepper-step>
          <v-divider :class="get_stepper_data(1).class"></v-divider>
          <v-stepper-step color="white" complete :complete-icon="get_stepper_data(1).icon">
            <span :class="`${get_stepper_data(2).class}--text`">2ème dose</span>
            <v-icon v-if="get_stepper_data(2).check" color="secondary" class="ml-1" small>mdi-check</v-icon>
          </v-stepper-step>
          <v-divider :class="get_stepper_data(2).class"></v-divider>
          <v-stepper-step color="white" complete :complete-icon="get_stepper_data(2).icon">
            <span :class="`${get_stepper_data(3).class}--text`">3+ dose(s)</span>
            <v-icon v-if="get_stepper_data(3).check" color="secondary" class="ml-1" small>mdi-check</v-icon>
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <v-divider></v-divider>
      <div :class="`${$vuetify.breakpoint.xs ? '' : 'd-flex'} mt-5`" v-if="suivi_texts">
        <span class="normal--text basic--text align-self-center pr-2">{{suivi_texts.text}}</span>
        <v-spacer></v-spacer>
        <TooltipMissingInfo v-if="missing_info" />
        <v-btn v-else color="secondary" elevation="0" class="align-self-center mt-3 mt-sm-0" @click="vaccination_click()" :disabled="loading">
          <span class="medium--text font-size--12">{{suivi_texts.button_text}}</span>
        </v-btn>
      </div>
      <div :class="`${$vuetify.breakpoint.xs ? 'mt-3' : 'd-flex justify-end'}`" v-if="show_button_suivi">
        <v-btn color="secondary" elevation="0" class="align-self-center" @click="vaccination_suivi_click()" :disabled="loading">
          <span class="medium--text font-size--12">Suivi post vaccinal</span>
        </v-btn>
      </div>
    </template>
  </PrCardComponent>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import TooltipMissingInfo from "../../../components/TooltipMissingInfo";
import axios from "axios";
import {mixin_vaccination} from "../../../../../data/mixin_vaccination";

export default {
  name: "VaccinationPatientSuiviCard",
  components: {TooltipMissingInfo, PrCardComponent},
  mixins: [mixin_vaccination],
  data: () => ({
    loading: false,
    show_button_suivi: false,
  }),
  mounted() {
    this.loading = true;
    axios.get("/api/vaccination_appointment_info").then(response => {
      this.response_api = response.data;
    }).finally(() => {
      this.loading = false;
    })
    axios.get("/api/has_access_to_suivi").then(response => {
      this.show_button_suivi = true;
      if (response.data.latest_questionnaire) {
        this.latest_suivi_questionnaire = response.data.latest_questionnaire;
      }
    }).catch(() => {
      this.show_button_suivi = false;
    })
  },
  computed: {
    missing_info() {
      return this.$store.getters.checkMissingInfoVaccination;
    },
    suivi_texts() {
      if (!this.response_api) return null;
      if (this.response_api.questionnaires.filter(x => x.questionnaire_is_completed).length) {
        if (this.response_api.is_eligible) {
          return {
            text: `Bienvenue sur le portail Vaccihelp de ${this.$store.getters.getAgencyName}, merci d’avoir répondu au questionnaire, votre centre de vaccination a pris en compte votre souhait de vous faire vacciner et reviendra vers vous quand des rendez-vous seront disponibles.`,
            button_text: "Modifier mes réponses"
          };
        }
        else {
          return {
            text: `Bienvenue sur le portail Vaccihelp de ${this.$store.getters.getAgencyName}, vous pouvez prendre rendez vous pour vous faire vacciner.`,
            button_text: "Prendre rendez-vous"
          };
        }
      }
      else {
        return {
          text: `Bienvenue sur le portail Vaccihelp de ${this.$store.getters.getAgencyName}, pour être autorisé à prendre un rendez-vous, prière de répondre au questionnaire suivant.`,
          button_text: "Répondre au questionnaire"
        };
      }
    }
  },
  methods: {
    get_stepper_data(vaccination_number) {
      if (this.response_api.vaccination_number > vaccination_number) {
        return {icon: "$vuetify.icons.vaccinationiconsecondary", check: true, class: "secondary"};
      }
      if (this.response_api.vaccination_number === vaccination_number && this.response_api.status === 9) {
        return {icon: "$vuetify.icons.vaccinationiconsecondary", check: true, class: "secondary"};
      }
      return {icon: "$vuetify.icons.vaccinationicon", check: false, class: "tertiary"};

    }
  }
}
</script>

<style scoped>

</style>
