<template>
  <v-card-title class="primary white--text semibold--text">
    {{ title }}
    <v-spacer></v-spacer>
    <v-btn fab small elevation="1" color="bg_primary" @click="$emit('click')">
      <v-icon color="white">mdi-close</v-icon>
    </v-btn>
  </v-card-title>
</template>

<script>
export default {
  name: "DialogCardTitleTemplate",
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
