const state = {
  pre_questionnaire: {
    sexe: null,
    birthdate: null
  }
}

const getters = {
  getPreQuestSexe: function (state) {
    return state.pre_questionnaire.sexe
  },
  getPreQuestBirthdate: function (state) {
    return state.pre_questionnaire.birthdate
  },

}

const mutations = {
  setPreQuestSexe: function(state, value) {
    state.pre_questionnaire.sexe = value
  },
  setPreQuestBirthdate: function(state, value) {
    state.pre_questionnaire.birthdate = value
  },
}

export default {
  state,
  getters,
  mutations
}
