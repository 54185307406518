<template>
  <v-tabs-items v-model="tab_selected">
    <v-tab-item value="tab_resume" class="bg pa-1">
      <DpaSummaryVMA :consultations="consultations" :loading="loading" />
    </v-tab-item>
    <v-tab-item value="tab_rdv" class="bg pa-1">
      <DpaAppointmentVMA :consultations="consultations" :questionnaires="questionnaires" :loading_questionnaires="loading_questionnaires" v-on:cancelled="get_consultations()" />
    </v-tab-item>
    <v-tab-item value="tab_notes" class="bg pa-1">
      <PrNoteTab app_name="vma" :separate_private_and_public="true" />
    </v-tab-item>
    <v-tab-item value="tab_visit" class="bg pa-1">
      <DpaMedicalVisitVMA :consultations="consultations" :questionnaires="questionnaires" :loading_questionnaires="loading_questionnaires" v-on:update_consultations="get_consultations()" />
    </v-tab-item>
    <v-tab-item value="tab_visio" class="bg pa-1">
      <AnTeleconsultation :email="$store.state.pat_admin.data.email" color_txt="primary" color_btn="secondary"/>
    </v-tab-item>
  </v-tabs-items>
</template>

<script>
import PrNoteTab from "prevana-library/src/components/dpa/tabs/PrNoteTab";
import AnTeleconsultation from "anam-library/src/components/an-teleconsultation/AnTeleconsultation";
import DpaAppointmentVMA from "./tabs/DpaAppointmentVMA";
import DpaMedicalVisitVMA from "./tabs/DpaMedicalVisitVMA";
import DpaSummaryVMA from "./tabs/DpaSummaryVMA";

export default {
  name: "TabsBody",
  components: {
    PrNoteTab,
    AnTeleconsultation,
    DpaAppointmentVMA,
    DpaMedicalVisitVMA,
    DpaSummaryVMA
  },
  data: () => ({
    consultations: [],
    questionnaires: [],
    loading: false,
    loading_questionnaires: false
  }),
  computed: {
    tab_selected: {
      get() {
        return this.$store.state.dpa.tab_selected;
      },
      set(value) {
        this.$store.state.dpa.tab_selected = value;
      },
    },
  },

  created() {
    this.get_consultations();
    this.get_questionnaires();
  },

  methods: {
    get_consultations() {
      this.loading = true;
      this.$http.get(`/api/patients/${this.$route.params.id}/consultations`, {params: {app_name: "vma", get_constants: true, get_notes: true}}).then(response => {
        this.consultations = response.data;
      }).finally(() => {
        this.$emit("set_loaded", "#tab_rdv");
        this.$emit("disable_tabs", this.consultations.filter(x => !x.cancelled).length === 0);
        this.loading = false;
      });
    },
    get_questionnaires() {
      this.loading_questionnaires = true;
      this.$http.get(`/api/patients/${this.$route.params.id}/questionnaires_vma`).then(response => {
        this.questionnaires = response.data;
      }).finally(() => {
        this.loading_questionnaires = false;
      })
    }
  }
};
</script>
