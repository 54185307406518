<template>
  <v-row class="mx-0">
    <template v-if="!_has_access_to_vaccination_open">
      <v-col cols="12" sm="4">
        <v-autocomplete v-model="selected_groupement"
                        :items="groupements"
                        :label="_label_groupements"
                        dense hide-details
                        color="primary" item-color="primary"
                        prepend-icon="mdi-magnify"
                        no-data-text="Aucun résultat"/>
      </v-col>
      <v-col cols="12" sm="4">
        <v-autocomplete v-model="selected_company"
                        :items="filtered_companies"
                        :label="_label_companies"
                        dense hide-details
                        color="primary" item-color="primary"
                        prepend-icon="mdi-magnify"
                        no-data-text="Aucun résultat"/>
      </v-col>
      <v-col cols="12" sm="4">
        <v-autocomplete v-model="selected_affectation"
                        :items="filtered_affectations"
                        :label="_label_affectations"
                        dense hide-details
                        color="primary" item-color="primary"
                        prepend-icon="mdi-magnify"
                        no-data-text="Aucun résultat"/>
      </v-col>
    </template>
    <v-col cols="12" sm="4">
      <PrAgeFilter :filter.sync="selected_age" :rounded="false" :filled="false" dense hide_details prepend_icon="mdi-magnify"/>
    </v-col>
    <template v-if="show_patient_category">
      <v-col cols="12" sm="4">
        <v-autocomplete v-model="selected_status"
                        :items="status_choices"
                        label="Filtrer par statut"
                        multiple dense hide-details
                        color="primary" item-color="primary"
                        prepend-icon="mdi-magnify"
                        no-data-text="Aucun résultat"/>
      </v-col>
      <v-col cols="12" sm="4">
        <v-autocomplete v-model="selected_profile"
                        :items="profile_choices"
                        label="Filtrer par profil"
                        multiple dense hide-details
                        color="primary" item-color="primary"
                        prepend-icon="mdi-magnify"
                        no-data-text="Aucun résultat"/>
      </v-col>
    </template>

    <v-col cols="12">
      <v-row class="mx-0 mt-6">
        <v-col cols="12" sm="4">
          <PrCardComponent :title="`Aptitude au vaccin (${nb_aptitude})`" icon="" max_height>
            <template v-slot:card-text>
              <v-progress-linear v-if="loading_charts === 1" indeterminate color="secondary"></v-progress-linear>
              <apex-chart v-else type="pie" height="300" :options="options_aptitude" :series="series_aptitude"/>
            </template>
          </PrCardComponent>
        </v-col>
        <v-col cols="12" sm="4">
          <PrCardComponent :title="`Vaccination en cours (${nb_in_progress})`" icon="" max_height>
            <template v-slot:card-text>
              <v-progress-linear v-if="loading_charts === 1" indeterminate color="secondary"></v-progress-linear>
              <apex-chart v-else type="pie" height="300" :options="options_in_progress" :series="series_in_progress"/>
            </template>
          </PrCardComponent>
        </v-col>
        <v-col cols="12" sm="4">
          <PrCardComponent title="Rendez-vous à venir" icon="" max_height>
            <template v-slot:card-text>
              <v-progress-linear v-if="loading_charts === 1" indeterminate color="secondary"></v-progress-linear>
              <template v-else>
                <apex-chart type="radialBar" height="270" :options="{labels: ['RDV pris'], colors: ['#E94E1B']}" :series="[filtered_ratio_rdv]"/>
                <p class="text-center">
                  <span class="primary--text font-size--16 semibold--text">{{filtered_slots('slots_taken')}}</span>
                  pris sur
                  <span class="primary--text font-size--16 semibold--text">{{filtered_slots('nb_slots')}}</span>
                  disponibles
                </p>
              </template>
            </template>
          </PrCardComponent>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import PrAgeFilter from "prevana-library/src/components/common/PrAgeFilter";

import moment from "moment"
import {get_list_category_by_agency} from "anam-library/src/data/dict_categories_pro";
import {get_terminology} from "anam-library/src/data/dict_terminology_by_agency";
import {show_patient_category} from "prevana-library/src/js/dict_permissions";

export default {
  name: "VaccinationStatisticsSummary",
  components: {
    PrCardComponent,
    PrAgeFilter
  },
  data() {
    return {
      selected_affectation: null,
      affectations: [],
      selected_company: null,
      companies: [],
      selected_groupement: null,
      groupements: [],
      aptitude_data : {},
      in_progress_data: {},
      loading_charts: 0,
      selected_age: [],
      selected_status: [],
      selected_profile: null,
      profile_choices: [
        {value: null, text: "Tous les Profils"},
        {value: "agent", text: "Agent"},
        {value: "pds", text: "Professionnel de santé"}
      ],
      status_choices: get_list_category_by_agency(this.$store.getters.getCurrentAgency)
    }
  },
  watch: {
    selected_groupement() {
      this.filter_search();
    },
    selected_company() {
      this.filter_search();
    },
    selected_affectation() {
      this.filter_search();
    },
    selected_age() {
      this.filter_search();
    },
    selected_status() {
      this.filter_search();
    },
    selected_profile() {
      this.filter_search();
    }
  },
  created() {
    this.get_table_data();
    let data = this.$store.getters.getEntitiesData;
    for (let el of data.affectations) {
      this.affectations.push(el);
      this.affectations.unshift({value: null, text: "Tous"})
    }
    for (let el of data.compagnies) {
      this.companies.push(el);
      this.companies.unshift({value: null, text: "Tous"})
    }
    for (let el of data.groupements) {
      this.groupements.push(el);
      this.groupements.unshift({value: null, text: "Tous"})
    }
    this.filter_search();
  },
  computed: {
    _has_access_to_vaccination_open: function() {
      return this.$store.getters.getCurrentAgency.access_to_vaccination_open;
    },
    _label_groupements() {
      return `Filtrer par ${get_terminology("niveau_2", this.$store.getters.getCurrentAgency)}`;
    },
    _label_companies() {
      return `Filtrer par ${get_terminology("niveau_3", this.$store.getters.getCurrentAgency)}`;
    },
    _label_affectations() {
      return `Filtrer par ${get_terminology("niveau_4", this.$store.getters.getCurrentAgency)}`;
    },
    filtered_companies() {
      if (this.selected_groupement) return this.companies.filter(x => (x.groupement_id === this.selected_groupement || x.value === null));
      return this.companies;
    },
    filtered_affectations() {
      if (this.selected_company) return this.affectations.filter(x => (x.groupement_id === this.selected_company || x.value === null));
      return this.affectations;
    },
    filtered_ratio_rdv() {
      if (!this.filtered_slots('nb_slots')) return 0;
      return ((this.filtered_slots('slots_taken') / this.filtered_slots('nb_slots')) * 100).toFixed(2);
    },
    show_patient_category: function() {
      return show_patient_category(this.$store.getters.getCurrentAgency.of_type);
    },
    filtered_data_aptitude: function() {
      for (let value of Object.values(this.aptitude_data)) {
        if(value !== 0) return this.aptitude_data;
      }
      return {};
    },
    nb_aptitude() {
      let nb_users = 0;
      for (let el in this.filtered_data_aptitude) {
        nb_users = nb_users + this.filtered_data_aptitude[el];
      }
      return nb_users;
    },
    series_aptitude: function() {
      return Object.values(this.filtered_data_aptitude)
    },
    options_aptitude: function() {
      return {
        labels: Object.keys(this.filtered_data_aptitude),
        legend: {position: 'bottom'},
        noData: {text: 'Aucune donnée'},
        dataLabels: {
          formatter: function(val ,opts) {
            return `${opts.w.config.series[opts.seriesIndex]} (${Math.round(val * 100) / 100}%)`;
          }
        },
        colors: ['#D3D3D3', '#FD9227', '#FED63F', '#FFCEE1', '#9A24FF', '#12B200']
      }
    },
    filtered_data_in_progress: function() {
      for (let value of Object.values(this.in_progress_data)) {
        if (value !== 0) return this.in_progress_data;
      }
      return {};
    },
    nb_in_progress: function() {
      let nb_users = 0;
      for (let el in this.filtered_data_in_progress) {
        nb_users = nb_users + this.filtered_data_in_progress[el];
      }
      return nb_users;
    },
    series_in_progress: function() {
      return Object.values(this.filtered_data_in_progress);
    },
    options_in_progress: function() {
      return {
        labels: Object.keys(this.filtered_data_in_progress),
        legend: {position: 'bottom'},
        noData: {text: 'Aucune donnée'},
        dataLabels: {
          formatter: function(val ,opts) {
            return `${opts.w.config.series[opts.seriesIndex]} (${Math.round(val * 100) / 100}%)`;
          }
        },
        colors: ['#B2CAFF', '#6374BD', '#274E12', '#E6739F', '#8092BA', '#155EFF', '#CC0200', '#C3EFBE', '#12B200']
      }
    }
  },
  methods: {
    get_table_data: function() {
      this.$http.get("/api/statistics/appointments", {params: {app_name: "prevanavaccination"}}).then(response => {
        this.items = [];
        for (let el in response.data) {
          let tmp = response.data[el];
          this.items.push(tmp);
        }
      })
    },
    filter_search() {
      if (this.loading_charts === 0) this.loading_charts = 1;
      let params = {};
      if (this.selected_groupement) params["services__health_facility__compagnie__groupement__id"] = this.selected_groupement;
      if (this.selected_company) params["services__health_facility__compagnie__id"] = this.selected_company;
      if (this.selected_affectation) params["services__id"] = this.selected_affectation;
      if (this.selected_age.length) {
        params["age_filter"] = JSON.stringify(this.selected_age)
      }
      if (this.selected_status.length) params["category_filter"] = JSON.stringify(this.selected_status);
      if (this.selected_profile) {
        params["user__professionnel__isnull"] = this.selected_profile === "agent";
      }
      this.$http.get("/api/statistics/summary", {params: params}).then(response => {
        this.in_progress_data = response.data.in_progress;
        this.aptitude_data = response.data.aptitude;
      }).finally(() => {
        this.loading_charts = 2;
      })
    },
    filtered_slots(type) {
      let nb_to_return = 0;
      for (let el of this.items) {
        // if (el.company && el.affectation && el.groupement) {
        //     if (this.selected_affectation === null || this.selected_affectation === el.affectation) {
        //         if (this.selected_company === null || this.selected_company === el.company) {
        //             if (this.selected_groupement === null || this.selected_groupement === el.groupement) {
        if (moment(el.date).isSame(moment(), 'day') || moment(el.date).isAfter(moment(), 'day')) nb_to_return += el[type];
      }
      //         }
      //     }
      // }
      // }
      return nb_to_return;
    }
  }
}
</script>
