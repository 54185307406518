<template>
  <v-row>
    <v-col cols="12" sm="6">
      <PrCardComponent title="Prochain RDV" icon="mdi-calendar">
        <template v-slot:card-text>
          <p class="soft_blue--text text-center mt-4 bold--text font-size--18" v-if="!next_rdv">
            Aucun rendez-vous à venir
          </p>
          <div v-else>
            <p class="soft_blue--text text-center mt-4 bold--text font-size--18">
              Date: {{ date_next_rdv }}
            </p>
            <p class="soft_blue--text text-center mt-4 bold--text font-size--18">
              Lieu/motif: {{ next_rdv.place }} <span v-if="next_rdv_consult_type">({{next_rdv_consult_type}})</span>
            </p>
          </div>
        </template>
      </PrCardComponent>

      <PrCardComponent v-if="consultations && consultations.length" title="Historique - Prise de RDV" icon="mdi-history" class="mt-6">
        <template v-slot:card-text>
          <v-list>
            <v-list-item-group :key="key" v-for="(item, key) in consultations">
              <v-list-item-content style="overflow: unset">
                <v-list-item-title style="white-space: unset; word-break: break-word" class="black--text">
                  {{get_creation_date(item)}}
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: unset; word-break: break-word" class="primary--text font-size--16">
                  <span class="semibold--text">{{get_consultation_sentence(item)}}</span>
                  <span v-if="item.cancelled" class="red--text"> {{get_consultation_cancelled(item)}}</span>
                  <span v-if="item.closed" class="orange--text"> {{get_consultation_closed(item)}}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item-group>
          </v-list>
        </template>
      </PrCardComponent>
    </v-col>

    <v-col cols="12" sm="6">
      <PrCardComponent title="Actions" icon="mdi-gesture-tap">
        <template v-slot:card-text>
          <v-list>
            <v-list-item @click="go_to_appointment()">
              Prendre RDV pour ce patient (sans questionnaire)
            </v-list-item>
            <v-list-item @click="answer_questionnaire()" :disabled="loading_questionnaires || questionnaires.length > 0">
              Répondre au questionnaire et prendre RDV pour ce patient
            </v-list-item>
            <v-list-item @click="dialog_cancel_rdv = true">
              Annuler un RDV
            </v-list-item>
            <v-list-item @click="set_eligible_consultation()">
              Autoriser la prise de rendez-vous
            </v-list-item>
          </v-list>
        </template>
      </PrCardComponent>
    </v-col>

    <v-dialog v-model="dialog_cancel_rdv" width="600">
      <v-card>
        <v-card-title class="primary--text semibold--text" style="word-break: break-word">
          Annuler un rendez-vous
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialog_cancel_rdv = false">
            <v-icon color="primary">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-select v-model="selected_rdv_to_cancel" :items="delete_rdv_items" outlined class="mt-5"
          label="Choisissez le RDV à annuler" no-data-text="Aucun RDV"></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click="dialog_cancel_rdv = false">Retour</v-btn>
          <v-btn color="secondary" :disabled="!selected_rdv_to_cancel"
                 :loading="is_loading_cancel_appointment" @click="cancel_appointment()">Confirmer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import {get_formatted_date} from "anam-library/src/js/dates";
import moment from "moment-timezone";

export default {
  name: "DpaAppointmentVMA",
  components: {PrCardComponent},
  props: {
    consultations: {
      type: Array,
      default: () => []
    },
    questionnaires: {
      type: Array,
      default: () => []
    },
    loading_questionnaires: {
      type: Boolean,
      required: true
    },
  },

  data: () => ({
    is_loading_cancel_appointment: false,
    dialog_cancel_rdv: false,
    selected_rdv_to_cancel: null
  }),

  computed: {
    delete_rdv_items() {
      let consults = [];
      for (let consultation of this.consultations.filter(x => x.cancelled === null && x.closed === false && x.slot && moment(x.slot.start_slot) > moment())) {
        consults.push({value: consultation.id, text: `${consultation.place} ${get_formatted_date(consultation.slot.start_slot)}`});
      }
      return consults;
    },
    next_rdv() {
      let next_consult = null;
      if (this.consultations.length) {
        let upcoming_consultations = this.consultations.filter(x => x.cancelled === null && x.closed === false && x.slot && moment(x.slot.start_slot) > moment());
        if (upcoming_consultations.length) {
          upcoming_consultations.sort((a, b) => moment(a.slot.start_slot) - moment(b.slot.start_slot));
          next_consult = upcoming_consultations[0];
        }
      }
      return next_consult;
    },
    date_next_rdv() {
      if (!this.next_rdv) return null;
      return get_formatted_date(this.next_rdv.slot.start_slot);
    },
    next_rdv_consult_type(){
      if (!this.next_rdv) return null;
      return this.$store.getters.getMotifName(this.next_rdv.consultation_reason);
    },
    patient_id() {
      return this.$route.params.id;
    }
  },

  methods: {
    cancel_appointment() {
      this.is_loading_cancel_appointment = true;
      this.$http.delete(`/api/consultations/${this.selected_rdv_to_cancel}`, {params: {app_name: "vma"}}).then(() => {
        this.$emit("cancelled");
        this.dialog_cancel_rdv = false;
        this.$store.commit("PostSuccess", "Rendez-vous annulé");
      }).finally(() => {
        this.is_loading_cancel_appointment = false;
      })
    },

    go_to_appointment() {
      this.$router.push({name: "VMAAppointment", params: {id: this.patient_id}});
    },

    get_consultation_sentence(item) {
      let motif = this.$store.getters.getMotifName(item.consultation_reason);
      return `RDV le ${get_formatted_date(item.slot.start_slot)} à ${item.place} (${motif})`;
    },

    get_consultation_cancelled(item) {
      return `Annulé le ${get_formatted_date(item.cancelled)}`;
    },

    get_consultation_closed(item) {
      return `Clôturé le ${get_formatted_date(item.closed_date_iso)}`;
    },

    get_creation_date(item) {
      return get_formatted_date(item.creation_date_iso);
    },

    answer_questionnaire() {
      this.$router.push({
        name: "DoctorQuestionnaireViewVMA", query: {
          questionnaire_type: "vma-escp",
          app_name: "vma",
          patient_id: this.patient_id,
          nb_question: 5,
          redirection_end_anam: `/doctor/vma/patient/${this.patient_id}/appointment`
        }
      });
    },

    set_eligible_consultation() {
      let data = {patient: {is_eligible_consultation: true}};
      this.$http.patch(`/api/patients/${this.patient_id}`, data).then(() => {
        this.$store.commit("PostSuccess", "Ce patient peut à présent prendre rendez-vous");
      })
    }
  },
};
</script>
