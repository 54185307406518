<template>
  <v-container class="bg_soft pb-sm-0" fluid>
    <v-row class="ma-0">
      <v-col col="12" md="8" class="soft_blue--text pl-sm-10 pb-0">
        <p class="bold--text font-size--30">
          Combien d’injections sont nécessaires pour la vaccination contre la Covid19 ?
        </p>
        <p class="medium--text mb-0">
          La majorité des vaccins autorisés dans le cadre de cette campagne de vaccination
          (vaccin du laboratoire Pfizer-BioNTech, Moderna et AstraZeneca) nécessitent 2 injections à un intervalle de
          21 jours à 6 semaines et dorénavant une 3ème dose de rappel 6 mois après la dernière injection (pour une partie de la population).
        </p>
        <ul>
          <li>Une première injection est injectée le jour J0.</li>
          <li>Une seconde injection est injectée 21 à 84 jours (selon le vaccin administré).</li>
          <li>Une dose de rappel à 3 mois après la 2ème injection (pour une partie de la population).</li>
        </ul>
        <p class="medium--text mb-0">
          Le vaccin du laboratoire Johnson & Johnson ne nécessite quant à lui qu’une seule injection.
          Cependant, depuis le 01/09/2021, une 2ème injection d’un vaccin à ARN messager (au moins 4 semaines après la 1ère injection) est nécessaire.
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-center pb-0 align-self-center hidden-xs-only">
        <img src="@/assets/vaccination/calendrier_vaccinal.png" height="200">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "BlocInjection"
  }
</script>

<style scoped>

</style>
