<template>
  <v-row class="mx-0 mt-4">
    <v-col cols="12">
      <CovidMonitoringTable/>
    </v-col>
  </v-row>
</template>

<script>
import CovidMonitoringTable from "@/components/common/covidhelp/monitoring/CovidMonitoringTable";

export default {
  name: "HFManagerCovidTableMonitoringView",
  components: {
    CovidMonitoringTable
  }
}
</script>

<style scoped>

</style>
