<template>
  <v-container fluid class="pa-6">
    <v-row class="mx-0">
      <v-col cols="12">
        <p class="primary--text bold--text font-size--28 mb-0">Bonjour {{ patient_fullname }}</p>
      </v-col>
      <v-col cols="12" sm="6">
        <DashboardBlocActions :rdv="rdv" />
      </v-col>
      <v-col cols="12" sm="6">
        <PatientRDVCardComponent see_details to_details="PatientRDV" app_name="rps,prevanavaccination,vma" v-on:api_call_completed="set_rdv($event)" />
      </v-col>
      <v-col cols="12" sm="6">
        <PatientDocumentsCardComponent :max_display="3" see_details />
      </v-col>
      <v-col cols="12" sm="6">
        <PatientRessourcesCardComponent :ressources="ressources"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardBlocActions from "@/components/patient/dashboard/actions/DashboardBlocActions";
import PatientRDVCardComponent from "@/components/patient/components/rdv/PatientRDVCardComponent";
import PatientRessourcesCardComponent from "@/components/patient/components/ressources/PatientRessourcesCardComponent";
import PatientDocumentsCardComponent from "@/components/patient/components/documents/PatientDocumentsCardComponent";
import ressources_1 from "@/assets/ressources/covidapp/gestes_barrieres.png";

export default {
  name: "PatientDashboardView",
  components: {
    PatientDocumentsCardComponent,
    DashboardBlocActions,
    PatientRessourcesCardComponent,
    PatientRDVCardComponent
  },
  data: () => ({
    ressources: [
      {
        name: "Gestes Barrières",
        logo: ressources_1,
        url: "https://solidarites-sante.gouv.fr/IMG/pdf/affiche_gestes_barriere.pdf"
      },
    ],
    rdv: []
  }),
  computed: {
    patient_fullname: function () {
      return `${this.$store.getters.getPatientData.first_name}`;
    }
  },
  methods: {
    set_rdv(data) {
      this.rdv = data;
    }
  }
}
</script>

<style scoped>

</style>
