<template>
  <v-container fluid class="pa-5">
    <v-row class="mx-0">
      <v-col cols="12">
        <div class="d-flex">
          <img :src="logo" width="50" height="auto" class="rounded-lg mr-4" alt="Logo Rendez-vous"/>
          <span class="secondary--text bold--text font-size--24 align-self-center">Gestion des rendez-vous</span>
        </div>
      </v-col>
      <v-col cols="12">
        <v-tabs color="secondary" background-color="bg_primary">
          <v-tab v-if="has_access_to_rps" class="text-capitalize semibold--text" to="/manager/appointments/rps">Bien-être</v-tab>
          <v-tab v-if="has_access_to_vaccination" class="text-capitalize semibold--text" to="/manager/appointments/vaccination">Vaccination</v-tab>
          <v-tab v-if="has_access_to_vma" class="text-capitalize semibold--text" to="/manager/appointments/vma">Visite médicale</v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import logo from "@/assets/picto_rdv.png"

export default {
  name: "HFManagerAppointmentsLayout",
  data: () => ({
    logo: logo,
  }),
  created() {
    if(this.has_access_to_rps) this.$router.push({name: 'HFManagerRPSAppointments'});
    else if(this.has_access_to_vaccination) this.$router.push({name: 'HFManagerVaccinationAppointments'});
    else if(this.has_access_to_vma) this.$router.push({name: 'HFManagerVMAAppointments'});
  },
  computed: {
    infos_manager: function() {
      return this.$store.getters.getManagerInfos;
    },
    has_access_to_rps: function() {
      return this.infos_manager.apps.includes('rps')
    },
    has_access_to_vma: function() {
      return this.infos_manager.apps.includes('vma');
    },
    has_access_to_vaccination: function() {
      return this.infos_manager.apps.includes('prevanavaccination');
    },
  }
}
</script>

<style scoped>
.v-tabs-bar a{
  letter-spacing: unset;
}
</style>
