import moment from "moment-timezone"
import {get_formatted_date} from "anam-library/src/js/dates";

export const vaccination_mixin = {
  data () {
    return {
      data_set_eligible: {
        ids_send_email: [this.$route.params.id],
        is_eligible: true,
        title_eligible: this.$t("email.MAIL_VACC_CHANGE_TO_ELIGIBLE_TITLE"),
        text_eligible: this.$t("email.MAIL_VACC_CHANGE_TO_ELIGIBLE_TEXT"),
        title_non_eligible: this.$t("email.MAIL_VACC_CHANGE_TO_NOT_ELIGIBLE_TITLE"),
        text_non_eligible: this.$t("email.MAIL_VACC_CHANGE_TO_NOT_ELIGIBLE_TEXT"),
        app: "prevanavaccination"
      }
    }
  },

  computed: {
    next_rdv() {
      if (this.$store.state.dpa_data.next_rdv) {
        return {
          place: this.$store.state.dpa_data.next_rdv.place,
          date: get_formatted_date(this.$store.state.dpa_data.next_rdv.slot.start_slot)
        }
      }
      return {
        place: "",
        date: 'Aucun rendez-vous à venir'
      };
    },
  },

  methods: {
    async get_patient_vaccination_data() {
        const response = await this.$http.get("/api/patients/" + this.$route.params.id + "/vaccination_data");
        let response_data = response.data;
        let next_consult = null;
        if (response_data.consultation_list && response_data.consultation_list.length) {
          let upcoming_consultations = response_data.consultation_list.filter(x => x.cancelled === null && x.slot && moment(x.slot.start_slot) > moment());
          if (upcoming_consultations.length) {
            upcoming_consultations.sort((a, b) => moment(a.slot.start_slot) - moment(b.slot.start_slot));
            next_consult = upcoming_consultations[upcoming_consultations.length - 1];
          }
        }
        this.$store.state.dpa_data.status_vaccination_id = response_data.id;
        if (response_data.consultation_list) this.$store.state.dpa_data.history_consultations = response_data.consultation_list.reverse();
        else this.$store.state.dpa_data.history_consultations = [];
        this.$store.state.dpa_data.is_eligible = response_data.is_eligible;
        this.$store.state.dpa_data.next_rdv = next_consult;

      this.$store.state.dpa_data.vaccination_data = {};
      if (response_data.actevaccination_list) {
        this.$store.state.dpa_data.vaccination_data.nb_of_injections_performed = response_data.actevaccination_list.filter(x => x.status >= 9).length;
        for (let acte of response_data.actevaccination_list){
          if (acte.vaccination_number) this.$store.state.dpa_data.vaccination_data[acte.vaccination_number] = acte;
        }
      }
      else this.$store.state.dpa_data.vaccination_data.nb_of_injections_performed = 0;
      this.$store.state.dpa_data.vaccination_data.nb_of_injections_needed = response_data.nb_of_injections_needed;
      this.$store.state.dpa_data.show_tabs["#tab_2_vaccination"] = this.$store.state.dpa_data.vaccination_data.nb_of_injections_performed > 0 && (this.$store.state.dpa_data.vaccination_data.nb_of_injections_performed !== response_data.nb_of_injections_needed || response_data.nb_of_injections_needed > 1);
      this.$store.state.dpa_data.show_tabs["#tab_3_vaccination"] = this.$store.state.dpa_data.vaccination_data.nb_of_injections_performed > 1 && (this.$store.state.dpa_data.vaccination_data.nb_of_injections_performed !== response_data.nb_of_injections_needed || response_data.nb_of_injections_needed > 2);

        this.$store.state.dpa_data.history_vaccination = [];
        this.$store.state.dpa_data.status = response_data.status;
        this.$store.state.dpa_data.follow_up = response_data.follow_up ? response_data.follow_up : 1;
        this.$store.state.dpa_data.follow_up_datetime = response_data.follow_up_datetime;

        for (let history of response_data.status_history) {
          this.$store.state.dpa_data.history_vaccination.unshift({
            date: moment.tz(history.datetime, "Europe/Paris").format("DD/MM/YYYY HH:mm"),
            status: history.status,
            is_correction: history.is_correction,
            is_primary: history.is_primary,
            acte_number: history.acte_number,
          })
        }
    },

    get_injections_list() {
      let injections = Object.keys(this.$store.state.dpa_data.vaccination_data);
      injections.splice(injections.indexOf("nb_of_injections_needed"), 1);
      injections.splice(injections.indexOf("nb_of_injections_performed"), 1);
      return injections;
    },

    get_status_reason() {
      setTimeout(() => {
        if (this.$store.state.dpa_data.status !== "") {
          if ([18, 28].includes(this.$store.state.dpa_data.status)) {
            this.$http.get("/api/get_reason_not_eligible/" + this.$route.params.id).then(response => {
              this.reasons_not_eligible = response.data.reasons;
              this.$store.state.vaccinated_14_days = response.data.vaccinated_14_days;
            }).finally(() => {
              this.is_loading = false;
            })
          } else {
            this.is_loading = false;
            this.$store.state.vaccinated_14_days = false;
          }
        } else this.get_status_reason();
      }, 1000)
    }
  },
};
