<template>
  <div>
   <AnHeader :elevation="1" color_app_bar="primary">
     <template v-slot:section-logo>
       <v-menu v-model="menu" :close-on-content-click="false" offset-y>
         <template v-slot:activator="{ on, attrs }">
           <AppButton :on="on" :attrs="attrs"
                      :app="app_selected"
                      :menu="menu"
                      class="ml-2"/>
         </template>
         <v-card color="primary">
           <v-card-actions v-for="app in app_enable" :key="app.route_name" class="pa-0">
             <AppButton :app="app" v-on:btn_click="switch_app(app_selected, app)"/>
           </v-card-actions>
         </v-card>
       </v-menu>
     </template>
     <template v-slot:actions>
       <template v-for="(item, key) in navigation_buttons">
          <AnHeaderNavigationButton v-if="item.primary_action" :key="key"
                                    :text="item.text"
                                    :icon="item.icon"
                                    :url="item.url"
                                    color="white"
                                    class="hidden-xs-only"/>
       </template>
       <AnHeaderMenuButton :navigation_buttons="navigation_buttons" :name="full_name" :optional_info="optional_info" color="white"/>
       <v-spacer class="hidden-sm-and-up"></v-spacer>
       <PrButtonDeconnexion/>
     </template>
   </AnHeader>
  </div>
</template>

<script>
import AnHeader from "anam-library/src/components/an-header/AnHeader";
import AnHeaderNavigationButton from "anam-library/src/components/an-header/AnHeaderNavigationButton";
import AnHeaderMenuButton from "anam-library/src/components/an-header/AnHeaderMenuButton";
import PrButtonDeconnexion from "prevana-library/src/components/common/PrButtonDeconnexion";
import AppButton from "./AppButton";

import picto_covid from "@/assets/picto-covidapp.png";
import picto_vaccination from "@/assets/picto-vaccination.png";
import picto_rps from "@/assets/picto-rps.png";
import picto_vma from "@/assets/picto-vma.png";
import {get_profession_name} from "anam-library/src/data/professions";

export default {
  name: "DoctorHeader",
  components: {
    AnHeader,
    AnHeaderNavigationButton,
    AnHeaderMenuButton,
    PrButtonDeconnexion,
    AppButton
  },
  props: {
    navigation_buttons: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      menu: false,
      apps: [
        {
          id: "rps",
          title: "Bien-être",
          image_name: picto_rps,
          route_name: "RPSLayout",
          path: "rps",
          iframe: true
        },
        {
          id: "vma",
          title: "Visite Médicale",
          image_name: picto_vma,
          route_name: "VMADoctorLayout",
          path: "vma",
          iframe: true
        },
        {
          id: "covidapp",
          title: "Suivi Covid",
          image_name: picto_covid,
          route_name: "CovidhelpLayout",
          path: "covidhelp",
        },
        {
          id: "prevanavaccination",
          title: "Vaccination",
          image_name: picto_vaccination,
          route_name: "VaccinationLayout",
          path: "vaccination",
        },
        {
          id: "ECOS",
          title: "Gestion du stress",
          image_name: picto_rps,
          route_name: "ECOSDoctorLayout",
          path: "ecos",
        },
        {
          id: "etude",
          title: "Étude",
          image_name: picto_rps, // TODO CEPHISMER: picto
          route_name: "EtudeDoctorLayout",
          path: "etude",
        },
      ],
    };
  },
  computed: {
    full_name: function() {
      return this.$store.getters.getProfessionnelFullName;
    },
    optional_info: function() {
      return get_profession_name(this.$store.getters.getProfessionnelDmp);
    },
    app_selected() {
      let routes_name = this.$route.matched.map((el) => el.name);

      let app_selected = this.apps.find((el) =>
        routes_name.includes(el.route_name)
      );

      if (app_selected !== undefined) return app_selected;
      else return this.apps[0];
    },
    app_enable() {
      return this.apps.filter((el) => el.title !== this.app_selected.title);
    },
  },
  methods: {
    async switch_app(old_app, new_app) {
      if (this.$store.getters.getAppsAllowed.includes(new_app.id)) {
        await this.$http.post(`/api/switch_app/${new_app.id}`);
      }
      let path = this.$route.path.replace(old_app.path, new_app.path);
      this.$router.push({path: path, params: this.$route.params});
      this.$store.commit("PostSuccess", "Changement d'application réussi");
    },
  },
};
</script>

<style scoped>

</style>
