<template>
  <PrCardComponent title="Détails de mes résultats" icon="">
    <template v-slot:card-text>
      <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
      <v-row>
        <v-col cols="12" sm="6">
          <PrRadarGraph
            ref_graph="radar_graph"
            class="mt-5"
            :patient_id="$store.getters.getPatientId"
            :use_short_text="true"
            :fontSize="15"
            :font-size-yaxis="6"
            :padding="padding_graph"
            :questionnaire_name="questionnaire_name"
            :bold_mean_line="!loading_graph"
            :show_only_patient="true"
            :no_data_text="['Nous ne pouvons pas afficher vos résultats tant', 'que vous n\'avez pas répondu à au moins 1 questionnaire']"
            v-on:has_data="loading_graph = false"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <PrSyntheseQuestionnaire :questionnaires="questionnaires" :show_rdv="false" />
        </v-col>
      </v-row>
    </template>
  </PrCardComponent>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import PrRadarGraph from "prevana-library/src/components/common/PrRadarGraph";
import PrSyntheseQuestionnaire from "prevana-library/src/components/dpa/tabs/PrSyntheseQuestionnaire";

export default {
  name: "PatientRpsResultsCardDetails",
  components: {PrCardComponent, PrRadarGraph, PrSyntheseQuestionnaire},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    questionnaires: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    loading_graph: true,
    padding_graph: {
      top: -10,
      bottom: -25,
      left: 0,
    }
  }),

  computed: {
    questionnaire_name() {
      return this.$store.getters.getQuestionnaireName;
    }
  }

}
</script>

<style scoped>

</style>
