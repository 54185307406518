<template>
  <div>
    <span v-if="value || value === 0" :class="color_text">{{ value }}</span>
    <v-icon v-else small>mdi-minus</v-icon>
  </div>
</template>

<script>
import {get_color_score} from "../../../../data/ecos/utils";

export default {
  name: "ScoreECOSItem",
  props: {
    item: {
      type: Object,
      required: true
    },
    variable: {
      type: String,
      required: true
    }
  },
  computed: {
    value() {
      return this.item[this.variable];
    },
    color_text() {
      return `score_${get_color_score(this.variable, this.value)}--text semibold--text font-size--16`;
    }
  },
  methods: {
    get_value_to_display(item, header) {
      return item[header] || "-";
    }
  }
}
</script>

<style scoped>

</style>
