<template>
  <v-app>
    <v-app-bar app class="primary" flat>
      <img :src="logo_prevana" height="100%" alt="Logo Prevana"/>
      <v-spacer></v-spacer>
      <AnLangSelect v-show="has_trad_choices" :agency_name="$route.params.agency_name" style="max-width: 150px" v-on:no_choice="has_trad_choices=false" />
    </v-app-bar>
    <v-main class="bg_primary">
      <router-view></router-view>
    </v-main>
    <v-footer class="bg_primary justify-center pt-10">
      <a :href="get_mentions_legales_url('prevana', 'prevana')" target="_blank">{{$t('footer.legals')}} - {{ current_agency.commercial_name }} </a>
    </v-footer>
  </v-app>
</template>

<script>
import AnLangSelect from "anam-library/src/components/an-ui/select/AnLangSelect";
import colors_themes from "@/plugins/colors_themes";
import {mixin_cgu} from "anam-library/src/js/utils";


export default {
  name: 'HomeLayout',
  mixins: [mixin_cgu],
  components: {AnLangSelect},
  data: () => ({
    logo_prevana: "https://www.anamnese.care/hubfs/Logiciels/PREVENTION/Prevana%20(g%C3%A9n%C3%A9ral)/Logos/logo-2022-prevana-blanc-BL.png",
    has_trad_choices: true
  }),
  computed: {
    current_agency: function() {
      return this.$store.getters.getCurrentAgency;
    }
  },
  created() {
    this.$vuetify.theme.themes.light = colors_themes.theme_patient["theme"]["themes"]["light"];
    this.$store.state.is_mounted_app = true;
  },
};
</script>

<style scoped>

</style>
