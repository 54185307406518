import moment from "moment-timezone";

export const mixin_vaccination = {
  data: () => ({
    response_api: null,
    latest_suivi_questionnaire: null
  }),
  methods: {
    vaccination_click: function() {
      switch (this.response_api.status) {
        case 0:
        case 1.5:
        case 2:
        case 3:
        case 5:
          this.go_to_consent(this.response_api, false);
          break;
        case 4:
        case 4.5:
          this.go_to_appointment(this.response_api);
          break;
        case 1:
        case 6:
        case 8:
          this.go_to_questionnaire(this.response_api);
          break;
        case 7:
          this.$router.push({name: 'PatientVaccinationAppointmentSummary', query: {status: 'stopped'}});
          break;
        case 9:
          this.go_to_consent(this.response_api, true);
          break;
        case 10:
          this.$router.push({name: 'PatientVaccinationAppointmentSummary', query: {status: 'finalised'}});
          break;
        default:
          this.$router.push({name: 'PatientVaccinationConsent', query: {vaccination_number: 1}});
      }
    },

    go_to_consent: function(data, post) {
      if (post) {
        let data_post = {
          status_history: [{datetime: moment().utc().format("YYYY-MM-DD HH:mmZ"), status: 1}],
          vaccination_number: data.vaccination_number + 1
        };
        this.$http.post("/api/set_vaccination_status", data_post).then(() => {
          this.$router.push({name: "QuestionnaireView", query: {questionnaire_type: "SecondVaccinationPrevana", app_name: "prevanavaccination", nb_question: 2, additional_data: {vaccination_number: data.vaccination_number + 1}}});
        })
      }
      else {
        let quest = data.questionnaires.filter(x => x.vaccination_number === data.vaccination_number);
        if (quest.length) {
          this.$router.push({name: 'PatientVaccinationQuestionnaire'});
        }
        else this.$router.push({name: 'PatientVaccinationConsent', query: {vaccination: data.acte_id, vaccination_number: data.vaccination_number}});
      }
    },

    go_to_appointment: function(data) {
      if (data.id) this.$router.push({name: 'PatientVaccinationAppointmentSummary'});
      else this.$router.push({name: 'PatientVaccinationAppointmentSummary', query: {status: 'programmed'}});
    },

    go_to_questionnaire: function(data) {
      let quest = data.questionnaires.filter(x => x.vaccination_number === data.vaccination_number);
      if (quest.length) {
        this.$router.push({name: 'PatientVaccinationQuestionnaire'});
      }
      else {
        if (data.vaccination_number === 1) this.$router.push({
          name: "QuestionnaireView",
          query: {questionnaire_type: "FirstVaccinationPrevana", app_name: "prevanavaccination", nb_question: 2}
        });
        else this.$router.push({
          name: "QuestionnaireView",
          query: {
            questionnaire_type: "SecondVaccinationPrevana",
            nb_question: 2,
            app_name: "prevanavaccination",
            additional_data: {vaccination_number: data.vaccination_number}
          }
        });
      }
    },

    vaccination_suivi_click() {
      if (this.latest_suivi_questionnaire) this.$router.push({name: 'PatientVaccinationEvolution'});
      else this.$router.push({name: "QuestionnaireView", query: {questionnaire_type: "SuiviVaccinationPrevana", app_name: "prevanavaccination", nb_question: 2}});
    }
  }
}
