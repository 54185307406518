import { render, staticRenderFns } from "./PatientContactCardComponent.vue?vue&type=template&id=6861115d&scoped=true&"
import script from "./PatientContactCardComponent.vue?vue&type=script&lang=js&"
export * from "./PatientContactCardComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6861115d",
  null
  
)

export default component.exports