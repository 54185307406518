<template>
  <v-dialog v-model="_dialog" max-width="700">
    <v-card>
      <v-card-title class="primary--text">
        <v-icon color="primary" class="mr-4">mdi-help</v-icon>
        <span class="semibold--text font-size--20">Aide</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="_dialog=false" color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider inset></v-divider>
      <v-card-text>
        <v-row class="pt-10">
          <v-col cols="12">
            <h2 class="primary--text">Gravité Instant T :</h2>
            <v-row no-gutters>
              <v-col cols="12" class="mt-2">
                <v-icon color="red darken-4">mdi-checkbox-blank-circle</v-icon>
                <span class="red--text darken-4--text">Alerte</span>
                <span>: Vérifier le patient en priorité, présence de symptômes alertants</span>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-icon color="red">mdi-checkbox-blank-circle</v-icon>
                <span class="red--text">Levée de doute</span>
                <span>
                   : Évaluation de la gravité du patient recommandée en 2e priorité (score >= 20)
                 </span>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-icon color="orange">mdi-checkbox-blank-circle</v-icon>
                <span class="orange--text">Contrôle jour J</span>
                <span> : Évaluation dans les 24h (score entre 10 et 19)</span>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-icon color="green">mdi-checkbox-blank-circle</v-icon>
                <span class="green--text">Abstention</span>
                <span> : Pas d'action particulière (score &lt;= 9)</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-for="(title, index) in titles_score_helper" :key="index">
            <h2 class="primary--text">{{ title }}</h2>
            <v-row no-gutters>
              <v-col cols="12" class="mt-2">
                <span class="red--text">Très probable</span>
                <span> : score supérieur ou égal à 20</span>
              </v-col>
              <v-col cols="12" class="mt-2">
                <span class="orange--text">Probable</span>
                <span> : score entre 10 et 19</span>
              </v-col>
              <v-col cols="12" class="mt-2">
                <span class="green--text">Peu probable</span>
                <span> : score inférieur à 10</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "HelpDialogRecommendations",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      titles_score_helper: [
        "Risque d'aggravation a posteriori",
        "Probabilité Covid+",
      ],
    };
  },
  computed: {
    _dialog: {
      get: function() {
        return this.dialog;
      },
      set: function(val) {
        this.$emit("update:dialog", val);
      }
    }
  }
};
</script>

<style>
</style>
