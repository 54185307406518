<template>
  <v-row>
    <v-col cols="12" sm="6">
      <PrCardComponent title="RDV" icon="mdi-calendar">
        <template v-slot:card-text>
          <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
          <template v-else>
            <p class="soft_blue--text mt-4 bold--text font-size--18">
              Rendez-vous
            </p>
            <div v-if="!next_rdv">
              Aucun rendez-vous à venir
            </div>
            <div v-else class="ml-5">
              <p class="semibold--text">Date: <span class="semibold--text secondary--text">{{ date_next_rdv }}</span></p>
              <p class="semibold--text">Lieu/motif :
                <span class="semibold--text secondary--text">{{ next_rdv.place }}</span>
                <span v-if="next_rdv_consult_type"> ({{next_rdv_consult_type}})</span>
              </p>
            </div>

            <p class="soft_blue--text mt-10 bold--text font-size--18">
              Visite médicale
            </p>
            <div v-if="!latest_visit">
              Aucune visite médicale
            </div>
            <div v-else class="ml-5">
              <p class="semibold--text">Aménagement: <span class="semibold--text secondary--text">{{ latest_visit.aptitude ? "Oui": "Non" }}</span></p>
              <p class="semibold--text">Date: <span class="semibold--text secondary--text">{{ date_latest_visit }}</span></p>
              <p class="semibold--text">Lieu/motif : <span class="semibold--text secondary--text">{{ latest_visit.place }}</span><span v-if="latest_visit_consult_type"> ({{latest_visit_consult_type}})</span></p>
            </div>

            <div class="mt-10 text-center">
              <v-btn text @click="dialog_documents = true">
                <v-icon color="primary">mdi-eye</v-icon>
                <span class="primary--text text-decoration-underline ml-3">Voir les documents échangés avec le patient</span>
              </v-btn>
            </div>
          </template>
        </template>
      </PrCardComponent>
    </v-col>

     <v-col cols="12" sm="6">
       <PrCardComponent title="Historique" icon="mdi-calendar">
         <template v-slot:card-text>
           <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
           <v-list v-else>
             <v-list-item-group :key="key" v-for="(item, key) in history">
               <v-list-item-content :key="key" style="overflow: unset">
                 <v-list-item-title style="white-space: unset; word-break: break-word">
                   <span v-html="get_date(item.date)"></span>
                 </v-list-item-title>
                 <v-list-item-subtitle class="black--text font-size--14 medium--text">
                   <v-icon color="pastel" class="hidden-xs-only">mdi-chevron-right</v-icon>
                   <span style="white-space: pre-wrap" v-html="item.text"></span>
                 </v-list-item-subtitle>
               </v-list-item-content>
             </v-list-item-group>
           </v-list>
         </template>
       </PrCardComponent>
    </v-col>

    <DialogVMAPatientDocuments :dialog.sync="dialog_documents"
                               :patient_id="$route.params.id"/>

  </v-row>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import moment from "moment-timezone";
import {get_formatted_date} from "anam-library/src/js/dates";
import DialogVMAPatientDocuments from "./DialogVMAPatientDocuments";

export default {
  name: "DpaSummaryVMA",
  components: {
    DialogVMAPatientDocuments,
    PrCardComponent
  },
  props: {
    consultations: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    dialog_documents: false
  }),

  computed: {
    history() {
      let history = [];
      for (let consultation of this.consultations) {
        let motif = this.$store.getters.getMotifName(consultation.consultation_reason);
        if (consultation.creation_date) {
          history.push({date: consultation.creation_date_iso, text: `Prise de RDV <span class="secondary--text">${consultation.place} (${motif})</span>`});
        }
        if (consultation.closed) {
          history.push({date: consultation.closed_date_iso, text: `<span class="green--text semibold--text">Visite médicale</span> <span class="secondary--text">${consultation.place} (${motif})</span> ${consultation.aptitude ? "=> <span class='semibold--text'>Aménagement</span>" : ""}`});
        }
        if (consultation.cancelled) {
          history.push({date: consultation.cancelled, text: `<span class="red--text semibold--text">Annulation</span> du RDV <span class="secondary--text">${consultation.place} (${motif})</span>`});
        }
      }
      return history.sort((a, b) => b.date - a.date);
    },

    next_rdv() {
      let next_consult = null;
      if (this.consultations.length) {
        let upcoming_consultations = this.consultations.filter(x => x.cancelled === null && x.closed === false && x.slot && moment(x.slot.start_slot) > moment());
        if (upcoming_consultations.length) {
          upcoming_consultations.sort((a, b) => moment(a.slot.start_slot) - moment(b.slot.start_slot));
          next_consult = upcoming_consultations[0];
        }
      }
      return next_consult;
    },
    date_next_rdv() {
      if (!this.next_rdv) return null;
      return get_formatted_date(this.next_rdv.slot.start_slot);
    },
    next_rdv_consult_type() {
      if (!this.next_rdv) return null;
      return this.$store.getters.getMotifName(this.next_rdv.consultation_reason);
    },
    latest_visit() {
      let latest_visit = null;
      if (this.consultations.length) {
        let visits = this.consultations.filter(x => x.closed === true);
        if (visits.length) {
          visits.sort((a, b) => moment(a.closed_date) - moment(b.closed_date));
          latest_visit = visits[0];
        }
      }
      return latest_visit;
    },
    latest_visit_consult_type() {
      if (!this.latest_visit) return null;
      return this.$store.getters.getMotifName(this.latest_visit.consultation_reason);
    },
    date_latest_visit() {
      if (!this.latest_visit) return null;
      return get_formatted_date(this.latest_visit.closed_date);
    },
  },

  methods: {
    get_date(date) {
      return get_formatted_date(date);
    }
  }
}
</script>

<style scoped>

</style>
