export const AMENAGEMENT_CHOICES = [
  {value: "1", text: "Étalement de scolarité (2ans au lieu de 1 an) pour des raisons médicales"},
  {value: "2", text: "Composition en salle isolée pour les examens et rattrapages"},
  {value: "3", text: "Temps majoré : tiers temps pour tous les examens et/ou rattrapages"},
  {value: "4", text: "Mise à disposition d'un ordinateur de l'école pour les examens et/ou rattrapages"},
  {value: "5", text: "Possibilité d’utiliser son propre ordinateur pendant les cours"},
  {value: "6", text: "Augmentation police de caractère pour les examens et/ou rattrapages"},
  {value: "7", text: "Possibilité d'utilisation calculatrice simple pour les examens de calcul et/ou rattrapages"},
  {value: "8", text: "Choix de campus d’accueil pour raisons médicales"},
  {value: "9", text: "Pas de pénalités d’absence en cours pour des raisons médicales sous présentation de justificatif"},
  {value: "10", text: "Possibilité d’un encas sucré pendant les examens et/ou rattrapages pour des raisons médicales"},
  {value: "11", text: "Report intégration / semestre pour raisons médicales"},
  {value: "12", text: "Temps de pause avec report de temps en fin d'épreuve pour raisons médicales : Entre 5 et 10 minutes"},
  {value: "13", text: "Possibilité d'utiliser des formules mathématiques, séparées de la copie, après l’accord des professeurs"},
  {value: "14", text: "Sanitaires à proximité de la salle examen"},
  {value: "15", text: "Absence aux examens sans pénalités pour raisons médicales"},
  {value: "16", text: "Report du rendu des travaux et/ou mémoire de fin d'études pour des raisons médicales"},
  {value: "17", text: "Pas de pénalité pour les fautes d'orthographe pour les examens et/ou rattrapages"},
  {value: "18", text: "Report des examens pour raisons médicales sans pénalités"},
  {value: "19", text: "Possibilité d’utiliser un logiciel de dictée ou son propre logiciel adapté à son handicap, non fourni par l'école"},
  {value: "20", text: "Possibilité d’utiliser les ascenseurs"},
  {value: "21", text: "Possibilité d'utiliser du matériel médical ou de prendre un traitement pendant les cours/examens/rattrapages pour des raisons médicales"},
  {value: "22", text: "Possibilité de sortir pendant les cours et/ou examens/rattrapages pour des raisons médicales"},
  {value: "23", text: "Arrivée retardée sur le campus d’accueil pour raisons médicales et poursuite de soins spécifiques"},
  {value: "24", text: "Possibilité de réaliser un stage professionnel de fin d’année après avis médical"},
  {value: "25", text: "Adaptation des sujets pour examens et/ou rattrapages (Agrandissement A4 en A3, type et taille de police Arial 14-16 interlignes)"},
  {value: "26", text: "Impossibilité de se rendre à l'étranger pour des raisons médicales"},
  {value: "27", text: "Matériel pour malvoyants"},
  {value: "28", text: "Accès à une salle de repos"},
  {value: "29", text: "Possibilité d'apporter son propre  clavier d'ordinateur à brancher à l'ordinateur de l'école pour les examens et rattrapages"},
]

export const AMENAGEMENT_DURATION_CHOICES = [
  {value: 1, text: "Année académique 22/23"},
  {value: 4, text: "Année académique 23/24"},
  {value: 5, text: "Année académique 24/25"},
  {value: 6, text: "Année académique 25/26"},
  {value: 2, text: "Semestre en cours"},
  {value: 3, text: "Autre"},
]

export const HANDICAP_CHOICES = [
  {value: "1", text: "Handicap moteur"},
  {value: "2", text: "Maladies invalidantes"},
  {value: "3", text: "Handicap psychique"},
  {value: "4", text: "Handicap auditif"},
  {value: "5", text: "Handicap mental"},
  {value: "6", text: "Handicap cognitif"},
  {value: "7", text: "Handicap visuel"},
]

export const APTITUDE_CHOICES = [
  {value: 1, text: "Oui"},
  {value: 2, text: "Non"},
  {value: 3, text: "En attente"},
]

export const HANDICAP_PRECISION_CHOICES = [
  {value: "11", text: "Lombalgies", handicap_type: "1"},
  {value: "12", text: "TMS, rhumatisme", handicap_type: "1"},
  {value: "13", text: "Paralysie", handicap_type: "1"},
  {value: "14", text: "AVC", handicap_type: "1"},
  {value: "15", text: "Autre", handicap_type: "1"},
  {value: "21", text: "Hypertention", handicap_type: "2"},
  {value: "22", text: "Insuffisance cardiaque", handicap_type: "2"},
  {value: "23", text: "Diabète", handicap_type: "2"},
  {value: "24", text: "Allergie", handicap_type: "2"},
  {value: "25", text: "Eczéma", handicap_type: "2"},
  {value: "26", text: "Cancer", handicap_type: "2"},
  {value: "27", text: "Épilepsie", handicap_type: "2"},
  {value: "28", text: "Autre", handicap_type: "2"},
  {value: "31", text: "Névrose", handicap_type: "3"},
  {value: "32", text: "TOC", handicap_type: "3"},
  {value: "33", text: "Phobies", handicap_type: "3"},
  {value: "34", text: "Addictions", handicap_type: "3"},
  {value: "35", text: "Dépression", handicap_type: "3"},
  {value: "36", text: "Autre", handicap_type: "3"},
  {value: "41", text: "Acouphène", handicap_type: "4"},
  {value: "42", text: "Surdité légère", handicap_type: "4"},
  {value: "43", text: "Surdité profonde", handicap_type: "4"},
  {value: "44", text: "Autre", handicap_type: "4"},
  {value: "51", text: "Trisomie 21", handicap_type: "5"},
  {value: "52", text: "Syndrome fragile", handicap_type: "5"},
  {value: "53", text: "Autre", handicap_type: "5"},
  {value: "61", text: "Trouble de déficit de l'attention", handicap_type: "6"},
  {value: "62", text: "Hyperactivité", handicap_type: "6"},
  {value: "63", text: "Troubles DYS", handicap_type: "6"},
  {value: "64", text: "Troubles du spectre autistique", handicap_type: "6"},
  {value: "65", text: "Autre", handicap_type: "6"},
  {value: "71", text: "Myopie", handicap_type: "7"},
  {value: "72", text: "Presbytie", handicap_type: "7"},
  {value: "73", text: "Strabisme", handicap_type: "7"},
  {value: "74", text: "Rétinite", handicap_type: "7"},
  {value: "75", text: "Daltonisme", handicap_type: "7"},
  {value: "76", text: "Autre", handicap_type: "7"},
]

export const DURATION_CHOICES = [
  {value: "1", text: "Maladie de courte durée"},
  {value: "2", text: "Maladie de longue durée"},
]


export function get_amenagement_type(item) {
  let text = "";
  if (item.amenagement_type) {
    for (let amenagement_type of item.amenagement_type) {
      let index = AMENAGEMENT_CHOICES.findIndex(x => x.value === amenagement_type);
      if (index > -1) text += `${AMENAGEMENT_CHOICES[index].text}\n`;
    }
  }
  return text;
}

const DICT_RESSOURCES = {
  "ESCP": [
    {
      name: "vma.ressources_flyer",
      logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/ESCP/DisabilityProcedureFlyerMini.png",
      url: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/ESCP/DisabilityProcedureFlyer.pdf"
    },
    {
      name: "vma.ressources_charte",
      logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/ESCP/CharteMissionHandicapMini.png",
      url: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/ESCP/CharteMissionHandicap.pdf"
    },
  ],
  "default": []
}

export function get_ressources(agency_name) {
  if (Object.keys(DICT_RESSOURCES).includes(agency_name)) return DICT_RESSOURCES[agency_name];
  return DICT_RESSOURCES.default;
}


export const DICT_INDICATORS_CONSULTATION = {
  "Vaccination": {
    "df382041bae84c2b8464e1d80f586e08": {
      "name": "Vaccinations à jour",
      "type_ind": "RiskFactor"
    }
  },
  "Situation médicale affectant votre scolarité": {
    "53827f3bb933466ba8b882d113878caa": {
      "name": "Antécédents de santé notable",
      "type_ind": "RiskFactor"
    },
    "45917": {
      "name": "Troubles du sommeil",
      "type_ind": "RiskFactor"
    },
    "SnIp1878215544784228965058": {
      "name": "Difficultés relationnelles",
      "type_ind": "Symptom"
    },
    "99b62560b3f54a66bffd0dc4df2ef3e8": {
      "name": "Fatigue",
      "type_ind": "Symptom"
    },
    "53060": {
      "name": "Trouble de concentration",
      "type_ind": "Symptom"
    },
    "f2e27d62df184814a25c6955f131fc5f": {
      "name": "Trouble de comportement alimentaire",
      "type_ind": "RiskFactor"
    }
  },
  "Demande d'aménagement de scolarité": {
    "1a2a4857c74f4f5980560864860d97b6": {
      "name": "Situation de handicap",
      "type_ind": "RiskFactor"
    },
    "e8e91d23b902480f9152518e09e2e881": {
      "name": "RQTH",
      "type_ind": "RiskFactor"
    }
  },
  "Mode de vie": {
    "1052": {
      "name": "Alcool",
      "type_ind": "RiskFactor"
    },
    "50813": {
      "name": "Tabac",
      "type_ind": "RiskFactor"
    },
    "SnIp280631525787839856325": {
      "name": "Drogues",
      "type_ind": "RiskFactor"
    }
  },
  "Suivi": {
    "17c00e8949a14a89baa34cd9cf8c950a": {
      "name": "Suivi dentaire régulier",
      "type_ind": "RiskFactor"
    },
    "82a87b59cd2a47ca9595414387276fa5": {
      "name": "Suivi ophtalmologique régulier",
      "type_ind": "RiskFactor"
    },
    "37e6001c7884410fbf8fda9aecb55989": {
      "name": "Suivi dermatologique régulier",
      "type_ind": "RiskFactor"
    },
    "9ea907ee4dfb48408addca72919a5ad3": {
      "name": "Suivi gynécologique régulier",
      "type_ind": "RiskFactor"
    }
  },
  "Antécédents familiaux": {
    "46644": {
      "name": "Cardiaque (Hypertension artérielle, ...)",
      "type_ind": "RiskFactor"
    },
    "7e1e6cd921b84cea977db068cf99e00f": {
      "name": "Digestif : maladie inflammatoire",
      "type_ind": "RiskFactor"
    },
    "SnIp2268615239902117690768": {
      "name": "Pulmonaire",
      "type_ind": "RiskFactor"
    },
    "e7903297079f421d9ceb7da281a09377": {
      "name": "Cancer",
      "type_ind": "RiskFactor"
    },
    "SnIp292371532528671880442": {
      "name": "Psychologique",
      "type_ind": "RiskFactor"
    }
  }
}
