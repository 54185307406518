const DICT_PATIENT_DATA_CEPHISMER = {
  items_boolean: [
    {value: 1, text: "Oui"},
    {value: 2, text: "Non"},
  ],
  items_birth_year: Array.from({length: new Date().getFullYear() - 1950},
    (value, index) => 1951 + index),
  items_service: [
    {value: 1, text: "Marine"},
    {value: 2, text: "Armée de l'air et de l'Espace"},
    {value: 3, text: "Armée de Terre"},
    {value: 4, text: "Service de Santé des Armées"},
    {value: 5, text: "Gendarmerie"},
    {value: 6, text: "Autre"},
  ],
  items_diver_before_internship: [
    {value: 1, text: "Jamais"},
    {value: 2, text: "Rarement (moins de cinq plongées par an)"},
    {value: 3, text: "Parfois (entre six et dix plongées par an)"},
    {value: 4, text: "Souvent (entre dix et vingt plongées par an)"},
    {value: 5, text: "Très souvent (plus de vingt plongées par an)"},
    {value: 6, text: "Je ne suis pas stagiaire à l'Ecoplong"},
  ],
  items_symptoms: [
    {value: 1, text: "Convulsion"},
    {value: 2, text: "Hyperventilation"},
    {value: 3, text: "Difficulté à maintenir la profondeur stable"},
    {value: 4, text: "Maux de tête"},
    {value: 5, text: "Altération visuelle (réduction du champs)"},
    {value: 6, text: "Confusion"},
    {value: 7, text: "Vertige"},
    {value: 8, text: "Désorientation"},
    {value: 9, text: "Contraction des muscles faciaux"},
    {value: 10, text: "Altération auditive"},
    {value: 11, text: "Nausées"},
    {value: 12, text: "Perte d’attention"},
    {value: 13, text: "Anxiété"},
    {value: 14, text: "Faiblesse"},
    {value: 15, text: "Sensation d’étouffement"},
    {value: 16, text: "Frissons"},
    {value: 17, text: "Pâleur"},
    {value: 18, text: "Sensation de chaleur faciale"},
    {value: 19, text: "Vomissement"},
    {value: 20, text: "Rien de la liste"},
  ]
}

export function get_patient_data_cephismer(type) {
  if (Object.keys(DICT_PATIENT_DATA_CEPHISMER).includes(type)) {
    return DICT_PATIENT_DATA_CEPHISMER[type];
  }
  return [];
}

export const LIST_SCORES_ETUDE = [
    {text: "Epuisement", short_text: "Epuisement", name: "var_epui_cephismer", value: 0, q_a: []},
    {text: "Distance mentale", short_text: "Distance mentale", name: "var_dismen_cephismer", value: 0, q_a: []},
    {text: "Déficience cognitive", short_text: "Déficience cognitive", name: "var_defco_cephismer", value: 0, q_a: []},
    {text: "Déficience émotionnelle", short_text: "Déficience émotionnelle", name: "var_defemo_cephismer", value: 0, q_a: []},
    {text: "Plainte psychique", short_text: "Plainte psychique", name: "var_planps_cephismer", value: 0, q_a: []},
    {text: "Plainte psychomatique", short_text: "Plainte psychomatique", name: "var_plansom_cephismer", value: 0, q_a: []},
]

export function get_score_name(name) {
  let index = LIST_SCORES_ETUDE.findIndex(x => x.name === name);
  if (index > -1) return LIST_SCORES_ETUDE[index].text;
  return name;
}

export function get_color_score(type, score) {
  return "black"; // TODO CEPHISMER
}
