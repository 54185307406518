<template>
  <v-icon v-if="item[variable] === undefined" small>mdi-minus</v-icon>
	<v-chip v-else :color="item[variable] ? 'red' : 'white'" :class="item[variable] ? 'font-weight-bold' : ''">{{item[variable]}}</v-chip>
</template>

<script>
  export default {
    name: "AlertItem",
    props: ['item', 'variable'],
  }
</script>

<style scoped>

</style>
