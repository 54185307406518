<template>
    <span> {{ get_value_to_display(item[variable]) }} </span>
</template>

<script>
export default {
  name: "StatusItem",
  props: ['item', 'variable'],
  methods: {
    get_value_to_display(data, _store=this.$store) {
      return _store.getters.GetStatusName(data);
    }
  }
}
</script>
