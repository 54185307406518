<template>
  <v-container fluid class="pa-6">
    <v-row class="mx-0">
      <v-col cols="12">
        <div class="d-flex">
<!--          TODO MKT : chercher l'image-->
<!--          <img :src="logo" width="50" height="auto" class="rounded-lg mr-4" alt="Logo"/>-->
          <v-icon color="secondary" large class="mr-2">mdi-cog</v-icon>
          <span class="secondary--text bold--text font-size--24 align-self-center">{{ $t("vma.settings_title") }}</span>
        </div>
        <v-divider class="mt-5" inset></v-divider>
      </v-col>
      <v-col cols="12">
        <div class="text-left">
          <span class="bold--text primary--text mr-2">{{ $t("vma.profile")}}</span>
          <span :class="`bold--text ${color_progression}--text`">{{ progression }}%</span>
        </div>
        <v-progress-linear v-model="progression" height="15" :color="color_progression" background-color="white" rounded></v-progress-linear>
        <p class="basic--text medium--text mt-3" v-if="progression < 100">
          {{ $t(profile_text) }}
        </p>
      </v-col>
      <v-col cols="12" sm="5">
        <v-form v-model="form_settings" autocomplete="off">
          <AnTextField :value.sync="sex" :title="$t('common.Sexe')" title_color="basic" :placeholder="$t('common.select')" type_field="sexe" icon=""/>
          <AnDate :date="birthdate"
                  :title="$t('common.Date de naissance')"
                  required is_birthdate show_title icon="" color_title="basic"
                  v-on:formatted_date="birthdate=$event"/>
          <AnTextField :value.sync="email" :title="$t('common.email')" :placeholder="$t('common.email')" required icon="" type_field="email" title_color="basic"/>
          <AnTextField :value.sync="postcode" :title="$t('common.Code Postal')" :placeholder="$t('common.Code Postal')" icon="" type_field="postcode" title_color="basic"/>
          <AnTextField :value.sync="height" :title="$t('common.Taille')" :placeholder="$t('common.Taille')" icon="" type_field="taille" title_color="basic"/>
          <AnTextField :value.sync="weight" :title="$t('common.Poids')" :placeholder="$t('common.Poids')" icon="" type_field="poids" title_color="basic"/>

          <AnTelInputVuetify
            :initial_number="phone_number"
            :title="$t('common.phone_number')"
            :countries_restrictions="['fr']"
            :required="phone_required" filled
            v-on:update_phone="phone_number = $event"
          />
        </v-form>
      </v-col>
      <v-col cols="12" sm="5" offset-sm="1">
        <template v-if="main_affectation">
          <div class="my-2">
            <span class="basic--text font-size--16 semibold--text">{{(text_niveau_4)}}:</span>
            <span class="mx-5 medium--text">{{ main_affectation.name }}</span>
          </div>
          <div class="my-2" v-if="show_secondary_affectation">
            <span class="basic--text font-size--16 semibold--text">{{(text_niveau_4)}} {{ $t("vma.secondaire") }}:</span>
            <span class="mx-5 medium--text">{{ $t(secondary_affectation ? secondary_affectation : "vma.none") }}</span>
          </div>
          <v-alert type="info" text>
            {{ $t("vma.settings_info_1") }}
          </v-alert>
        </template>
        <template v-else-if="show_affectations">
          <div class="pl-1 font-size--16 medium--text basic--text">{{ $t("vma.settings_info_2") }}</div>
          <v-autocomplete v-model="choose_main_affectation" :items="affectations" filled :placeholder="$t('common.select')"></v-autocomplete>
          <div class="pl-1 font-size--16 medium--text basic--text">{{ $t("vma.settings_info_3") }}</div>
          <v-autocomplete v-model="choose_secondary_affectation" :placeholder="$t('common.select')" :items="get_affectations()" filled></v-autocomplete>
        </template>

        <v-divider v-if="show_affectations"></v-divider>

        <template v-else-if="show_rank_job_site">
          <div class="pl-1 font-size--16 medium--text basic--text">Grade</div>
          <v-select v-model="rank" :items="items_rank" :placeholder="$t('common..select')" filled></v-select>
          <div class="pl-1 font-size--16 medium--text basic--text">Métier</div>
          <v-select  v-model="job" :items="items_job" :placeholder="$t('common.select')" filled></v-select>
          <div class="pl-1 font-size--16 medium--text basic--text">Site</div>
          <v-select  v-model="site" :items="items_site" :placeholder="$t('common.select')" filled></v-select>
        </template>

        <template v-else-if="show_patient_status">
          <div class="pl-1 font-size--16 medium--text basic--text">Statut</div>
          <v-select v-model="status" :items="items_status" :placeholder="$t('common.select')" filled></v-select>
        </template>
      </v-col>

      <v-col cols="12" class="mt-6">
        <v-alert v-if="weight_height_needed" type="error" text>{{ $t("vma.alert_info") }}</v-alert>
        <p>{{ $t("vma.setting_save_infos")}} :</p>
        <CguCheckbox :cgu.sync="cgu" :confidentiality.sync="confidentiality" :agency="$store.getters.getAgencyName" app_name="prevana" :form_required="true"/>
      </v-col>

      <v-col cols="12" class="text-center">
        <v-btn @click="save_settings()" color="secondary" class="px-10" large elevation="0"
               :disabled="validate_disabled"
               :loading="loading_settings">
          <span class="medium--text">{{ $t("vma.save_btn") }}</span>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-alert v-if="settings_success" dense text type="success">
          <span>{{ $t("vma.save_success")}}</span>
        </v-alert>
        <v-alert v-if="settings_error" dense text type="error">
          <span>{{ $t("vma.save_failed") }}</span>
        </v-alert>
        <v-alert v-if="settings_username_error" dense text type="error">
          <span>{{ $t("vma.mail_exists")}}</span>
        </v-alert>
        <v-alert v-if="settings_parameters_error" dense text type="error">
          <span>{{ $t("vma.infos_invalid") }}</span>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import CguCheckbox from "@/components/common/CguCheckbox";
import AnDate from "anam-library/src/components/an-date/AnDate";
import AnTextField from "anam-library/src/components/an-ui/textfield/AnTextField";
import AnTelInputVuetify from "anam-library/src/components/an-ui/phone/AnTelInputVuetify";
import {get_profile_text} from "@/data/rps/text_by_agency";
import {getCookie} from "../../../main";
import {get_terminology} from "anam-library/src/data/dict_terminology_by_agency";
import {
  get_list_category_by_agency,
  get_list_job_by_agency,
  get_list_rank_by_agency,
  get_list_site_by_agency
} from "anam-library/src/data/dict_categories_pro";
import {show_affectations, show_rank_job_site, show_patient_status, show_associated_profiles, patient_phone_required} from "prevana-library/src/js/dict_permissions";

export default {
    name: "SettingsPatient",
    components: {CguCheckbox, AnDate, AnTextField, AnTelInputVuetify},
    data: () => ({
      form_settings: false,
      sex: null,
      birthdate: null,
      email: "",
      postcode: "",
      height: "",
      weight: "",
      rank: null,
      job: null,
      site: null,
      status: null,
      phone_number: "",
      loading_settings: false,
      settings_success: false,
      settings_error: false,
      settings_parameters_error: false,
      settings_username_error: false,
      main_affectation: null,
      secondary_affectation: null,
      choose_main_affectation: null,
      choose_secondary_affectation: null,
      affectations: [],
      cgu: false,
      confidentiality: false,
    }),
    computed: {
      current_agency() {
        return this.$store.getters.getCurrentAgency;
      },
      validate_disabled() {
        return !this.form_settings || (!this.phone_number && this.phone_required) || !this.cgu || !this.confidentiality || this.weight_height_needed;
      },
      text_niveau_4() {
        return get_terminology("niveau_4", this.$store.getters.getCurrentAgency);
      },
      weight_height_needed() {
        return this.$store.getters.getPatientHasAccessCovidapp && (!this.height || !this.weight);
      },
      items_rank() {
        return get_list_rank_by_agency(this.current_agency);
      },
      items_job() {
        return get_list_job_by_agency(this.current_agency);
      },
      items_site() {
        return get_list_site_by_agency(this.current_agency);
      },
      items_status() {
        return get_list_category_by_agency(this.current_agency);
      },
      progression() {
        let patient_data = this.$store.getters.getPatientData;
        let nb_of_info = 0;
        if (patient_data.last_name) nb_of_info++;
        if (patient_data.first_name) nb_of_info++;
        if (patient_data.email) nb_of_info++;
        if (patient_data.personnephysique.sexe) nb_of_info++;
        if (patient_data.personnephysique.tailleCorporelle) nb_of_info++;
        if (patient_data.personnephysique.poidsCorporel) nb_of_info++;
        if (patient_data.personnephysique.birthdate) nb_of_info++;
        if (patient_data.personnephysique.adresse && patient_data.personnephysique.adresse.codePostal) nb_of_info++;
        if (this.$store.getters.getPatientCgu) nb_of_info++;
        if (this.$store.getters.getPatientConfidentiality) nb_of_info++;
        return ((nb_of_info / 10) * 100).toFixed(0);
        },
      color_progression() {
        if (this.progression < 50) return 'red';
        else if (this.progression < 80) return 'orange';
        else if (this.progression < 100) return 'lime';
        else return 'green';
      },
      profile_text() {
        return get_profile_text(this.$store.getters.getAgencyName);
      },
      show_affectations() {
        return show_affectations(this.current_agency.of_type);
      },
      show_secondary_affectation() {
        return !show_associated_profiles(this.current_agency.name) && this.current_agency.of_type === "SDIS";
      },
      show_rank_job_site() {
        return show_rank_job_site(this.current_agency.name);
      },
      show_patient_status() {
        return show_patient_status(this.current_agency.name);
      },
      phone_required() {
        return patient_phone_required(this.current_agency.name);
      }
    },
    watch: {
      choose_main_affectation(val) {
        if (val === this.choose_secondary_affectation) this.choose_secondary_affectation = null;
      }
    },
    mounted() {
      let patient_data = this.$store.getters.getPatientData;
      this.email = patient_data.email;
      if (patient_data.personnephysique) {
        this.sex = patient_data.personnephysique.sexe;
        this.height = patient_data.personnephysique.tailleCorporelle;
        this.weight = patient_data.personnephysique.poidsCorporel;
        this.phone_number = patient_data.personnephysique.personel_phone || "";
        if (patient_data.personnephysique.birthdate) {
          this.birthdate = patient_data.personnephysique.birthdate.birthdate;
        }
        if (patient_data.personnephysique.adresse) {
          this.postcode = patient_data.personnephysique.adresse.codePostal;
        }
      }
      if (patient_data.patient) {
        this.rank = patient_data.patient.rank;
        this.job = patient_data.patient.job;
        this.site = patient_data.patient.site;
        this.status = patient_data.patient.categorie ? parseInt(patient_data.patient.categorie) : null;
        this.main_affectation = patient_data.patient.main_affectation;
      }
      this.cgu = !!this.$store.getters.getPatientCgu;
      this.confidentiality = !!this.$store.getters.getPatientConfidentiality;
    },
    methods: {
      save_settings() {
        this.loading_settings = true;
        let data = {
          email: this.email,
          personnephysique: {
            sexe: this.sex,
            personel_phone: this.phone_number,
            birthdate: {
              birthdate: this.birthdate,
            },
            adresse: {
              codePostal: this.postcode
            }
          },
          patient: {
            rank: this.rank,
            job: this.job,
            site: this.site,
            categorie: this.status
          }
        };
        if (this.weight) data.personnephysique.poidsCorporel = this.weight;
        if (this.height) data.personnephysique.tailleCorporelle = this.height;
        axios.patch("/api/save_patient_settings", data, {headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(response => {
          this.settings_success = true;
          this.settings_error = false;
          this.settings_username_error = false;
          this.settings_parameters_error = false;
          this.$store.commit("setPatientData", response.data);
          if (this.choose_main_affectation) this.save_affectations();
          else this.loading_settings = false;
          if (!this.$store.getters.getPatientCgu || !this.$store.getters.getPatientConfidentiality) {
            this.$http.patch("/api/save_cgu_and_confidentialite").then(() => {});
          }
        }).catch(error => {
          if (error.response.status === 400) this.settings_username_error = true;
          else if (error.response.status === 422) this.settings_parameters_error = true;
          else this.settings_error = true;
          this.settings_success = false;
          this.loading_settings = false;
        })
      },
      get_affectations() {
        let affectations = [{value: null, text: "Aucune"}];
        for (let el of this.affectations) {
          if (el.value !== this.choose_main_affectation) affectations.push(el);
        }
        return affectations;
      },
      save_affectations() {
        let dict_update = {main_affectation: this.choose_main_affectation};
        if (this.choose_secondary_affectation) dict_update.secondary_affectation = this.choose_secondary_affectation;
        axios.patch("/api/update_affectations", dict_update, {headers: {"X-CSRFToken": getCookie("csrftoken")}}).then(response => {
          this.main_affectation = response.data.main_affectation;
          if (this.choose_secondary_affectation) this.secondary_affectation = response.data.secondary_affectation;
          else this.loading_settings = false;
        }).catch(() => {
          this.snackbar_error = true;
          this.loading_settings = false;
        })
      },
      get_list_affectations() {
        axios.get("/api/list_affectations").then(response => {
          for (let el of response.data) {
            this.affectations.push({value: el.name, text: el.com_name + " (" + el.grp_name + ")"});
          }
          this.affectations.unshift({value: null, text: "Aucune"});
        }).catch(() => {
          this.snackbar_error = true;
        })
      }
    }
  }
</script>

<style scoped>
.v-progress-linear{
  border-radius: 50px;
}
.vue-tel-input{
  background-color: #d6ddde;
}
</style>
