<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>
    <v-row class="ma-2 ma-sm-5">
        <v-col cols="12" sm="8" offset-sm="2" class="mt-10">
          <PrCardComponent title="Questionnaire terminé" icon="mdi-calendar-check" color="secondary">
            <template v-slot:card-title-append>
              <p class="normal--text black--text font-size--14 mt-5 text-break">
                Nous vous remercions d’avoir répondu à ce questionnaire de suivi.
                <br/>
                Votre centre de vaccination ({{ agency_name }}) reviendra vers vous si nécessaire.
                <br/>
                Si votre situation change, vous pourrez revenir à tout moment et répondre à ce questionnaire pour mettre à jour votre déclaration.
              </p>
              <v-col cols="12" class="text-center">

                <ButtonBackDashboard redirection="PatientVaccinationDashboard"/>
              </v-col>
            </template>
          </PrCardComponent>
        </v-col>
    </v-row>
  </div>
</template>

<script>
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import ButtonBackDashboard from "../../components/ButtonBackDashboard";

export default {
  name: "EndQuestionnaireSuivi",
  components: {ButtonBackDashboard, PrBreadcrumbsComponent, PrCardComponent},
  data: () => ({
    navigations: [
      {text: 'Vaccination', to: '/patient/vaccination/dashboard'},
      {text: 'Fin de suivi', to: '/patient/vaccination/end_questionnaire_suivi'},
    ]
  }),
  computed: {
    agency_name() {
      return this.$store.getters.getAgencyName;
    }
  }
}
</script>
