<template>
  <div class="mb-8">
    <AnOverlayLoading :is_mounted="!launch_questionnaire"/>
    <BlocPresentation/>
    <img src="@/assets/bg2.png" width="100%" class="hidden-xs-only"/>
    <BlocExplication/>
    <BlocInjection/>
    <img src="@/assets/bg2.png" width="100%" class="hidden-xs-only">
    <BlocRisque/>
    <BlocLinks/>
    <BlocConsentement :launch_questionnaire.sync="launch_questionnaire" v-on:connexion="$emit('connexion')"/>
  </div>
</template>

<script>
  import AnOverlayLoading from "anam-library/src/components/an-ui/loading/AnOverlayLoading";
  import BlocPresentation from "./BlocPresentation";
  import BlocExplication from "./BlocExplication";
  import BlocInjection from "./BlocInjection";
  import BlocRisque from "./BlocRisque";
  import BlocLinks from "./BlocLinks";
  import BlocConsentement from "./BlocConsentement";

  export default {
    name: "VaccinationConsentementView",
    components: {
      AnOverlayLoading,
      BlocPresentation,
      BlocExplication,
      BlocInjection,
      BlocRisque,
      BlocLinks,
      BlocConsentement
    },
    data: () => ({
      launch_questionnaire: false
    })
  }
</script>

<style scoped>

</style>
