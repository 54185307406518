<template>
  <v-container fluid class="pa-6">
    <v-row class="mx-0">
      <v-col cols="12">
        <div class="d-flex">
<!--          TODO MKT : chercher l'image-->
<!--          <img :src="logo" width="50" height="auto" class="rounded-lg mr-4" alt="Logo Bien-Être"/>-->
          <v-icon color="secondary" large class="mr-2">mdi-folder</v-icon>
          <span class="secondary--text bold--text font-size--24 align-self-center">{{ $t("vma.my_documents") }}</span>
        </div>
      </v-col>
      <v-col cols="12">
        <PrCardComponent title="" icon="">
          <template v-slot:card-text>
            <v-row class="mx-0">
              <v-col cols="12">
                <AnAttachmentFilters :search.sync="search" :filter_type.sync="filter_type"/>
              </v-col>
              <v-col cols="12" class="pt-0">
                <DocumentsNewButton :choose_pds="import_attachment_choose_pds" patient_id="0" v-on:new_document="new_document($event)" :app_name="app_name" />
              </v-col>
              <v-col cols="12">
                <AnAttachmentBloc patient_id="0" :files.sync="files" :search="search" :filter_type="filter_type" :show_author="true" :get_shared_with="true" :app_name="app_name" delete_document_option />
              </v-col>
            </v-row>
          </template>
        </PrCardComponent>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AnAttachmentBloc from "anam-library/src/components/an-attachment/AnAttachmentBloc";
import AnAttachmentFilters from "anam-library/src/components/an-attachment/AnAttachmentFilters";
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import DocumentsNewButton from "@/components/patient/components/documents/DocumentsNewButton";
import {import_attachment_choose_pds} from "prevana-library/src/js/dict_permissions";


export default {
  name: "PatientDocumentsView",
  components: {DocumentsNewButton, PrCardComponent, AnAttachmentBloc, AnAttachmentFilters},
  data: () => ({
    files: [],
    filter_type: null,
    search: null,
  }),
  computed: {
    import_attachment_choose_pds() {
      return import_attachment_choose_pds(this.$store.getters.getAgencyName);
    },
    app_name() {
      if (this.$store.getters.getPatientHasAccessVma) return "vma";
      return "covidapp";
    }
  },
  methods: {
    new_document(document) {
      this.files.unshift(document);
    },
  }
}
</script>

<style scoped>

</style>
