<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>
    <v-row class="ma-2 ma-sm-5">
      <v-col cols="12" sm="8" offset-sm="2" class="mt-10">
        <PrCardComponent :title="title" icon="mdi-calendar-check" color="secondary">
          <template v-slot:card-title-append v-if="!not_eligible">
            <v-spacer></v-spacer>
            <ButtonCancelRDV
              :rdv_id="id"
              v-on:rdv_cancelled="back_to_dashboard()"
              :small="false"
            ></ButtonCancelRDV>
          </template>
          <template v-slot:card-text>
            <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
            <template v-if="not_eligible">
              <p class="medium--text black--text mt-5">
                La prise de RDV est ouverte par votre centre de prévention.
                <br/>
                Si vous souhaitez prendre RDV, votre centre de prévention sera informé et vous enverra un mail vous donnant accès au choix du créneau.
              </p>
              <v-divider></v-divider>
              <p class="text-center semibold--text primary--text my-5">
                Souhaitez-vous informer votre centre de prévention que vous voulez prendre rendez-vous ?
              </p>
            </template>

            <template v-else>
              <p class="medium--text black--text mt-5">Nous vous confirmons votre rendez-vous du
                <span class="primary--text font-size--18 semibold--text">{{ date }}</span>
              </p>
              <p class="medium--text black--text"> Ce rendez-vous aura lieu au
                <span  class="primary--text font-size--18 semibold--text">{{ address }}</span>
              </p>
            </template>

            <div class="text-center mt-5">
              <ButtonBackDashboard redirection="PatientRpsDashboard"/>
              <v-tooltip v-if="not_eligible" bottom :disabled="!wants_consultation">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn :disabled="wants_consultation || loading" color="secondary" elevation="0" class="mr-5 px-6" @click="set_wants_consultation()">
                      Je souhaite prendre RDV
                    </v-btn>
                  </span>
                </template>
                <span>Vous avez déjà indiqué souhaiter prendre RDV</span>
              </v-tooltip>
            </div>
          </template>
        </PrCardComponent>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import ButtonCancelRDV from "../../components/rdv/ButtonCancelRDV";
import ButtonBackDashboard from "../../components/ButtonBackDashboard";

export default {
  name: "PatientRPSAppointmentSummary",
  components: {ButtonBackDashboard, PrBreadcrumbsComponent, PrCardComponent, ButtonCancelRDV},
  data: () => ({
    date: null,
    address: null,
    id: null,
    not_eligible: false,
    wants_consultation: false,
    loading: false,
    navigations: [
        {
          text: 'Bien-Être',
          to: '/patient/rps/dashboard',
        },
        {
          text: 'Rendez-vous',
          to: '/patient/rps/summary'
        },
      ]
  }),

  computed: {
    title() {
      if (this.not_eligible) return "Vous n'êtes pas autorisé à prendre rendez-vous";
      return "Rendez-vous confirmé !";
    }
  },

  created() {
    this.loading = true;
    this.$http.get("/api/rps_appointment_info/0").then(response => {
      if (response.data.has_appointment) {
        this.address = response.data.address;
        this.date = response.data.date;
        this.id = response.data.id;
      }
      else if (!response.data.eligible_to_consultation) {
        this.not_eligible = true;
        this.wants_consultation = response.data.wants_consultation;
      }
      else this.back_to_dashboard();
    }).finally(() => {
      this.loading = false;
    })
  },

  methods: {
    back_to_dashboard() {
      this.$router.push({name: 'PatientRpsDashboard'});
    },

    set_wants_consultation() {
      this.loading = true;
      let data = {wants_consultation: true};
      this.$http.post(`/api/set_wants_consultation`, data).then(() => {
        this.$store.commit('PostSuccess', 'Opération réalisée avec succès');
        this.back_to_dashboard();
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>
