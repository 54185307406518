<template>
  <div>
    <AnDashboard
      v-if="dashboard_data_tables"
      ref="an_dashboard"
      :body="body"
      :tab_items="Object.keys(dashboard_data_tables)"
      :tab_content="dashboard_data_tables"
      :search="$store.state.dashboard_filter.dashboard_search"
      :custom_computed_data="custom_computed_data"
      custom_filter
      :custom_filter_function="custom_filter_rps"
      :show_inactive="show_inactive"
      allow_mass_update
      :allow_export="pds_access_export"
      :mass_update_actions="actions"
      mass_update_key="patient_id"
      v-on:mass_action_click="manage_mass_action_click($event)"
      @move_to_dpa="open_dpa($event, false)"
      @open_dpa_window="open_dpa($event, true)"
      @tab_changed="tab_changed($event)"
    >
      <template v-slot:header>
        <v-card-title>
          <PrPatientSearch ref="patient_search"
                         search_text="dashboard.filters.search_patient.filter"
                         :calendar_type="4"
                         calendar_text="Site de consultation"
                         :filter_list="['place', 'date', 'age', 'category', 'is_eligible', 'wants_consultation', 'service']"
                         v-on:show_inactive="show_inactive = $event"
          />
        </v-card-title>
      </template>
    </AnDashboard>
    <DialogSendEmail :dialog.sync="dialog_send_email" :ids_send_email="ids_send_email"/>
    <PrDialogEmailSetEligible
      :dialog.sync="dialog_set_eligible"
      :data="data_set_eligible"
      v-on:update_mass_action="update_mass_action($event)"
    ></PrDialogEmailSetEligible>
    <AnAppointmentDialogMassCancel
      :dialog.sync="dialog_delete_consult"
      :loading.sync="loading_delete_consult"
      v-on:delete_consults="delete_consults()"
    />
  </div>
</template>

<script>
import moment from "moment-timezone";
import AnDashboard from "anam-library/src/components/an-dashboard/AnDashboard";
import PrPatientSearch from "prevana-library/src/components/common/PrPatientSearch";

import dashboard_data_tables from "@/data/rps/dashboard_data_tables.json";
import SexItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/SexItem";
import DateTimeItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/DateTimeItem";
import ScoreRPSItem from "./dashboard_items/ScoreRPSItem";
import PrRdvWantedItem from "prevana-library/src/components/dpa/PrRdvWantedItem";
import DialogSendEmail from "anam-library/src/components/an-dialogs/DialogSendEmail";
import {LIST_SCORES_RPS, get_score_names} from "anam-library/src/data/scores_rps";
import BooleanItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/BooleanItem";
import PrDialogEmailSetEligible from "prevana-library/src/components/dialogs/PrDialogEmailSetEligible";
import AnAppointmentDialogMassCancel
  from "anam-library/src/components/an-appointments/dialogs/AnAppointmentDialogMassCancel";
import DateTimeItemIsPassed
  from "anam-library/src/components/an-dashboard/an-dashboard-table-items/DateTimeItemIsPassed";
import {get_terminology} from "anam-library/src/data/dict_terminology_by_agency";
import {filter_tabs_by_agency} from "../../../../data/rps/text_by_agency";

export default {
  components: {
    AnDashboard,
    DialogSendEmail,
    PrPatientSearch,
    PrDialogEmailSetEligible,
    AnAppointmentDialogMassCancel
  },
  data() {
    return {
      body: {
        sex: SexItem,
        latest_questionnaire: DateTimeItem,
        score_global: ScoreRPSItem,
        score_rythme_charge: ScoreRPSItem,
        score_orga: ScoreRPSItem,
        score_relation: ScoreRPSItem,
        score_auto: ScoreRPSItem,
        score_sante_bien_etre: ScoreRPSItem,
        score_vie_etudiante: ScoreRPSItem,
        score_intensite_complexite: ScoreRPSItem,
        score_horaire_travail_difficile: ScoreRPSItem,
        score_exigence_emotionnelle: ScoreRPSItem,
        score_faible_autonomie: ScoreRPSItem,
        score_rapports_sociaux: ScoreRPSItem,
        score_conflit_valeur: ScoreRPSItem,
        score_insecurite_emploi: ScoreRPSItem,
        score_contraintes_quantitatives: ScoreRPSItem,
        score_autonomie: ScoreRPSItem,
        score_sante_be: ScoreRPSItem,
        score_orga_leadership: ScoreRPSItem,
        score_relations_horizontales: ScoreRPSItem,
        score_vecu_professionnel: ScoreRPSItem,
        score_contrainte_travail: ScoreRPSItem,
        score_organisation_travail: ScoreRPSItem,
        score_rel_inter_et_leadership: ScoreRPSItem,
        score_rel_travail_employe: ScoreRPSItem,
        score_capital_social: ScoreRPSItem,
        rdv_wanted: PrRdvWantedItem,
        is_eligible: BooleanItem,
        date_next_rdv: DateTimeItemIsPassed
      },
      filter_by_groupement: "",
      filter_by_affectation: "",
      dashboard_data_tables: null,
      search: "",
      search_items_groupements: [{ text: "Tous", value: "all" }],
      affectations_items: [{ text: "Tous", value: "all" }],
      show_inactive: false,
      dialog_send_email: false,
      ids_send_email: [],
      data_set_eligible: {},
      dialog_set_eligible: false,
      dialog_delete_consult: false,
      loading_delete_consult: false,
      consult_ids: [],
    };
  },

  methods: {
    get_dashboard_headers() {
      let tmp = JSON.parse(JSON.stringify(dashboard_data_tables));
      tmp = filter_tabs_by_agency(tmp, this.$store.getters.getAgencyName);
      for (let i in tmp) {
        for (let header of tmp[i].headers) {
          if (header.is_variable) header.text = get_terminology(header.text, this.$store.getters.getCurrentAgency);
        }
        for (let el of LIST_SCORES_RPS) {
          if (el.questionnaire === this.$store.getters.getQuestionnaireName) {
            tmp[i].headers.push({text: this.$t(el.short_text), value: el.name, align: "center", tooltip: el.text});
          }
        }
      }
      this.dashboard_data_tables = tmp;
    },

    async open_dpa(patient, new_window) {
      const path = {name: 'DoctorRPSDpa', params: {id: patient.patient_id }, query: {tab: this.$route.query.tab}}

      if(new_window){
        const routeData = this.$router.resolve(path);
        window.open(routeData.href, "_blank", "resizable");
      } else {
        this.$router.push(path);
      }
    },

    tab_changed(tab) {
      this.$router.push({name: 'RPSDashboard', query: {tab: tab}});
    },

    custom_filter_rps(list) {
      if (!this.$refs.patient_search) return list;
      if (this.$store.state.dashboard_filter.age.length) {
        list = list.filter(x => this.filter_by_age(x.age));
      }
      if (this.$store.state.dashboard_filter.service.length) {
        list = list.filter(x => this.filter_by_service(x.promotion));
      }
      if (this.$store.state.dashboard_filter.is_eligible === true || this.$store.state.dashboard_filter.is_eligible === false) {
        list = list.filter(x => x.is_eligible === this.$store.state.dashboard_filter.is_eligible);
      }
      if (this.$store.state.dashboard_filter.wants_consultation) {
        list = list.filter(x => x.rdv_wanted === this.$store.state.dashboard_filter.wants_consultation);
      }
      if (this.$store.state.dashboard_filter.category) {
        list = list.filter(x => x.category === this.$store.state.dashboard_filter.category);
      }
      if (this.$store.state.dashboard_filter.selected_place) {
        list = list.filter(x => x.consult_location === this.$store.state.dashboard_filter.selected_place);
      }
      if (this.$store.state.dashboard_filter.date) {
        list = list.filter(x => this.filter_by_rdv(x.date_next_rdv));
      }
      if (this.$store.state.dashboard_filter.passed_consultation) {
        list = list.filter(x => x.date_next_rdv && moment(x.date_next_rdv, "YYYY-MM-DD HH:mm").isBefore(moment()));
      }
      return list;
    },

    custom_computed_data(data) {
      for (let patient of data) {
        let nb_scores = 0;
        let score = 0;
        let score_names = get_score_names();
        for (let name in patient) {
          if (score_names.includes(name)) {
            score += patient[name];
            nb_scores++;
          }
        }
        if (nb_scores === 0) patient["score_global"] = null;
        else patient["score_global"] = Math.round(score / nb_scores);

        for (let main_score of LIST_SCORES_RPS) {
          let result = 0;
          let has_score = false;
          for (let score of main_score.scores) {
            result += patient[score.name];
            has_score = true;
          }
          if (has_score) patient[main_score.name] = Math.round(result / main_score.scores.length);
          else patient[main_score.name] = null;
        }
      }
      return data.sort((a, b) => a.matricule - b.matricule);
    },

    filter_by_age(age) {
      for (let filter of this.$store.state.dashboard_filter.age) {
        if (age >= filter[0] && age <= filter[1]) return true;
      }
      return false;
    },

    filter_by_service(service) {
      return this.$store.state.dashboard_filter.service.includes(service);
    },

    filter_by_rdv(date) {
      if (date === null) return false;
      let date_rdv = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
      let date_filter = moment(this.$store.state.dashboard_filter.date, "DD/MM/YYYY").format("YYYY-MM-DD");
      return date_filter === date_rdv;
    },

    manage_mass_action_click(data) {
      this.ids_send_email = [];
      if (data.action === "send_email") {
        for (let patient of data.selected) {
          this.ids_send_email.push(patient.user_id);
        }
        this.dialog_send_email = true;
        return;
      }
      for (let patient of data.selected) {
        this.ids_send_email.push(patient.patient_id);
      }
      if (data.action === "cancel_appointment") {
        this.mass_cancel_appointment(data);
        return;
      }

      this.data_set_eligible = {
        ids_send_email: this.ids_send_email,
        is_eligible: data.action === "mass_set_eligible",
        app: "rps"
      };

      if (data.action === "mass_set_eligible") {
        this.data_set_eligible.title_eligible = this.$t("email.MAIL_RPS_CHANGE_TO_ELIGIBLE_TITLE");
        this.data_set_eligible.text_eligible = this.$t("email.MAIL_RPS_CHANGE_TO_ELIGIBLE_TEXT");
      }
      else if (data.action === "mass_set_non_eligible") {
        this.data_set_eligible.title_non_eligible = this.$t("email.MAIL_RPS_CHANGE_TO_NOT_ELIGIBLE_TITLE");
        this.data_set_eligible.text_non_eligible = this.$t("email.MAIL_RPS_CHANGE_TO_NOT_ELIGIBLE_TEXT");
      }
      this.dialog_set_eligible = true;
    },

    update_mass_action(refresh_patients) {
      if (refresh_patients) this.$refs.an_dashboard.get_patients();
    },

    mass_cancel_appointment(data) {
      this.consult_ids = [];
      for (let resp of data.selected) {
        if (resp.id_next_rdv) this.consult_ids.push(resp.id_next_rdv);
      }
      this.dialog_delete_consult = true;
    },

    delete_consults() {
      this.loading_delete_consult = true;
      this.$http.post("/api/mass_cancel_appointment", {consult_ids: this.consult_ids, app: "rps"}).then(() => {
        this.dialog_delete_consult = false;
        this.consult_ids = [];
        this.$refs.an_dashboard.get_patients();
        this.$store.commit('PostSuccess', 'Opération réalisée avec succès');
      }).finally(() => {
        this.loading_delete_consult = false;
      })
    }
  },
  computed: {
    actions() {
      let actions =  [{value: "send_email", text: "Envoyer un email"}]
      if (this.$store.getters.getCurrentAgency.patient_access_rdv) {
        actions.push(
          {value: "mass_set_eligible", text: "Autoriser à prendre RDV"},
          {value: "mass_set_non_eligible", text: "Ne pas autoriser à prendre RDV"},
          {value: "cancel_appointment", text: "Annuler le RDV"}
        )
      }
      return actions;
    },
    search_items_affectations() {
      if (this.filter_by_groupement === "all" || !this.filter_by_groupement) {
        return this.affectations_items;
      }
      return this.affectations_items.filter(
        (value) =>
          value.groupement === this.filter_by_groupement ||
          value.value === "all"
      );
    },
    pds_access_export() {
      return this.$store.getters.getCurrentAgency.pds_access_export;
    }
  },
  async created() {
    this.get_dashboard_headers();
  },

};
</script>

<style>
</style>
