<template>
  <PrCalendarView app_name="vma"></PrCalendarView>
</template>

<script>
import PrCalendarView from "prevana-library/src/components/calendar/PrCalendarView";

export default {
  name: "HFManagerVMARdvView",
  components: {
    PrCalendarView
  },
}
</script>

<style scoped>

</style>
