<template>
  <div>
    <v-container v-if="signup_validated" class="mt-6 text-center">
      <p class="soft_blue--text font-size--22">
        <v-icon color="success" class="mr-2" large>mdi-check-circle</v-icon>
        Vos informations ont été enegistrées
      </p>
      <p class="soft_blue--text font-size--22 semibold--text">
        Afin de pouvoir prendre RDV, merci de cliquer sur le lien (valable 1 heure) qui vous été envoyé par mail à
        l'adresse suivante : {{ email }}
      </p>
      <p class="soft_blue--text font-size--22">NB : Pensez à vérifier vos spams.</p>
      <v-btn elevation="0" color="soft_blue" large class="white--text px-8 mt-8" @click="goTo('VaccinationHomeView')">
        <span class="font-size--16 medium--text">Retour</span>
      </v-btn>
    </v-container>
    <v-container v-else fluid class="mb-10">
      <v-form ref="form_signup" v-model="valid_form" autocomplete="chrome-off">
        <v-row no-gutters>
          <v-col cols="10" md="8" offset="1" offset-md="2" class="soft_blue--text">
            <v-row>
              <ItemSignupForm :model.sync="health_care_number" label="Numéro de sécurité sociale"
                              :rules="[rules.required, rules.healthcare_number]" name="health_care_number"
                              counter="15"/>
              <v-col cols="12" sm="6" class="py-0" v-if="show_category">
                <span class="medium--text">Catégorie <span class="red--text">*</span></span>
                <v-select v-model="categorie" :items="items_categorie" filled dense color="soft_blue" name="categorie" :rules="[rules.required]"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <ItemSignupForm :model.sync="last_name" label="Nom de naissance" :rules="[rules.required, rules.text]"
                              name="family-name"/>
              <ItemSignupForm :model.sync="maiden_name" label="Nom marital/d'usage (si différent)" :rules="[rules.text]"
                              name="maiden-name" :required="false"/>
            </v-row>
            <v-row>
              <ItemSignupForm :model.sync="first_name" label="Prénom" :rules="[rules.required, rules.text]"
                              name="given-name"/>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <span class="medium--text">Sexe <span class="red--text">*</span></span>
                <AnSelectSexe :sex.sync="sexe" :filled="true" placeholder="Sélectionnez" label="" :dense="true"
                              :required="true"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <AnDate :date="birthdate"
                        class="required"
                        required is_birthdate dense
                        v-on:formatted_date="birthdate=$event"/>
              </v-col>
            </v-row>
            <v-row>
              <ItemSignupForm :model.sync="email" label="Adresse email" :rules="[rules.required, rules.email]"
                              name="new-email"/>
              <ItemSignupForm :model.sync="confirm_email" label="Validez votre adresse email" :rules="[rules.required]"
                              :confirm_item="error_email" confirm_error_txt="Non identique à l'email renseigné"
                              name="new-email-confirm"/>
            </v-row>
            <v-row>
              <ItemSignupForm :model.sync="phone" label="Nº de téléphone portable"
                              :rules="[rules.required, rules.phone_number]" name="tel"/>
            </v-row>
            <v-row>
              <ItemSignupForm :model.sync="password" label="Mot de passe" type="password"
                              :rules="[rules.required, rules.password.min_length, rules.password.lower, rules.password.upper,
                              rules.password.number, rules.password.unauthorized_characters]" name="new-password"/>
              <ItemSignupForm :model.sync="confirm_password" label="Confirmez votre mot de passe"
                              :rules="[rules.required]"
                              type="password" :confirm_item="error_password"
                              confirm_error_txt="Non identique au mot de passe renseigné" name="new-password-confirm"/>
            </v-row>

            <v-row>
              <v-col cols="12" class="soft_blue--text mt-5">
                <p class="font-size--14 mb-0">En validant ce formulaire, vous :</p>
                <CguCheckbox :cgu.sync="cgu" :confidentiality.sync="confidentiality" :agency="$route.params.agency_name" app_name="prevana"
                             :form_required="true"/>
              </v-col>

              <v-col v-if="!valid_form" cols="12" class="soft_blue--text mt-5">
                <p class="semibold--text error--text font-size--16">Vous ne pouvez pas valider votre inscription pour les raisons suivantes :</p>
                <ListItemError v-if="!valid_form" content="Vous n'avez pas rempli tous les champs, ou certaines valeurs sont incorrectes"/>
                <ListItemError v-if="password !== confirm_password" content="Vos mots de passe ne correspondent pas"/>
                <ListItemError v-if="!cgu" content="Vous n'avez pas validé nos Conditions Générales d'Utilisation"/>
                <ListItemError v-if="!confidentiality" content="Vous n'avez pas validé notre Politique de confidentialité"/>
              </v-col>

              <v-col cols="12" class="text-center mt-6">
                <v-btn outlined color="soft_blue" class="px-8 mx-3 mb-3 mb-sm-0" elevation="0"
                       @click="goTo('VaccinationHomeView')">
                  <span class="medium--text font-size--16">Retour</span>
                </v-btn>
                <v-btn color="soft_blue" class="white--text px-5 mx-3" elevation="0" @click="dialog_confirm_infos=true"
                       :disabled="!valid_form">
                  <span class="medium--text font-size--16">Valider mes informations</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-dialog v-model="dialog_confirm_infos" width="600" persistent>
      <v-card>
        <v-card-title class="soft_blue white--text">Confirmation de vos informations</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="primary--text medium--text">Numéro de sécurité sociale
                </v-list-item-subtitle>
                <v-list-item-title class="soft_blue--text semibold--text">{{ health_care_number }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="show_category">
              <v-list-item-content>
                <v-list-item-subtitle class="primary--text medium--text">Catégorie</v-list-item-subtitle>
                <v-list-item-title class="soft_blue--text semibold--text">{{ categorie_txt }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$vuetify.breakpoint.smAndUp">
              <v-list-item-content>
                <v-list-item-subtitle class="primary--text medium--text">Nom de naissance</v-list-item-subtitle>
                <v-list-item-title class="soft_blue--text semibold--text">{{ last_name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle class="primary--text medium--text">Nom marital/d'usage</v-list-item-subtitle>
                <v-list-item-title class="soft_blue--text semibold--text">{{
                    maiden_name || 'Non renseigné'
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <div v-else>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle class="primary--text medium--text">Nom de naissance</v-list-item-subtitle>
                  <v-list-item-title class="soft_blue--text semibold--text">{{ last_name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle class="primary--text medium--text">Nom marital/d'usage</v-list-item-subtitle>
                  <v-list-item-title class="soft_blue--text semibold--text">{{
                      maiden_name || 'Non renseigné'
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="primary--text medium--text">Prénom</v-list-item-subtitle>
                <v-list-item-title class="soft_blue--text semibold--text">{{ first_name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="primary--text medium--text">Sexe</v-list-item-subtitle>
                <v-list-item-title class="soft_blue--text semibold--text">{{ sexe_txt }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="primary--text medium--text">Date de naissance</v-list-item-subtitle>
                <v-list-item-title class="soft_blue--text semibold--text">{{
                    birthdate|birthdate_formatted
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="primary--text medium--text">Email</v-list-item-subtitle>
                <v-list-item-title class="soft_blue--text semibold--text">{{ email }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-row no-gutters class="text-center">
            <v-col cols="12" sm="5" class="mb-3 mb-sm-0">
              <v-btn outlined color="soft_blue" @click="dialog_confirm_infos=false"
                     :large="$vuetify.breakpoint.smAndUp">Modifier mes informations
              </v-btn>
            </v-col>
            <v-col cols="12" sm="7">
              <v-btn color="success" class="white--text px-sm-6" :large="$vuetify.breakpoint.smAndUp" @click="signup()"
                     :loading="loading">
                <v-icon class="mr-2 hidden-xs-only" color="white">mdi-check</v-icon>
                <span class="font-size--16 medium--text">Je confirme mes informations</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_email_already_exist" width="600">
      <v-card>
        <v-card-title class="soft_blue white--text">
          <v-icon color="white" class="pr-3">mdi-information</v-icon>
          Information
        </v-card-title>
        <v-card-text class="pt-6">
          <p class="font-size--18 soft_blue--text semibold--text">
            Il semble qu'un compte utilisant cette adresse mail existe déjà.
          </p>
          <p class="mb-0 font-size--16 normal--text">
            Pour prendre RDV, nous vous invitons à vous connecter en renseignant votre adresse mail et votre mot de
            passe en cliquant sur "Connexion"
          </p>
          <p class="font-size--16 normal--text">Si vous avez oublié votre mot de passe, cliquer sur "Mot de passe
            oublié"</p>
        </v-card-text>
        <v-card-actions class="justify-center pb-4">
          <v-btn color="soft_blue" outlined @click="dialog_email_already_exist=false" class="mx-sm-3 px-sm-6">Fermer
          </v-btn>
          <v-btn color="soft_blue" elevation="0" @click="dialog_email_already_exist=false;$emit('connexion')"
                 class="mx-sm-3 px-sm-6 white--text">Connexion
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ItemSignupForm from "./ItemSignupForm";
import AnSelectSexe from "anam-library/src/components/an-ui/select/AnSelectSexe";
import AnDate from "anam-library/src/components/an-date/AnDate";
import CguCheckbox from "../../../common/CguCheckbox";
import ListItemError from "@/components/home/signup/ListItemError";

import rules from "anam-library/src/js/rules";
import moment from "moment-timezone"
import {get_list_category_by_agency} from "anam-library/src/data/dict_categories_pro";
import {show_patient_category} from "prevana-library/src/js/dict_permissions";

export default {
    name: "Signup",
    components: {
      ItemSignupForm,
      AnSelectSexe,
      AnDate,
      CguCheckbox,
      ListItemError
    },
    data() {
      return {
        valid_form: false,
        rules: rules,
        health_care_number: null,
        categorie: null,
        last_name: null,
        maiden_name: null,
        first_name: null,
        sexe: null,
        birthdate: null,
        email: null,
        confirm_email: null,
        phone: null,
        password: '',
        confirm_password: '',
        cgu: false,
        confidentiality: false,
        loading: false,
        signup_validated: false,
        dialog_email_already_exist: false,
        items_categorie: get_list_category_by_agency(this.$store.getters.getCurrentAgency),
        dialog_confirm_infos: false
      }
    },
    computed: {
      error_email() {
        return this.confirm_email !== '' && this.email !== this.confirm_email;
      },
      error_password() {
        return this.confirm_password !== '' && this.password !== this.confirm_password;
      },
      categorie_txt() {
        if(this.categorie) {
          for (let el in this.items_categorie){
            if(this.items_categorie[el]['value'] === this.categorie) return this.items_categorie[el]['text'];
          }
        }
        return null;
      },
      sexe_txt() {
        if(this.sexe) {
          if(this.sexe === 'M') return 'Homme';
          else if(this.sexe === 'F') return 'Femme';
        }
        return null;
      },
      show_category() {
        return show_patient_category(this.$store.getters.getCurrentAgency.of_type);
      }
    },
    created() {
      this.sexe = this.$store.getters.getPreQuestSexe;
      this.birthdate = this.$store.getters.getPreQuestBirthdate;
      this.$vuetify.goTo(0);
    },
    filters: {
      birthdate_formatted: function(value) {
        if(value) return moment(value, 'DD/MM/YYYY').locale('fr').format('DD MMMM YYYY')
      }
    },
    methods: {
      signup: function() {
        this.$store.commit('ResetSnackBar');
        this.loading = true;

      if (this.$store.getters.getCurrentAgency.access_to_vaccination_open) {
        this.categorie = 6;
      }

      let map = {
        'health_care_number': this.health_care_number,
        'last_name': this.last_name,
        'first_name': this.first_name,
        'sexe': this.sexe,
        'birthdate': moment(this.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        'email': this.email,
        'phone': this.phone,
        'password': this.password,
        'cgu': this.cgu,
        'politique': this.confidentiality,
        'agency_name': this.$route.params.agency_name,
        'categorie': this.categorie
      }

      let form_data = new FormData();
      for (let el in map) {
        form_data.append(el, map[el])
      }

      if (this.maiden_name) form_data.append('maiden_name', this.maiden_name);

      this.$http.post('/api/vaccination/signup', form_data).then(() => {
        this.signup_validated = true;
      }).catch(error => {
        if (error.response.status === 422) {
          if (error.response.data['email']) {
            this.$store.commit('PostError', error.response.data["email"][0])
            this.$vuetify.goTo(0);
            this.dialog_email_already_exist = true;
          }
        } else if (error.response.status === 417) {
          this.$router.push('/expiration_token')
        }
      }).finally(() => {
        this.dialog_confirm_infos = false
        this.loading = false;
      })
    }
  }
}

</script>

<style>
.v-list-item__title {
  white-space: normal;
  word-break: break-word;
}
</style>
