<template>
  <div>
    <PrTabsHeader
      v-on:come_back="$router.push({name: 'VaccinationDashboard', query: { tab: $route.query.tab }})"
      :tabs="tabs"
    />
    <v-row>
      <v-col cols="3">
        <AnAdminInfos
          :admin_infos="$store.state.pat_admin.infos_admin"
          :name="name"
          editable
          :full_width="true"
          v-on:edit="$refs.edit_dialog.dialog = true"
          :patient_edit="false"
          :option_icon="false"
          title_secondary_panel="Voir plus"
          color="primary"
        />
      </v-col>
      <v-col cols="9">
        <v-tabs-items v-model="$store.state.dpa.tab_selected">
          <v-tab-item value="tab_anamnese" class="bg">
            <DpaSummary></DpaSummary>
          </v-tab-item>
          <v-tab-item value="tab_rdv" class="bg">
            <DpaAppointment></DpaAppointment>
          </v-tab-item>
          <v-tab-item value="tab_1_vaccination" class="bg">
            <DpaVaccination :vaccination_number="1"></DpaVaccination>
          </v-tab-item>
          <v-tab-item value="tab_2_vaccination" class="bg">
            <DpaVaccination :vaccination_number="2" v-on:success_vaccination_status="additional_injections = true;"></DpaVaccination>
          </v-tab-item>
          <v-tab-item value="tab_3_vaccination" class="bg">
            <AdditionalInjections v-if="!loading && additional_injections"></AdditionalInjections>
          </v-tab-item>
          <v-tab-item value="tab_follow_up" class="bg">
            <DpaFollowUp></DpaFollowUp>
          </v-tab-item>
          <v-tab-item value="tab_notes" class="bg">
            <PrNoteTab></PrNoteTab>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <PrEditDialog ref="edit_dialog" />
  </div>
</template>

<script>
import PrTabsHeader from "prevana-library/src/components/dpa/PrTabsHeader";
import AnAdminInfos from "anam-library/src/components/an-dpa/AnAdminInfos";
import {vaccination_mixin} from "./vaccination_mixin";
import dpa_mixin from "prevana-library/src/js/dpa_mixin";
import DpaSummary from "./tabs/DpaSummary";
import DpaVaccination from "./tabs/DpaVaccination";
import AdditionalInjections from "./tabs/AdditionalInjections";
import DpaAppointment from "./tabs/DpaAppointment";
import DpaFollowUp from "./tabs/DpaFollowUp";
import PrNoteTab from "prevana-library/src/components/dpa/tabs/PrNoteTab";
import PrEditDialog from "prevana-library/src/components/dpa/PrEditDialog";

export default {
  name: "DpaLayout",
  components: {
    PrTabsHeader,
    DpaAppointment,
    DpaVaccination,
    AdditionalInjections,
    DpaSummary,
    DpaFollowUp,
    AnAdminInfos,
    PrNoteTab,
    PrEditDialog,
  },
  mixins: [dpa_mixin, vaccination_mixin],
  data() {
    return {
    tabs: [
      { title: "Résumé",icons: ["mdi-account"], href: "#tab_anamnese", show: true},
      { title: "RDV",icons: ["mdi-calendar-month"], href: "#tab_rdv", show: true},
      {
        title: "1ère injection",
        icons: ["mdi-needle"],
        href: "#tab_1_vaccination",
        show: this.$store.getters.getUserPermission === 2
      },
      {
        title: "2ème injection",
        icons: ["mdi-needle", "mdi-needle"],
        href: "#tab_2_vaccination",
        show: this.$store.getters.getUserPermission === 2,
      },
      {
        title: "injections supplémentaires",
        icons: ["mdi-needle", "mdi-needle", "mdi-needle", "mdi-needle"],
        href: "#tab_3_vaccination",
        show: this.$store.getters.getUserPermission === 2,
      },
      {
        title: "Suivi",
        icons: ["mdi-star"],
        href: "#tab_follow_up",
        show: true
      },
      { title: "Notes",icons: ["mdi-square-edit-outline"], href: "#tab_notes", badge_getters:'GetNbNote', show: true },
    ],
    dialog_health_care_number: false,
    loading: true,
    additional_injections: false
  }},

  async created() {
    await this.get_patient_vaccination_data();
    if (this.$store.state.dpa_data.vaccination_data.nb_of_injections_needed >= 3) this.additional_injections = true;
    this.loading = false;
  },


  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      document.title = "Dossier patient - vaccination - " + vm.$store.getters.getCurrentAgency.commercial_name;
    });
  },
  beforeRouteLeave: (to, from, next) => {
    document.title = "Prévana";
    next();
  },

};
</script>
