<template>
  <v-row class="pb-6">
    <v-col cols="6" order="3" order-md="2">
      <PrCardComponent title="Résumé" icon="mdi-format-list-bulleted">
<!--        PDF Certificat Vaccination : supprimé le 23/01/2024 avec les modifications d'infra (rm weasyprint) ; en attente non retours utilisateurs pour suppression définitive-->
<!--        <template v-slot:card-title-append v-if="$store.state.dpa_data.vaccination_data.nb_of_injections_performed > 0">-->
<!--          <v-spacer></v-spacer>-->
<!--          <v-tooltip bottom>-->
<!--            <template v-slot:activator="{ on }">-->
<!--              <v-btn v-on="on" icon color="primary" :href="get_pdf_url()" text>-->
<!--                <v-icon>mdi-download</v-icon>-->
<!--              </v-btn>-->
<!--            </template>-->
<!--            <span> Télécharger le certificat de vaccination </span>-->
<!--          </v-tooltip>-->
<!--        </template>-->
        <template v-slot:card-text>
          <v-progress-linear v-if="is_loading" indeterminate color="secondary"></v-progress-linear>
           <v-list>
            <v-list-item-content>
              <v-list-item-title class="primary--text semibold--text font-size--20 mb-1">
                Statut
              </v-list-item-title>
              <v-list-item-subtitle :class="'font-size--16 semibold--text ' + color_status">
                {{ $store.getters.GetStatusName($store.state.dpa_data.status) }}
              </v-list-item-subtitle>
              <div v-if="$store.state.dpa_data.status === 8">
                <v-list-item-subtitle
                  v-for="(reason, i) in reasons_not_eligible"
                  :key="i"
                  ><span>- {{ reason }}</span></v-list-item-subtitle
                >
              </div>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title class="primary--text semibold--text font-size--20 mb-1"
                >Prochain rendez-vous</v-list-item-title
              >
              <v-list-item-subtitle>{{ next_rdv.date }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{
                next_rdv.place
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title class="primary--text semibold--text font-size--20 mb-1">Nombre d'injections</v-list-item-title>
              <v-list-item-subtitle>
                <span class="secondary--text font-weight-bold">{{ $store.state.dpa_data.vaccination_data.nb_of_injections_performed }}</span> réalisée sur
                <span class="secondary--text font-weight-bold">{{ $store.state.dpa_data.vaccination_data.nb_of_injections_needed }}</span> nécessaires
              </v-list-item-subtitle>
            </v-list-item-content>
            <InfoVaccination
              title="Première injection"
              :vaccination="$store.state.dpa_data.vaccination_data['1']"
            ></InfoVaccination>
            <InfoVaccination
              title="Dernière injection"
              :vaccination="get_latest_injection()"
            ></InfoVaccination>
            <v-list-item-content>
              <v-list-item-title class="primary--text semibold--text font-size--20 mb-1">Autorisé à la prise de rendez-vous</v-list-item-title>
              <v-list-item-subtitle>{{$store.state.dpa_data.is_eligible ? "Oui" : "Non" }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="!$store.state.dpa_data.is_eligible">
                <v-btn class="mt-3" color="secondary" @click="dialog_set_eligible = true">
                  Autoriser la prise de RDV
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list>
          <BackHistory class="mb-4"></BackHistory>
        </template>
      </PrCardComponent>
    </v-col>

    <v-col cols="6" order="2" order-md="3">
      <PrCardComponent title="Historique" icon="mdi-history">
        <template v-slot:card-text>
          <v-progress-linear v-if="is_loading" indeterminate color="secondary"></v-progress-linear>
          <v-list-item-group :key="key" v-for="(item, key) in $store.state.dpa_data.history_vaccination">
            <v-list-item-content :key="key" style="overflow: unset">
              <v-list-item-title style="white-space: unset; word-break: break-word">
                <span v-html="item.date"></span>
              </v-list-item-title>
              <v-list-item-subtitle class="black--text font-size--14 medium--text">
                <v-icon color="pastel" class="hidden-xs-only">mdi-chevron-right</v-icon>
                <span style="white-space: pre-wrap">{{$store.getters.GetStatusNameActe(item.status) }}</span>
                <span v-if="item.is_correction" class="error--text">(état annulé)</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item-group>
        </template>
      </PrCardComponent>
    </v-col>
    <PrDialogEmailSetEligible
      :dialog.sync="dialog_set_eligible"
      :data="data_set_eligible"
    ></PrDialogEmailSetEligible>
  </v-row>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import {vaccination_mixin} from "../vaccination_mixin";
import InfoVaccination from "../components/InfoVaccination";
import PrDialogEmailSetEligible from "prevana-library/src/components/dialogs/PrDialogEmailSetEligible";
import BackHistory from "../components/BackHistory";

export default {
  name: "DpaSummary",
  components: {
    PrDialogEmailSetEligible,
    PrCardComponent,
    InfoVaccination,
    BackHistory,
  },
  mixins: [vaccination_mixin],
  data: () => ({
    dialog_set_eligible: false,
    dialog_health_care_number: false,
    reasons_not_eligible: [],
    is_loading: true,
  }),
  mounted() {
    this.get_status_reason();
  },
  computed: {
    color_status() {
        return this.$store.state.vaccinated_14_days ? "red--text": "secondary--text";
    }
  },
  methods: {
    get_latest_injection() {
      let injections = this.get_injections_list();
      if (injections.length === 1) return null;
      return this.$store.state.dpa_data.vaccination_data[injections.length];
    }
  },
};
</script>

<style>

</style>
