import { render, staticRenderFns } from "./ConsentementItem.vue?vue&type=template&id=819e6be6&scoped=true&"
import script from "./ConsentementItem.vue?vue&type=script&lang=js&"
export * from "./ConsentementItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "819e6be6",
  null
  
)

export default component.exports