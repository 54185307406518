<template>
  <PrDpaView return_component="RPSDashboard" :tabs="tabs" :show_rdv="show_rdv" />
</template>

<script>
import PrDpaView from "prevana-library/src/components/dpa/PrDpaView";
import {get_terminology} from "anam-library/src/data/dict_terminology_by_agency";
import {show_teleconsultation} from "prevana-library/src/js/dict_permissions";

export default {
  components: {PrDpaView},
  computed: {
    tabs() {
      let current_agency = this.$store.getters.getCurrentAgency
      let tabs = [
        { title: `Fiche ${get_terminology("patient", current_agency)}`, icons: ["mdi-account"], href: "#tab_anamnese", show: true, disabled: false},
      ]
      if (current_agency.patient_access_rdv) tabs.push({ title: "RDV", icons: ["mdi-calendar"], href: "#tab_rdv", show: true, disabled: true});
      tabs.push(
        { title: "Historique", icons: ["mdi-history"], href: "#tab_history", show: this.$store.getters.getUserPermission === 2, disabled: true},
        { title: "Évolution", icons: ["mdi-chart-line"], href: "#tab_stats", show: true, disabled: true}
      )
      if (show_teleconsultation(current_agency.name)) {
        tabs.push({ title: "Téléconsultation", icons: ["mdi-television-play"], href: "#tab_visio",  show: this.$store.getters.getUserPermission === 2, disabled: false});
      }
      tabs.push({ title: "Notes",icons: ["mdi-square-edit-outline"], href: "#tab_notes", show: true, badge_getters:'GetNbNote', disabled: false});
      return tabs;
    },
    show_rdv() {
      return this.$store.getters.getCurrentAgency.patient_access_rdv;
    }
  }
};
</script>
