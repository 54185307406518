<template>
  <div>
    <AnDashboard
      v-if="dashboard_data_tables"
      ref="an_dashboard"
      :body="body"
      :tab_items="Object.keys(dashboard_data_tables)"
      :tab_content="dashboard_data_tables"
      :get_patients_on_created="false"
      :search="$store.state.dashboard_filter.dashboard_search"
      allow_mass_update
      :mass_update_actions="actions"
      mass_update_key="patient_id"
      allow_export
      :show_inactive="show_inactive"
      custom_filter
      :custom_filter_function="custom_filter_ecos"
      v-on:mass_action_click="manage_actions($event)"
      @move_to_dpa="open_dpa($event, false)"
      @open_dpa_window="open_dpa($event, true)"
    >
      <template v-slot:header>
        <v-card-title>
          <PatientSearch
            :marital_status_filter.sync="marital_status_filter"
            :studies_funding_filter.sync="studies_funding_filter"
            :travel_time_filter.sync="travel_time_filter"
            :stress_filter.sync="stress_filter"
            :repeated_year_filter.sync="repeated_year_filter"
            v-on:show_inactive="show_inactive = $event"
          />
        </v-card-title>
      </template>
    </AnDashboard>
    <PrDialogSendQuestionnaire
      :dialog.sync="dialog_send_questionnaire"
      :ids_selected="ids_selected"
      :questionnaires="questionnaires"
      app_name="ECOS"
    />
  </div>
</template>

<script>
import AnDashboard from "anam-library/src/components/an-dashboard/AnDashboard";
import PrDialogSendQuestionnaire from "prevana-library/src/components/dialogs/PrDialogSendQuestionnaire";
import ScoreECOSItem from "./ScoreECOSItem";
import PatientSearch from "./PatientSearch";
import AlertItem from "./AlertItem";
import QuestionnaireECOSItem from "./QuestionnaireECOSItem";
import QuestionnaireECOSStatusItem from "./QuestionnaireECOSStatusItem";

import dashboard_data_tables from "@/data/ecos/dashboard_data_tables.json";

export default {
  name: "ECOSDoctorDashboardView",
  components: {
    PatientSearch,
    AnDashboard,
    PrDialogSendQuestionnaire
  },
  data() {
    return {
      body: {
        latest_questionnaire_sent_date: QuestionnaireECOSStatusItem,
        latest_questionnaire_completed_date: QuestionnaireECOSItem,
        ecos_phq9: ScoreECOSItem,
        ecos_gad7: ScoreECOSItem,
        eva_stress_pre: ScoreECOSItem,
        eva_stress_post: ScoreECOSItem,
        ecos_reco_etudiant: ScoreECOSItem,
        ecos_phq9_suicide: AlertItem
      },
      dashboard_data_tables: null,
      search: "",
      show_inactive: false,
      dialog_send_questionnaire: false,
      ids_selected: [],
      marital_status_filter: [],
      studies_funding_filter: [],
      travel_time_filter: [],
      stress_filter: [],
      repeated_year_filter: [],
      questionnaires: []
    };
  },
  created() {
    this.questionnaires = JSON.parse(JSON.stringify(this.$store.getters.getStandardAppsQuestionnaires));
    this.questionnaires.sort((a, b) => parseInt(a.id.replace("ecos-week", "")) - parseInt(b.id.replace("ecos-week", "")));
    this.dashboard_data_tables = JSON.parse(JSON.stringify(dashboard_data_tables));
    this.dashboard_data_tables["TOUS"].loading = true;
    this.$http.get("/api/ecos/patients/all").then(response => {
      this.dashboard_data_tables["TOUS"].patients = response.data;
    }).finally(() => {
      this.dashboard_data_tables["TOUS"].loading = false;
    })
  },
  methods: {
    async open_dpa(patient, new_window) {
      const path = {name: 'ECOSDoctorDpa', params: {id: patient.patient_id }, query: {tab: this.$route.query.tab}}
      if (new_window){
        const routeData = this.$router.resolve(path);
        window.open(routeData.href, "_blank", "resizable");
      }
      else {
        this.$router.push(path);
      }
    },
    manage_actions(event) {
      this.ids_selected = [];
      for (let patient of event.selected) {
        this.ids_selected.push(patient.user_id);
      }
      this.dialog_send_questionnaire = true;
    },
    custom_filter_ecos(list) {
      if (this.marital_status_filter.length) list = list.filter(x => this.marital_status_filter.filter(y => x.marital_status && x.marital_status.includes(y)).length);
      if (this.studies_funding_filter.length) list = list = list.filter(x => this.studies_funding_filter.filter(y => x.studies_funding && x.studies_funding.includes(y)).length);
      if (this.travel_time_filter.length) list = list.filter(x => this.travel_time_filter.includes(x.travel_time));
      if (this.stress_filter.length) list = list.filter(x => this.stress_filter.includes(x.stress));
      if (this.repeated_year_filter.length) list = list.filter(x => this.repeated_year_filter.includes(x.repeated_year));
      return list;
    }
  },
  computed: {
    actions() {
      return [{value: "send_questionnaire", text: "Envoyer un questionnaire"}]
    }
  }
};
</script>

<style>
</style>
