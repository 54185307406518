<template>
  <v-list-item class="py-2">
    <v-list-item-content>
      <v-list-item-title class="secondary--text semibold--text font-size--18">
        <v-icon color="secondary" class="mr-2">{{ icon }}</v-icon>
        {{ title }}
      </v-list-item-title>
      <v-list-item-subtitle class="normal--text basic--text text-break" style="white-space: normal">
        {{ subtitle }}
      </v-list-item-subtitle>
      <v-list-item-subtitle class="hidden-sm-and-up mt-2">
        <slot name="btn_action"></slot>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="hidden-xs-only">
      <slot name="btn_action"></slot>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: "DashboardBlocActionsListItem",
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
