<template>
  <div>
    <div v-if="aptitude" class="text-center">
      <span>{{ aptitude.text }}</span>
    </div>
    <v-icon v-else color="soft_blue">mdi-minus</v-icon>
  </div>
</template>

<script>
import {APTITUDE_CHOICES} from "@/data/vma/dict_vma";

export default {
  name: "VMAAmenagementItem",
  props: ['item'],
  computed: {
    aptitude() {
      return APTITUDE_CHOICES.find(aptitude => aptitude.value === this.item.aptitude);
    }
  }
}
</script>

<style scoped>

</style>
