<!-- Composant global pour la structure du dashboard d'un patient ayant l'application RPS -->
<template>
  <v-row class="mx-0">
    <v-col cols="12" sm="6">
      <RpsDashboardCardBilan/>
    </v-col>
    <v-col cols="12" sm="6">
      <v-row>
        <v-col v-if="show_rdv_card" cols="12" sm="6">
          <PatientRDVCardComponent name_details="Bien-Être" app_name="rps" small_version>
            <template v-slot:append-action>
              <v-divider></v-divider>
              <div class="text-center mt-5">
                <v-btn color="secondary" class="px-6" elevation="0" @click="go_to_appointment()" :loading="loading_rdv">
                  <span class="medium--text font-size--12">Prendre rendez-vous</span>
                </v-btn>
              </div>
            </template>
          </PatientRDVCardComponent>
        </v-col>
        <v-col v-for="(item, title) in contacts" cols="12" :sm="show_rdv_card ? 6 : 12" :key="title">
          <PatientContactCardComponent :contacts="item" :title="title" :nb_columns="show_rdv_card ? 1 : 2" />
        </v-col>
        <v-col cols="12">
          <PatientRessourcesCardComponent :ressources="ressources_page_1" />
        </v-col>
        <v-col cols="12" v-if="ressources_page_2.length">
          <PatientRessourcesCardComponent :ressources="ressources_page_2" title="C'est quoi les RPS ?" subtitle="Les vidéos ci-dessous sont de notre assureur SOFAXIS" subtitle_logo="https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/sofaxis.jpg.png" />
        </v-col>
        <v-col cols="12">
          <RpsDashboardCardFAQ/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import PatientContactCardComponent from "@/components/patient/components/contacts/PatientContactCardComponent";
import PatientRessourcesCardComponent from "@/components/patient/components/ressources/PatientRessourcesCardComponent";
import PatientRDVCardComponent from "@/components/patient/components/rdv/PatientRDVCardComponent";

import RpsDashboardCardBilan from "@/components/patient/rps/dashboard/bilan/RpsDashboardCardBilan";
import RpsDashboardCardFAQ from "@/components/patient/rps/dashboard/faq/RpsDashboardCardFAQ";

import {get_contacts, get_ressources} from "../../../../data/rps/text_by_agency";

export default {
  name: "PatientRPSDashboardView",
  components: {
    PatientContactCardComponent,
    PatientRessourcesCardComponent,
    PatientRDVCardComponent,
    RpsDashboardCardFAQ,
    RpsDashboardCardBilan,
  },
  data: () => ({
    loading_rdv: false
  }),
  computed: {
    contacts: function() {
      return get_contacts(this.$store.getters.getAgencyName);
    },
    ressources_page_1() {
      let ressources = get_ressources(this.$store.getters.getAgencyName);
      return ressources.filter(x => !x.page || x.page === 1);
    },
    ressources_page_2() {
      let ressources = get_ressources(this.$store.getters.getAgencyName);
      return ressources.filter(x => x.page === 2);
    },
    show_rdv_card() {
      return this.$store.getters.getCurrentAgency.patient_access_rdv;
    }
  },
  methods: {
    go_to_appointment() {
      this.loading_rdv = true;
      this.$http.get("/api/rps_appointment_info/0").then(response => {
        if (response.data.has_appointment || !response.data.eligible_to_consultation) this.$router.push({name: 'PatientRPSAppointmentSummary'});
        else this.$router.push({name: 'PatientRPSAppointement'});
      }).finally(() => {
        this.loading_rdv = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
