<template>
  <div>
    <PrCardComponent title="Visite médicale" icon="mdi-clipboard-plus-outline" class="mt-3">
      <template v-slot:card-text>
        <v-row class="mx-0">
          <v-col cols="12" sm="8">
            <v-select v-model="chosen_consultation" :items="consultation_items" placeholder="Sélectionnez une visite" :no-data-text="`Aucune visite ${see_closed_consults ? 'clôturée' : 'programmée'}`"></v-select>
          </v-col>
          <v-col cols="12" sm="4" class="d-flex justify-center align-center">
            <v-btn text @click="toggle_see_consults()" class="font-italic text-decoration-underline">Voir les visites {{see_closed_consults ? "programmées" : "clôturées"}}</v-btn>
          </v-col>
        </v-row>
        <div v-if="chosen_consultation" class="mt-10">
          <AnConstantesCard
            v-if="!user_is_psychologue"
            ref="constants_ref"
            :patient_id="patient_id"
            :consultation_id="chosen_consultation.id"
            :constantes_to_display="constants_list"
            :init_constants="init_constants"
            :number_column="4"
            :use_initial_constants="false"
            title_class="primary--text pa-0"
            :elevation="0"
            :outlined="true"
          />

          <v-row class="mx-0 mt-3" v-if="!user_is_psychologue">
            <v-col cols="12" sm="6">
              <AnProgressLinear v-if="loading_questionnaires" />
              <div v-else-if="!questionnaire">
                <p class="font-italic black--text ml-3">Ce patient n'a pas répondu au questionnaire</p>
              </div>
              <AnRestitutionQuestionsAnswers
                v-else
                :questions="questionnaire.questions_answers"
                title="Dernières réponses du patient"
                :dense="true"
                :formulaire_view="true"
                :panel="0"
                :show_input_free_pre_text="false"
              />
            </v-col>

            <v-col cols="12" sm="6" class="px-10">
              <p class="semibold--text primary--text font-size--18 mb-0 mt-5">Reprise du médecin</p>
              <p class="font-italic mt-0">
                Les réponses du patient sont remontées automatiquement :
                <br/>
                <span class="ml-5">Si la réponse est oui : la case est cochée</span>
                <br/>
                <span class="ml-5">Si non ou occasionnel : elle n'est pas cochée</span>
                <br/>
                Vous pouvez les modifier.
                <br/>
                Les informations modifiées par le médecin apparaîtront en rouge.
              </p>
              <v-progress-linear v-if="loading_indicators" indeterminate color="primary"></v-progress-linear>
              <v-row class="mx-5 mt-0 mb-10">
                <v-col cols="12" class="pa-0" v-for="(item, index) in indicators" :key="index">
                  <p v-if="item.title" class="semibold--text mb-0 mt-8 ml-5">{{item.title}}</p>
                  <v-checkbox v-else v-model="item.value" dense hide-details>
                    <template v-slot:label>
                      <span :class="get_color_indicator(item)">{{item.name}}</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>

              <p class="semibold--text primary--text font-size--18 mt-10">Auscultation</p>
              <v-textarea v-model="auscultation" outlined counter placeholder="Entrez votre texte ici"></v-textarea>
            </v-col>
          </v-row>

          <v-row v-else class="mx-0">
            <v-col cols="12">
              <p class="semibold--text primary--text font-size--18">Auscultation</p>
              <v-textarea v-model="auscultation" outlined counter placeholder="Entrez votre texte ici"></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="3">
              <v-select v-model="status" :items="status_choices" label="Présence/absence au RDV" outlined hide-details></v-select>
            </v-col>

            <v-col cols="12" sm="2" v-if="!user_is_psychologue">
              <v-select v-model="aptitude" :items="aptitude_choices" label="Aménagement" outlined></v-select>
            </v-col>
            <v-col cols="12" sm="7">
              <v-select v-if="aptitude" v-model="amenagement" multiple :items="amenagement_choices" label="Type d'aménagement" outlined deletable-chips small-chips></v-select>
            </v-col>
            <v-col cols="12" sm="3" offset-sm="5">
              <v-select v-if="aptitude" v-model="amenagement_duration" :items="amenagement_duration_choices" label="Durée de l'aménagement" outlined></v-select>
            </v-col>
            <v-expand-transition>
              <v-col v-if="aptitude && amenagement_duration === 3" cols="12" sm="2">
                <AnDate
                  :date="amenagement_end"
                  label="Valable jusqu'au"
                  class="filter-date"
                  outlined
                  :filled="false"
                  :validate="false"
                  :min="new Date().toISOString().substr(0, 10)"
                  v-on:formatted_date="amenagement_end = $event"
                />
              </v-col>
            </v-expand-transition>

            <v-col cols="12">
              <v-btn color="primary" elevation="0" class="mb-2" @click="add_handicap()">
                <v-icon class="mr-3">mdi-plus-circle</v-icon>
                <span>Renseigner un handicap</span>
              </v-btn>

              <div v-for="(handicap, i) in handicaps" :key="i">
                <v-divider></v-divider>
                <HandicapType
                  :handicap_type.sync="handicap.handicap_type"
                  :handicap_type_precision.sync="handicap.handicap_type_precision"
                  :duration.sync="handicap.duration"
                  v-on:delete_handicap="delete_handicap(handicap.id)"
                />
              </div>
            </v-col>
          </v-row>

          <div class="justify-center d-flex">
            <v-btn @click="save(false)" outlined class="primary--text mr-3" :loading="loading">Enregistrer les modifications</v-btn>
            <v-btn @click="save(true)" class="primary ml-3" :disabled="save_disabled" :loading="loading">Enregistrer et clôturer la visite</v-btn>
          </div>
        </div>

        <div class="text-center mt-10" v-if="chosen_consultation">
          <v-btn color="primary" class="white--text" @click="up_generate_pdf()" :loading="generate_pdf">{{ $t('notes.generate_cr') }}</v-btn>
        </div>

        <div class="text-center mt-5">
          <v-btn text @click="dialog_history = true" class="primary--text">
            <v-icon>mdi-history</v-icon>
            <span>Historique des visites médicales</span>
          </v-btn>
        </div>
      </template>
    </PrCardComponent>
    <HistoryVisitVMA :dialog.sync="dialog_history" :consultations="consultations.filter(x => x.closed === true)" />
    <AnCompteRendu app_name="vma"
                   :logo="logo"
                   :download.sync="generate_pdf"
                   :generate_xml="false"
                   doc_title="Fiche synthèse de la visite médicale"
                   med_info_title=""
                   adresse_postale="ESCP - Campus de Champerret - 8, av. de la Porte de Champerret"
                   code_postal="75017 Paris - France"
                   phone_contact="01 49 23 20 00"
                   observation_title="Rendez-vous :"
                   clinical_exam_title="Auscultation :"
                   :patient_id="patient_id"
                   :display_patient_email="true"
                   :display_patient_promo="true"
                   :patient_promotion="patient_promotion"
                   :questionnaires_data="questionnaires_data"
                   :display_type_handicap="true"
                   :display_type_amenagement="true"
                   :display_website="true"
                   :display_constantes="true"
                   :display_med_infos="false"
                   :display_diag="false"
                   :display_observation="true"
                   :display_clinical_exam="true"
                   :display_patient_name_used="false"
                   :display_patient_birth_place="false"
                   :clinical_exam_page_break="true"
                   :display_address="true">
      <template v-slot:observations>
        <div>
          <ul>
            <li v-for="(item, ind) in consultation_items" :key="ind">
              {{ item.text }}
            </li>
          </ul>

        </div>
      </template>
      <template v-slot:clinical_exam>
        <div>
          <p style="white-space: pre-wrap; padding-right: 10px">{{auscultation}}</p>
        </div>
      </template>
      <template v-slot:info_spe_psy>
        <div style="margin-right: 30px; margin-top: 20px">
          <p style="font-size: 16px; margin-right: 30px;">Présence RDV :</p>
          <span  v-if="get_status_text !== '' && get_amenagement_txt !== '' " style="font-size: 16px; margin-left: 30px;">{{ get_status_text }} : {{ get_amenagement_txt }}</span>

        </div>
      </template>
      <template  v-slot:infos_type_amenagement>
        <div style="margin-right: 30px; margin-top: 20px">
          <p style="font-size: 16px; margin-right: 30px;">Type d'aménagement :</p>
          <ul v-for="(choice_text, ind) in get_selected_amenagement_text" :key="ind">
            <li style="font-size: 16px; margin-left: 30px;" >{{ choice_text.text }}</li>
          </ul>
        </div>
      </template>
      <template  v-slot:infos_type_handicap>
        <div style="margin-right: 30px; margin-top: 20px" v-if="get_selected_handicap_info_text.length > 0">
          <p style="font-size: 16px; margin-right: 30px;">Type de handicap :</p>
          <ul v-for="(handicap_text, ind) in get_selected_handicap_info_text" :key="ind">
            <li style="font-size: 16px; margin-left: 30px;" >{{ handicap_text.text }}</li>
          </ul>
        </div>
      </template>

      <template v-slot:questionnaire_interpretation>
        <div>
          <ul>
            <li v-for="(indicator_text, ind) in indicators.filter(indicator => indicator.value === true)" :key="ind">
              <span>{{ indicator_text.name }}</span>

            </li>
          </ul>
          <v-icon v-if="indicators.filter(indicator => indicator.value === true).length === 0">mdi-minus</v-icon>
        </div>
      </template>

    </AnCompteRendu>
  </div>
</template>

<script>
import AnRestitutionQuestionsAnswers from "anam-library/src/components/an-questionnaire/AnRestitutionQuestionsAnswers";
import AnProgressLinear from "anam-library/src/components/an-ui/loading/AnProgressLinear";
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import HistoryVisitVMA from "./HistoryVisitVMA";
import {get_formatted_date} from "anam-library/src/js/dates";
import rules from "anam-library/src/js/rules";
import AnConstantesCard from "anam-library/src/components/an-constantes/AnConstantesCard";
import {AMENAGEMENT_CHOICES, AMENAGEMENT_DURATION_CHOICES, APTITUDE_CHOICES, HANDICAP_CHOICES, HANDICAP_PRECISION_CHOICES, DURATION_CHOICES, DICT_INDICATORS_CONSULTATION} from "@/data/vma/dict_vma";
import HandicapType from "./HandicapType";
import AnCompteRendu from 'anam-library/src/components/an-compte-rendu/AnCompteRendu';
import AnDate from "anam-library/src/components/an-date/AnDate";


export default {
  name: "DpaMedicalVisitVMA",
  components: {
    AnRestitutionQuestionsAnswers,
    AnProgressLinear,
    HandicapType,
    PrCardComponent,
    HistoryVisitVMA,
    AnConstantesCard,
    AnCompteRendu,
    AnDate
  },
  props: {
    consultations: {
      type: Array,
      default: null
    },
    questionnaires: {
      type: Array,
      default: () => []
    },
    loading_questionnaires: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    dialog_history: false,
    rules: rules,
    chosen_consultation: null,
    constants_list: ["taille", "poids", "avisOG", "avisOD", "pa_syst", "pa_diast", "pulse"],
    status: null,
    status_choices: [
      {value: 3, text: "Présence"},
      {value: 1, text: "Absence excusée"},
      {value: 2, text: "Absence non excusée"},
    ],
    aptitude: null,
    auscultation: null,
    loading: false,
    amenagement: [],
    amenagement_choices: AMENAGEMENT_CHOICES,
    amenagement_duration: [],
    amenagement_duration_choices: AMENAGEMENT_DURATION_CHOICES,
    amenagement_end: null,
    handicaps: [],
    id_handicap: 1,
    init_constants: {},
    indicators: [],
    loading_indicators: false,
    initial_indicators: [],
    doctor_modifications_indicators: [],
    see_closed_consults: false,
    is_set_from_consult: false,
    generate_pdf: false,
    diagnostics: {},
    logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/partenaire/logo_escp.jpeg"
  }),

  computed: {
    aptitude_choices: function() {
      return APTITUDE_CHOICES
    },
    user_is_psychologue() {
      return this.$store.getters.isPsychologist;
    },
    get_status_text: function() {
      let choice = this.status_choices.find(choice => choice.value === this.status);
      return choice ? choice.text : '';
    },
    get_amenagement_txt: function() {
      let aptitude = APTITUDE_CHOICES.find(aptitude => aptitude.value === this.aptitude);
      return aptitude ? aptitude.text : '';
    },
    get_selected_amenagement_text: function() {
      if (this.amenagement === null || this.amenagement.length === 0) {
        return [];
      }
      const selected_choices = this.amenagement_choices.filter(choice => this.amenagement.includes(choice.value));
      if (selected_choices.length === 0) {
        return [];
      }
      return selected_choices;
    },
    get_selected_handicap_info_text: function() {
      let selected_handicaps = [];
      for (let handicap of this.handicaps) {
        const handicap_choice = HANDICAP_CHOICES.find(c => c.value === handicap.handicap_type);
        const precision_choice = HANDICAP_PRECISION_CHOICES.find(c => c.value === handicap.handicap_type_precision);
        const duration_choice = DURATION_CHOICES.find(c => c.value === handicap.duration);
        if (handicap_choice && precision_choice && duration_choice) {
          selected_handicaps.push({
            value: handicap.handicap_type,
            text: `${handicap_choice.text} (${precision_choice.text}, ${duration_choice.text})`
          });
        }
      }
      return selected_handicaps;
    },
    patient_promotion: function(){
      let infos_admin = this.$store.state.pat_admin.infos_admin;
      if(infos_admin.secondary && infos_admin.secondary[2] && infos_admin.secondary[2].main_affectation)
        return infos_admin.secondary[2].main_affectation.value
      return ""
    },
    consultation_items() {
      // Les médecins ont accès aux aménagements
      // Les psychiatres aux consultation psychiatriques
      // Les infirmiers aux deux
      // Les psychologues aux consultations psychologiques mais uniquement les leurs
      let items = [];
      let pro_data = this.$store.getters.getUserInfos("professionnel");
      let motifs = this.$store.getters.getMotifsConsultation;
      let authorized_motifs = motifs.filter(x => (x.authorized_dmp_type === pro_data.dmp_type && x.authorized_speciality_code === null) || (x.authorized_dmp_type === pro_data.dmp_type && x.authorized_speciality_code === pro_data.speciality_code));
      if (pro_data.dmp_type === "10" && pro_data.speciality_code === "SM42") authorized_motifs = authorized_motifs.filter(x => x.authorized_speciality_code !== null);
      let authorized_motifs_ids = authorized_motifs.map(x => x.id);
      let motifs_psy = motifs.filter(x => x.authorized_dmp_type === "93");
      let ids_motif_psy = motifs_psy.map(x => x.id);
      let filtered_consultations = this.consultations.filter(x => x.cancelled === null && x.closed === this.see_closed_consults);
      if (pro_data.dmp_type === "60") {
        filtered_consultations = filtered_consultations.filter(x => !ids_motif_psy.includes(x.consultation_reason));
      }
      else {
        filtered_consultations = filtered_consultations.filter(x => authorized_motifs_ids.includes(x.consultation_reason));
      }
      if (this.user_is_psychologue) {
        filtered_consultations = filtered_consultations.filter(x => x.health_practitioner === pro_data.id);
      }
      for (let consult of filtered_consultations) {
        let text = consult.place;
        let motif = this.$store.getters.getMotifName(consult.consultation_reason);
        if (motif) text = `${text} (${motif})`;
        text = `${text} le ${get_formatted_date(consult.slot.start_slot)}`;
        items.push({
          text: text,
          value: consult.id
        })
      }
      return items;
    },

    save_disabled() {
      if (this.status === null) return true;
      if (this.user_is_psychologue) return false;
      if ([1, 2].includes(this.status)) return false; // Patient absent
      if (this.aptitude === null) return true;
      if (this.aptitude === 1 && this.amenagement && this.amenagement.length === 0) return true;
      if (this.handicaps.filter(x => x.handicap_type === null || x.handicap_type_precision === null || x.duration === null).length > 0) return true;
      if (this.aptitude === 1 && !this.amenagement_duration) return true;
      if (this.amenagement_duration === 3 && !this.amenagement_end) return true;
      return false;
    },
    patient_id() {
      return this.$route.params.id;
    },
    questionnaire() {
      if (!this.loading_questionnaires && this.questionnaires.length) return this.questionnaires[0];
      return null;
    },
    questionnaires_data() {
      let tmp = [];
      for (let questionnaire of this.questionnaires) {
        tmp.push({
            "questions": questionnaire.questions_answers,
            "date": get_formatted_date(questionnaire.creation_date, {output_format: "DD/MM/YYYY HH:mm"})
          });
      }
      return tmp;
    }
  },

  watch: {
    aptitude(val) {
      if(val === 2) this.amenagement = [];
    },
    chosen_consultation(val) {
      let index = this.consultations.findIndex(x => x.id === val);
      if (index > -1) {
        let consult = this.consultations[index];
        this.aptitude = consult.aptitude;
        this.status = consult.status;
        this.amenagement = consult.amenagement_type;
        this.amenagement_duration = consult.amenagement_duration;
        this.amenagement_end = consult.amenagement_end;
        this.init_constants = consult.constants;
        this.auscultation = consult.med_obs;
        this.handicaps = [];
        for (let handicap of consult.handicaps) {
          let ind1 = HANDICAP_CHOICES.findIndex(x => x.text === handicap.handicap_type);
          let ind2 = HANDICAP_PRECISION_CHOICES.findIndex(x => x.text === handicap.handicap_type_precision);
          let ind3 = DURATION_CHOICES.findIndex(x => x.text === handicap.duration);
          if (ind1 > -1 && ind2 > -1 && ind3 > -1) {
            this.handicaps.push({
              handicap_type: HANDICAP_CHOICES[ind1].value,
              handicap_type_precision: HANDICAP_PRECISION_CHOICES[ind2].value,
              duration: DURATION_CHOICES[ind3].value
            })
          }
        }
        this.is_set_from_consult = false;
        this.get_indicators();
      }
    }
  },

  methods: {
    toggle_see_consults() {
      this.see_closed_consults = !this.see_closed_consults;
      this.reset_data();
    },
    save(close) {
      this.loading = true;
      let data = {
        aptitude: this.aptitude,
        status: this.status,
        closed: close,
        amenagement_type: this.amenagement,
        amenagement_duration: this.amenagement_duration,
        handicaps: this.handicaps
      };
      if (this.amenagement_duration === 3) data.amenagement_end = this.amenagement_end;
      let constants = {};
      if (!this.user_is_psychologue) {
        constants = {"acte_id": this.chosen_consultation};
        for (let constant of this.$refs.constants_ref.constantes) {
          if (constant.value) constants[constant.id] = constant.value;
        }
      }
      if (this.auscultation) data["auscultation"] = this.auscultation;
      this.$http.patch(`/api/patients/${this.patient_id}/consultation-vma/${this.chosen_consultation}`, data).then(() => {
        let tmp = [];
        for (let indicator of this.indicators.filter(x => x.id_neo4j)) {
          if (this.is_set_from_consult === false) {
            tmp.push({
              acte: this.chosen_consultation,
              id_neo4j: indicator.id_neo4j,
              type_ind: indicator.type_ind,
              status: indicator.value === true ? "1" : "0"
            })
          }
          else {
            let index = this.initial_indicators.findIndex(x => x.id_neo4j === indicator.id_neo4j);
            if (index > -1) {
              if (this.doctor_modifications_indicators[index].value !== indicator.value) {
                tmp.push({
                  acte: this.chosen_consultation,
                  id_neo4j: indicator.id_neo4j,
                  type_ind: indicator.type_ind,
                  status: indicator.value === true ? "1" : "0"
                })
              }
            }
          }
        }
        this.$http.post(`/api/consultations/${this.chosen_consultation}/indications`, tmp).then(() => {
          if (Object.keys(constants).length > 1) {
            this.$http.post(`/api/patients/${this.patient_id}/constantes`, constants).then(() => {
              this.$emit("update_consultations");
              this.$store.commit('PostSuccess', "Modification enregistrées");
            });
          }
          else {
            this.$emit("update_consultations");
            this.$store.commit('PostSuccess', "Modification enregistrées");
          }
        })
        if (close) this.reset_data();
      }).finally(() => {
        this.loading = false;
      })
    },

    reset_data() {
      this.auscultation = null;
      this.aptitude = null;
      this.status = null;
      this.handicaps = [];
      this.id_handicap = 1;
      this.amenagement = [];
      this.amenagement_duration = null;
      this.amenagement_end = null;
      this.chosen_consultation = null;
    },

    add_handicap() {
      this.handicaps.push({id: this.id_handicap, handicap_type: null, handicap_type_precision: null, duration: null});
      // id_handicap est utilisé pour gérer la suppression en front, aucun lien avec le back
      this.id_handicap += 1;
    },

    delete_handicap(id) {
      let index = this.handicaps.findIndex(x => x.id === id);
      if (index > -1) this.handicaps.splice(index, 1);
    },

    get_indicators() {
      this.loading_indicators = true;
      this.indicators = [];
      this.initial_indicators = [];
      this.doctor_modifications_indicators = [];
      this.$http.get(`/api/consultations/${this.chosen_consultation}/indications`).then(response => {
        if (response.data.length) {
          this.is_set_from_consult = true;
          this.set_indicators(response.data, ["indicators", "doctor_modifications_indicators"]);
        }
        this.$http.get(`/api/patients/${this.patient_id}/questionnaires_vma/indications`).then(response_ => {
          if (this.is_set_from_consult === false) {
            this.set_indicators(response_.data, ["indicators", "initial_indicators"]);
          }
          else {
            this.set_indicators(response_.data, ["initial_indicators"]);
          }
        })
      }).finally(() => {
        this.loading_indicators = false;
      })
    },

    set_indicators(data, targets) {
      for (let target of targets) {
        for (let title in DICT_INDICATORS_CONSULTATION) {
          this[target].push({title: title});
          for (let key in DICT_INDICATORS_CONSULTATION[title]) {
            let index = data.findIndex(x => x.id_neo4j === key);
            let status = null;
            if (index === -1) status = false;
            else status = data[index].status;
            let data_to_push = {
              id_neo4j: key,
              name: DICT_INDICATORS_CONSULTATION[title][key].name,
              value: status,
              type_ind: DICT_INDICATORS_CONSULTATION[title][key].type_ind
            }
            this[target].push(data_to_push);
          }
        }
      }
    },

    get_color_indicator(item) {
      let index = this.initial_indicators.findIndex(x => x.id_neo4j === item.id_neo4j);
      if (index > -1) return item.value === this.initial_indicators[index].value ? "primary--text" : "red--text";
      return "primary--text";
    },
    up_generate_pdf: function (){
      this.generate_pdf = true;
    },
  }
}
</script>

<style scoped>
.input-constantes input{
  padding-right: 4px !important;
  text-align: right;
}
</style>
