<template>
  <v-container class="py-6">
    <v-alert v-if="inscription_success" prominent text type="success" dismissible>
      {{$t("prevana.page_inscription_1")}}
      <a @click="return_to_homepage()" class="bold--text text-decoration-underline success--text">{{$t("prevana.page_inscription_2")}}</a>
    </v-alert>

    <v-alert v-else-if="inscription_error" prominent text type="error" dismissible>
      <p>{{$t("prevana.page_inscription_3")}}</p>
      <p v-for="(error, key, index) in form_errors" :key="index">{{ key }}: {{ error }}</p>
    </v-alert>

    <p class="font-size--28 primary--text bold--text pl-12 letter-spacing--1">{{$t("prevana.page_inscription_4")}}</p>

    <div v-if="!data_fetched" class="text-center my-6">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" sm="10" offset-sm="1">
        <v-form ref="form_inscription" v-model="valid_inscription" autocomplete="off">
          <v-row>
            <v-col cols="6" class="px-sm-12">
              <AnTextField :value.sync="last_name" :label="$t('common.Nom')" class="required"
                           :required="true" :show_title="false" type_field="last_name"/>
              <AnTextField :value.sync="last_name_used" :label="$t('common.Nom utilisé')"
                           :required="false" :show_title="false" type_field="text"/>
              <AnTextField :value.sync="first_name_used" :label="$t('common.Prénom utilisé')"
                           :required="false" :show_title="false" type_field="text"/>
              <AnTextField :value.sync="sex" :label="$t('common.Sexe')" class="required"
                           :required="true" type_field="sexe" :show_title="false"/>
              <AnTextField :value.sync="postcode" :label="$t('common.Code Postal')" class="required"
                           :required="true" type_field="postcode" :show_title="false"/>
              <AnTextField :value.sync="height" :label="$t('common.Taille')" class="required" required
                           type_field="taille" :show_title="false"/>
              <v-autocomplete v-model="main_affectation"
                              :items="affectations"
                              autocomplete="chrome-off"
                              :label="$t('prevana.page_inscription_12')"
                              :rules="[rules.required]"
                              filled color="primary">
              </v-autocomplete>

              <AnBirthCountry
                :birth_country.sync="birth_country"
                :label="$t('common.Lieu de naissance')"
                required
              ></AnBirthCountry>

            </v-col>
            <v-col cols="6" class="px-sm-12">
              <AnTextField :value.sync="first_name" :label="$t('common.Prénom')" class="required"
                           :required="true" :show_title="false" type_field="text"/>
              <AnTextField :value.sync="first_name_all" :label="$t('common.Prénom(s) de naissance')"
                           :required="false" :show_title="false" type_field="text"/>
              <AnDate :date="birthdate"
                      required is_birthdate label="Date de naissance" class="required"
                      v-on:formatted_date="birthdate=$event"/>
              <AnTextField :value.sync="email" :label="$t('common.Email')" :show_title="false"
                           :required="true" type_field="email" disabled/>
              <AnTextField :value.sync="weight" :label="$t('common.Poids')" class="required" required
                           type_field="poids" :show_title="false"/>

              <AnTelInputVuetify :initial_number="phone" required filled v-on:update_phone="phone = $event" />

              <v-autocomplete v-model="secondary_affectation"
                              :items="get_affectations()"
                              autocomplete="chrome-off"
                              :label="$t('prevana.page_inscription_13')"
                              filled color="primary">
              </v-autocomplete>
            </v-col>
            <v-col cols="6" class="px-sm-12">
              <v-text-field v-model="password"
                            :label="$t('common.new_password')"
                            autocomplete="chrome-off"
                            :type="show_password ? 'text' : 'password'"
                            :rules="[rules.password_signup_min_length, rules.lower, rules.upper, rules.number, rules.unauthorized_characters]"
                            error-count="5"
                            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                            filled background-color="bg_light" color="primary"
                            @click:append="show_password = !show_password">
              </v-text-field>
            </v-col>
            <v-col cols="6" class="px-sm-12">
              <v-text-field v-model="confirm_password"
                            :label="$t('common.new_password_confirmation')"
                            autocomplete="chrome-off"
                            :type="show_password ? 'text' : 'password'"
                            :error-messages="password !== confirm_password ? 'Les mots de passe ne correspondent pas' : ''"
                            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                            filled background-color="bg_light" color="primary"
                            @click:append="show_password = !show_password">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <p class="semibold--text font-size--18 primary--text">{{$t("prevana.page_inscription_5")}}</p>
              <CguCheckbox :cgu.sync="cgu" :confidentiality.sync="confidentiality" :agency="$route.params.agency" app_name="prevana" />
            </v-col>
            <v-col cols="12" v-if="disable_inscription" class="my-6">
              <p class="semibold--text error--text font-size--16">{{$t("prevana.page_inscription_6")}}</p>
              <ListItemError v-if="!valid_inscription" :content="$t('prevana.page_inscription_7')"/>
              <ListItemError v-if="password !== confirm_password" :content="$t('prevana.page_inscription_8')"/>
              <ListItemError v-if="!cgu" :content="$t('prevana.page_inscription_9')"/>
              <ListItemError v-if="!confidentiality" :content="$t('prevana.page_inscription_10')"/>
              <ListItemError v-if="!phone" :content="$t('prevana.page_inscription_14')"/>
            </v-col>
            <v-col cols="12" class="text-center mt-6">
              <v-btn @click="reset_form()" outlined color="primary" x-large class="px-8 mx-5">
                {{$t("prevana.page_inscription_11")}}
              </v-btn>
              <v-btn @click="validate_inscription()"
                     color="primary" x-large class="px-10 mx-5 white--text"
                     :disabled="disable_inscription"
                     :loading="loading_inscription">
                {{$t("common.validate")}}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AnDate from "anam-library/src/components/an-date/AnDate";
import AnBirthCountry from "anam-library/src/components/an-ui/autocomplete/AnBirthCountry";
import AnTextField from "anam-library/src/components/an-ui/textfield/AnTextField";
import CguCheckbox from "../../common/CguCheckbox";
import ListItemError from "./ListItemError";
import AnTelInputVuetify from "anam-library/src/components/an-ui/phone/AnTelInputVuetify";

import rules from "anam-library/src/js/rules";
import moment from "moment";

export default {
    name: "SignupLayout",
    components: {
      AnDate,
      AnBirthCountry,
      AnTextField,
      ListItemError,
      CguCheckbox,
      AnTelInputVuetify
    },
    data: () => ({
      data_fetched: false,
      autolog_uid: null,
      last_name: null,
      first_name: null,
      sex: null,
      birthdate: null,
      postcode: null,
      email: null,
      phone: null,
      height: null,
      weight: null,
      password: "",
      confirm_password: "",
      valid_inscription: false,
      loading_inscription: false,
      cgu: false,
      confidentiality: false,
      rules: {
        ...rules,
        password_signup_min_length: v => v.length >= 9 || "Votre mot de passe doit contenir au moins 9 caractères",
        postcode: (v) =>
          /^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/.test(v) || "Code postal invalide",
        lower: (v) =>
          /^.*[a-z]+.*$/.test(v) ||
          "Votre mot de passe doit contenir au moins une minuscule",
        upper: (v) =>
          /^.*[A-Z]+.*$/.test(v) ||
          "Votre mot de passe doit contenir au moins une majuscule",
        number: (v) =>
          /^.*[0-9]+.*$/.test(v) ||
          "Votre mot de passe doit contenir au moins un chiffre",
        unauthorized_characters: (v) =>
          /^[0-9a-zA-Z-@#$%&<>:;,-._!=|~?[ \]^"'()*+/{}]+$/.test(v) ||
          "Seuls les caractères spéciaux suivant sont autorisés: @[ ]^_!\"#$%&'()*+,-./:;{}<>=|~?",
      },
      sex_items: [
        { value: "M", text: "Homme" },
        { value: "F", text: "Femme" },
      ],
      inscription_success: false,
      inscription_error: false,
      form_errors: {},
      affectations: [],
      main_affectation: null,
      secondary_affectation: null,
      show_password: false,
      first_name_all: null,
      first_name_used: null,
      last_name_used: null,
      birth_country: null
    }),
    watch: {
      main_affectation(val) {
        if (val === this.secondary_affectation) this.secondary_affectation = null;
      }
    },
    computed: {
      disable_inscription() {
        return (!this.valid_inscription || this.password !== this.confirm_password || !this.cgu || !this.confidentiality || !this.phone);
      },
    },
    created() {
      this.$http.get(`/api/${this.$route.params.agency}/inscription/${this.$route.params.autolog_uid}`).then((response) => {
          let data = response.data;
          this.last_name = data.last_name;
          this.first_name = data.first_name;
          if (data.personnephysique && data.personnephysique.birthdate) {
            this.birthdate = moment(data.personnephysique.birthdate.birthdate, 'DD/MM/YYYY').format('DD/MM/YYYY');
          }
          this.sex = data.personnephysique.sexe;
          this.phone = data.personnephysique.personel_phone;
          this.email = data.email;
          this.autolog_uid = data.autolog_uid;
          this.agency = this.$route.params.agency;
          this.main_affectation = data.main_aff;
          this.secondary_affectation = data.sec_aff;

          for (let aff of data.affectations) {
            this.affectations.push({value: aff.id, text: `${aff.com_name} (${aff.cmp_name} - ${aff.grp_name})`});
          }
        }).catch((error) => {
          if(error.response.status === 422) this.show_errors(error.response.data);
          else if(error.response.status === 417) this.$router.push('/expiration_token');
        }).finally(() => {
          this.data_fetched = true;
      });
    },
    methods: {
      validate_inscription() {
        this.loading_inscription = true;
        this.inscription_success = false;
        let formdata = new FormData();
        for (let el of ["last_name", "first_name", "sex", "postcode", "email", "phone", "password", "main_affectation",
          "height", "weight", "cgu", "confidentiality", "birthdate"]) {
          if (el === "birthdate") formdata.append(el, moment(this[el], "DD/MM/YYYY").format("YYYY-MM-DD"));
          else formdata.append(el, this[el]);
        }
        if (this.secondary_affectation) formdata.append("secondary_affectation", this.secondary_affectation);
        if (this.first_name_used) formdata.append("first_name_used", this.first_name_used);
        if (this.last_name_used) formdata.append("last_name_used", this.last_name_used);
        if (this.first_name_all) formdata.append("first_name_all", this.first_name_all);
        if (this.birth_country) formdata.append("birth_country", this.birth_country);

        this.$http.put("/api/" + this.agency + "/confirm_inscription/" + this.autolog_uid, formdata).then(() => {
          this.inscription_success = true;
            setTimeout(() => {
              this.return_to_homepage();
            }, 3000);
          }).catch((error) => {
            if(error.response.data === 422) this.show_errors(error.response.data);
          }).finally(() => {
            this.loading_inscription = false;
            this.$vuetify.goTo(0);
          });
      },
      show_errors(error) {
        this.form_errors = {};
        for (let el in error) {
          this.form_errors[this.labels[el]] = error[el][0];
        }
        this.inscription_error = true;
      },
      reset_form() {
        let email = this.email;
        this.$refs.form_inscription.reset();
        this.phone = "";
        this.inscription_error = false;
        setTimeout(() => {
          this.email = email;
        });
      },
      return_to_homepage() {
        window.location.href = "/" + this.agency;
      },
      get_affectations() {
        let affectations = [{ value: null, text: "Aucune" }];
        for (let el of this.affectations) {
          if (el.value !== this.main_affectation) affectations.push(el);
        }
        return affectations;
      }
    },
  };
</script>

<style scoped>
  .vue-tel-input{
    height: 50px;
    background-color: #f7f7f7;
    border: none;
    border-bottom: solid 1px #949494;
    border-radius: 4px 4px 0 0;
  }
</style>
