<template>
  <v-row class="my-1">
    <v-col cols="12" sm="4" class="pb-0">
      <v-select v-model="_handicap_type" :items="handicap_type_items" label="Type de handicap" outlined hide-details></v-select>
    </v-col>
    <v-col cols="12" sm="4">
      <v-select v-if="_handicap_type" v-model="_handicap_type_precision" :items="handicap_type_precision_items" label="Précision" outlined hide-details></v-select>
    </v-col>
    <v-col cols="12" sm="4">
      <v-select v-if="_handicap_type_precision" v-model="_duration" :items="duration_items" label="Durée" outlined hide-details></v-select>
    </v-col>
    <v-col cols="2" class="pa-0">
      <v-btn color="red" text @click="$emit('delete_handicap')">
        <span class="text-decoration-underline">Supprimer</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import {DURATION_CHOICES, HANDICAP_CHOICES, HANDICAP_PRECISION_CHOICES} from "../../../../../data/vma/dict_vma";

export default {
  name: "HandicapType",
  props: {
    handicap_type: {
      type: String,
      default: null
    },
    handicap_type_precision: {
      type: String,
      default: null
    },
    duration: {
      type: String,
      default: null
    }
  },

  computed: {
    _handicap_type: {
      get() {
        return this.handicap_type;
      },
      set(value) {
        this.$emit("update:handicap_type", value);
      },
    },
    _handicap_type_precision: {
      get() {
        return this.handicap_type_precision;
      },
      set(value) {
        this.$emit("update:handicap_type_precision", value);
      },
    },
    _duration: {
      get() {
        return this.duration;
      },
      set(value) {
        this.$emit("update:duration", value);
      },
    },
    handicap_type_items() {
      return HANDICAP_CHOICES;
    },
    handicap_type_precision_items() {
      return HANDICAP_PRECISION_CHOICES.filter(x => x.handicap_type === this.handicap_type);
    },
    duration_items() {
      return DURATION_CHOICES;
    }
  }
}
</script>

<style scoped>

</style>
