<template>
  <div v-if="value">
    <p :class="`ma-0 ${color}--text medium--text`">{{value.name}}</p>
    <p class="ma-0 font-italic font-size--14">({{ value.date | datetime }})</p>
  </div>
  <v-icon v-else small>mdi-minus</v-icon>
</template>

<script>
import {mixin_dates} from "anam-library/src/js/dates";

export default {
  name: "QuestionnaireECOSStatusItem",
  props: ['item', 'variable'],
  mixins: [mixin_dates],
  computed: {
    value() {
      return this.item.latest_questionnaire_sent;
    },
    color() {
      if (this.value.status === "not_started") return "red";
      if (this.value.status === "in_progress") return "orange";
      if (this.value.status === "finished") return "green";
      return "";
    }
  }
}
</script>

<style scoped>

</style>
