<template>
  <v-row>
    <v-col cols="12">
      <p class="soft_blue--text normal--text font-size--18 mb-0 mb-sm-3">
        Afin de passer à l'étape suivante, merci de nous indiquer les informations suivantes :
      </p>
    </v-col>
    <v-col cols="12" sm="2" offset-sm="1" class="align-self-center">
      <AnTextField :value.sync="_sex" title="Sexe" placeholder="Sexe" :required="true" type_field="sexe" icon="mdi-gender-male-female"/>
    </v-col>
    <v-col cols="12" sm="4" offset-sm="1" class="align-self-center">
      <AnDate :date="_birthdate"
              :required="true"
              :show_title="true"
              is_birthdate
              icon=""
              title="Date de naissance"
              v-on:formatted_date="_birthdate = $event"/>
    </v-col>
  </v-row>
</template>

<script>
import AnTextField from "anam-library/src/components/an-ui/textfield/AnTextField";
import AnDate from "anam-library/src/components/an-date/AnDate";

export default {
  name: "ExpandInfoStartAnonymous",
  components: {AnDate, AnTextField},
  props: ["sex", "birthdate"],

  computed: {
    _sex: {
      get() {
        return this.sex;
      },
      set(val) {
        this.$emit("update:sex", val);
      }
    },
    _birthdate: {
      get() {
        return this.birthdate;
      },
      set(val) {
        this.$emit("update:birthdate", val);
      }
    }
  }
}
</script>
