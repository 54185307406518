<template>
  <v-container fluid class="pa-6">
    <v-row class="mx-0">
      <v-col cols="12">
        <div class="d-flex">
          <img :src="logo" width="50" height="auto" class="rounded-lg mr-4" alt="Logo Vaccination"/>
          <span class="secondary--text bold--text font-size--24 align-self-center">Vaccination</span>
        </div>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import logo from "@/assets/picto-vaccination.png"

export default {
  name: "PatientVaccinationLayout",
  data: () => ({
    logo: logo
  })
}
</script>

<style scoped>

</style>
