import { render, staticRenderFns } from "./HFManagerCovidClustersView.vue?vue&type=template&id=3fe198b4&scoped=true&"
import script from "./HFManagerCovidClustersView.vue?vue&type=script&lang=js&"
export * from "./HFManagerCovidClustersView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fe198b4",
  null
  
)

export default component.exports