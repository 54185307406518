// TODO: passer en JSONField sur agency pour pouvoir gérer par django admin
import SDIS30WelcomeDialog from "../../components/patient/rps/WelcomeDialog/SDIS30WelcomeDialog";

const DICT_CONTACT = {
  "ESSCA": {
    "Mes contacts": [
      {'first_name': '', 'last_name': 'M Hadrien ROBIDAS', 'profession': '', 'email': 'celluledecoute-campus@essca.fr', 'phone': '0241577311'},
    ]
  },
  "ESCP": {
    "Mes contacts": [
      {'first_name': 'Bruna', 'last_name': 'Da Silva Ferreira', 'profession': 'vma.Infirmière & Référente handicap', 'email': 'health@escp.eu', 'phone': '+33 1 49 23 22 03'},
    ]
  },
  "IRBA": {
    "Mes contacts": [
      {'first_name': '', 'last_name': 'PG TYMEN', 'profession': "", 'email': "Chef d'organisme", 'phone': '01 78 65 10 04'},
      {'first_name': '', 'last_name': 'Mme EGALON', 'profession': '', 'email': 'Responsable RH de proximité', 'phone': '01 78 65 14 88'},
      {'first_name': '', 'last_name': 'Mme VAILLANT', 'profession': '', 'email': 'Chargé de prévention des risques professionnels', 'phone': '01 78 65 14 70'},
      {'first_name': '', 'last_name': 'MC RAMDANI', 'profession': '', 'email': 'Référent Risques Psychosociaux', 'phone': '01 78 65 12 65'},
      {'first_name': '', 'last_name': 'M. FAILLIE', 'profession': '', 'email': 'Médecin de prévention', 'phone': '01 81 84 04 05'},
      {'first_name': '', 'last_name': 'OLIVE', 'profession': '', 'email': 'Infirmière de prévention ISG 2G', 'phone': '01 81 84 04 37'},
      {'first_name': '', 'last_name': 'MC JALADY', 'profession': '', 'email': 'CMA de Versailles - Antenne médicale de Brétigny', 'phone': '01 81 84 03 45'},
      {'first_name': '', 'last_name': 'MED BOUE', 'profession': '', 'email': 'CMA de Versailles - Antenne médicale de Brétigny', 'phone': '01 81 84 03 34'},
      {'first_name': '', 'last_name': 'Mme MAS', 'profession': '', 'email': 'Assistant de service social', 'phone': '01 60 85 55 92'},
    ]
  },
  "UP8": {
    "Mes contacts": [
      {'first_name': '', 'last_name': 'Médecine préventive', 'profession': "", 'email': "Maison de l’étudiant – 1er étage\nmedecine.preventive@univ-paris8.fr", 'phone': '01 49 40 65 10'},
    ]
  },
  "SDIS30": {
    "Professionnels de Santé": [
      {'first_name': '', 'last_name': 'Secrétariat de la SDS - Irène DELAVAUD', 'profession': "", 'email': "sssm-secretariat@sdis30.fr", 'phone': "04 66 63 36 60", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/PDS/Secr%C3%A9tariat%20SDS-Mme%20DELAVAUD%20Ir%C3%A8ne.png"},
      {'first_name': 'Dr Philippe', 'last_name': 'AGOPIAN', 'profession': "Médecin-chef du SDIS30", 'email': "p.agopian@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/PDS/M%C3%A9decin-chef%20Dr%20AGOPIAN%20Philippe.png"},
      {'first_name': 'Dr Isabelle', 'last_name': 'ARNAUD', 'profession': "Médecin", 'email': "i.arnaud@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/PDS/Dr%20ARNAUD%20Isabelle.png"},
      {'first_name': 'Dr Julien', 'last_name': 'CHERET', 'profession': "Médecin - Groupement Garrigues Camargues", 'email': "j.cheret@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/PDS/M%C3%A9decin%20GPT%20GC-Dr%20CHERET%20Julien.png"},
      {'first_name': 'Dr Louis', 'last_name': 'FODOUP FOTSO', 'profession': "Médecin - Groupement Cévennes Aigoual", 'email': "l.fodoup@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/PDS/M%C3%A9decin%20GPT%20CA-Dr%20FODOUP%20Louis.png"},
      {'first_name': 'Dr Frank', 'last_name': 'PONS', 'profession': "Médecin - Groupement Vallée du Rhône", 'email': "f.pons@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/PDS/M%C3%A9decin%20GPT%20VDR-Dr%20PONS%20Frank.png"},
      {'first_name': 'Thierry', 'last_name': 'CRUZ', 'profession': "Psychologue", 'email': "t.cruz@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/PDS/PSYCHOLOGUE%20COORDONATEUR-Thierry%20CRUZ.png"},
      {'first_name': 'Anne', 'last_name': 'DEHAPIOT', 'profession': "Infirmière - Groupement Vallée du Rhône", 'email': "a.dehapiot@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/PDS/INF%20GPT%20VDR-DEHAPIOT%20Anne.png"},
      {'first_name': 'David', 'last_name': 'DRAUSSIN', 'profession': "Infirmier - Groupement Garrigues Camargues", 'email': "d.draussin@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/PDS/INF%20GPT%20GC%20-%20DRAUSSIN%20David.png"},
      {'first_name': 'Laurent', 'last_name': 'HALLOSSERIE', 'profession': "Infirmier - Groupement Cévennes Aigoual", 'email': "l.hallosserie@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/PDS/INF%20GPT%20CA-HALLOSSERIE%20Laurent.png"},
      {'first_name': 'Angélique', 'last_name': 'JUSTE', 'profession': "Infirmière - Groupement Garrigues Camargues", 'email': "a.juste@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/PDS/INF%20GPT%20GC-JUSTE%20Ang%C3%A9lique.png"},
      {'first_name': 'Laurent', 'last_name': 'LANDES', 'profession': "Infirmier - Groupement Vallée du Rhône", 'email': "l.landes@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/PDS/INF%20GPT%20VDR-LANDES%20Laurent.png"},
      {'first_name': 'Paul', 'last_name': 'RIBELLES', 'profession': "Infirmier Chef - Cadre de santé", 'email': "p.ribelles@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/PDS/INFIRMIER%20Chef-RIBELLES%20Paul.png"},
      {'first_name': 'Catherine', 'last_name': 'SOL', 'profession': "Infirmière de groupement - Direction", 'email': " c.sol@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/PDS/INF%20GPT%20DIRECTION%20-%20SOL%20Cathy.png"},
    ],
    "Service BMS": [
      {'first_name': 'Maryline', 'last_name': 'POULAT', 'profession': "Responsable du BMS", 'email': "m.poulat@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/service%20BMS/Mme%20POULAT%20Maryline.png"},
      {'first_name': 'Amélie', 'last_name': 'DELIGNÉ', 'profession': "Adjointe du BMS", 'email': "a.deligne@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/service%20BMS/Am%C3%A9lie%20DELIGN%C3%89.jpg"},
    ],
    "Assistants de prévention": [
      {'first_name': 'Florian GAU & Martine SEVES', 'last_name': '', 'profession': "Assistant de prévention (CSP NIMES)", 'email': "m.seves@sdis30.fr\nf.gau@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/AP/Florian%20GAU.jpg"},
      {'first_name': 'Benoit', 'last_name': 'DEVALUEZ', 'profession': "Assistant de prévention (CS BEAUCAIRE)", 'email': "b.devaluez@sdis30.FR", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/AP/Beno%C3%AEt%20DEVALUEZ.jpg"},
      {'first_name': 'Eugene SALLES & Manoel VALENCIA', 'last_name': '', 'profession': "Assistant de prévention (CS LE VIGAN)", 'email': "e.salles@sdis30.fr\nm.valencia@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/AP/Manoel%20VALENCIA.jpg"},
      {'first_name': 'Alexis', 'last_name': 'VIDAL', 'profession': "Assistant de prévention (CS MARGUERITTES et CTA / CODIS)", 'email': "a.vidal@sdis30.fr", 'phone': "", "avatar": ""},
      {'first_name': 'Marina', 'last_name': 'BEYOU', 'profession': "Assistant de prévention (CS SAINT GILLES)", 'email': "m.beyou@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/AP/Marina%20BEYOU.jpg"},
      {'first_name': 'Vincent', 'last_name': 'IRLE', 'profession': "Assistant de prévention (CS SOMMIERES)", 'email': "v.irle@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/AP/Vincent%20IRLE.jpg"},
      {'first_name': 'Sebastien', 'last_name': 'VIDAL', 'profession': "Assistant de prévention (CS VERGEZE)", 'email': "s.vidal@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/AP/VIDAL%20SEBASTIEN.png"},
      {'first_name': 'David', 'last_name': 'MARTINEZ', 'profession': "Assistant de prévention (CSP BAGNOLS SUR CEZE)", 'email': "d.martinez@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/AP/David%20MARTINEZ.jpg"},
      {'first_name': 'Alexandra BONNET & Sébastien VIDAL', 'last_name': '', 'profession': "Assistant de prévention (CSP VAUVERT)", 'email': "a.bonnet@sdis30.fr\ns.vidal@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/AP/Alexandra%20BONNET.jpg"},
      {'first_name': 'Tommy', 'last_name': 'BAROFFIO', 'profession': "Assistant de prévention (GF FORMATION)", 'email': "t.baroffio@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/AP/Tommy%20BAROFFIO.jpg"},
      {'first_name': 'Amélie', 'last_name': 'DELIGNÉ', 'profession': "Assistant de prévention (DIRECTION : site Pavlov et Joliot Curie)", 'email': "a.deligne@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/service%20BMS/Am%C3%A9lie%20DELIGN%C3%89.jpg"},
      {'first_name': 'Guillaume', 'last_name': 'DAUNAY', 'profession': "Assistant de prévention (Service Prévention)", 'email': "g.daunay@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/AP/Guillaume%20DAUNAY.jpg"},
      {'first_name': 'Marilyn', 'last_name': 'TAFANI', 'profession': "assistant de prévention (Service SSSM)", 'email': "m.tafani@sdis30.fr", 'phone': "", "avatar": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Contacts_SDIS30/AP/Marylin%20TAFANI.jpg"},
    ]
  },
  "TISSEO": {
    "Mes contacts": [
      {'first_name': 'Cyril', 'last_name': 'Bisiaux', 'profession': 'CFDT', 'email': '', 'phone': ''},
    ]
  },
  "demo-ecole": {
    "Mes contacts": [
      {'first_name': 'Sophie', 'last_name': 'Durand', 'profession': 'Infirmière scolaire', 'email': 'sophie.durand@gmail.com', 'phone': '06 57 89 35 77'},
      {'first_name': 'Fabrice', 'last_name': 'Joly', 'profession': 'Psychologue', 'email': 'fabrice.joly@gmail.com', 'phone': '07 67 45 23 12'},
      {'first_name': 'Pierre', 'last_name': 'Coubert', 'profession': 'Responsable pédagogique', 'email': 'pierre.coubert@gmail.com', 'phone': '07 56 45 34 89'},
    ]
  },
  "demo-entreprise": {
    "Mes contacts": [
      {'first_name': 'Sophie', 'last_name': 'Durand', 'profession': 'Responsable QVT', 'email': 'sophie.durand@gmail.com', 'phone': '06 57 89 35 77'},
      {'first_name': 'Fabrice', 'last_name': 'Joly', 'profession': 'Responsable ressources humaines', 'email': 'fabrice.joly@gmail.com', 'phone': '07 67 45 23 12'},
    ]
  },
  "demo-sdis": {
    "Mes contacts": [
      {'first_name': 'Sophie', 'last_name': 'Durand', 'profession': 'Infirmière', 'email': 'sophie.durand@gmail.com', 'phone': '06 57 89 35 77'},
      {'first_name': 'Fabrice', 'last_name': 'Joly', 'profession': 'Psychologue', 'email': 'fabrice.joly@gmail.com', 'phone': '07 67 45 23 12'},
      {'first_name': 'Pierre', 'last_name': 'Coubert', 'profession': 'Médecin chef', 'email': 'pierre.coubert@gmail.com', 'phone': '07 56 45 34 89'},
    ]
  },
  "demo-hopital": {
    "Mes contacts": [
      {'first_name': 'Sophie', 'last_name': 'Durand', 'profession': 'Responsable QVT', 'email': 'sophie.durand@gmail.com', 'phone': '06 57 89 35 77', 'avatar': 'https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20HOPITAL/contact%201%20centre%20hospitalier.png'},
      {'first_name': 'Fabrice', 'last_name': 'Joly', 'profession': 'Responsable ressources humaines', 'email': 'fabrice.joly@gmail.com', 'phone': '07 67 45 23 12', 'avatar': 'https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20HOPITAL/contact%202%20centre%20hospitalier.png'},
    ]
  },
  "cephismer": {
    "Mes contacts": [
      {'first_name': 'Céline', 'last_name': 'RAMDANI', 'email': 'celine.ramdani@intradef.gouv.fr', 'phone': '06 62 19 42 97'},
    ]
  },
  "default": {
    "Mes contacts": []
  }
}

export function get_contacts(agency_name) {
  if (Object.keys(DICT_CONTACT).includes(agency_name)) {
    return DICT_CONTACT[agency_name];
  }
  return DICT_CONTACT.default;
}

const DICT_RESSOURCES = {
  "UP8": [
    {name: "Services d'écoute et d'urgence", logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/UP8/logop8-transparent.png", url: "https://www.univ-paris8.fr/Services-d-ecoute-et-d-urgence"},
  ],
  "SDIS30": [
    {name: "NDS - Assistante Sociale", logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Miniature%20-%20NDS-%20Assistante%20Sociale.png", url: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/NDS-%20Assistante%20Sociale.pdf", page: 1},
    {name: "NDS - Outrages, Menaces ou Violences", logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Miniature%20-%20NDS-%20Outrages%20Menaces%20Violences.png", url: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/NDS-Outrages%20Menaces%20Violences.pdf", page: 1},
    {name: "Note d'information - Logiciel Prevana-RPS", logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Miniature%20NDI%20Logiciel%20pr%C3%A9vana%20rps.png", url: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/NOTE%20D%20INFORMATION%20-%20LOGICIEL%20PREVANA-RPS.pdf", page: 1},
    {name: "Interview - Thierry CRUZ, Psychologue Expert", logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Miniature%20-%20Interview-%20Thierry%20Cruz%2c%20Psychologue%20Expert.jpg", url: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Thierry%20Cruz%20-%20Psychologue%20Expert%20(Interview).mp4", page: 1},
    {name: "Dispositif de signalement", logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Dispositif%20de%20signalement.jpg", url: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Dispositif%20de%20signalement.jpg", page: 1},
    {name: "Agissements sexistes dans les SDIS", logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Ep%201.png", url: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Episode%2001%20Des%20agissements%20sexistes%20dans%20les%20SDIS%20.mp4", page: 2},
    {name: "Harcèlement moral dans les SDIS", logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Ep%202.png", url: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Episode%2002%20Du%20harc%C3%A8lement%20moral%20dans%20les%20SDIS%20.mp4", page: 2},
    {name: "Discriminations dans les SDIS", logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Ep%203.png", url: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Episode%2003%20Des%20discriminations%20dans%20les%20SDIS%20.mp4", page: 2},
    {name: "Violences dans les SDIS", logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Ep%204.png", url: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Episode%2004%20Des%20violences%20dans%20les%20SDIS%20.mp4", page: 2},
    {name: "Harcèlement sexuel dans les SDIS", logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Ep%205.png", url: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Ressources/Episode%2005%20Du%20harc%C3%A8lement%20sexuel%20dans%20les%20SDIS%20.mp4", page: 2},
  ],
  "DEMO": [
    {name: "Équilivre vie personnelle et professionnelle", logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/DEMO%20Entreprise/%C3%89quilibre%20vie%20personnelle%20et%20professionnelle.png", url: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/DEMO%20Entreprise/%C3%89quilibre%20vie%20personnelle%20et%20professionnelle.png"},
    {name: "Faire face au stress", logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/DEMO%20Entreprise/Faire%20face%20au%20stress.png", url: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/DEMO%20Entreprise/Faire%20face%20au%20stress.png"},
    {name: "Manager sans harceler", logo: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/DEMO%20Entreprise/Manager%20sans%20harceler.png", url: "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/DEMO%20Entreprise/Manager%20sans%20harceler.png"}
  ],
  "demo-hopital": [
    {name: "", logo: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20HOPITAL/ressource%20statuts%20centre%20hospitalier.png", url: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20HOPITAL/CFG_DEMO_Ressources_Les%20diff%C3%A9rents%20statuts%20en%20milieu%20hospitalier.pdf"},
    {name: "", logo: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20HOPITAL/ressource%20organigramme%20centre%20hospitalier.png", url: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20HOPITAL/CFG_DEMO_Ressources_Les%20diff%C3%A9rents%20services%20en%20milieu%20hospipalier.pdf"},
    {name: "", logo: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20HOPITAL/ressource%20don%20du%20sang%20centre%20hospitalier.png", url: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20HOPITAL/CFG_DEMO_Ressources_Don%20du%20sang.pdf"},
    {name: "", logo: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20HOPITAL/ressource%20stress%20centre%20hospitalier.png", url: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20HOPITAL/CFG_DEMO_Ressources_Gestion%20stress.pdf"},
  ],
  "demo-ecole": [
    {name: "", logo: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20ECOLE/1.jpg", url: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20ECOLE/1.jpg"},
    {name: "", logo: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20ECOLE/2.jpg", url: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20ECOLE/2.jpg"},
    {name: "", logo: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20ECOLE/3.jpg", url: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20ECOLE/3.jpg"},
  ],
  "demo-entreprise": [
    {name: "", logo: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20ENTREPRISE/1.jpg", url: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20ENTREPRISE/1.jpg"},
    {name: "", logo: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20ENTREPRISE/2.jpg", url: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20ENTREPRISE/2.jpg"},
    {name: "", logo: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20ENTREPRISE/3.jpg", url: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20ENTREPRISE/3.jpg"},
  ],
  "demo-sdis": [
    {name: "", logo: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20SDIS/Ressources%20SDIS%20demo%20(5).gif", url: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20SDIS/Ressources%20SDIS%20demo%20(5).gif"},
    {name: "", logo: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20SDIS/Ressources%20SDIS%20demo%20(6).gif", url: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20SDIS/Ressources%20SDIS%20demo%20(6).gif"},
    {name: "", logo: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20SDIS/Ressources%20SDIS%20demo%20(7).gif", url: "https://www.anamnese.care/hubfs/Visuels/DEMO/DEMO%20SDIS/Ressources%20SDIS%20demo%20(7).gif"},
  ],
  "default": []
}

export function get_ressources(agency_name) {
  if (Object.keys(DICT_RESSOURCES).includes(agency_name)) {
    return DICT_RESSOURCES[agency_name];
  }
  return DICT_RESSOURCES.default;
}


const DICT_DURATION = {
  "IRBA": "30",
  "default": "10"
}

export function get_duration(agency_name) {
  if (Object.keys(DICT_DURATION).includes(agency_name)) {
    return DICT_DURATION[agency_name];
  }
  return DICT_DURATION.default;
}

const DICT_QUESTIONNAIRE_INFO = {
  "IRBA": "Ceci est un questionnaire sécurisé qui permettra à l'IRBA de mener son enquête RPS",
  "SDIS30": "C'est un questionnaire sécurisé qui permettra au SDIS de mieux connaître l'état général de ses services.<br/><br/>" +
    "En répondant au questionnaire, vous aidez à l'amélioration de la qualité de vie au travail.<br/>" +
    "Seuls les MSPP et le psychologue expert auront accès à vos données de santé.<br/><br/>" +
    "Nous attirons votre attention sur le fait que l’échelle peut sembler inversée car nous suivons la règle fixée par" +
    " le COPSOQ.<br/>" +
    "De plus, par entreprise, on comprendra la collectivité territoriale.",
  "default": null
}

export function get_questionnaire_info(agency_name) {
  if (Object.keys(DICT_QUESTIONNAIRE_INFO).includes(agency_name)) {
    return DICT_QUESTIONNAIRE_INFO[agency_name];
  }
  return DICT_QUESTIONNAIRE_INFO.default;
}

const DICT_SHOW_DOCTOR_IMAGE = {
  "IRBA": false,
  "default": true
}

export function get_show_doctor_image(agency_name) {
  if (Object.keys(DICT_SHOW_DOCTOR_IMAGE).includes(agency_name)) {
    return DICT_SHOW_DOCTOR_IMAGE[agency_name];
  }
  return DICT_SHOW_DOCTOR_IMAGE.default;
}

const DICT_PROFILE = {
  "IRBA": "Vérifiez les information qui vous concernent et ne remplissez que les cases obligatoires",
  "default": "vma.profile_infos_status"
}

export function get_profile_text(agency_name) {
  if (Object.keys(DICT_PROFILE).includes(agency_name)) {
    return DICT_PROFILE[agency_name];
  }
  return DICT_PROFILE.default;
}

const DICT_WELCOME_COMPONENT = {
  "SDIS30": SDIS30WelcomeDialog,
  "default": null
}

export function get_welcome_component(agency_name) {
  if (Object.keys(DICT_WELCOME_COMPONENT).includes(agency_name)) {
    return DICT_WELCOME_COMPONENT[agency_name];
  }
  return DICT_WELCOME_COMPONENT.default;
}

const DICT_ADDITIONAL_LOGO_HEADER = {
  "SDIS30": "https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Logos/LOGO_SDIS30_fondblanc.jpg",
  "default": null
}

export function get_additional_logo_header(agency_name) {
  if (Object.keys(DICT_ADDITIONAL_LOGO_HEADER).includes(agency_name)) {
    return DICT_ADDITIONAL_LOGO_HEADER[agency_name];
  }
  return DICT_ADDITIONAL_LOGO_HEADER.default;
}

const DICT_TABS_BY_AGENCY = {
  "SDIS30": ["/api/all_patients"]
}

export function filter_tabs_by_agency(data, agency_name) {
  if (Object.keys(DICT_TABS_BY_AGENCY).includes(agency_name)) {
    let tmp = {};
    for (let key in data) {
      if (DICT_TABS_BY_AGENCY[agency_name].includes(data[key].axios)) {
        tmp[key] = data[key];
      }
    }
    return tmp;
  }
  return data;
}
