<template>
  <v-row class="text-center mt-3" id="decision_buttons" justify="center">
    <v-col cols="12" v-if="state !== 'check' && state !== 'newcheck_need'">
      <p class="mt-2 font-size--18">
        Ce patient est actuellement en
        <span class="primary--text font-size--18 bold--text">{{ state }}</span>.
      </p>
    </v-col>
    <v-col v-show="state !== type.state" cols="auto" v-for="(type, index) in types" :key="index">
      <v-btn outlined color="primary" @click="update_state(type.state)">
        {{ type.title }}
      </v-btn>
    </v-col>
    <v-col cols="12" class="mt-3">
      <p>Statut de vaccination: <span
        class="primary--text font-size--18 bold--text">{{ vaccination_status_text }}</span>
      </p>
      <p>Nombre d'injections réalisées: <span
        class="primary--text font-size--18 bold--text">{{ has_event_vaccination ? "-" : injections_performed }}</span>
      </p>
    </v-col>
  </v-row>
</template>

<script>
import {DICT_STATUS_STATUS_VACCINATION} from "prevana-library/src/js/dict_status";
import * as Sentry from "@sentry/vue";


export default {
  props: {
    pds_uid: {
      type: String,
    },
  },
  data() {
    return {
      help_definitions: false,
      types: [
        {title: "Suivi SSSM", state: "suivi"},
        {title: "Surveillance", state: "surveillance"},
        {title: "Autosuivi", state: "autosuivi"},
        {title: "Convalescence", state: "convalescence"},
      ],
      injections_performed: 0,
      vaccination_status: null,
      DICT_STATUS_STATUS_VACCINATION: DICT_STATUS_STATUS_VACCINATION
    };
  },

  mounted() {
    this.$http.get('/api/patients/' + this.$route.params.id + "/vaccination_data").then(response => {
      if (response.data.actevaccination_list) this.injections_performed = response.data.actevaccination_list.filter(x => x.status === 9).length;
      if (response.data.status) this.vaccination_status = response.data.status;
    });
  },

  methods: {
    update_state: function (new_state) {
      let form_data = new FormData();
      form_data.append("state", new_state);

      this.$http.post(`/api/demande_de_depistage/${this.pds_uid}/state`, form_data).then(() => {
        this.$store.commit("PostSuccess", "Modifications enregistrées");
        this.state = new_state;
      }).catch((error) => {
        if(error.response === undefined) {
          Sentry.captureException(error);
        }
      });
    },
  },
  computed: {
    state: {
      get() {
        return this.$store.state.dpa.state;
      },
      set(value) {
        this.$store.state.dpa.state = value;
      },
    },
    title_decision() {
      if (this.state !== "check" && this.state !== "newcheck_need") {
        return "Votre Décision";
      }
      return "Modification";
    },
    has_event_vaccination() {
      return this.$store.state.dpa.evenements.vaccination.status;
    },
    vaccination_status_text() {
      if (this.has_event_vaccination) return "Vacciné";
      if (this.vaccination_status) return DICT_STATUS_STATUS_VACCINATION[this.vaccination_status];
      return "Non évalué";
    }
  },
};
</script>

<style>
</style>
