<template>
  <div>
    <v-container fluid class="bg_soft">
      <v-row>
        <v-col cols="12" sm="4" md="2" class="text-center">
          <img src="@/assets/vaccination/picto_woman.png" class="picto_woman"/>
        </v-col>
        <v-col cols="12" sm="8" md="10" class="align-self-center">
          <p class="soft_blue--text bold--text font-size--25 mb-0">Votre inscription est confirmée</p>
          <p v-show="can_access_to_rdv" class="soft_blue--text normal--text font-size--16">
            Veuillez patienter pendant que nous vous redirigeons vers la prise de RDV.
          </p>
          <p v-show="can_access_to_rdv === false" class="soft_blue--text normal--text font-size--16">
            Vos réponses ne vous permettent pas de prendre RDV. Votre centre de vaccination va évaluer votre situtation
            et vous proposera une autre solution.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pt-6 justify-center text-center">
      <v-progress-circular v-if="loading" indeterminate color="soft_blue" :size="70"></v-progress-circular>
      <div v-show="can_access_to_rdv === false">
        <v-btn large class="px-8 white--text mt-4 mt-sm-10" color="soft_blue" @click="goTo('VaccinationHomeView')">
          <span class="font-size--16 normal--text">Retour à l'accueil</span>
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
    name: "VaccinationSignupConfirmedView",
    data: () => ({
      status_vaccination: null,
      can_access_to_rdv: null,
      loading: true,
    }),
    watch: {
      status_vaccination(val) {
        if (val === 1) {
          this.can_access_to_rdv = true;
          setTimeout(() => (this.$router.push({name: 'PatientVaccinationAppointement'})), 3000);
        }
        else {
          this.loading = false;
          this.can_access_to_rdv = false;
        }
      }
    },
    created() {
      this.$store.commit('setAgencyName', this.$route.params.agency_name);
      this.$http.get(`/api/vaccination/signup/${this.$route.params.token}`).then(response_ => {
        this.status_vaccination = response_.data.status;
        this.$http.get("/api/user").then(response => {
          this.$store.commit("setPatientData", response.data);
          this.$store.commit('setPatientCgu', !!response.data.userutils.prevana_cgu_accord);
          this.$store.commit('setPatientConfidentiality', !!response.data.userutils.prevana_politique_confidentialite_accord);
          this.$store.commit("setQuestionnaireName", response.data.questionnaire_name);
          this.$store.commit("setAgencyType", response.data.agency_type);
        })
      })
    },
  }
</script>

<style scoped>
  img.picto_woman{
    height: 100px;
  }

  @media (max-width: 479px) {
    img.picto_woman{
      width: 80%;
      height: auto;
    }
  }
</style>
