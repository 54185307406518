<template>
  <v-row class="mt-2">
    <v-col cols="1" class="py-0" offset="8">
      <v-btn fab dark elevation="2" :color="filter_selected ? 'red' : 'primary'" @click="show_filters = !show_filters">
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </v-col>

    <v-col cols="3" class="py-0">
      <v-card style="border-radius: 50px">
        <v-text-field rounded filled hide-details clearable prepend-inner-icon="mdi-magnify" label="Rechercher" v-model="$store.state.dashboard_filter.dashboard_search"/>
      </v-card>
    </v-col>

    <v-col cols="12" v-if="show_filters">
      <v-row class="white px-3 pb-3">
        <v-col cols="12" class="text-center">
          <v-alert type="info" text class="text-left" dense>
            <span class="medium--text">Les filtres ne s'appliquent qu'aux étudiant ayant rempli au moins une fois le questionnaire.</span>
          </v-alert>
          <v-btn text @click="reset_filter()" color="primary">
            <span class="font-weight-bold">Réinitialiser les filtres</span>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="4">
          <v-badge :value="_marital_status_filter.length" color="red" left overlap style="width: 100%">
            <v-select v-model="_marital_status_filter" :items="items_marital_status" multiple color="primary" filled item-color="primary" label="Statut"/>
          </v-badge>
        </v-col>

        <v-col cols="4">
          <v-badge :value="_studies_funding_filter.length" color="red" left overlap style="width: 100%">
            <v-select v-model="_studies_funding_filter" :items="items_studies_funding" multiple color="primary" filled item-color="primary" label="Financement des études"/>
          </v-badge>
        </v-col>

        <v-col cols="4">
          <v-badge :value="_travel_time_filter.length" color="red" left overlap style="width: 100%">
            <v-select v-model="_travel_time_filter" :items="items_travel_time" multiple color="primary" filled item-color="primary" label="Temps de trajet"/>
          </v-badge>
        </v-col>

        <v-col cols="4">
          <v-badge :value="_stress_filter.length" color="red" left overlap style="width: 100%">
            <v-select v-model="_stress_filter" :items="items_stress" multiple color="primary" item-color="primary" filled label="Études facteur de stress"/>
          </v-badge>
        </v-col>

        <v-col cols="4">
          <v-badge :value="_repeated_year_filter.length" color="red" left overlap style="width: 100%">
            <v-select v-model="_repeated_year_filter" :items="items_repeated_year" multiple color="primary" item-color="primary" filled label="Redoublement"/>
          </v-badge>
        </v-col>

        <v-col cols="4">
          <v-switch v-model="show_inactive" @change="$emit('show_inactive', $event)" label="Afficher les utilisateurs désactivés" hide-details></v-switch>
        </v-col>

      </v-row>
    </v-col>

  </v-row>
</template>

<script>
import {get_patient_data_ecos} from "../../../../data/ecos/utils";

export default {
  name: "PatientSearch",
  props: {
    marital_status_filter: {
      type: Array,
      default: () => []
    },
    studies_funding_filter: {
      type: Array,
      default: () => []
    },
    travel_time_filter: {
      type: Array,
      default: () => []
    },
    stress_filter: {
      type: Array,
      default: () => []
    },
    repeated_year_filter: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    show_filters: false,
    show_inactive : false,
    items_repeated_year: [
      {value: true, text: "Oui"},
      {value: false, text: "Non"},
    ]
  }),
  computed: {
    _marital_status_filter: {
      get() {
        return this.marital_status_filter;
      },
      set(value) {
        this.$emit("update:marital_status_filter", value);
      },
    },
    _studies_funding_filter: {
      get() {
        return this.studies_funding_filter;
      },
      set(value) {
        this.$emit("update:studies_funding_filter", value);
      },
    },
    _travel_time_filter: {
      get() {
        return this.travel_time_filter;
      },
      set(value) {
        this.$emit("update:travel_time_filter", value);
      },
    },
    _stress_filter: {
      get() {
        return this.stress_filter;
      },
      set(value) {
        this.$emit("update:stress_filter", value);
      },
    },
    _repeated_year_filter: {
      get() {
        return this.repeated_year_filter;
      },
      set(value) {
        this.$emit("update:repeated_year_filter", value);
      },
    },
    filter_selected() {
      return this._marital_status_filter.length
        || this._studies_funding_filter.length
        || this._travel_time_filter.length
        || this._stress_filter.length
        || this._repeated_year_filter.length
        || this.show_inactive
    },
    items_marital_status() {
      return get_patient_data_ecos("items_marital_status");
    },
    items_studies_funding() {
      return get_patient_data_ecos("items_studies_funding");
    },
    items_travel_time() {
      return get_patient_data_ecos("items_travel_time");
    },
    items_stress() {
      return get_patient_data_ecos("items_stress");
    }
  },
  methods: {
    reset_filter() {
      this._marital_status_filter = [];
      this._studies_funding_filter = [];
      this._travel_time_filter = [];
      this._stress_filter = [];
      this._repeated_year_filter = [];
      this.show_inactive = false;
    }
  }
}
</script>
