import FooterFr from 'anam-library/src/i18n/footer/fr.json'
import HeaderFr from 'anam-library/src/i18n/header/fr.json'
import RulesFr from 'anam-library/src/i18n/rules/fr.json'
import ErrorFr from 'anam-library/src/i18n/error-pages/fr.json'
import DashboardFr from 'anam-library/src/i18n/dashboard/fr.json'
import CommonFr from 'anam-library/src/i18n/common/fr.json'
import NotesFr from 'anam-library/src/i18n/notes/fr.json'
import DpaFr from 'anam-library/src/i18n/dpa/fr.json'
import EmailFr from './email/fr.json'
import VisioFr from "anam-library/src/i18n/teleconsultation/fr.json"
import AuthFr from 'anam-library/src/i18n/auth/fr.json'
import MedicalFormFr from 'anam-library/src/i18n/MedicalForm/fr.json'
import prevanaFr from 'prevana-library/src/i18n/prevana/fr.json'
import VmaFr from './vma/fr.json'
import CalendarFr from 'anam-library/src/i18n/calendar/fr.json'

import FooterEn from 'anam-library/src/i18n/footer/en.json'
import HeaderEn from 'anam-library/src/i18n/header/en.json'
import RulesEn from 'anam-library/src/i18n/rules/en.json'
import ErrorEn from 'anam-library/src/i18n/error-pages/en.json'
import DashboardEn from 'anam-library/src/i18n/dashboard/en.json'
import CommonEn from 'anam-library/src/i18n/common/en.json'
import NotesEn from 'anam-library/src/i18n/notes/en.json'
import DpaEn from 'anam-library/src/i18n/dpa/en.json'
import VisioEn from "anam-library/src/i18n/teleconsultation/en.json"
import AuthEn from 'anam-library/src/i18n/auth/en.json'
import MedicalFormEn from 'anam-library/src/i18n/MedicalForm/en.json'
import prevanaEn from 'prevana-library/src/i18n/prevana/en.json'
import VmaEn from './vma/en.json'
import CalendarEn from 'anam-library/src/i18n/calendar/en.json'



export const defaultLocale = 'fr';

export const languages = {
  fr: {
    auth: AuthFr,
    common: CommonFr,
    dashboard: DashboardFr,
    email: EmailFr,
    error_pages: ErrorFr,
    footer: FooterFr,
    header: HeaderFr,
    notes: NotesFr,
    rules: RulesFr,
    dpa: DpaFr,
    teleconsultation: VisioFr,
		MedicalForm: MedicalFormFr,
    prevana: prevanaFr,
    vma: VmaFr,
    calendar: CalendarFr
  },

  en: {
    auth: AuthEn,
    common: CommonEn,
    dashboard: DashboardEn,
    email: EmailFr,
    error_pages: ErrorEn,
    footer: FooterEn,
    header: HeaderEn,
    notes: NotesEn,
    rules: RulesEn,
    dpa: DpaEn,
    teleconsultation: VisioEn,
		MedicalForm: MedicalFormEn,
    prevana: prevanaEn,
    vma: VmaEn,
    calendar: CalendarEn
  }
}
