<template>
  <v-container class="bg_soft pb-0" fluid>
    <v-row>
      <v-col cols="12" md="7" class="pb-0 pl-sm-10">
        <p class="primary--text font-size--30 bold--text">Vaccination contre la covid19 en France</p>
        <p class="soft_blue--text medium--text">
          À partir du 27 novembre 2021, l'injection de la dose de rappel est ouverte à tous les adultes. Elle doit être administrée 3 mois après la dernière injection de vaccin (Pfizer-BioNTech, Moderna ou AstraZeneca) et 4 semaines après l'injection unique de Janssen (www.service-public.fr).
          <br>
          Le 06/10/2021, la Haute Autorité de santé (HAS) recommande, dans un communiqué, la « troisième dose » pour les soignants, les professionnels du secteur médico-social ainsi que pour l’entourage des personnes immunodéprimées et les personnels du transport sanitaire.
          <br>
          Le 15/09/2021, une obligation vaccinale concernant les professionnels de secteurs spécifiques est mise en place, dont les sapeurs-pompiers.
          <br>
          A compter du 01/09/2021, une campagne de rappel est lancée avec : une 3ème dose pour la population de plus de 65 ans, les personnes ayant des comorbidités pouvant faire des formes graves (6 mois après la dernière injection) et une seconde injection avec un vaccin à ARN Messager pour les personnes ayant reçu une dose du vaccin Janssen (4 semaines après leur injection).
          <br>
          Le 31/05/2021, la vaccination est ouverte à l’ensemble de la population majeure, puis aux 12 à 17 ans depuis le 15/06/2021.
        </p>
      </v-col>
      <v-col cols="12" md="5" class="pb-sm-0 text-center align-self-center">
        <img height="200px" src="@/assets/vaccination/covid_explication.png"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "BlocPresentation"
  }
</script>

<style scoped>

</style>
