<template>
  <div>
    <div v-if="place" class="text-center">
      <span>{{place}}</span>
      <v-chip v-if="type_consultation" :color="chip_color">{{type_consultation}}</v-chip>
    </div>
    <v-icon v-else color="soft_blue" class="ml-8">mdi-minus</v-icon>
  </div>
</template>

<script>
export default {
  name: "VMAPlaceItem",
  props: ['item'],
  computed: {
    place() {
      return this.item.place;
    },
    type_consultation() {
      if (this.item.type_consultation === 1) return "présentiel";
      if (this.item.type_consultation === 2) return "visio";
      return null;
    },
    chip_color() {
      if (this.item.type_consultation === 1) return "#ffc576";
      if (this.item.type_consultation === 2) return "#91d1b6";
      return null;
    }
  }
}
</script>

<style scoped>

</style>
