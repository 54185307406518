import { render, staticRenderFns } from "./EtudeDoctorDashboardView.vue?vue&type=template&id=2eef5f52&"
import script from "./EtudeDoctorDashboardView.vue?vue&type=script&lang=js&"
export * from "./EtudeDoctorDashboardView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports