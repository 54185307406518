<template>
  <v-btn text color="secondary" class="text-decoration-underline medium--text px-6" elevation="0" @click="back_to_dashboard()">Retour à l'accueil</v-btn>
</template>

<script>
export default {
  name: "ButtonBackDashboard",
  props: {
    redirection: {
      type: String,
      required: true
    }
  },
  methods: {
    back_to_dashboard() {
      this.$router.push({name: this.redirection});
    }
  }
}
</script>

<style scoped>

</style>
