<template>
  <v-row class="mx-0">
    <v-col cols="12" sm="6">
      <VaccinationPatientSuiviCard/>
      <PatientRessourcesCardComponent to_details="" :name_details="name" class="mt-6"/>
    </v-col>
    <v-col cols="12" sm="6">
      <v-row>
        <v-col cols="12">
          <PatientRDVCardComponent :name_details="name" app_name="prevanavaccination" />
        </v-col>
        <v-col cols="12">
          <VaccinationDashboardCardFAQ/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import VaccinationPatientSuiviCard from "@/components/patient/vaccination/dashboard/suivi/VaccinationPatientSuiviCard";
import VaccinationDashboardCardFAQ from "./faq/VaccinationDashboardCardFAQ";
import PatientRessourcesCardComponent from "@/components/patient/components/ressources/PatientRessourcesCardComponent";
import PatientRDVCardComponent from "@/components/patient/components/rdv/PatientRDVCardComponent";

export default {
  name: "PatientVaccinationDashboardView",
  components: {
    VaccinationDashboardCardFAQ,
    PatientRessourcesCardComponent,
    PatientRDVCardComponent,
    VaccinationPatientSuiviCard
  },
  data: () => ({
    name: 'Vaccination',
    ressources: [],
  })
}
</script>

<style scoped>

</style>
