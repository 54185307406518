<!-- Composant pour l'affichage du questionnaire. Le patient peut répondre aux différentes questions. Chacune de ces
questions correspond à un des domaines liés à son agence. Ces domaines sont affichés en steps en haut de la page pour
permettre une meilleure visibilité du niveau d'avancement du questionnaire-->
<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>
    <PrPatientRPSQuestionnaireView color_step_current="secondary"
                                   question_color="primary"
                                   main_color="primary"
                                   button_color="secondary"
                                   :show_title_question="show_title_question"
                                   :show_back_button="!hide_questionnaire_back_button"
    />
  </div>
</template>

<script>
import PrPatientRPSQuestionnaireView from "prevana-library/src/components/questionnaire/PrPatientRPSQuestionnaireView";
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";
import {hide_questionnaire_back_button, show_title_question_questionnaire} from "prevana-library/src/js/dict_permissions";

export default {
  name: "PatientRPSQuestionnaireView",
  components: {
    PrPatientRPSQuestionnaireView,
    PrBreadcrumbsComponent
  },
  data: () => ({
    navigations: [
      {text: "Bien-Être", to: '/patient/rps/dashboard'},
      {text: "Questionnaire", to: '/patient/rps/questionnaire'}
    ]
  }),
  computed: {
    show_title_question() {
      return show_title_question_questionnaire(this.$store.getters.getAgencyName);
    },
    hide_questionnaire_back_button() {
      return hide_questionnaire_back_button(this.$store.getters.getAgencyName);
    }
  }
}
</script>

<style>
</style>
