<template>
  <v-btn
    @click="$emit('btn_click')"
    x-large
    color="primary"
    depressed
    :width="$vuetify.breakpoint.mdAndUp ? '200px' : '150px'"
    class="px-4"
    dark
    v-bind="attrs"
    v-on="on"
    right
    :disabled="app_is_disabled">

    <img :src="app.image_name" class="hidden-sm-and-down mr-2 rounded-lg" height="40" alt="Logo Application"/>
    {{ app.title }}
    <v-spacer></v-spacer>

    <v-icon v-if="menu !== null">mdi-menu-{{ menu ? "up" : "down" }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    app: {
      type: Object,
      default: () => {}
    },
    menu: {
      type: Boolean,
      default: null,
    },
    attrs: {
      type: Object,
      default: null,
    },
    on: {
      type: Object,
      default: null,
    },
  },
  computed: {
    app_is_disabled() {
      return !this.$store.getters.getAppsAllowed.includes(this.app.id) && !this.app.iframe;
    }
  }
};
</script>

<style>
</style>
