<template>
  <v-col cols="12" sm="6" class="py-0">
    <span class="medium--text">
      {{ label }}
      <span v-if="required" class="red--text">*</span>
    </span>
    <v-text-field v-model="_model"
                  :rules="rules"
                  :counter="counter"
                  :type="type" :error-messages="confirm_item ? confirm_error_txt : ''"
                  filled dense :autocomplete="name"
    ></v-text-field>
  </v-col>
</template>

<script>
  export default {
    name: "ItemForm",
    props: {
      model: {
        required: true
      },
      name: {
        type: String
      },
      label: {
        type: String
      },
      required: {
        type: Boolean,
        default: true
      },
      rules: {
        type: Array,
        default: () => []
      },
      counter: {
        type: String,
        default: null
      },
      type: {
        type: String,
        default: 'text'
      },
      confirm_item: {
        type: Boolean,
        default: false
      },
      confirm_error_txt: {
        type: String,
        default: null
      }
    },
    computed: {
      _model: {
        get: function() {
          return this.model
        },
        set: function(value) {
          this.$emit('update:model', value)
        }
      }
    }
  }
</script>

<style scoped>

</style>
