<template>
  <v-app id="app">
    <link rel="stylesheet" href="/static/css/common_vue29122020.css">
    <AnEnvSystemBar/>
    <router-view></router-view>
    <AnSnackbar :snackbar_model="$store.state.errors.snack_model"
                :snackbar_info="$store.state.errors.snack_info"
                :icon_class="$store.state.errors.snack_info.icon_class"
                v-on:closed="$store.state.errors.snack_model = false"/>
  </v-app>
</template>


<script>
import AnEnvSystemBar from "anam-library/src/components/an-ui/an-system-bar/AnEnvSystemBar.vue";
import AnSnackbar from "anam-library/src/components/an-ui/snackbar/AnSnackbar";

export default {
    name: 'App',
    components: {AnSnackbar, AnEnvSystemBar},
    created (){
      document.title = "Prévana conçu par Anamnèse"
    },
  };
</script>
