<template>
  <v-container fluid>
    <PrCalendarView app_name="rps"></PrCalendarView>
  </v-container>
</template>

<script>
import PrCalendarView from "prevana-library/src/components/calendar/PrCalendarView";

export default {
  name: "DoctorRPSAppointmentsView",
  components: {
    PrCalendarView
  },
  mounted() {
    if (!this.$store.getters.getCurrentAgency.patient_access_rdv) {
      this.$router.push({name: "RPSDashboard"});
    }
  }
}
</script>

<style scoped>

</style>
