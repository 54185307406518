<template>
  <div>
    <v-btn :small="small" color="bg_secondary" elevation="0" class="red--text mx-1" @click="open_dialog_cancel_rdv()">
      <v-icon color="red" class="mr-1" small>mdi-cancel</v-icon>
      <span class="semibold--text">{{$t("common.cancel")}}</span>
    </v-btn>

    <v-dialog v-model="dialog_cancel_rdv" width="600">
      <v-card>
        <DialogCardTitleTemplate :title="$t('common.cancel_rdv')" v-on:click="dialog_cancel_rdv=false"/>
        <v-card-text class="mt-8 text-center">
          <v-icon x-large color="red">mdi-cancel</v-icon>
          <p class="mt-4 semibold--text black--text font-size--20">{{$t("common.validate_cancel_rdv")}}</p>
        </v-card-text>
        <v-card-actions class="justify-center py-6">
          <v-btn text color="secondary" class="px-sm-6" @click="dialog_cancel_rdv = false">{{$t("common.closed")}}</v-btn>
          <v-btn color="secondary" class="px-sm-6" elevation="0" :loading="loading" @click="cancel_rdv()">
            <span class="medium--text font-size--14">{{$t("common.confirmed")}}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DialogCardTitleTemplate from "../DialogCardTitleTemplate";

export default {
  name: "ButtonCancelRDV",
  components: {DialogCardTitleTemplate},
  props: {
    rdv_id: {
      type: Number,
      required: true
    },
    not_allowed: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    dialog_cancel_rdv: false,
    loading: false
  }),

  methods: {
    open_dialog_cancel_rdv() {
      if (this.not_allowed) this.$emit("not_allowed");
      else this.dialog_cancel_rdv = true;
    },
    cancel_rdv() {
      this.loading = true;
      this.$http.delete(`/api/consultations/${this.rdv_id}`).then(() => {
        this.dialog_cancel_rdv = false;
        this.$emit("rdv_cancelled", this.rdv_id);
        this.$store.commit("PostSuccess", "Rendez-vous annulé");
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
