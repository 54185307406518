<template>
  <p class="basic--text font-size--14 my-10 font-italic text-center">{{ txt }}</p>
</template>

<script>
export default {
  name: "NoDataTxt",
  props: {
    txt: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
