<template>
  <v-icon @click="$emit('move_to_dpa', item)">mdi-folder-account</v-icon>
</template>

<script>
    export default {
        name: "VaccinationActionButton",
        props: ['item']
    }
</script>
