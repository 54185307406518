<template>
  <div style="border-left: solid 1px var(--anam-orange_color)">
    <v-row>
      <v-col cols="12">
        <p
          v-if="state == 'newcheck_need'"
          class="my-2 ml-0 bold--text red--text"
        >
          Ce patient nécessite une ré-évaluation, son score a changé
        </p>
        <p class="mt-2">
          Gravité Instant T :
          <v-icon v-if="priorisation !== -1" :color="color_priorisation">
            mdi-checkbox-blank-circle
          </v-icon>
          <span v-else> - </span>
        </p>
        <p class="mt-2">
          Probabilité Covid+ :
          <span
            v-if="proba.score != -1"
            :class="proba.color + ' font-weight-bold'"
          >
            {{ proba.text }}
          </span>
          <span v-if="aggravation.score != -1">
            (score = {{ proba.score }})
          </span>
          <span v-else> - </span>
        </p>
        <p class="mt-2">
          Risque d'aggravation a posteriori :
          <span
            v-if="aggravation.score != -1"
            :class="aggravation.color + ' font-weight-bold'"
          >
            {{ aggravation.text }}
          </span>
          <span v-if="aggravation.score != -1">
            (score = {{ aggravation.score }})
          </span>
          <span v-else> - </span>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MixinColorScore from "../common/MixinColorScore"

export default {
  mixins: [MixinColorScore],
  computed: {
    state() {
      return this.$store.state.dpa.state;
    },
    proba() {
      const proba_score = this.$store.state.dpa.proba_score;

      if (!proba_score) return { score: null, color: null, txt: null };

      return {
        score: proba_score,
        text: this.get_text(proba_score),
        color: this.get_color_scores(proba_score),
      };
    },
    aggravation() {
      const aggravation_score = this.$store.state.dpa.aggravation_score;

      if (!aggravation_score) return { score: null, color: null, txt: null };

      return {
        score: aggravation_score,
        text: this.get_text(aggravation_score),
        color: this.get_color_scores(aggravation_score),
      };
    },
    priorisation() {
      return this.$store.state.dpa.priorisation;
    },
    color_priorisation() {
      return this.get_color_priorisation(this.$store.state.dpa.priorisation);
    },
  },
  methods: {
    get_text(score) {
      if (!score) return "";

      if (score >= 20) {
        return "Très probable";
      } else if (score >= 10) {
        return "Probable";
      } else {
        return "Peu probable";
      }
    },
  },
};
</script>

<style>
</style>
