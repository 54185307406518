<template>
  <div>
    <v-icon v-if="item[variable]" class="red--text">mdi-plus</v-icon>
    <v-icon v-else small>mdi-close</v-icon>
  </div>
</template>

<script>
export default {
  name: "CovidItem",
  props: ["item", "variable"],
  methods: {
    get_value_to_display(data) {
      return data ? "+" : "x";
    }
  }
};
</script>
