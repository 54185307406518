<template>
  <div>
    <AnDashboard
      v-if="dashboard_data_tables"
      ref="an_dashboard"
      :body="body"
      :tab_items="Object.keys(dashboard_data_tables)"
      :tab_content="dashboard_data_tables"
      :get_patients_on_created="false"
      :search="$store.state.dashboard_filter.dashboard_search"
      allow_mass_update
      :mass_update_actions="actions"
      mass_update_key="patient_id"
      allow_export
      :show_inactive="show_inactive"
      custom_filter
      :custom_filter_function="custom_filter_etude"
      v-on:mass_action_click="manage_actions($event)"
      @move_to_dpa="open_dpa($event, false)"
      @open_dpa_window="open_dpa($event, true)"
    >
      <template v-slot:header>
        <v-card-title>
          <PatientSearch
            :certified_military.sync="certified_military"
            :certified_military_diver.sync="certified_military_diver"
            :certified_older_18.sync="certified_older_18"
            :birth_year.sync="birth_year"
            :marital_status.sync="marital_status"
            :service.sync="service"
            :diver_before_internship.sync="diver_before_internship"
            :symptoms.sync="symptoms"
            v-on:show_inactive="show_inactive = $event"
          />
        </v-card-title>
      </template>
    </AnDashboard>
    <DialogAuthoriseQuestionnaire
      :dialog.sync="dialog_authorise_questionnaire"
      :ids_selected="ids_selected"
      :questionnaires="questionnaires"
    />
  </div>
</template>

<script>
import AnDashboard from "anam-library/src/components/an-dashboard/AnDashboard";
import DialogAuthoriseQuestionnaire from "./DialogAuthoriseQuestionnaire";
import EtudeAuthorisationItem from "./EtudeAuthorisationItem";
import DateItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/DateItem";
import PatientSearch from "./PatientSearch";
import dashboard_data_tables from "@/data/etude/dashboard_data_tables.json";

export default {
  name: "EtudeDoctorDashboardView",
  components: {
    AnDashboard,
    DialogAuthoriseQuestionnaire,
    PatientSearch
  },
  data() {
    return {
      body: {
        "latest_questionnaire": DateItem,
        "authorisations": EtudeAuthorisationItem
      },
      dashboard_data_tables: null,
      search: "",
      show_inactive: false,
      dialog_authorise_questionnaire: false,
      ids_selected: [],
      certified_military: [],
      certified_military_diver: [],
      certified_older_18: [],
      birth_year: [],
      marital_status: [],
      service: [],
      diver_before_internship: [],
      symptoms: [],
      questionnaires: []
    };
  },
  created() {
    let questionnaires = JSON.parse(JSON.stringify(this.$store.getters.getStandardAppsQuestionnaires));
    this.questionnaires = questionnaires.filter(x => x.id !== "cephismer_journal_plongees");
    this.dashboard_data_tables = JSON.parse(JSON.stringify(dashboard_data_tables));
    this.dashboard_data_tables["TOUS"].loading = true;
    this.$http.get("/api/etude/patients/all").then(response => {
      this.dashboard_data_tables["TOUS"].patients = response.data;
    }).finally(() => {
      this.dashboard_data_tables["TOUS"].loading = false;
    })
  },
  methods: {
    async open_dpa(patient, new_window) {
      const path = {name: 'EtudeDoctorDpa', params: {id: patient.patient_id }, query: {tab: this.$route.query.tab}}
      if (new_window){
        const routeData = this.$router.resolve(path);
        window.open(routeData.href, "_blank", "resizable");
      }
      else {
        this.$router.push(path);
      }
    },
    manage_actions(event) {
      this.ids_selected = [];
      for (let patient of event.selected) {
        this.ids_selected.push(patient.user_id);
      }
      this.dialog_authorise_questionnaire = true;
    },
    custom_filter_etude(list) {
      if (this.certified_military.length) list = list.filter(x => this.certified_military.includes(x.certified_military));
      if (this.certified_military_diver.length) list = list.filter(x => this.certified_military_diver.includes(x.certified_military_diver));
      if (this.certified_older_18.length) list = list.filter(x => this.certified_older_18.includes(x.certified_older_18));
      if (this.birth_year.length) list = list.filter(x => this.birth_year.includes(x.birth_year));
      if (this.marital_status.length) list = list.filter(x => this.marital_status.includes(x.marital_status));
      if (this.service.length) list = list.filter(x => this.service.includes(x.service));
      if (this.diver_before_internship.length) list = list.filter(x => this.diver_before_internship.includes(x.diver_before_internship));
      if (this.symptoms.length) list = list.filter(x => this.symptoms.filter(y => x.symptoms && x.symptoms.includes(y)).length);
      return list;
    },
  },
  computed: {
    actions() {
      return [{value: "authorise_questionnaire", text: "Autoriser un questionnaire"}]
    }
  }
};
</script>

<style>
</style>
