<template>
  <v-app>
    <PrHfManagerHeader/>
    <v-main class="bg_primary">
      <router-view app_name="covidapp"/>
    </v-main>
    <AnFooter :display="{}" :social_mapping="[]" app_name="covidapp" univers="prevana" class="bg_primary pt-8"/>
  </v-app>
</template>

<script>
import PrHfManagerHeader from "prevana-library/src/components/hfmanager/PrHfManagerHeader";
import AnFooter from "anam-library/src/components/an-footer/AnFooter";

export default {
  name: "HfManagerLayout",
  components: {
    PrHfManagerHeader,
    AnFooter
  },
}
</script>

<style scoped>

</style>
