<template>
  <VaccinationStatisticsSummary class="mt-6"/>
</template>

<script>
import VaccinationStatisticsSummary from "@/components/doctor/vaccination/statistics/components/VaccinationStatisticsSummary";

export default {
  name: "HFManagerVaccinationResumeView",
  components: {
    VaccinationStatisticsSummary
  }
}
</script>

<style scoped>

</style>
