<template>
  <v-dialog v-model="_dialog" fullscreen>
      <v-card>
        <v-card-title class="primary--text semibold--text">
          <v-icon color="primary" class="mr-5">mdi-file</v-icon>
          Documents échangés avec le patient
          <v-spacer></v-spacer>
          <v-btn icon color="primary" @click="_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider inset></v-divider>
        <v-card-text class="pt-8">
          <DocumentsNewButton v-on:new_document="new_document($event)" :patient_id="patient_id" :choose_pds="false" class="mb-5" app_name="vma" />
          <AnAttachmentFilters :search.sync="search" :filter_type.sync="filter_type"/>
          <AnAttachmentBloc :patient_id="patient_id"
                            :files.sync="documents"
                            :search="search"
                            :filter_type="filter_type"
                            :get_shared_with="true"
                            app_name="vma"
                            cols_file="6" cols_file_sm="3"
                            cols_file_md="2"
                            :filter_visible_by="import_attachment_choose_pds"
                            :show_author="true"
                            :user_is_pds="true"
                            delete_document_option
          />
        </v-card-text>
        <v-card-actions class="justify-center py-6">
          <v-btn color="primary" text @click="_dialog=false">
            <v-icon class="mr-2">mdi-close</v-icon>
            <span class="medium--text font-size--16 text-decoration-underline">Fermer</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import AnAttachmentFilters from "anam-library/src/components/an-attachment/AnAttachmentFilters";
import AnAttachmentBloc from "anam-library/src/components/an-attachment/AnAttachmentBloc";
import DocumentsNewButton from "../../../../patient/components/documents/DocumentsNewButton";
import {import_attachment_choose_pds} from "prevana-library/src/js/dict_permissions";

export default {
  name: "DialogVMAPatientDocuments",
  components: {
    AnAttachmentFilters,
    AnAttachmentBloc,
    DocumentsNewButton
  },
  props: {
    patient_id: {
      type: String,
      required: true
    },
    dialog: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    search: null,
    filter_type: null,
    documents: []
  }),
  computed: {
    _dialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },
    import_attachment_choose_pds() {
      return import_attachment_choose_pds(this.$store.getters.getAgencyName);
    }
  },
  methods: {
    new_document(document) {
      this.documents.unshift(document);
    },
  }
}
</script>

<style scoped>

</style>
