<template>
  <v-app>
    <v-app-bar app color="primary" flat height="80px">
      <img :src="logo" class="mx-sm-5 pb-2" height="100%"/>
      <v-spacer></v-spacer>
      <v-btn
        color="white"
        elevation="0"
        class="primary--text px-sm-8"
        @click="dialog_connexion = true"
        >Connexion</v-btn
      >
    </v-app-bar>
    <v-main>
      <AnAlertMsg
        :model.sync="$store.state.errors.snack_model"
        :snackbar_info="$store.state.errors.snack_info"
      />
      <router-view v-on:connexion="dialog_connexion = true" />
      <DialogConnexion :dialog.sync="dialog_connexion" />
    </v-main>
  </v-app>
</template>

<script>
import AnAlertMsg from "anam-library/src/components/an-ui/snackbar/AnAlertMsg";
import DialogConnexion from "./common/DialogConnexion";
import colors_themes from "../../plugins/colors_themes";

export default {
  name: "VaccinationLayout",
  components: {
    AnAlertMsg,
    DialogConnexion,
  },
  data: () => ({
    dialog_connexion: false,
  }),
  created() {
    this.$vuetify.theme.themes.light = colors_themes.theme_patient["theme"]["themes"]["light"];
  },
  computed: {
    logo() {
      return this.$store.getters.getCurrentAgency.logo;
    }
  }
};
</script>

<style scoped>
</style>
