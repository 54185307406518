<template>
  <v-card>
    <v-card-title class="primary--text semibold--text font-size--24 pl-10">
      Besoin d'aide ?
    </v-card-title>
    <v-divider inset class="mr-10"/>
    <v-card-text class="pl-8 pr-10 pb-10">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon color="soft_blue">mdi-help</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="normal--text">
              Accéder à notre page d'aide en cliquant sur ce lien :
              <a href="https://aide.anamnese.care" target="_blank"
                 class="soft_blue--text semibold--text text-decoration-underline">
                https://aide.anamnese.care
              </a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SettingsHelp"
}
</script>

<style scoped>

</style>
