<template>
  <apex-chart type="bar" :height="height"
              :series="[{name: '', data: Object.values(data)}]"
              :options="{
                colors: colors,
                chart: {
                  toolbar: {
                    show: false,
                  },
                },
                xaxis: {
                  categories: Object.keys(data),
                },
                plotOptions: {
                  bar: {
                    columnWidth: column_width,
                    horizontal: horizontal,
                  },
                },
              }">
  </apex-chart>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    colors: {
      type: Array,
      default: () => [],
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 300
    },
    column_width: {
      type: String,
      default: null
    }
  },
};
</script>

<style>
</style>
