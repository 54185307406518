<template>
  <v-row class="mx-0">
    <v-col cols="12" sm="6">
      <CovidhelpPatientSuiviCard/>
    </v-col>
    <v-col cols="12" sm="6">
      <v-row>
        <v-col cols="12">
          <CovidDashboardCardFAQ />
        </v-col>
        <v-col cols="12">
          <PatientRessourcesCardComponent :ressources="ressources"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CovidhelpPatientSuiviCard from "@/components/patient/covidhelp/dashboard/suivi/CovidhelpPatientSuiviCard";
import CovidDashboardCardFAQ from "./faq/CovidDashboardCardFAQ";
import PatientRessourcesCardComponent from "@/components/patient/components/ressources/PatientRessourcesCardComponent";

import ressources_1 from "@/assets/ressources/covidapp/gestes_barrieres.png";

export default {
  name: "PatientCovidDashboardView",
  components: {
    CovidhelpPatientSuiviCard,
    CovidDashboardCardFAQ,
    PatientRessourcesCardComponent,
  },
  data: () => ({
    name: 'Suivi Covid',
    ressources: [
      {
        name: "Gestes Barrières",
        logo: ressources_1,
        url: "https://solidarites-sante.gouv.fr/IMG/pdf/affiche_gestes_barriere.pdf"
      },
    ]
  })
}
</script>

<style scoped>

</style>
