<template>
  <AnDialogComponent :dialog.sync="_dialog" width="800" title="Bienvenue">
      <template v-slot:card-text>
        <div class="text-center mb-5">
          <img width="500" src="https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/AGENTS%20DU%20SDIS%2030.png" />
        </div>
        <p class="primary--text font-size--16 normal--text mb-0">
          Bonjour {{patient_name}},
          <br/><br/>
          Bienvenue sur le logiciel Prévana, un outil pour la prévention RPS et le bien-être au sein du SDIS30.
          <br/><br/>
          Le SDIS30 s’est lancé le défi d'intégrer la <span class="semibold--text font-size--16">prévention des
          risques psychosociaux</span> pour faire en sorte que chaque agent du SDIS30 exerce son métier dans les
          meilleures conditions possibles. Bien sûr, les informations que vous partagez via ce questionnaire sont
          anonymes pour vos supérieurs hiérarchiques. Votre Direction accède à une présentation synthétique et anonyme,
          lui permettant d'<span class="semibold--text font-size--16">identifier les points d'amélioration</span>
          et <span class="semibold--text font-size--16">bâtir une politique de prévention</span> adaptée.
          <br/><br/>
          Merci pour votre implication.
        </p>
        <div class="text-right mr-3">
          <img width="100" src="https://www.anamnese.care/hubfs/Visuels/Logos%20entreprises/agences/SDIS30/Logos/LOGO_SDIS30_fondtransparent.png" />
        </div>
      </template>
    </AnDialogComponent>
</template>

<script>
import AnDialogComponent from "anam-library/src/components/an-ui/an-dialog/AnDialogComponent";

export default {
  name: "SDIS30WelcomeDialog",
  components: {AnDialogComponent},
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    patient_name: {
      type: String,
      default: ""
    }
  },
  computed: {
    _dialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },
  }
}
</script>

<style scoped>

</style>
