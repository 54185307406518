<template>
  <div>
    <EvenementItem
      v-for="item in evenements_list_1"
      :key="item.ref"
      :ref="item.ref"
      :event="evenements[item.event]"
      :title="item.title"
      :title_date="item.title_date"
      :type="item.type"
      :pds_uid="pds_uid"
      v-on:enable_snackbar="$store.commit('PostSuccess', 'Modifications enregistrées')"
    />

    <v-divider class="mt-5"></v-divider>

    <EvenementItem
      v-for="item in evenements_list_2"
      :key="item.ref"
      :ref="item.ref"
      :event="evenements[item.event]"
      :title="item.title"
      :title_date="item.title_date"
      :type="item.type"
      :pds_uid="pds_uid"
      v-on:enable_snackbar="$store.commit('PostSuccess', 'Modifications enregistrées')"
    />

    <v-divider class="mt-5"></v-divider>

    <EvenementItem
      v-for="item in evenements_list_3"
      :key="item.ref"
      :ref="item.ref"
      :event="evenements[item.event]"
      :title="item.title"
      :title_date="item.title_date"
      :type="item.type"
      :pds_uid="pds_uid"
      v-on:enable_snackbar="$store.commit('PostSuccess', 'Modifications enregistrées')"
    />

    <v-divider class="mt-5"></v-divider>

    <EvenementItem
      v-for="item in evenements_list_4"
      :key="item.ref"
      :ref="item.ref"
      :event="evenements[item.event]"
      :title="item.title"
      :title_date="item.title_date"
      :type="item.type"
      :pds_uid="pds_uid"
      v-on:enable_snackbar="$store.commit('PostSuccess', 'Modifications enregistrées')"
    />

    <div class="text-center">
      <v-btn
        color="primary"
        outlined
        class="mt-5"
        @click="dialog_history_evenements = true"
      >
        <v-icon class="mr-2"> mdi-history </v-icon>
        Voir l'historique
      </v-btn>
    </div>

    <DialogHistoryEvenements
      :dialog_history_evenements.sync="dialog_history_evenements"
    />
  </div>
</template>

<script>
import EvenementItem from "./evenement/EvenementItem";
import DialogHistoryEvenements from "./evenement/DialogHistoryEvenements";

export default {
  components: {
    EvenementItem,
    DialogHistoryEvenements,
  },
  props: {
    pds_uid: {
      type: String,
    },
  },
  data() {
    return {
      evenements_list_1: [
        {
          ref: "event_premierssymptomes",
          event: "premierssymptomes",
          title: "Premiers symptômes",
          title_date: "Date des premiers symptômes",
          type: "premierssymptomes",
        },
        {
          ref: "event_contact",
          event: "contact",
          title: "Contact (asymptomatique)",
          title_date: "Date apparition contact",
          type: "contact",
        },
        {
          ref: "event_isolement",
          event: "isolement",
          title: "Possible/Isolement",
          title_date: "Date possible/Isolement",
          type: "isolement",
        },
        {
          ref: "event_confirme",
          event: "confirme",
          title: "Confirmé",
          title_date: "Date confirmé",
          type: "confirme",
        },
        {
          ref: "event_vaccination",
          event: "vaccination",
          title: "Vaccination",
          title_date: "Date vaccination",
          type: "vaccination",
        },
      ],
      evenements_list_2: [
        {
          ref: "event_gueri",
          event: "gueri",
          title: "Guéri",
          title_date: "Date Guéri",
          type: "gueri",
        },
        {
          ref: "event_endquarantaine",
          event: "endquarantaine",
          title: "Sortie d'isolement",
          title_date: "Date sortie d'isolement",
          type: "endquarantaine",
        },
      ],
      evenements_list_3: [
        {
          ref: "event_hospitalisation",
          event: "hospitalisation",
          title: "Hospitalisé",
          title_date: "Date d'hospitalisation",
          type: "hospitalisation",
        },
        {
          ref: "event_arrettravail",
          event: "arrettravail",
          title: "Patient en Arrêt de Travail",
          title_date: "Date début arrêt de travail",
          type: "arrettravail",
        },
      ],
      evenements_list_4: [
        {
          ref: "event_rappel_todo",
          event: "rappel_todo",
          title: "Rappel TODO",
          title_date: "Date de rappel",
          type: "rappel_todo",
        },
      ],
      dialog_history_evenements: false,
    };
  },
  computed: {
    evenements() {
      return this.$store.getters.evenements_formatted;
    },
  },
};
</script>

<style>
</style>
