<template>
  <v-container fluid class="pa-5">
    <iframe v-if="!has_access_to_rps" :src="url" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>
    <template v-else-if="!has_access_to_stats">
      <p class="text-center mt-12 secondary--text semibold--text font-size--22">Vous n'avez pas accès aux statistiques de cette application</p>
    </template>
    <template v-else>
      <v-row class="mx-0">
        <v-col cols="12">
          <div class="d-flex">
            <img :src="logo" width="50" height="auto" class="rounded-lg mr-4" alt="Logo Bien-être"/>
            <span class="secondary--text bold--text font-size--24 align-self-center">Bien-être</span>
          </div>
        </v-col>
        <v-col cols="12">
          <v-tabs color="secondary" background-color="bg_primary">
            <v-tab class="text-capitalize semibold--text" to="/manager/rps/statistics">Suivi & Événements</v-tab>
          </v-tabs>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <router-view></router-view>
    </template>
  </v-container>
</template>

<script>
import logo from "@/assets/picto-rps.png"

export default {
  name: "HFManagerRPSLayout",
  data: () => ({
    logo: logo,
    url: "https://www.prevana.care/module-prevana-bien-etre"
  }),
  computed: {
    infos_manager: function() {
      return this.$store.getters.getManagerInfos;
    },
    has_access_to_rps: function() {
      return this.infos_manager.apps.includes('rps')
    },
    has_access_to_stats() {
      return this.infos_manager.has_access_stats;
    }
  }
}
</script>

<style scoped>
.v-tabs-bar a{
  letter-spacing: unset;
}
</style>
