<template>
  <div>
    <AnDashboard ref="an_dashboard"
                 allow_mass_update
                 :mass_update_actions="actions"
                 :search="$store.state.dashboard_filter.dashboard_search"
                 :tab_items="Object.keys(data_tables_json)"
                 :tab_content="data_tables_json"
                 custom_filter
                 :custom_filter_function="custom_filter_vaccination"
                 :show_inactive="show_inactive"
                 allow_export
                 header_color="soft_blue"
                 :body="body"
                 v-on:mass_action_click="manage_mass_action_click($event)"
                 v-on:move_to_dpa="move_to_dpa($event)"
                 v-on:tab_changed="tab_changed($event)">
      <template v-slot:header>
        <v-card-title>
          <PrPatientSearch ref="patient_search"
                         search_text="dashboard.filters.search_patient.filter"
                         :calendar_type="3"
                         calendar_text="Site de vaccination"
                         :filter_list="['place', 'date', 'status', 'age', 'is_eligible', 'nb_injections', 'time_injection']"
                         v-on:show_inactive="show_inactive = $event"
                         v-on:create_patient="$refs.dialog_new_user.dialog = true"/>
        </v-card-title>
      </template>
    </AnDashboard>

    <CreatePatientDialog ref="dialog_new_user" v-on:action_after_success="$refs.an_dashboard.get_patients()" />
    <DialogSendEmail :dialog.sync="dialog_send_email" :ids_send_email="ids_send_email"/>
    <PrDialogEmailSetEligible
      :dialog.sync="dialog_set_eligible"
      :data="data_set_eligible"
      v-on:update_mass_action="update_mass_action($event)"
    ></PrDialogEmailSetEligible>
    <AnAppointmentDialogMassCancel :dialog.sync="dialog_delete_consult"
                                 :loading.sync="loading_delete_consult"
                                 v-on:delete_consults="delete_consults()"
    />
  </div>
</template>

<script>
import moment from "moment-timezone"
import AnDashboard from "anam-library/src/components/an-dashboard/AnDashboard";
import VaccinationActionButton from "./VaccinationActionButton";
import DateTimeItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/DateTimeItem";
import DateTimeItemIsPassed
  from "anam-library/src/components/an-dashboard/an-dashboard-table-items/DateTimeItemIsPassed";
import BooleanItem from "anam-library/src/components/an-dashboard/an-dashboard-table-items/BooleanItem";
import StatusItem from "@/components/doctor/vaccination/dashboard/items/StatusItem"
import AlertPrerequisItem from "@/components/doctor/vaccination/dashboard/items/AlertPrerequisItem";
import PrDialogEmailSetEligible from "prevana-library/src/components/dialogs/PrDialogEmailSetEligible";
import DialogSendEmail from "anam-library/src/components/an-dialogs/DialogSendEmail";
import AnAppointmentDialogMassCancel
  from "anam-library/src/components/an-appointments/dialogs/AnAppointmentDialogMassCancel";
import PrPatientSearch from "prevana-library/src/components/common/PrPatientSearch";
import CreatePatientDialog from "@/components/doctor/common/CreatePatientDialog";
import data_tables_json from "@/data/dashboard/data_tables.json";

export default {
        name: "DashboardLayout",
        components: {
            CreatePatientDialog,
            PrPatientSearch,
            DialogSendEmail,
            PrDialogEmailSetEligible,
            AnDashboard,
            AnAppointmentDialogMassCancel
        },
        data () {
            return {
                affectations: [],
                body: {
                    "details": VaccinationActionButton,
                    "next_rdv": DateTimeItemIsPassed,
                    "latest_vaccination": DateTimeItem,
                    "is_eligible": BooleanItem,
                    "status": StatusItem,
                    "alert_prerequis": AlertPrerequisItem
                },
                actions: [
                    {value: "mass_set_eligible", text: "Autoriser à prendre RDV"},
                    {value: "mass_set_non_eligible", text: "Ne pas autoriser à prendre RDV"},
                    {value: "send_email", text: "Envoyer un email"},
                    {value: "cancel_appointment", text: "Annuler le RDV"},
                    {value: "add_injection", text: "Nécessite une injection de rappel"},
                    {value: "remove_injection", text: "Finaliser (pas de rappel nécessaire)"},
                ],
                data_tables_json,
                consult_ids: [],
                show_inactive: false,
                loading_delete_consult: false,
                dialog_delete_consult: false,
                data_set_eligible: {},
                dialog_set_eligible: false,
                dialog_send_email: false,
                ids_send_email: [],
            }
        },
        mounted() {
            if (this.$route.query) this.$refs.an_dashboard.tab = parseInt(this.$route.query.tab);
        },
        methods: {
            move_to_dpa(item) {
                let url = "/doctor/vaccination/patient/" + item.id;
                if (this.$route.query) url = url + "?tab=" + this.$route.query.tab;

                this.$router.push(url);
            },
            tab_changed(tab) {
                this.$router.push({name: 'VaccinationDashboard', query: {tab: tab}});
            },

            manage_mass_action_click(data) {
                this.ids_send_email = [];

                if (data.action === "send_email") {
                  for (let patient of data.selected) {
                    this.ids_send_email.push(patient.user_id);
                  }
                  this.dialog_send_email = true;
                  return;
                }
                for (let patient of data.selected) {
                  this.ids_send_email.push(patient.id);
                }
                if (data.action === "cancel_appointment") {
                  this.mass_cancel_appointment(data);
                  return;
                }
                if (data.action === "add_injection") {
                  this.add_or_remove_injection(data, true);
                  return;
                }
              if (data.action === "remove_injection") {
                this.add_or_remove_injection(data, false);
                return;
              }

                this.data_set_eligible = {
                  ids_send_email: this.ids_send_email,
                  is_eligible: data.action === "mass_set_eligible",
                  app: "prevanavaccination"
                };

                if (data.action === "mass_set_eligible") {
                    this.data_set_eligible.title_eligible = this.$t("email.MAIL_VACC_CHANGE_TO_ELIGIBLE_TITLE");
                    this.data_set_eligible.text_eligible = this.$t("email.MAIL_VACC_CHANGE_TO_ELIGIBLE_TEXT");
                }
                else if (data.action === "mass_set_non_eligible") {
                  this.data_set_eligible.title_non_eligible = this.$t("email.MAIL_VACC_CHANGE_TO_NOT_ELIGIBLE_TITLE");
                  this.data_set_eligible.text_non_eligible = this.$t("email.MAIL_VACC_CHANGE_TO_NOT_ELIGIBLE_TEXT");
                }
                this.dialog_set_eligible = true;
            },

            mass_cancel_appointment(data) {
              this.consult_ids = [];
              for (let resp of data.selected) {
                if (resp.next_rdv_id) this.consult_ids.push(resp.next_rdv_id);
              }
              this.dialog_delete_consult = true;
            },

          add_or_remove_injection(data, add_injection) {
              this.$http.post("/api/mass_add_or_remove_injection", {ids: this.ids_send_email, add_injection: add_injection}).then(() => {
                for (let patient of data.selected) {
                  if (add_injection && patient.status === 10) patient.status = 9;
                  else if (!add_injection && patient.status === 9) patient.status = 10;
                }
                this.$store.commit('PostSuccess', 'Opération réalisée avec succès');
              })
            },

            delete_consults() {
              this.loading_delete_consult = true;
              this.$http.post("/api/mass_cancel_appointment", {consult_ids: this.consult_ids, app: "prevanavaccination"}).then(() => {
                this.dialog_delete_consult = false;
                this.consult_ids = [];
                this.$refs.an_dashboard.get_patients();
                this.$store.commit('PostSuccess', 'Opération réalisée avec succès');
              }).finally(() => {
                this.loading_delete_consult = false;
              })
            },

            update_mass_action(refresh_patients) {
              if (refresh_patients) this.$refs.an_dashboard.get_patients();
            },

            custom_filter_vaccination(list) {
                if (!this.$refs.patient_search) return list;
                if (this.$store.state.dashboard_filter.selected_place) list = list.filter(x => x.vaccination_place === this.$store.state.dashboard_filter.selected_place);
                if (this.$store.state.dashboard_filter.date) list = list.filter(x => moment(x.next_rdv).format("DD/MM/YYYY") === this.$store.state.dashboard_filter.date);
                if (this.$store.state.dashboard_filter.selected_statuses.length) {
                  let statuses_to_check = [];
                  for (let status of this.$store.state.dashboard_filter.selected_statuses) {
                    if (status >= 0) statuses_to_check.push(parseFloat(status));
                    else statuses_to_check.push(null);
                  }
                  list = list.filter(x => statuses_to_check.includes(x.status));
                }
                if (this.$store.state.dashboard_filter.is_eligible === true || this.$store.state.dashboard_filter.is_eligible === false) {
                  list = list.filter(x => x.is_eligible === this.$store.state.dashboard_filter.is_eligible);
                }
                if (this.$store.state.dashboard_filter.age.length) {
                  list = list.filter(x => this.filter_by_age(x.age));
                }
                if (this.$store.state.dashboard_filter.passed_consultation) {
                  list = list.filter(x => x.next_rdv && moment(x.next_rdv, "YYYY-MM-DD HH:mm").isBefore(moment()));
                }
                if (this.$store.state.dashboard_filter.nb_of_injections.length) {
                  list = list.filter(x => this.filter_by_nb_vaccination(x.nb_vaccinations));
                }
                if (this.$store.state.dashboard_filter.time_injection.length) {
                  list = list.filter(x => this.filter_time_injection(x.latest_vaccination));
                }
                return list;
            },

          filter_time_injection(date) {
              let now = moment(date, "YYYY-MM-DD");
              for (let filter of this.$store.state.dashboard_filter.time_injection) {
                if (moment().subtract(filter[0], "months") >= now
                  && moment().subtract(filter[1], "months") <= now) return true;
              }
              return false;
            },

            filter_by_age(age) {
              for (let filter of this.$store.state.dashboard_filter.age) {
                if (age >= filter[0] && age <= filter[1]) return true;
              }
              return false;
            },

            filter_by_nb_vaccination(nb_injections) {
              for (let filter of this.$store.state.dashboard_filter.nb_of_injections) {
                if (filter === 4 && nb_injections >= 4) return true;
                else if (filter === nb_injections) return true;
              }
              return false;
            },
         },

        beforeRouteEnter: (to, from, next) => {
          next(vm => {
            document.title = "Tableau de bord - vaccination - " + vm.$store.getters.getCurrentAgency.commercial_name;
          });
        },
        beforeRouteLeave: (to, from, next) => {
          document.title = "Prévana";
          next();
        },
    }
</script>

<style>
  .v-data-table-header__icon::before {
    color: white;
}
</style>
