<template>
  <v-container fluid class="pa-5">
    <v-row class="mx-0">
      <v-col cols="12">
        <div class="d-flex">
          <img :src="logo" width="50" height="auto" class="rounded-lg mr-4" alt="Logo Vaccination"/>
          <span class="secondary--text bold--text font-size--24 align-self-center">Vaccination</span>
        </div>
      </v-col>
      <v-col cols="12">
        <v-tabs color="secondary" background-color="bg_primary">
          <v-tab class="text-capitalize semibold--text" to="/manager/vaccination/resume">Résumé</v-tab>
          <v-tab class="text-capitalize semibold--text" to="/manager/vaccination/statistics">Statistiques états vaccination</v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import logo from "@/assets/picto-vaccination.png"

export default {
  name: "HFManagerCovidLayout",
  data: () => ({
    logo: logo,
  }),
}
</script>

<style scoped>
.v-tabs-bar a{
  letter-spacing: unset;
}
</style>
