<template>
    <v-tabs-items v-model="tab_selected">
      <v-tab-item value="tab_anamnese" class="bg pa-1">
        <SummaryTab :pds_uid="pds_uid" />
      </v-tab-item>
      <v-tab-item value="tab_history" class="bg pa-1">
        <HistoryTab :pds_uid="pds_uid" />
      </v-tab-item>
      <v-tab-item value="tab_stats" class="bg pa-1">
        <EvolutionTab :pds_uid="pds_uid" />
      </v-tab-item>
      <v-tab-item value="tab_visio" class="bg pa-1">
        <AnTeleconsultation :email="$store.state.pat_admin.data.email" color_txt="primary" color_btn="secondary"/>
      </v-tab-item>
      <v-tab-item value="tab_notes" class="bg pa-1">
        <PrNoteTab/>
      </v-tab-item>
      <v-tab-item value="tab_comorbidity" class="bg pa-1">
        <ComorbidityTab :pds_uid="pds_uid" />
      </v-tab-item>
    </v-tabs-items>
</template>

<script>
import SummaryTab from "./tabs/SummaryTab";
import HistoryTab from "./tabs/HistoryTab.vue";
import EvolutionTab from "./tabs/EvolutionTab";
import PrNoteTab from "prevana-library/src/components/dpa/tabs/PrNoteTab";
import ComorbidityTab from "./tabs/ComorbidityTab";
import AnTeleconsultation from "anam-library/src/components/an-teleconsultation/AnTeleconsultation";

export default {
  components: {
    SummaryTab,
    HistoryTab,
    EvolutionTab,
    AnTeleconsultation,
    PrNoteTab,
    ComorbidityTab,
  },
  props: {
    pds_uid: {
      type: String,
    },
  },
  computed: {
    tab_selected: {
      get() {
        return this.$store.state.dpa.tab_selected;
      },
      set(value) {
        this.$store.state.dpa.tab_selected = value;
      },
    },
  },
};
</script>

<style>
</style>
