<template>
<div>
  <template v-if="questionnaires_loaded">
    <v-tabs v-model="tab_selected" center-active :show-arrows="true" centered color="primary" background-color="transparent">
      <v-tab v-for="(tab, index) in tabs" :key="index">
        <span style="text-transform: none !important;">{{tab}}</span>
      </v-tab>
    </v-tabs>
    <apex-chart :options="chart_options" :series="series" />
  </template>
  <template v-else>
    <v-progress-linear indeterminate color="primary"></v-progress-linear>
  </template>
</div>
</template>

<script>
import {get_questionnaire_names} from "../../../../data/ecos/utils";
import {get_score_name} from "../../../../data/ecos/utils";

export default {
  name: "EvolutionTab",
  props: {
    questionnaires: {
      type: Array,
      default: () => []
    },
    questionnaires_loaded: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    tab_selected: 0,
    tabs: ["EVA", "PHQ9", "GAD7"],
  }),
  computed: {
    series() {
      let series = [];
      let scores_to_get = ["eva_stress_pre", "eva_stress_post"];
      for (let quest of this.questionnaires) {
        if (this.tab_selected === 1) scores_to_get = ["ecos_phq9"];
        if (this.tab_selected === 2) scores_to_get = ["ecos_gad7"];
        for (let score_name of scores_to_get) {
          if (!Object.keys(quest.scores).includes(score_name)) continue;
          let name = get_score_name(score_name);
          let index = series.findIndex(x => x.name === name);
          if (index === -1) series.push({name: name, data: [quest.scores[score_name]]});
          else series[index].data.push(quest.scores[score_name]);
        }
      }
      return series;
    },
    chart_options() {
      let keys = [];
      for (let quest of this.questionnaires) {
        keys.push(get_questionnaire_names(quest.meta_questionnaire).short_name);
      }
      let colors = ["#00838F", "#9BBB59"];
      let max = 10;
      if (this.tab_selected === 1) {
        colors = ["#00838F"];
        max = 27;
      }
      else if (this.tab_selected === 2) {
        colors = ["#00838F"];
        max = 21;
      }
      return {
        colors: colors,
        chart: {
          type: "line",
          zoom: {enabled: false},
          toolbar: {tools: {download: false}},
        },
        dataLabels: {enabled: false},
        xaxis: {categories: keys, tickPlacement: "between"},
        yaxis: {min: 0, max: max}
        }
    }
  }
}
</script>

<style scoped>

</style>
