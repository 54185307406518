<!--
Composant pour l'inscription des utilisateurs de prevana (commun à toutes les apps)
-->

<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-row class="mx-0" align="center" style="height: 100vh">
          <v-col cols="12" sm="6" md="4" :class="`${$vuetify.breakpoint.xsOnly ? 'd-flex' : 'px-sm-6'} mb-3 mt-3`">
            <img :src="logo" :width="$vuetify.breakpoint.xsOnly? '25%' : '50%'" height="auto" alt="logo-rps"/>
            <div class="mt-md-12 mb-md-12">
              <p :class="($vuetify.breakpoint.xsOnly ? 'font-size--16 mb-0 mt-4 ml-3' : 'font-size--34') + ' primary--text bold--text'">Bienvenue</p>
              <p :class="($vuetify.breakpoint.xsOnly ? 'font-size--14 ml-3' : 'font-size--28') + ' primary--text semibold--text'">Authentifiez vous pour créer votre espace personnel</p>
            </div>
            <img width="50%" :src="current_agency.logo" alt="logo-agence" class="hidden-xs-only"/>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="mt-3">
            <v-card elevation="4">
              <v-card-title class="primary white--text justify-center medium--text">
                {{ from_token ? 'Authentification' : 'Inscription' }}
              </v-card-title>
              <v-card-text class="pt-6">
                <div v-if="from_token" class="pl-1 primary--text font-size--16">
                  <p class="medium--text mb-0">Votre nom</p>
                  <p class="bold--text">{{ last_name }}</p>
                  <p class="mb-0" style="position: relative">
                    <span class="medium--text font-size--16 mr-2">Votre adresse email</span>
                    <v-tooltip right color="primary">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="primary" style="position: absolute; top: 0;">mdi-information</v-icon>
                      </template>
                      <span>Cet email pourra être changé après votre inscription</span>
                    </v-tooltip>
                  </p>
                  <p class="bold--text mb-8">{{ email }}</p>
                </div>

                <v-form v-model="valid_form" autocomplete="off">
                  <div :class="$vuetify.breakpoint.smAndDown ? '' : 'd-flex'">
                    <AnTextField v-if="!from_token" :value.sync="last_name" dense title="Votre nom" icon="" title_color="primary" class="mr-md-2" required type_field="last_name" />
                    <AnTextField :value.sync="first_name" dense title="Votre prénom" icon="" title_color="primary" class="mr-md-2" required type_field="text" />
                    <AnTextField v-if="from_token" :value.sync="id_agency" dense :title="id_agency_text" icon="" title_color="primary" class="ml-md-2" required type_field="matricule" />
                  </div>
                  <AnTextField v-if="!from_token" :value.sync="id_agency" dense :title="id_agency_text" icon="" title_color="primary" required type_field="matricule"/>
                  <AnTextField v-if="!from_token" :value.sync="email" dense title="Votre adresse mail" icon="" title_color="primary" type_field="email" required :tooltip="tooltip" />
                  <AnTextField v-if="!from_token" :value.sync="confirm_email" dense title="Confirmez votre adresse mail" icon="" title_color="primary" type_field="email" required
                               :error_messages="(confirm_email && email !== confirm_email) ? 'Vous avez saisi un email différent de celui ci-dessus' : null"
                  />
                </v-form>

                <AnPasswordField v-if="from_token" :password.sync="password" :confirm_password.sync="confirm_password" title_color="primary" dense :list_rules="list_rules" :is_valid.sync="valid_password"/>

                <v-checkbox dense hide-details v-model="cgu">
                  <template v-slot:label>
                    <span>J'accepte les <a class="blue--text" :href="get_mentions_legales_url('prevana', 'prevana')" target="_blank" @click.stop>conditions générales d'utilisation</a></span>
                  </template>
                </v-checkbox>
                <v-checkbox dense hide-details v-model="confidentiality">
                  <template v-slot:label>
                    <span>J'accepte la <a class="blue--text" :href="get_politique_confidentialite_url('prevana', 'prevana')" target="_blank" @click.stop>politique de confidentialité</a></span>
                  </template>
                </v-checkbox>
                <v-alert v-if="errors" type="error" text class="mt-3">
                  Les informations que vous avez renseignées ne sont pas valides.
                  Il est important que les accents & espaces soient en accord avec ce que l'entreprise a renseigné (import RH) pour votre nom et prénom.
                  Si l'import est fait en majuscule pour le nom, peut-être que l'accent de votre nom a sauté ?
                  Si votre nom d'usage ne marche pas, peut-être êtes vous inscrit avec votre nom patrimonial ?
                </v-alert>
                <v-alert v-if="error_cgu" type="error" text class="mt-3">Vous devez accepter les cgu et la politique de confidentialité pour continuer</v-alert>
                <v-alert v-if="error_email" type="error" text class="mt-3">{{error_email}}</v-alert>
                <v-alert v-if="success" text type="success" class="mt-3">
                  Inscription terminée avec succès ! Vous pouvez vous connecter en
                  <a @click="dialog_connexion = true" class="bold--text text-decoration-underline success--text">cliquant ici</a>
                </v-alert>
              </v-card-text>
              <v-card-actions class="text-center d-block">
                <div>
                  <v-btn color="primary" class="white--text px-13" elevation="0" large
                         @click="inscription()" :disabled="button_disabled" :loading="button_loading">
                    <span class="font-size--16 medium--text">M'inscrire</span>
                  </v-btn>
                </div>
                <div class="my-3">
                  <v-divider></v-divider>
                </div>
                <div>
                  <v-btn text color="primary" @click="dialog_connexion = true">
                    <span class="text-decoration-underline medium--text">J'ai déjà un compte</span>
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
            <p class="red--text font-italic mt-1" v-html="helper_text"></p>
          </v-col>
        </v-row>
        <ConnexionDialog :dialog.sync="dialog_connexion" :agency="current_agency" />
        <v-dialog v-model="dialog_success_email" persistent width="700">
          <v-card>
            <v-card-title class="medium--text primary white--text justify-center">
              Inscription validée
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-10 text-center">
              <p class="font-size--22 medium--text primary--text">Nous venons de vous envoyer un email de confirmation.
                Veuillez cliquer sur le lien à l'intérieur de celui-ci pour définir un mot de passe et accéder à votre espace.</p>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import AnTextField from "anam-library/src/components/an-ui/textfield/AnTextField";
import ConnexionDialog from "../../home/dialogs/ConnexionDialog";
import AnPasswordField from "anam-library/src/components/an-ui/textfield/AnPasswordField";
import {get_terminology, get_inscription_helper_text} from "anam-library/src/data/dict_terminology_by_agency";
import {mixin_cgu} from "anam-library/src/js/utils";
import getCookie from "anam-library/src/js/common";
import logo from "@/assets/picto-rps.png";

export default {
  name: "InscriptionView",
  mixins: [mixin_cgu],
  components: {
    AnTextField,
    AnPasswordField,
    ConnexionDialog
  },
  data() {
    return {
      id_agency: "",
      email: "",
      confirm_email: "",
      last_name: "",
      first_name: "",
      autolog_uid: "",
      password: "",
      confirm_password: "",
      from_token: false,
      button_loading: false,
      cgu: false,
      confidentiality: false,
      valid_password: false,
      valid_form: false,
      list_rules: ["min_length", "lower", "upper", "number", "unauthorized_characters"],
      errors: false,
      error_cgu: false,
      error_email: null,
      success: false,
      dialog_success_email: false,
      dialog_connexion: false,
      logo: logo
    }
  },

  computed: {
    current_agency() {
      return this.$store.getters.getCurrentAgency;
    },
    button_disabled() {
      let is_disabled = false;
      if (this.from_token) {
        is_disabled = !this.first_name || !this.id_agency || !this.password || !this.confirm_password || !this.valid_password;
      }
      else {
        is_disabled = !this.valid_form;
      }
      if (!this.cgu || !this.confidentiality) is_disabled = true;
      if (this.success || this.dialog_success_email) is_disabled = true;
      return is_disabled;
    },
    id_agency_text() {
      return `Votre ${get_terminology("identifiant", this.current_agency)}`;
    },
    tooltip() {
      return `Si une adresse email a déjà été renseignée dans votre fiche par votre administrateur,
      vous devez renseigner cette adresse (il s'agit généralement de votre adresse mail ${this.current_agency.of_type === "Etablissement" ? "étudiante" : "professionnelle"})`;
    },
    helper_text() {
      return get_inscription_helper_text(this.current_agency.name);
    }
  },

  created() {
    if (this.$route.params.token) {
      this.from_token = true;
      this.$http.get(`/api/${this.$route.params.agency}/inscription/${this.$route.params.token}`).then(response => {
        let data = response.data;
        this.last_name = data.last_name;
        this.email = data.email;
        this.autolog_uid = data.autolog_uid;
      })
    }
    axios.get(`/api/agency_data/${this.$route.params.agency}`).then(response => {
        this.$store.commit("setCurrentAgency", response.data);
      }).catch(() => {})
  },

  methods: {
    inscription() {
      this.button_loading = true;
      this.errors = false;
      this.error_cgu = false;
      this.error_email = null;
      let formdata = new FormData();
      formdata.append("first_name", this.first_name);
      formdata.append("id_agency", this.id_agency);
      formdata.append("password", this.password);
      formdata.append("confirm_password", this.confirm_password);
      formdata.append("cgu", this.cgu);
      formdata.append("confidentiality", this.confidentiality);
      if (this.from_token) {
        axios.put(`/api/${this.$route.params.agency}/validate_inscription/${this.autolog_uid}`, formdata, {headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(() => {
          this.success = true;
          this.dialog_connexion = true;
        }).catch(error => {
          if (error.response.status === 422) {
            if(error.response.data.cgu) this.error_cgu = true;
            else this.errors = true;
          }
          else this.$store.dispatch('ManageErrorLight', error.response.status);
        }).finally(() => {
          this.button_loading = false;
        });
      }
      else {
        formdata.append("last_name", this.last_name);
        formdata.append("email", this.email);
        formdata.append("confirm_email", this.confirm_email);
        axios.put(`/api/${this.$route.params.agency}/validate_inscription`, formdata, {headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(() => {
          this.dialog_success_email = true;
        }).catch(error => {
          if (error.response.status === 422) {
            if (error.response.data.email) this.error_email = error.response.data.email[0];
            if (error.response.data.cgu) this.error_cgu = true;
            else this.errors = true;
          }
          else this.$store.dispatch('ManageErrorLight', error.response.status);
        }).finally(() => {
          this.button_loading = false;
        });
      }
    }
  }
}
</script>

<style scoped>
.v-card{
  border-radius: 20px;
}
</style>
