<template>
<!--  TODO MKT : a supprimer si possible d'enlever de 'InscriptionView'-->
  <v-dialog v-model="_dialog" width="400">
    <AnAuth :basic_auth="false" :flat="true" card_class=""
            :agency_name="agency.name" app_name="covidapp" :agency_type="agency.of_type"
            v-on:connected="redirect($event)">
      <template v-slot:card-title>
        <v-card-title class="primary primary-title white--text pr-0">
          Connexion
          <v-spacer></v-spacer>
          <v-btn icon @click="_dialog=false" text>
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      </template>
      <template v-slot:under_card>
        <v-divider></v-divider>
        <v-row class="mx-0 mt-2 text-center">
          <v-col cols="12">
            <span>Je suis un nouvel utilisateur :</span>
            <v-btn @click="$emit('open_inscription');_dialog=false;" color="soft_blue" text>
              <span class="text-decoration-underline">Inscription</span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </AnAuth>
  </v-dialog>
</template>

<script>
import AnAuth from "anam-library/src/components/an-auth/AnAuth";

export default {
    name: "ConnexionDialog",
    components: {
      AnAuth
    },
    props: {
      agency: {
        type: Object,
        default: null,
      },
      dialog: {
        type: Boolean,
        default: false,
      }
    },

    computed: {
      _dialog: {
        get: function() {
          return this.dialog
        },
        set: function(val) {
          this.$emit('update:dialog', val)
        }
      }
    },
    methods: {
      redirect: function(data) {
        this.$store.commit('ResetSnackBar');
        this.$router.push({name: "RedirectionView", query: data});
      },
    }
  };
</script>
