<template>
  <PrCardComponent title="Mon suivi d'état de santé" icon="mdi-clipboard-text">
    <template v-slot:card-text>
      <p class="secondary--text semibold--text">Évolution de mes scores</p>
      <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
      <NoDataTxt v-else-if="!series || !series[0].data.length" txt="Nous ne pouvons pas afficher vos résultats tant que vous n'avez pas répondu à au moins 1 questionnaire" />
      <apex-chart
        v-else
        type="line"
        :options="{
          chart: {
            zoom: {enabled: false},
            toolbar: {show: false}
          },
          noData: {
            text: ['Nous ne pouvons pas afficher vos résultats tant', 'que vous n\'avez pas répondu à au moins 1 questionnaire']
          },
          xaxis: {
            categories: categories
          },
          yaxis: {
            min: 0,
            max: 200
          },
        }"
        :series="series"
    ></apex-chart>
      <div :class="`${$vuetify.breakpoint.xs ? '' : 'd-flex'} mt-5`">
        <span class="normal--text basic--text align-self-center pr-2">Votre questionnaire hebdomadaire de suivi covid est désormais disponible ! Cliquez sur le bouton pour commencer.</span>
        <v-spacer></v-spacer>
        <TooltipMissingInfo v-if="missing_info" />
        <CovidappButtonStartQuestionnaire v-else class="mt-3 mt-sm-0" />
      </div>
      <v-checkbox :value="send_reminder_email" @change="update_send_reminder($event)" label="Je souhaite recevoir les emails de relance" dense class="mt-5"></v-checkbox>
    </template>
  </PrCardComponent>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import CovidappButtonStartQuestionnaire from "@/components/patient/covidhelp/components/CovidappButtonStartQuestionnaire";
import TooltipMissingInfo from "../../../components/TooltipMissingInfo";
import {get_formatted_date} from "anam-library/src/js/dates";
import NoDataTxt from "../../../components/NoDataTxt";

export default {
  name: "CovidhelpPatientSuiviCard",
  components: {PrCardComponent, CovidappButtonStartQuestionnaire, TooltipMissingInfo, NoDataTxt},
  data: () => ({
    loading: false,
    series: [],
    categories: [],
    send_reminder_email: false
  }),
  computed: {
    missing_info() {
      return this.$store.getters.checkMissingInfoCovid;
    }
  },
  mounted() {
    this.loading = true;
    this.send_reminder_email = this.$store.getters.getPatientReminderEmail;
    this.$http.get("/api/patients/0/scores_covid").then(response => {
      let graph_data = {};
      for (let data of response.data) {
        let date = get_formatted_date(data.date, {output_format: "D MMMM YYYY"}, true);
        graph_data[date] = data.value;
      }
      this.categories = Object.keys(graph_data);
      this.series = [{name: "Score Covid+", data: Object.values(graph_data)}];
    }).finally(() => {
      this.loading = false;
    });
  },

  methods: {
    update_send_reminder(value) {
      this.$http.patch("/api/update_send_reminder_email", {"value": !!value}).then(() => {
        this.$store.commit("setSpecificPatientPrevanaData", {key: "send_reminder_email", val: !!value});
        this.$store.commit('PostSuccess', 'Votre modification a bien été enregistrée');
      }).catch(() => {
        this.$store.commit('PostCommonError');
      })
    }
  }
}
</script>

<style scoped>

</style>
