<template>
  <div>
    <PrFilterStatistics
      :response_api="response_api"
      :filter_list.sync="filter_list"
      :filter_date.sync="filter_date"
      :level_filter.sync="level_filter">
      <template v-slot:additional_header>
        <v-col cols="12" >
          <AnExportBtn :loading="loading"
                       :text="false"
                       v-on:generate_csv="generate_csv_vma()"
                       v-on:generate_xlsx="generate_xlsx_vma()"/>
        </v-col>
      </template>
    </PrFilterStatistics>

    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <v-expand-transition>
      <v-row v-if="!loading" class="mx-0">
        <v-col cols="12" sm="6">
          <PrCardComponent icon="mdi-clipboard-text" title="Types de consultation">
            <template v-slot:card-text>
              <apex-chart
                type="bar"
                height="400"
                :options="chart_options_consultation"
                :series="chart_series_consultation"/>
            </template>
          </PrCardComponent>
        </v-col>

        <v-col cols="12" sm="6">
          <PrCardComponent icon="mdi-clipboard-text" title="Types de handicap">
            <template v-slot:card-title-append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" class="pt-1 ml-2" color="primary" @click="dialog_handicap_precision=true">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
                <span>Voir les précisions</span>
              </v-tooltip>
            </template>
            <template v-slot:card-text>
              <apex-chart
                type="bar"
                height="400"
                :options="chart_options_handicap"
                :series="chart_series_handicap"/>
            </template>
          </PrCardComponent>
        </v-col>

        <v-col cols="12" sm="6">
          <PrCardComponent icon="mdi-clipboard-text" title="Nationalité des inscrits">
            <template v-slot:card-text>
              <apex-chart
                type="bar"
                height="400"
                :options="chart_options_nationality"
                :series="chart_series_nationality" />
            </template>
          </PrCardComponent>
        </v-col>

        <v-col cols="12" sm="6">
          <PrCardComponent icon="mdi-clipboard-text" title="Réponses RQTH">
            <template v-slot:card-text>
              <apex-chart
                type="pie"
                height="400"
                :options="chart_options_rqth"
                :series="chart_series_rqth" />
            </template>
          </PrCardComponent>
        </v-col>

        <v-col cols="12" sm="6">
          <PrCardComponent icon="mdi-clipboard-text" title="État des rendez-vous">
            <template v-slot:card-text>
              <apex-chart
                type="pie"
                height="400"
                :options="chart_options_rdv"
                :series="chart_series_rdv"/>
            </template>
          </PrCardComponent>
        </v-col>

        <v-col cols="12" sm="6">
          <PrCardComponent icon="mdi-clipboard-text" title="Rendez-vous par professionnel">
            <template v-slot:card-title-append>
              <AnExportBtn :loading="loading_csv"
                           v-on:generate_csv="generate_csv('pro', 'Rendez-vous par professionnel')"
                           v-on:generate_xlsx="generate_xlsx('pro', 'Rendez-vous par professionnel')"/>
            </template>
            <template v-slot:card-text>
              <v-data-table
                :items="items_pro"
                :headers="headers_pro"
                no-data-text="Aucune donnée"
                sort-by="value"
                sort-desc
                hide-default-footer
                :items-per-page="-1"
              ></v-data-table>
            </template>
          </PrCardComponent>
        </v-col>

        <v-col cols="12">
          <PrCardComponent icon="mdi-clipboard-text" title="Types d'aménagement">
            <template v-slot:card-title-append>
              <AnExportBtn :loading="loading_csv"
                           v-on:generate_csv="generate_csv('amenagement', 'Type d\'aménagement')"
                           v-on:generate_xlsx="generate_xlsx('amenagement', 'Type d\'aménagement')"/>
            </template>
            <template v-slot:card-text>
              <v-data-table
                :items="items_amenagement"
                :headers="headers_amenagement"
                no-data-text="Aucune donnée"
                sort-by="value"
                sort-desc
                hide-default-footer
                :items-per-page="-1"
              ></v-data-table>
            </template>
          </PrCardComponent>
        </v-col>
      </v-row>
    </v-expand-transition>

    <v-dialog v-model="dialog_handicap_precision" fullscreen>
      <v-card>
        <v-card-title class="primary normal--text white--text">
          Détails des types de handicap
          <v-spacer></v-spacer>
          <v-btn icon text color="white" @click="dialog_handicap_precision = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="pa-3">
            <v-col cols="3">
              <v-select v-model="select_handicap_precision" :items="items_select_handicap_precision" label="Type de handicap"></v-select>
            </v-col>
            <v-col cols="12" class="text-center mt-5">
              <apex-chart
                v-if="select_handicap_precision"
                type="bar"
                height="400"
                :options="chart_options_handicap_precision"
                :series="chart_series_handicap_precision"/>
              <p v-else>Sélectionnez un type de handicap pour afficher les statistiques associées</p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import PrFilterStatistics from "prevana-library/src/components/statistics/components/PrFilterStatistics";
import moment from "moment-timezone";
import {HANDICAP_CHOICES, HANDICAP_PRECISION_CHOICES} from "../../../../data/vma/dict_vma";
import {get_age, get_formatted_date} from "anam-library/src/js/dates";
import AnExportBtn from "anam-library/src/components/an-export/AnExportBtn"
import {generate_csv} from "anam-library/src/js/export_file";
import {generateXlsx} from "anam-library/src/js/export_xlsx";


export default {
  name: "HFManagerVMAResumeView",
  components: {
    PrCardComponent,
    PrFilterStatistics,
    AnExportBtn
  },
  data() {
    return {
      loading: false,
      response_api: null,
      filtered_response: {},
      level_filter: null,
      filter_list: [],
      filter_date: moment().format('DD/MM/YYYY'),
      categories_consultation: [],
      chart_series_consultation: [],
      categories_handicap: [],
      chart_series_handicap: [],
      categories_handicap_precision: [],
      chart_series_handicap_precision: [],
      chart_series_rdv: [],
      categories_nationality: [],
      chart_series_nationality: [],
      chart_series_rqth: [],
      items_amenagement: [],
      headers_amenagement: [
        {value: "name", text: "Type d'aménagement"},
        {value: "value", text: "Nombre de patients"},
      ],
      items_pro: [],
      headers_pro: [
        {value: "name", text: "Professionnel"},
        {value: "value", text: "Nombre de rendez-vous"},
      ],
      dialog_handicap_precision: false,
      select_handicap_precision: null,
      items_select_handicap_precision: HANDICAP_CHOICES,
      loading_csv: false,
      loading_export: false,
      headers_export_file : ["Identifiant externe", "Sexe", "Age", "Lieu de naissance", "Programme",
        "Type de consultation", "Date de consultation", "Année", "Lieu", "Aménagement", "Absence/ présence au rdv",
        "RQTH", "Type d'aménagement mis en place", "Durée de l'aménagement", "Type de handicap",
        "Précision du handicap ", "Durée du handicap"
      ]
    }
  },
  mounted() {
    this.get_stats();
  },
  watch: {
    filter_list() {
      this.get_filtered_response();
    },
    filter_date() {
      this.get_stats();
    },
    select_handicap_precision() {
      this.set_precision_graph();
    },
    dialog_handicap_precision() {
      this.select_handicap_precision = null;
    }
  },

  computed: {
    chart_options_base() {
      return {
        chart: {
          zoom: {enabled: false},
          toolbar: {show: true, tools: {download: true}}
        },
        noData: {text: 'Aucune donnée'},
        colors: [this.secondary],
        plotOptions: {
          bar: {
            columnWidth: "35%",
          },
        },
      }
    },
    chart_options_consultation() {
      let chart_options = JSON.parse(JSON.stringify(this.chart_options_base));
      chart_options.xaxis = {categories: this.categories_consultation}
      return chart_options;
    },
    chart_options_handicap() {
      let chart_options = JSON.parse(JSON.stringify(this.chart_options_base));
      chart_options.xaxis = {categories: this.categories_handicap}
      return chart_options;
    },
    chart_options_handicap_precision() {
      let chart_options = JSON.parse(JSON.stringify(this.chart_options_base));
      chart_options.xaxis = {categories: this.categories_handicap_precision}
      return chart_options;
    },
    chart_options_rdv() {
      return {
        chart: {
          zoom: {enabled: false},
          toolbar: {show: true, tools: {download: true}}
        },
        noData: {text: 'Aucune donnée'},
        colors: [this.primary, this.tertiary, this.secondary],
        labels: ["Pris", "Annulés", "Réalisés"]
      }
    },
    chart_options_rqth() {
      return {
        chart: {
          zoom: {enabled: false},
          toolbar: {show: true, tools: {download: true}}
        },
        noData: {text: 'Aucune donnée'},
        colors: [this.primary, this.secondary],
        labels: ["Oui", "Non"]
      }
    },
    chart_options_nationality() {
      let chart_options = JSON.parse(JSON.stringify(this.chart_options_base));
      chart_options.xaxis = {categories: this.categories_nationality}
      return chart_options;
    },
    primary() {
      return this.$vuetify.theme.currentTheme.primary;
    },
    secondary() {
      return this.$vuetify.theme.currentTheme.secondary;
    },
    tertiary() {
      return this.$vuetify.theme.currentTheme.primary_2;
    },
  },
  methods: {
    get_stats() {
      this.loading = true;
      let filter_date = get_formatted_date(this.filter_date, {input_format: "DD/MM/YYYY", output_format: "YYYY-MM-DD"});
      this.$http.get("/api/stats_vma", {params: {filter_date: filter_date}}).then(response => {
        this.response_api = response.data;
        this.get_filtered_response();
      }).finally(() => {
        this.loading = false;
      });
    },
    get_filtered_response() {
      let data = {};
      if (!this.level_filter) {
        for (let key in this.response_api.campus) {
          data[key] = this.response_api.campus[key];
        }
      } else {
        for (let key in this.response_api[this.level_filter]) {
          if (this.filter_list.includes(parseInt(key)) || this.filter_list.length === 0) {
            data[key] = this.response_api[this.level_filter][key];
          }
        }
      }
      this.filtered_response = data;
      this.get_chart_series("consultation");
      this.get_chart_series("handicap");
      this.get_chart_series("rdv");
      this.get_chart_series("nationality");
      this.get_chart_series("rqth");
      this.get_data_table("amenagement");
      this.get_data_table_pro();
    },
    get_data_table(type) {
      for (let key in this.filtered_response) {
        for (let el in this.filtered_response[key][type]) {
          let index = this[`items_${type}`].findIndex(x => x.name === el);
          if (index === -1) this[`items_${type}`].push({name: el, value: this.filtered_response[key][type][el]});
          else this[`items_${type}`][index].value += this.filtered_response[key][type][el];
        }
      }
    },
    get_data_table_pro() {
      this.items_pro = [];
      for (let key in this.filtered_response) {
        for (let el in this.filtered_response[key]["data_pro"]) {
          let index = this.items_pro.findIndex(x => x.id === el);
          if (index === -1) this.items_pro.push({
            id: el,
            name: this.filtered_response[key]["data_pro"][el].name,
            value: this.filtered_response[key]["data_pro"][el].value
          });
          else this.items_pro[index].value += this.filtered_response[key]["data_pro"][el].value;
        }
      }
    },
    get_chart_series(type) {
      this[`categories_${type}`] = [];
      this[`chart_series_${type}`] = [];
      let data = {};
      for (let key in this.filtered_response) {
        for (let el in this.filtered_response[key][type]) {
          if (!this[`categories_${type}`].includes(el)) this[`categories_${type}`].push(el);
          if (!Object.keys(data).includes(el)) data[el] = 0;
          data[el] += this.filtered_response[key][type][el];
        }
      }
      if (type === "rqth") {
        let is_null = true;
        for (let el in data) {
          if (data[el] !== 0) is_null = false;
        }
        if (is_null) data = {};
      }
      if (["rdv", "rqth"].includes(type)) this[`chart_series_${type}`] = Object.values(data);
      else this[`chart_series_${type}`] = [{name: "", data: Object.values(data)}];
    },
    set_precision_graph() {
      if (!this.select_handicap_precision) return;
      this.categories_handicap_precision = [];
      this.chart_series_handicap_precision = [];
      let data = {};
      let no_data = true;
      for (let key in this.filtered_response) {
        for (let handicap_precision of HANDICAP_PRECISION_CHOICES) {
          if (handicap_precision.handicap_type === this.select_handicap_precision) {
            for (let el in this.filtered_response[key].handicap_precision) {
              if (handicap_precision.value === el) {
                this.categories_handicap_precision.push(handicap_precision.text);
                if (!Object.keys(data).includes(handicap_precision.text)) data[handicap_precision.text] = 0;
                let value = this.filtered_response[key].handicap_precision[el];
                data[handicap_precision.text] += value;
                if (value) no_data = false;
              }
            }
          }
        }
      }
      if (!no_data) this.chart_series_handicap_precision = [{name: "", data: Object.values(data)}];
    },
    headers_xlsx(type) {
      let headers = [];
      for (let header of this[`headers_${type}`]) {
        headers.push(header.text);
      }
      return headers
    },
    download_xlsx(type) {
      this.loading_csv = true;
      let data_xlsx = [];
      for (let item of this[`items_${type}`]) {
        let rowData = [];
        for (let header of this[`headers_${type}`]) {
          if (item[header.value] !== null) {
            rowData.push(item[header.value].toString());
          } else {
            rowData.push("N/A");
          }
        }
        data_xlsx.push(rowData);
      }
      this.loading_csv = false;
      return data_xlsx
    },
    async get_export_vma() {
      this.loading_export = true;
      let response = await this.$http.get("/api/export_vma");
      let data_xlsx = [];
      for (let data of response.data) {
        data_xlsx.push([
          data.identifier,
          data.sexe,
          get_age(data.birth_date, true),
          data.nationality,
          data.level_3,
          data.consultation_type,
          get_formatted_date(data.consultation_date),
          data.level_4,
          data.place,
          data.amenagement,
          data.status,
          data.rqth,
          data.amenagement_type,
          data.amenagement_duration,
          data.handicaps,
          data.handicaps_details,
          data.handicaps_duration
        ]);
      }
      this.loading_export = false;
      return data_xlsx
    },
    async generate_csv_vma() {
      generate_csv(await this.get_export_vma(), this.headers_export_file, "export_vma", false);
    },
    async generate_xlsx_vma() {
      generateXlsx(await this.get_export_vma(), this.headers_export_file, "export_vma", "export_vma");
    },
    generate_csv: function (of_type, filename) {
      generate_csv(this.download_xlsx(of_type), this.headers_xlsx(of_type), filename, false);
    },
    generate_xlsx: function (of_type, filename) {
      generateXlsx(this.download_xlsx(of_type), this.headers_xlsx(of_type), filename, filename);
    }
  }
}
</script>

<style scoped>

</style>
