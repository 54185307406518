<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>
    <RpsDashboardCardFAQ :see_details="false" />
  </div>
</template>

<script>
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";
import RpsDashboardCardFAQ from "../dashboard/faq/RpsDashboardCardFAQ";

export default {
  name: "PatientRpsFAQView",
  components: {
    RpsDashboardCardFAQ,
    PrBreadcrumbsComponent,
  },
  data: () => ({
    navigations: [
      {text: "Bien-Être", to: '/patient/rps/dashboard'},
      {text: "Questions Fréquentes", to: '/patient/rps/faq'}
    ]
  })
}
</script>

<style scoped>

</style>
