<template>
  <v-row class="mx-0">
    <v-col cols="12">
      <PrCardComponent :title="$t('vma.informations')" icon="mdi-information">
        <template v-slot:card-title-append>
          <v-spacer></v-spacer>
          <v-btn icon color="primary" @click="expand_informations=!expand_informations">
            <v-icon>{{ expand_informations ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </template>
        <template v-slot:card-text>
          <v-expand-transition>
            <div v-if="expand_informations">
              <p class="font-size--14 normal--text basic--text">
                {{$t("vma.info_1")}}<br>
                {{$t("vma.info_2")}} <span class="secondary--text semibold--text font-size--14">{{$t("vma.info_3")}}</span> {{$t("vma.info_4")}}<br>
                <v-icon color="primary">mdi-circle-small</v-icon> <span class="secondary--text semibold--text font-size--14">{{$t("vma.info_5")}}</span> {{$t("vma.info_6")}}<br>
                <v-icon color="primary">mdi-circle-small</v-icon> <span class="secondary--text semibold--text font-size--14">{{$t("vma.info_7")}}</span>. {{$t("vma.info_8")}}<br><br>
                {{$t("vma.info_9")}}
                <br/><br/>
                <!-- TODO VMA: modifier l'email si nouvelle agence-->
                {{$t("vma.info_9_bis")}} <a href="mailto:health@escp.eu">health@escp.eu</a>
                <br/><br/>
                {{$t("vma.info_10")}} <a href="https://www.doctolib.fr/" target="_blank">Doctolib</a>{{$t("vma.info_10_bis")}}
              </p>
            </div>
          </v-expand-transition>
        </template>
      </PrCardComponent>
    </v-col>
    <v-col cols="12" sm="6">
      <VmaPatientBilanCard :loading="loading" :consultations="rdv" v-on:new_document="new_document($event)" />
      <PatientDocumentsCardComponent ref="documents_card" :max_display="3" see_details class="mt-5" app_name="vma" />
    </v-col>
    <v-col cols="12" sm="6">
      <v-row>
        <v-col cols="12">
          <PatientRDVCardComponent small_version app_name="vma" v-on:rdv_loaded="rdv = $event; loading = false" />
        </v-col>
        <v-col v-for="(item, title) in contacts" cols="12" sm="12" :key="title">
          <PatientContactCardComponent :contacts="item" :title="title" />
        </v-col>
        <v-col cols="12">
          <PatientRessourcesCardComponent :ressources="ressources" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import PatientRessourcesCardComponent from "@/components/patient/components/ressources/PatientRessourcesCardComponent";
import PatientRDVCardComponent from "@/components/patient/components/rdv/PatientRDVCardComponent";
import VmaPatientBilanCard from "@/components/patient/vma/dashboard/bilan/VmaPatientBilanCard";
import PatientDocumentsCardComponent from "@/components/patient/components/documents/PatientDocumentsCardComponent";
import {get_ressources} from "../../../../data/vma/dict_vma";
import PatientContactCardComponent from "../../components/contacts/PatientContactCardComponent";
import {get_contacts} from "../../../../data/rps/text_by_agency";

export default {
  name: "PatientVMADashboardView",
  components: {
    PatientContactCardComponent,
    PatientDocumentsCardComponent,
    VmaPatientBilanCard,
    PatientRDVCardComponent,
    PatientRessourcesCardComponent,
    PrCardComponent,
  },
  data: () => ({
    expand_informations: true,
    rdv: [],
    loading: true
  }),
  computed: {
    contacts: function() {
      return get_contacts(this.$store.getters.getAgencyName);
    },
    ressources() {
      return get_ressources(this.$store.getters.getAgencyName);
    }
  },
  methods: {
    new_document(document) {
      this.$refs.documents_card.new_document(document);
    }
  }
}
</script>

<style scoped>

</style>
