<template>
  <div>
    <PrTabsHeader :tabs="tabs" :full_width="true"
                v-on:come_back="$router.push({ name: 'VMADoctorDashboard', query: {tab: $route.query.tab} })"/>
    <AnOverlayLoading :is_mounted="!loading"/>
    <v-row v-if="!loading">
      <v-col cols="12" md="3">
        <AnAdminInfos :name="name"
                      :admin_infos="$store.state.pat_admin.infos_admin"
                      editable
                      title_secondary_panel="Voir plus"
                      :patient_edit="false"
                      :full_width="true"
                      color="primary"
                      v-on:edit="$refs.edit_dialog.dialog = true"
                      :option_icon="false"/>
      </v-col>
      <v-col cols="12" md="9">
        <TabsBody v-on:set_loaded="set_loaded($event)" v-on:disable_tabs="disable_tabs($event)" />
      </v-col>
    </v-row>
    <PrEditDialog ref="edit_dialog"/>
  </div>
</template>

<script>
import AnOverlayLoading from "anam-library/src/components/an-ui/loading/AnOverlayLoading";
import AnAdminInfos from "anam-library/src/components/an-dpa/AnAdminInfos";
import PrEditDialog from "prevana-library/src/components/dpa/PrEditDialog";
import PrTabsHeader from "prevana-library/src/components/dpa/PrTabsHeader";
import TabsBody from "./TabsBody";

import dpa_mixin from "prevana-library/src/js/dpa_mixin";

export default {
  name: "DoctorVMADpaView",
  components: {
    AnOverlayLoading,
    AnAdminInfos,
    PrEditDialog,
    TabsBody,
    PrTabsHeader
  },
  mixins: [dpa_mixin],
  data: () => ({
    loading: true,
    tabs: [
      {title: "Résumé", icons: ["mdi-account"], href: "#tab_resume", show: true, disabled: false},
      {title: "RDV", icons: ["mdi-calendar-month"], href: "#tab_rdv", show: true, disabled: false},
      {title: "Notes", icons: ["mdi-pencil-box-outline"], href: "#tab_notes", show: true, disabled: false},
      {title: "Visite médicale", icons: ["mdi-clipboard-plus-outline"], href: "#tab_visit", show: true, disabled: false},
      {title: "Téléconsultation", icons: ["mdi-television-play"], href: "#tab_visio", show: true, disabled: false},
    ],
  }),
  async created () {
    this.loading = false;
  },
  methods: {
    set_loaded(tab) {
      let index = this.tabs.findIndex(x => x.href === tab);
      if (index > -1) this.tabs[index].disabled = false;
    },
    disable_tabs(disable) {
      if (disable) {
        for (let href of ["#tab_visit", "#tab_visio"]) {
          let index = this.tabs.findIndex(x => x.href === href);
          if (index > -1) this.tabs[index].disabled = true;
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
