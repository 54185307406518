<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>
    <v-row class="ma-2 ma-sm-5">
        <v-col cols="12" sm="8" offset-sm="2" class="mt-10">
          <PrCardComponent title="Questionnaire terminé" icon="mdi-calendar-check" color="secondary">
            <template v-slot:card-text>
              <v-row>
                <v-col cols="12">
                  <p v-if="can_take_rdv" class="normal--text black--text font-size--14 mt-5 text-break">
                    Merci pour vos réponses ! Si vous le souhaitez, vous pouvez prendre rendez-vous en cliquant ici:
                  </p>
                  <p v-else class="normal--text black--text font-size--14 mt-5 text-break">
                    Merci pour vos réponses !
                  </p>
                  <p v-if="info_profile" class="normal--text black--text font-size--14 mt-5 text-break">
                    Elles ont bien été enregistrées en tant que <span class="semibold--text">{{info_profile}}</span>
                  </p>
                </v-col>

                <v-col cols="12" v-if="can_take_rdv">
                  <v-btn  color="secondary" elevation="0" class="medium--text px-6" @click="go_to_appointment()" :loading="loading_rdv">Prendre rendez-vous</v-btn>
                </v-col>

                <v-col cols="12" class="text-center">
                  <v-btn color="primary" elevation="0" large rounded class="px-6 px-sm-10" @click="$router.push({name: 'PatientRpsDashboard'})">
                    <span class="medium--text font-size--16">Retour à l'accueil</span>
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <p v-if="loading">
                    <span class="mr-3">Chargement de vos réponses...</span>
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </p>
                  <AnRestitutionQuestionsAnswers
                    v-else
                    :questions="questions"
                    :panel="0"
                    dense
                  ></AnRestitutionQuestionsAnswers>
                </v-col>
              </v-row>
            </template>
          </PrCardComponent>
        </v-col>
    </v-row>

    <AnDialogComponent :dialog.sync="dialog_set_wants_consultation" title="Approfondir vos réponses" actions>
        <template v-slot:card-text>
          <p class="normal--text black--text font-size--18 text-break text-center">
            Souhaitez-vous approfondir vos réponses avec un professionnel de santé ?
          </p>
        </template>
        <template v-slot:card-actions>
          <v-btn color="bg_primary" elevation="0" large rounded class="px-6 primary--text mr-5" @click="set_wants_consultation(true)" :loading="loading_wants_consultation">
            <span class="bold--text font-size--16">Oui</span>
          </v-btn>
          <v-btn color="bg_primary" elevation="0" large rounded class="px-6 primary--text ml-5" @click="set_wants_consultation(false)" :loading="loading_wants_consultation">
            <span class="bold--text font-size--16">Non</span>
          </v-btn>
        </template>
      </AnDialogComponent>
  </div>
</template>

<script>
import AnDialogComponent from "anam-library/src/components/an-ui/an-dialog/AnDialogComponent";
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import AnRestitutionQuestionsAnswers from "anam-library/src/components/an-questionnaire/AnRestitutionQuestionsAnswers";
import {get_categorie_name} from "anam-library/src/data/dict_categories_pro";
import {can_set_wants_consultation} from "prevana-library/src/js/dict_permissions";

export default {
  name: "PatientRPSEndQuestionnaire",
  components: {PrBreadcrumbsComponent, PrCardComponent, AnRestitutionQuestionsAnswers, AnDialogComponent},
  data: () => ({
    questions: [],
    loading: false,
    loading_rdv: false,
    loading_wants_consultation: false,
    dialog_set_wants_consultation: false
  }),
  computed: {
    questionnaire_uid() {
      return this.$route.params.questionnaire_uid;
    },
    navigations() {
      return [
        {text: 'Bien-être', to: '/patient/rps/dashboard'},
        {text: 'Fin de questionnaire', to: `/patient/rps/end-questionnaire/${this.questionnaire_uid}`},
      ]
    },
    can_take_rdv() {
      return this.$store.getters.getCurrentAgency.patient_access_rdv;
    },
    can_set_wants_consultation() {
      return can_set_wants_consultation(this.$store.getters.getAgencyName);
    },
    info_profile() {
      let profiles = this.$store.getters.getProfiles;
      if (!profiles) return null;
      if (profiles.length === 0) return null;
      let data = this.$store.getters.getPatientData;
      if (!data) return null;
      return `${get_categorie_name(data.patient.categorie)}, ${data.patient.main_affectation.name}`;
    }
  },
  created() {
    if (!this.can_take_rdv && this.can_set_wants_consultation) {
      this.set_wants_consultation(null);
      this.dialog_set_wants_consultation = true;
    }
    this.loading = true;
    this.$http.get(`/api/patient/questionnaire-restitution/${this.questionnaire_uid}`).then(response => {
      this.questions = response.data;
    }).finally(() => {
      this.loading = false;
    })
  },

  methods: {
    go_to_appointment() {
      this.loading_rdv = true;
      this.$http.get("/api/rps_appointment_info/0").then(response => {
        if (response.data.has_appointment || !response.data.eligible_to_consultation) this.$router.push({name: 'PatientRPSAppointmentSummary'});
        else this.$router.push({name: 'PatientRPSAppointement'});
      }).finally(() => {
        this.loading_rdv = false;
      })
    },
    set_wants_consultation(wants_consultation) {
      this.loading_wants_consultation = true;
      let data = {wants_consultation: wants_consultation};
      this.$http.post(`/api/set_wants_consultation/${this.$store.getters.getPatientData.patient.id}`, data).then(() => {
        if (wants_consultation !== null) {
          this.$store.commit('PostSuccess', "L'information a bien été enregistrée");
          this.dialog_set_wants_consultation = false;
        }
      }).finally(() => {
        this.loading_wants_consultation = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
