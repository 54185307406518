<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>
    <v-row class="ma-2 ma-sm-5">
      <v-col cols="12" sm="8" offset-sm="2" class="mt-10">
        <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
        <PrCardComponent v-else title="Évolution" icon="mdi-calendar-check" color="secondary">
          <template v-slot:card-title-append>
            <p class="normal--text black--text font-size--14 mt-5 text-break">
              Votre dernière déclaration a été enregistrée le <span class="semibold--text primary--text font-size--16">{{date_latest_questionnaire}}:</span>
              <br/>
              <QuestionAnswers class="mt-2" :questionnaire="latest_suivi_questionnaire.q_a"></QuestionAnswers>
              <v-divider class="primary mt-5 mb-10"></v-divider>
              Si votre situation a évolué, cliquez sur <span class="semibold--text primary--text font-size--16">Mon état de santé a changé</span>.
              <br/>
              Cela démarrera un nouveau questionnaire de suivi afin de transmettre au SSSM l'évolution de votre état de santé.
            </p>
            <v-col cols="12" class="text-center">
              <ButtonBackDashboard redirection="PatientVaccinationDashboard" />
              <v-btn color="secondary" elevation="0" class="medium--text px-6" @click="start_suivi()">Mon état de santé a changé</v-btn>
            </v-col>
          </template>
        </PrCardComponent>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import QuestionAnswers from "@/components/doctor/vaccination/dpa/components/QuestionAnswers";
import {get_formatted_date} from "anam-library/src/js/dates";
import ButtonBackDashboard from "../../components/ButtonBackDashboard";

export default {
  name: "EvolutionVaccination",
  components: {ButtonBackDashboard, PrBreadcrumbsComponent, PrCardComponent, QuestionAnswers},
  data: () => ({
    navigations: [
        {text: 'Vaccination', to: '/patient/vaccination/dashboard'},
        {text: 'Suivi post vaccinal', to: '/patient/vaccination/evolution'},
      ],
    loading: false,
    latest_suivi_questionnaire: null
  }),
  computed: {
    date_latest_questionnaire() {
      return get_formatted_date(this.latest_suivi_questionnaire.date);
    }
  },
  mounted() {
    this.loading = true;
    this.$http.get("/api/has_access_to_suivi").then(response => {
      this.latest_suivi_questionnaire = response.data.latest_questionnaire;
    }).finally(() => {
      this.loading = false;
    })
  },
  methods: {
    start_suivi() {
      this.$router.push({name: 'QuestionnaireView', query: {questionnaire_type: 'SuiviVaccinationPrevana', app_name: 'prevanavaccination'}});
    }
  }
}
</script>

<style scoped>
</style>
