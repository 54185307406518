<template>
  <v-container class="pa-md-10 pa-lg-10 pa-xl-10 pa-xs-1 pa-sm-1">
    <AnQuestionBloc
      :patient_id="$route.query.patient_id"
      :questionnaire_type="$route.query.questionnaire_type"
      :app_name="$route.query.app_name"
      :redirection_end_anam="$route.query.redirection_end_anam"
      :additional_data="$route.query.additional_data"
      :nb_question="$route.query.nb_question"
      type_redirection="start"
      main_color="primary"
      progressbar_color="primary_2"
      button_color="secondary"
      question_color="primary"/>
  </v-container>
</template>

<script>
import AnQuestionBloc from "anam-library/src/components/an-questionnaire/patient/AnQuestionBloc";

export default {
  name: "QuestionnaireView",
  components: {AnQuestionBloc}
}
</script>
