<template>
  <div>
    <v-row v-if="access_forbidden">
      <v-col cols="12" class="text-center primary--text">
        <h4 class="font-weight-bold">
          Vous n'avez pas le droit d'accéder aux données de ce groupement ou de
          cette affectation
        </h4>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <p class="primary--text semibold--text font-size--20">Tableau "photo" du jour</p>
        <v-progress-linear v-if="loading_table1" indeterminate color="primary"></v-progress-linear>
        <v-simple-table class="elevation-1 table_monitoring" v-else>
          <template v-slot:default>
            <thead class="primary">
              <tr>
                <th class="text-left white--text">Groupement</th>
                <th class="text-right white--text" v-for="(item, index) in items_head1" :key="index">
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datas_table1" :key="index">
                <td>{{item.name}}</td>
                <td class="text-right">{{ item.confirme }}</td>
                <td class="text-right">{{ item.possible }}</td>
                <td class="text-right">{{ item.contact }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12" class="mt-3">
        <p class="primary--text semibold--text font-size--20">Tableau "état cumulatif"</p>
        <v-progress-linear v-if="loading_table2" indeterminate color="primary"></v-progress-linear>
        <v-simple-table class="elevation-1 table_monitoring" v-else>
          <template v-slot:default>
            <thead class="primary">
              <tr>
                <th class="text-left white--text">Groupement</th>
                <th class="text-right white--text" v-for="(item, index) in items_head2" :key="index">
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datas_table2" :key="index">
                <td>{{ item.name }}</td>
                <td class="text-right">{{ item.confirme }}</td>
                <td class="text-right">{{ item.possible }}</td>
                <td class="text-right">{{ item.contact }}</td>
                <td class="text-right">{{ item.exclu }}</td>
                <td class="text-right">{{ item.gueri }}</td>
                <td class="text-right">{{ item.endquarantaine }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: "CovidMonitoringTable",
  data: () => ({
    access_forbidden: false,
    items_head1: ["Confirmé", "Possible", "Contact"],
    items_head2: [
      "Confirmé",
      "Possible",
      "Contact",
      "Exclu",
      "Guéri",
      "Sortie d'isolement",
    ],
    loading_table1: true,
    loading_table2: true,
    datas_table1: {},
    datas_table2: {},
  }),
  created: function () {
    this.$http.get("/api/table_suivi").then((response) => {
        this.datas_table1 = response.data["table1"];
        this.datas_table2 = response.data["table2"];
      }).finally(() => {
        this.loading_table1 = false;
        this.loading_table2 = false;
      });
  }
};
</script>

<style>
.table_monitoring, .table_monitoring .v-data-table__wrapper{
  border-radius: 8px !important;
}
</style>
