<template>
  <v-tabs-items v-model="tab_selected">
    <v-tab-item value="tab_questionnaires" class="bg pa-1">
      <PrCardComponent title="Historique des questionnaires" icon="mdi-history">
          <template v-slot:card-text>
            <PrSimpleHistoryTab v-if="questionnaires_loaded" :questionnaires="questionnaires" :list_scores="list_scores" :get_color_function="get_color_function" />
            <v-progress-linear v-else indeterminate color="primary" class="mt-5" />
          </template>
        </PrCardComponent>
    </v-tab-item>
    <v-tab-item value="tab_evolution" class="bg pa-1">
      <PrCardComponent title="Évolution des scores" icon="mdi-chart-line">
        <template v-slot:card-text>
          <EvolutionTab :questionnaires="questionnaires" :questionnaires_loaded="questionnaires_loaded" />
        </template>
      </PrCardComponent>
    </v-tab-item>
    <v-tab-item value="tab_notes" class="bg pa-1">
      <PrNoteTab app_name="ECOS" :separate_private_and_public="true" />
    </v-tab-item>
  </v-tabs-items>
</template>

<script>
import PrNoteTab from "prevana-library/src/components/dpa/tabs/PrNoteTab";
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import {LIST_SCORES_ECOS, get_color_score} from "../../../../data/ecos/utils";
import PrSimpleHistoryTab from "prevana-library/src/components/dpa/tabs/PrSimpleHistoryTab";
import EvolutionTab from "./EvolutionTab";

export default {
  name: "TabsBody",
  components: {
    PrSimpleHistoryTab, EvolutionTab, PrNoteTab, PrCardComponent
  },
  data: () => ({
    consultations: [],
    questionnaires: [],
    questionnaires_loaded: false,
    list_scores: LIST_SCORES_ECOS,
    get_color_function: get_color_score
  }),
  computed: {
    tab_selected: {
      get() {
        return this.$store.state.dpa.tab_selected;
      },
      set(value) {
        this.$store.state.dpa.tab_selected = value;
      },
    }
  },
  created() {
    this.get_questionnaires();
  },
  methods: {
    get_questionnaires() {
      let params = {};
      let scores = [];
      for (let item of LIST_SCORES_ECOS) {
        scores.push(item.name);
      }
      params = {"scores": scores};
      this.$http.get(`/api/patients/${this.$route.params.id}/questionnaires-ecos`, {params: params}).then(response => {
        this.questionnaires = response.data;
      }).finally(() => {
        this.questionnaires_loaded = true;
      });
    },
  }
};
</script>
