import { render, staticRenderFns } from "./DoctorVaccinationAppointmentsView.vue?vue&type=template&id=57c50ddc&scoped=true&"
import script from "./DoctorVaccinationAppointmentsView.vue?vue&type=script&lang=js&"
export * from "./DoctorVaccinationAppointmentsView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57c50ddc",
  null
  
)

export default component.exports