<template>
  <v-container fluid class="pa-sm-10">
    <v-row class="mx-0">
      <v-col cols="12" sm="10" offset-sm="1" md="6" offset-md="3" class="mb-10 text-center">
        <v-card rounded elevation="2" class="mt-sm-8">
          <v-card-title class="justify-center pt-sm-8">
            <p class="primary--text bold--text font-size--28 mb-0 text-break">Questionnaire terminé !</p>
          </v-card-title>
          <v-card-text class="text-center pt-5 pt-sm-10 px-4 px-md-14">
            <p class="primary--text normal--text font-size--20 mb-0 text-break">Félicitations, tu as complété une séance de plus. À la semaine prochaine !</p>
            <p class="primary--text normal--text font-size--20 mb-0 text-break">Si tu veux refaire l'exercice, il est accessible dans tes ressources en
              <a class="blue--text" href="https://sorbonne.anamnese.care/accompagnement-gestion-stress-etudiant-sorbonne-ecos">cliquant ici.</a>
            </p>
            <v-img class="ma-5" src="https://www.anamnese.care/hubfs/config/Prevana/La%20Sorbonne/ECOS/page-fin-questionnaire/fin-questionnaire.png" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PatientECOSEndQuestionnaire"
}
</script>

<style scoped>

</style>
