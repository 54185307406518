<template>
  <CovidStatisticsComponent :csv_anonymous="true"/>
</template>

<script>
import CovidStatisticsComponent from "@/components/common/covidhelp/statistics/CovidStatisticsComponent";

export default {
  name: "HFManagerCovidStatisticsView",
  components: {CovidStatisticsComponent}
}
</script>

<style scoped>

</style>
