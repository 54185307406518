<template>
  <div>
    <v-container fluid class="bg_soft">
      <v-row>
        <v-col cols="2" class="text-center">
          <img src="@/assets/vaccination/picto_pds.png" height="100px"/>
        </v-col>
        <v-col cols="10" class="align-self-center">
          <p class="soft_blue--text bold--text font-size--25 mb-0">
            Une ou plusieurs de vos réponses ne permettent pas de prendre RDV.
          </p>
          <p class="soft_blue--text normal--text font-size--16">
            Cependant votre centre de vaccination ({{ $route.params.agency_name }}) va évaluer votre situation pour vous proposer une autre solution.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <SignupForm/>
  </div>

</template>

<script>
import SignupForm from "./SignupForm";

export default {
        name: "VaccinationNotEligibleView",
        components: {
          SignupForm
        }
    }
</script>

<style scoped>

</style>
