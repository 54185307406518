<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>

    <PatientAppointementComponent title_recap_place="Lieu de la vaccination"
                                  title_recap_date="Date de la vaccination"
                                  type_rdv="3"
                                  :redirection="get_redirection()"
                                  :is_eligible="is_eligible"
                                  app_name="prevanavaccination">
      <template v-slot:text_not_eligible>
        <p class="normal--text black--text font-size--14">
          Votre centre de vaccination ({{ $store.state.agency_commercial_name }}) a bien pris en compte votre souhait
          de vous faire vacciner.
          <br />
          Vous avez été mis en liste d'attente car soit il n'y a plus de créneaux disponibles soit pas assez de doses
          ont été livrées. De nouvelles doses arrivent régulièrement.
          <br />
          Votre centre de vaccination ({{ $store.state.agency_commercial_name }}) vous préviendra par mail dès que de
          nouveaux créneaux seront disponibles, selon les doses reçues, pour que vous puissiez prendre RDV.
        </p>
        <div class="text-center">
          <ButtonBackDashboard redirection="PatientVaccinationDashboard" />
        </div>
      </template>
    </PatientAppointementComponent>
  </div>
</template>

<script>
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";
import PatientAppointementComponent from "@/components/patient/appointment/PatientAppointementComponent";
import ButtonBackDashboard from "../../components/ButtonBackDashboard";

export default {
  name: "PatientVaccinationAppointementView",
  components: {
    ButtonBackDashboard,
    PrBreadcrumbsComponent,
    PatientAppointementComponent
  },
  data: () => ({
    is_eligible: true,
    navigations: [
      {
        text: 'Vaccination',
        to: '/patient/vaccination/dashboard'
      },
      {
        text: 'Prendre rendez-vous',
        to: '/patient/vaccination/appointment'
      }
    ]
  }),
  mounted() {
    let id = "0";
    if (this.$route.params.id) id = this.$route.params.id;
    this.$http.get("/api/patients/" + id + "/vaccination_data").then(response => {
      this.is_eligible = response.data.is_eligible;
    })
  },
  methods: {
    get_redirection() {
      if (this.$route.params.id) return {name: 'VaccinationDpa', params: {id: this.$route.params.id}};
      return {name: 'PatientVaccinationAppointmentSummary'};
    }
  }
}</script>
