<template>
  <div>
		<span v-if="item[variable]">
			<v-icon color="soft_red" class="mr-1"> mdi-alert </v-icon>
		</span>
    <div class="text-center" v-else>
			<span class="soft_blue--text medium--text">
				<v-icon class="soft_blue--text"> mdi-minus </v-icon>
			</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertPrerequisItem",
  props: ['item', 'variable'],
  methods: {
    get_value_to_display(data, _this=this) {
      return data ? _this.$t("dashboard.true") : _this.$t("dashboard.false");
    }
  }
}
</script>
