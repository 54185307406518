<template>
  <PatientAppointementComponent
    type_rdv="4"
    :redirection="get_redirection()"
    app_name="vma"
    title_left_card="Motif de consultation"
    show_rdv_type
  >
  </PatientAppointementComponent>
</template>

<script>
import PatientAppointementComponent from "../../patient/appointment/PatientAppointementComponent";

export default {
  name: "VMAAppointment",
  components: {PatientAppointementComponent},
  methods: {
    get_redirection() {
      if (this.$route.params.id) return {name: 'VMADoctorDpa', params: {id: this.$route.params.id}};
      return {name: 'PatientDashboard'};
    }
  }
}
</script>

<style scoped>

</style>
