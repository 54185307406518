<template>
  <v-row>
    <v-col cols="12" md="6">
      <PrCardComponent title="Recommandation" icon="mdi-lightbulb-on-outline">
        <template v-slot:card-title-append>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" small @click="dialog_help_recos=true">
            <v-icon class="mr-1" x-small>mdi-help</v-icon>
            <span class="medium--text text-decoration-underline">Aide</span>
          </v-btn>
          <HelpDialogRecommendations :dialog.sync="dialog_help_recos"/>
        </template>
        <template v-slot:card-text>
          <Recommendations />
        </template>
      </PrCardComponent>

      <PrCardComponent title="Synthèse Questionnaire" icon="mdi-format-list-bulleted" class="mt-6">
        <template v-slot:card-text>
          <v-progress-linear v-if="$store.state.dpa.loading_synthesis" color="secondary" indeterminate></v-progress-linear>
          <Synthesis />
        </template>
      </PrCardComponent>

      <div class="text-center mt-5">
        <v-btn v-if="can_launch_questionnaire" class="white--text px-6" elevation="0" color="secondary" @click="launch_questionnaire()">
          <v-icon class="mr-2" color="white">mdi-launch</v-icon>
          <span class="medium--text">Répondre au Questionnaire</span>
        </v-btn>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn class="mt-5 white--text" color="secondary" disabled>
                <v-icon class="mr-2" color="white">mdi-launch</v-icon>
                Répondre au Questionnaire
              </v-btn>
            </div>
          </template>
          <span>Veuillez d'abord renseigner le sexe, la taille et le poids du patient</span>
        </v-tooltip>
      </div>
    </v-col>

    <v-col cols="12" md="6">
      <PrCardComponent title="Situation" icon="mdi-call-split">
        <template v-slot:card-title-append>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" small @click="dialog_help_situation=true">
            <v-icon class="mr-1" x-small>mdi-help</v-icon>
            <span class="medium--text text-decoration-underline">Aide</span>
          </v-btn>
          <HelpDialogDecision :dialog.sync="dialog_help_situation" />
        </template>
        <template v-slot:card-text>
          <v-progress-linear v-if="$store.state.dpa.loading_state" indeterminate color="secondary"></v-progress-linear>
          <Decision :pds_uid="pds_uid" />
        </template>
      </PrCardComponent>

      <PrCardComponent title="Événements" icon="mdi-calendar" class="mt-6">
        <template v-slot:card-title-append>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" small @click="dialog_help_events=true">
            <v-icon class="mr-1" x-small>mdi-help</v-icon>
            <span class="medium--text text-decoration-underline">Aide</span>
          </v-btn>
          <HelpDialogEvenements :dialog.sync="dialog_help_events"/>
        </template>
        <template v-slot:card-text>
          <v-progress-linear v-if="$store.state.dpa.loading_evenement" color="secondary" indeterminate></v-progress-linear>
          <Evenement :pds_uid="pds_uid" />
        </template>
      </PrCardComponent>
    </v-col>
  </v-row>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import Synthesis from "./summary_items/Synthesis";
import Evenement from "./summary_items/Evenement.vue";
import HelpDialogEvenements from "./summary_items/dialogs/HelpDialogEvenements";
import Recommendations from "./summary_items/Recommendations";
import HelpDialogRecommendations from "./summary_items/dialogs/HelpDialogRecommendations";
import Decision from "./summary_items/Decision";
import HelpDialogDecision from "./summary_items/dialogs/HelpDialogDecision.vue";

export default {
  components: {
    Synthesis,
    PrCardComponent,
    Evenement,
    HelpDialogEvenements,
    Recommendations,
    HelpDialogRecommendations,
    Decision,
    HelpDialogDecision,
  },
  props: {
    pds_uid: {
      type: String,
    },
  },
  data: () => ({
    dialog_help_recos: false,
    dialog_help_situation: false,
    dialog_help_events: false
  }),
  mounted() {
    this.$store.dispatch("fetchState", this.pds_uid);
    this.$store.dispatch("fetchEvenements", this.pds_uid);
    this.$store.dispatch("fetchSynthesis", this.pds_uid);
  },

  computed: {
    can_launch_questionnaire() {
      let sex_needed = (!this.$store.state.pat_admin.data.sex || this.$store.state.pat_admin.data.sex === "");
      let height_needed = (!this.$store.state.pat_admin.data.size || this.$store.state.pat_admin.data.size === "");
      let weight_needed = (!this.$store.state.pat_admin.data.weight || this.$store.state.pat_admin.data.weight === "");
      return !(sex_needed || height_needed || weight_needed);
    }
  },

  methods: {
    launch_questionnaire: function () {
      this.$router.push({name: "CovidHelpDoctorQuestionnaireView", query: {questionnaire_type: "Dpistagepatientcovid19v2", app_name: "covidapp", patient_id: this.$route.params.id}});
    },
  },
};
</script>

<style>
</style>
