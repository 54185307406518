<template>
  <div>
    <PrTabsHeader
      :tabs="tabs"
      v-on:come_back="$router.push({ name: 'CovidhelpDashboard', query: {tab: $route.query.tab} })"
    />
    <AnOverlayLoading :is_mounted="!loading" />
    <v-row v-if="!loading">
      <v-col cols="12" md="3">
        <AnAdminInfos
          :name="name"
          :admin_infos="$store.state.pat_admin.infos_admin"
          editable
          :patient_edit="false"
          :full_width="true"
          v-on:edit="$refs.edit_dialog.dialog = true"
          :option_icon="false"
          title_secondary_panel="Voir plus"
          color="primary"
        />
      </v-col>
      <v-col cols="12" md="9">
        <TabsBody :pds_uid="pds_uid" />
      </v-col>
    </v-row>
    <PrEditDialog ref="edit_dialog" />
  </div>
</template>

<script>
import AnOverlayLoading from "anam-library/src/components/an-ui/loading/AnOverlayLoading";
import AnAdminInfos from "anam-library/src/components/an-dpa/AnAdminInfos";
import dpa_mixin from "prevana-library/src/js/dpa_mixin";
import PrTabsHeader from "prevana-library/src/components/dpa/PrTabsHeader";
import TabsBody from "./TabsBody";
import PrEditDialog from "prevana-library/src/components/dpa/PrEditDialog";

export default {
  mixins: [dpa_mixin],
  data() {
    return {
      pds_uid: undefined,
      loading: true,
      tabs: [
        { title: "Résumé", icons: ["mdi-account"], href: "#tab_anamnese", show: true },
        { title: "Historique",icons: ["mdi-history"], href: "#tab_history", show: this.$store.getters.getUserPermission === 2 },
        {
          title: "Évolutions",
         icons: ["mdi-chart-line"],
          href: "#tab_stats",
          show: true
        },
        {
          title: "Téléconsultation",
         icons: ["mdi-television-play"],
          href: "#tab_visio",
          show: this.$store.getters.getUserPermission === 2
        },
        { title: "Notes",icons: ["mdi-square-edit-outline"], href: "#tab_notes", show: true, badge_getters:'GetNbNote' },
        {
          title: "Comorbidités",
         icons: ["mdi-clipboard-pulse-outline"],
          href: "#tab_comorbidity",
          show: true
        },
      ],
    };
  },
  components: {
    AnOverlayLoading,
    PrTabsHeader,
    TabsBody,
    AnAdminInfos,
    PrEditDialog,
  },
  async created () {
    let { data } = await this.$http.post(`/api/patients/${this.$route.params.id}/create_parcours_de_soin?app=covidapp`);
    this.pds_uid = data.pds_uid;
    this.loading = false;
  },
};
</script>

<style>
</style>
