<template>
  <PrCardComponent :title="$t(title)" icon="mdi-file-document">
    <template v-slot:card-title-append>
      <v-spacer></v-spacer>
      <ButtonDetails v-if="see_details" :txt="`${$t('vma.ressources_2')} ${name_details}`" :redirection="to_details"/>
    </template>
    <template v-slot:card-text>
      <NoDataTxt v-if="ressources.length === 0" :txt="$t('vma.ressources_3')"/>
      <v-row class="mx-0">
        <v-col cols="12" sm="6" v-if="subtitle" class="py-0">
          <div class="d-flex fill-height align-center">
             <p class="font-italic normal--text">{{subtitle}}</p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" v-if="subtitle_logo" class="py-0">
          <img :src="subtitle_logo" height="50" width="auto" />
        </v-col>
        <v-col cols="12" md="4" v-for="(item, key) in ressources" :key="key" class="text-center">
          <v-hover v-slot="{ hover }">
            <div style="position: relative">
              <img :src="item.logo" width="100%" alt="Image ressource"/>
              <v-fade-transition>
                <v-overlay v-if="hover" absolute>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" fab elevation="0" color="secondary" x-small :href="item.url" target="_blank">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('common.download')}}</span>
                  </v-tooltip>
                </v-overlay>
              </v-fade-transition>
            </div>
          </v-hover>
          <span class="basic--text font-size--14 normal--text mt-1">{{ $t(item.name )}}</span>
        </v-col>
      </v-row>
    </template>
  </PrCardComponent>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import ButtonDetails from "@/components/patient/components/ButtonDetails";
import NoDataTxt from "@/components/patient/components/NoDataTxt";

export default {
  name: "PatientRessourcesCardComponent",
  components: {NoDataTxt, ButtonDetails, PrCardComponent},
  props: {
    ressources: {
      type: Array,
      default: () => []
    },
    see_details: {
      type: Boolean,
      default: false,
    },
    to_details: {
      type: String,
      default: null
    },
    name_details: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: "vma.ressources_1"
    },
    subtitle: {
      type: String,
      default: null
    },
    subtitle_logo: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
