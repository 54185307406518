<template>
  <v-container fluid>
    <PrCalendarView app_name="prevanavaccination"></PrCalendarView>
  </v-container>
</template>

<script>
import PrCalendarView from "prevana-library/src/components/calendar/PrCalendarView";

export default {
  name: "DoctorVaccinationAppointmentsView",
  components: {PrCalendarView}
}
</script>

<style scoped>

</style>
