<template>
  <PrCardComponent title="Modification des comorbidités | Facteurs de risques" icon="mdi-clipboard-pulse-outline">
    <template v-slot:card-text>
      <v-progress-linear v-if="loading_rfs" indeterminate color="secondary"></v-progress-linear>
      <v-row>
        <v-col cols="12" class="text-center pt-6">
          <v-icon color="#FFC17D" class="mr-2">mdi-alert</v-icon>
          <span>
            Si vous effectuez des modifications, n'oubliez pas de relancer le
            calcul des scores en cliquant sur le bouton en bas de cet onglet.
          </span>
        </v-col>
        <v-col cols="12">
          <v-row no-gutters>
            <v-col v-for="(item, uid, key) in comorbidites" :key="key" cols="12" sm="6">
              <v-checkbox v-model="item.status"
                          color="primary"
                          :label="item.name"
                          hide-details
                          @change="update_comorbidite(uid, item.status, item.name)">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="text-center pt-6">
          <v-btn color="primary" class="white--text" elevation="0" @click="save_new_scores" :loading="loading_save_new_scores">
            Relancer le calcul des scores
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </PrCardComponent>
</template>

<script>
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";

export default {
  components: {
    PrCardComponent,
  },
  props: {
    pds_uid: {
      type: String,
    },
  },
  data: () => ({
    loading_rfs: false,
    loading_save_new_scores: false,
    comorbidites: {},
  }),
  mounted: function () {
    this.loading_rfs = true;
    this.$http.get("/api/demande_de_depistage/" + this.pds_uid + "/comorbidites").then((response) => {
      this.comorbidites = response.data;
    }).finally(() => {
      this.loading_rfs = false;
    });
  },
  methods: {
    update_comorbidite: function (uid, status, name) {
      status = status ? 1 : 0;
      let form_data = new FormData();
      form_data.append("id_neo4j", uid);
      form_data.append("status", status);

      this.$http.post("/api/demande_de_depistage/" + this.pds_uid + "/comorbidites", form_data).then((response) => {
        let index = this.indications.comorbidites.indexOf(name);
        if (index > -1) {
          this.indications.comorbidites.splice(index, 1);
        } else {
          this.indications.comorbidites.push(name);
        }
        this.$store.commit("PostSuccess", "Modifications enregistrées");
      });
    },
    save_new_scores: function () {
      this.loading_save_new_scores = true;
      this.$http.get("/api/demande_de_depistage/" + this.pds_uid + "/scores").then((response) => {
        this.aggravation_score = response.data["aggravation_score"];
        this.priorisation = response.data["priorisation"];
        this.$store.state.dpa.tab_selected = "tab_anamnese";
        this.$store.commit("PostSuccess", "Modifications enregistrées");
      }).finally(() => {
        this.loading_save_new_scores = false;
      });
    },
  },
  computed: {
    indications: {
      get() {
        return this.$store.state.dpa.indications;
      },
      set(value) {
        this.$store.state.dpa.indications = value;
      },
    },
    aggravation_score: {
      get() {
        return this.$store.state.dpa.aggravation_score;
      },
      set(value) {
        this.$store.state.dpa.aggravation_score = value;
      },
    },
    priorisation: {
      get() {
        return this.$store.state.dpa.priorisation;
      },
      set(value) {
        this.$store.state.dpa.priorisation = value;
      },
    },
  },
};
</script>

<style>
</style>
