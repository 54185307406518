<template>
  <v-container>
    <v-row class="mx-0 justify-center">
      <v-col cols="12" sm="6">
        <AnAuth :basic_auth="false" :style_v2="true"
                :flat="true" :btn_rounded="false" btn_connexion_color="secondary"
                card_class="bg_primary"
                :agency_name="agency_name" :agency_type="agency_type"
                app_name="covidapp"
                option_inscription
                color_card_dialog_mdp="primary"
                color_btn_dialog_mdp="secondary"
                v-on:inscription="on_inscription()"
                v-on:connected="redirect($event)">
          <template v-slot:card-title>
            <div class="text-center mb-5">
              <img v-if="agency_data" width="30%" :src="agency_data.logo" alt="Logo"/>
            </div>
          </template>
          <template v-slot:under_card>
            <v-row class="mx-0 mt-2 text-center">
              <v-col cols="12">
                <v-btn color="primary" text>
                  <a href="https://aide.anamnese.care/inscription-patient" target="_blank">{{$t('prevana.help_first_connection')}}</a>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </AnAuth>
      </v-col>
    </v-row>
    <InscriptionDialog :model="dialog_inscription" v-on:closed="dialog_inscription = false" :agency_type="agency_type" />
  </v-container>
</template>

<script>
import AnAuth from "anam-library/src/components/an-auth/AnAuth";
import InscriptionDialog from "@/components/home/dialogs/InscriptionDialog";
import axios from "axios";

export default {
  name: "ConnexionView",
  components: {InscriptionDialog, AnAuth},
  data: () => ({
    uses_inscription_rps: false,
    dialog_inscription: false,
    agency_data: null,
  }),
  created() {
    this.$vuetify.goTo(0);
    this.$http.get(`/api/agency_data/${this._agency}`).then(response => {
      this.agency_data = response.data;
      this.$store.commit("setCurrentAgency", response.data);
    })
  },

  computed: {
    _agency: function() {
      return this.$route.params.agency_name;
    },
    agency_name: function() {
      if(this.agency_data) return this.agency_data.name;
      return "";
    },
    agency_type: function() {
      if(this.agency_data) return this.agency_data.of_type;
      return null;
    }
  },
  methods: {
    on_inscription() {
      if (this.uses_inscription_rps) this.$router.push(`/rps/${this._agency}/inscription`);
      else this.dialog_inscription = true;
    },
    redirect: function(query) {
      this.$store.commit('ResetSnackBar');
      if (this.$router.currentRoute.query.redirection) {
        query["redirection"] = this.$router.currentRoute.query.redirection;
      }
      this.$router.push({name: "RedirectionView", query: query});
    },
  },
  watch: {
    "$route.params.agency_name": {
      handler(val) {
        if (val) {
          axios.get(`/api/agency_data/${val}`).then(response => {
            // TODO RPS: supprimer cette ligne quand la factorisation des pages d'inscription sera faite
            this.uses_inscription_rps = response.data.uses_inscription_rps;
          }).catch(() => {})
        }
      },
      deep: true,
      immediate: true
    }
  },
};
</script>
