<template>
  <v-container class="pa-md-10 pa-lg-10 pa-xl-10 pa-xs-1 pa-sm-1">
    <AnQuestionBloc
      :nb_question="$route.query.nb_question"
      patient_id="0"
      :questionnaire_type="$route.query.questionnaire_type"
      :specific_start_anam_url="$route.query.specific_start_anam_url"
      :app_name="$route.query.app_name"
      :additional_data="$route.query.additional_data"
      :type_redirection="$route.query.redirection || 'start'"
      :questionnaire_uid="$route.params.questionnaire_uid"
      :redirection_end_anam="$route.query.redirection_end_anam"
      :show_progressbar="$route.query.nb_question > 0"
      main_color="primary"
      progressbar_color="primary_2"
      button_color="secondary"
      question_color="primary"
      flat/>
  </v-container>
</template>

<script>
import AnQuestionBloc from "anam-library/src/components/an-questionnaire/patient/AnQuestionBloc";

export default {
  name: "QuestionnaireView",
  components: {AnQuestionBloc}
}
</script>
