<!-- Composant pour afficher un texte explicatif avant le lancement d'un nouveau questionnaire RPS -->
<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>
    <v-card class="my-8 ma-sm-auto mt-sm-12" max-width="800" elevation="4" rounded>
      <v-card-title :class="$vuetify.breakpoint.xsOnly ? 'justify-center primary' : 'primary'">
        <span class="font-size--24 white--text bold--text text-break">Questionnaire</span>
      </v-card-title>
      <AnWelcomeView
        :title="false"
        btn_color="secondary"
        :btn_text="btn_text"
        :start_disabled="loading"
        height_img="200"
        v-on:next_step="start_questionnaire()"
        :show_doctor_image="show_doctor_image"
        app_name="rps"
        univers="prevana"
      >
        <template v-slot:texte>
          <p class="primary--text medium--text font-size--16" v-if="questionnaire_info" v-html="questionnaire_info"></p>
          <p class="tertiary--text medium--text font-size--16" v-else>
            C'est un questionnaire sécurisé qui permettra à votre {{niveau_1}} de mieux connaître l'état général de ses {{niveau_4}}.<br>
            En répondant au questionnaire, vous permettez la détection d'éventuels problèmes au sein des {{patient}}.<br>
            <template v-if="show_pds_access_phrase_start_questionnaire">
              Seuls vos professionnels de santé auront accès à vos données personnelles de santé.
            </template>
          </p>
          <p v-if="profiles.length" class="tertiary--text medium--text font-size--16">
            Vous êtes sur le point de compléter le questionnaire avec votre profil <span class="bold--text">{{info_profile}}</span></p>
        </template>
        <template v-slot:txt_time>
          <div class="text-center">
            <span class="primary--text font-italic medium--text font-size--14">Durée {{duration}} minutes</span>
          </div>
        </template>
      </AnWelcomeView>
    </v-card>
  </div>

</template>

<script>
import AnWelcomeView from "anam-library/src/components/an-questionnaire/patient/AnWelcomeView";
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";

import {get_terminology} from "anam-library/src/data/dict_terminology_by_agency";
import {get_questionnaire_info, get_duration, get_show_doctor_image} from "../../../../data/rps/text_by_agency";
import {get_categorie_name} from "anam-library/src/data/dict_categories_pro";
import {show_pds_access_phrase_start_questionnaire} from "prevana-library/src/js/dict_permissions";

export default {
  name: "PatientRPSStartQuestionnaireView",
  components: {
    AnWelcomeView,
    PrBreadcrumbsComponent
  },
  data: () => ({
    navigations: [
      {text: "Bien-Être", to: '/patient/rps/dashboard'},
      {text: "Questionnaire", to: '/patient/rps/start-questionnaire'}
    ],
    questionnaire_uid: null,
    loading: false
  }),

  mounted() {
    this.loading = true;
    this.$http.get(`/api/patients/${this.$store.getters.getPatientId}/questionnaires_rps`, {params: {filter_questionnaire_completed: false}}).then(response => {
      if (response.data.length) {
        let questionnaire = response.data[0];
        if (!questionnaire.questionnaire_is_completed) {
          this.questionnaire_uid = questionnaire.uid;
        }
      }
    }).finally(() => {
      this.loading = false;
    })
  },

  methods: {
    start_questionnaire: function() {
      if (this.questionnaire_uid) {
        this.$router.push({
          name: "PatientRPSQuestionnaire",
          params: {questionnaire_uid: this.questionnaire_uid, patient_id: 0},
          query: {redirection: "continue"}
        });
      }
      else {
        this.$router.push({
          name: "PatientRPSQuestionnaire"
        });
      }
    }
  },

  computed: {
    niveau_1() {
      return get_terminology("niveau_1", this.$store.getters.getCurrentAgency).toLowerCase();
    },
    niveau_4() {
      return `${get_terminology("niveau_4", this.$store.getters.getCurrentAgency).toLowerCase()}s`;
    },
    patient() {
      return `${get_terminology("patient", this.$store.getters.getCurrentAgency).toLowerCase()}s`;
    },
    questionnaire_info() {
      return get_questionnaire_info(this.$store.getters.getAgencyName);
    },
    duration() {
      return get_duration(this.$store.getters.getAgencyName);
    },
    show_doctor_image() {
      return get_show_doctor_image(this.$store.getters.getAgencyName);
    },
    profiles() {
      return this.$store.getters.getProfiles;
    },
    info_profile() {
      let data = this.$store.getters.getPatientData;
      return `${get_categorie_name(data.patient.categorie)}, ${data.patient.main_affectation.name}`;
    },
    show_pds_access_phrase_start_questionnaire() {
      return show_pds_access_phrase_start_questionnaire(this.$store.getters.getAgencyName)
    },
    btn_text() {
      if (this.questionnaire_uid) return "Continuer mon questionnaire";
      return "Commencer le questionnaire";
    }
  }
}
</script>

<style scoped>
.v-card{
  border-radius: 20px !important;
}
</style>
