<template>
  <v-container>
    <p class="soft_blue--text bold--text text-center font-size--30 pt-4">Que souhaitez-vous faire ?</p>
    <v-radio-group v-model="consent">
      <ConsentementItem :value="1" content="Je souhaite prendre rendez-vous et me faire vacciner (1ère injection)."
                        txt_optionnel="J'ai compris le bénéfice-risque de recevoir un des vaccins Covid-19
                          actuellement autorisés par l'Agence Nationale de Sécurité du Médicament"
                        :selected="consent===1"/>
      <v-expand-transition>
        <ExpandInfoStartAnonymous
          v-if="consent === 1"
          :sex.sync="sex"
          :birthdate.sync="birthdate"
        />
      </v-expand-transition>

      <ConsentementItem :value="9" :content="'Je me suis déjà fait vacciner ailleurs (au moins 1 injection) et je souhaite prendre rendez-vous pour une injection de rappel (2ème injection et plus) à ' + $route.params.agency_name" :selected="consent === 9" />
      <v-expand-transition>
        <div v-if="consent === 9">
          <ExpandInfoStartAnonymous
            :sex.sync="sex"
            :birthdate.sync="birthdate"
          />
          <v-row>
            <v-col cols="12" sm="6" offset-sm="1">
              <AnDate :date="vaccination_date"
                      title="Quand avez-vous bénéficié de votre dernière dose (injection) ?"
                      show_title
                      required
                      icon=""
                      v-on:formatted_date="vaccination_date=$event"/>
            </v-col>
            <v-col cols="12" sm="6" offset-sm="1">
              <span class="medium--text">Combien de doses (injections) avez-vous déjà eu ? <v-icon color="red" size="8" class="mb-3">mdi-asterisk</v-icon></span>
              <v-select v-model="number_of_injections" :items="number_of_injections_choices" filled dense></v-select>
            </v-col>
            <v-col cols="12" sm="6" offset-sm="1">
              <span class="medium--text">Quel vaccin vous a été administré ?</span>
              <v-select v-model="vaccine_type" :items="vaccine_choices" filled dense></v-select>
            </v-col>
            <v-col cols="12" sm="6" offset-sm="1">
              <span class="medium--text">À quel endroit de votre corps le vaccin vous a été injecté ?</span>
              <v-select v-model="injection_site" :items="injection_choices" filled dense></v-select>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
      <ConsentementItem :value="2" content="J’ai déjà pris rendez-vous une première fois (compte existant)" :selected="consent===2"/>
    </v-radio-group>

    <div class="text-center mt-4">
      <v-btn large class="px-sm-6 mr-2 mx-sm-3" color="soft_blue" outlined @click="goTo('VaccinationHomeView')">
        <span class="font-size--16 medium--text">Revenir à l'accueil</span>
      </v-btn>
      <v-btn large class="white--text px-sm-12 mx-sm-3" color="soft_blue" elevation="0" :disabled="!validate_form" @click="validate_consentement()" :loading="loading">
        <span class="font-size--16 medium--text">Valider</span>
      </v-btn>
    </div>

    <v-dialog v-model="dialog" width="750">
      <v-card>
        <v-card-text class="text-center pt-6 px-6">
          <p class="semibold--text soft_blue--text font-size--18">
            Si vous avez déjà été vacciné, nous vous invitons à vous connecter avec vos identifiants. Vous pourrez ainsi prendre rendez-vous pour votre prochaine injection.
          </p>
          <p>(Il est conseillé de réaliser sa deuxième injection dans le même centre que la première)</p>
        </v-card-text>
        <v-card-actions class="justify-center pb-6">
          <v-btn outlined color="soft_blue" class="mx-3 px-sm-5" @click="dialog=false;goTo('VaccinationHomeView')">Fermer</v-btn>
          <v-btn elevation="0" color="soft_blue" class="white--text mx-3 px-sm-5" @click="dialog=false;$emit('connexion')">Se Connecter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ConsentementItem from "./ConsentementItem";
import AnDate from "anam-library/src/components/an-date/AnDate";
import {LIST_INJECTION_CHOICES_PATIENT, LIST_VACCINE_CHOICES} from "@/data/data_vaccination";
import ExpandInfoStartAnonymous from "./ExpandInfoStartAnonymous";

export default {
    name: "BlocConsentement",
    components: {
      ConsentementItem,
      ExpandInfoStartAnonymous,
      AnDate
    },
    props: {
      launch_questionnaire: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      consent: null,
      sex: null,
      birthdate: null,
      loading: false,
      dialog: false,
      vaccination_date: null,
      vaccine_type: null,
      injection_site: null,
      number_of_injections: null,
      vaccine_choices: LIST_VACCINE_CHOICES,
      injection_choices: LIST_INJECTION_CHOICES_PATIENT,
      number_of_injections_choices: [
        {value: 1, text: "1"},
        {value: 2, text: "2"},
      ]
    }),

    watch: {
      consent(val) {
        this.sex = null;
        this.birthdate = null;
        this.vaccination_date = null;
        this.vaccine_type = null;
        this.injection_site= null;
      }
    },

    computed: {
      validate_form: function() {
        if(this.consent) {
          if(this.consent === 1){
            return !!(this.sex && this.birthdate);
          }
          else if (this.consent === 9) {
            return !!(this.sex && this.birthdate && this.vaccination_date && this.number_of_injections);
          }
          else return true
        }
        else return false
      }
    },
    methods: {
      validate_consentement: function() {
        this.loading = true;
        if (this.consent === 2){
          this.dialog = true;
          this.loading = false;
        } else if(this.consent === 1) {
          this.start_questionnaire("FirstVaccinationPrevana");
        }
        else if (this.consent === 9) {
          this.start_questionnaire("SecondVaccinationPrevana");
        }
        else {
          this.loading = false
        }
      },
      start_questionnaire: function(questionnaire_type) {
        this.$emit('update:launch_questionnaire', true);
        let form_data = new FormData();
        form_data.append('app_name', 'prevanavaccination');
        form_data.append('agency_name', this.$route.params.agency_name);
        form_data.append('sexe', this.sex);
        form_data.append('birthdate', this.birthdate);

        if (this.vaccination_date) form_data.append('vaccination_date', this.vaccination_date);
        if (this.vaccine_type) form_data.append('vaccine_type', this.vaccine_type);
        if (this.injection_site) form_data.append('injection_site', this.injection_site);
        if (this.number_of_injections) form_data.append('number_of_injections', this.number_of_injections);

        this.$http.post(`/api/start_anamnese_anonymous/${questionnaire_type}`, form_data).then(() => {
          this.$store.commit('setPreQuestSexe', this.sex);
          this.$store.commit('setPreQuestBirthdate', this.birthdate);
          this.$router.push({name: "QuestionnaireView", query: {questionnaire_type: questionnaire_type, app_name: "prevanavaccination",
            additional_data: {vaccination_number: this.number_of_injections+1}}});
        }).catch(() => {
          this.$emit('update:launch_questionnaire', false);
          this.loading = false;
        })
      }
    }
  }
</script>

<style scoped>

</style>
