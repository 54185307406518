<template>
  <div>
    <v-icon v-if="value in [0, 1, 2, 3]" :color="color_priorisation">
      mdi-checkbox-blank-circle
    </v-icon>
    <span v-else class="medium--text">-</span>
  </div>
</template>

<script>
export default {
  name: "PriorisationItem",
  props: ["item", "variable"],

  computed: {
    value() {
      return this.item[this.variable];
    },
    color_priorisation() {
      switch (this.value) {
        case 3:
          return "red darken-4";
        case 2:
          return "red";
        case 1:
          return "orange";
        default:
          return "green";
      }
    },
  },

  methods: {
    get_value_to_display(data) {
      return data in [0, 1, 2, 3] ? data : "-";
    }
  }
};
</script>
