<template>
  <div>
    <PrBreadcrumbsComponent :navigations="navigations"/>
    <v-row class="ma-2 ma-sm-5 justify-center">
      <v-col cols="12" sm="8">
        <PrCardComponent title="Questionnaire préparatoire à la vaccination" icon="mdi-clipboard-text" color="secondary">
          <template v-slot:card-text>
            <v-progress-linear v-if="loading_init" indeterminate color="primary"></v-progress-linear>
            <div v-if="vaccination_number === 1">
              <p v-if="questionnaire.length" class="primary--text medium--text my-5">Vous avez déjà répondu au questionnaire de première injection:</p>
              <p v-else class="primary--text medium--text my-5">Un questionnaire a été commencé mais n'a pas été sauvegardé</p>
              <QuestionAnswers v-if="questionnaire.length" :questionnaire="questionnaire"></QuestionAnswers>
            </div>

            <div v-if="vaccination_number > 1">
              <p v-if="questionnaire.length" class="primary--text medium--text my-5">Vous avez déjà répondu au questionnaire de {{vaccination_number}}ème injection:</p>
              <p v-else class="primary--text medium--text my-5">Un questionnaire a été commencé mais n'a pas été sauvegardé</p>
              <QuestionAnswers v-if="questionnaire.length" :questionnaire="questionnaire"></QuestionAnswers>
            </div>

            <div class="text-center mt-sm-6" v-if="vaccination_number > 0">
              <v-btn text color="secondary" class="text-decoration-underline medium--text px-6" elevation="0" @click="modify_answers()">Modifier mes réponses</v-btn>
              <v-btn :disabled="!questionnaire.length" elevation="0" class="medium--text px-6" color="secondary" @click="go_to_appointment()" :loading="loading_rdv">
                Prendre rendez-vous
              </v-btn>
            </div>
          </template>
        </PrCardComponent>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import PrBreadcrumbsComponent from "prevana-library/src/components/breadcrumbs/PrBreadcrumbsComponent";
import PrCardComponent from "prevana-library/src/components/common/PrCardComponent";
import QuestionAnswers from "@/components/doctor/vaccination/dpa/components/QuestionAnswers";

export default {
    name: "PatientVaccinationQuestionnaireView",
    components: {QuestionAnswers, PrBreadcrumbsComponent, PrCardComponent},
    data: () => ({
      loading_init: false,
      loading: false,
      loading_rdv: false,
      vaccination_number: null,
      questionnaire: null,
      navigations: [
        {
          text: 'Vaccination',
          to: '/patient/vaccination/dashboard',
        },
        {
          text: 'Questionnaire',
          to: '/patient/vaccination/questionnaire'
        },
      ]
    }),

    mounted() {
      this.loading_init = true;
      this.$http.get("/api/vaccination_appointment_info").then(response => {
        let quest = response.data.questionnaires.filter(x => x.vaccination_number === response.data.vaccination_number);
        this.vaccination_number = response.data.vaccination_number;
        if (quest.length) this.questionnaire = quest[0].questions_answers;
        this.$vuetify.goTo(0);
      }).finally(() => {
        this.loading_init = false;
      })
    },

    methods: {
      modify_answers() {
        this.loading = true;
        if (this.vaccination_number === 1) this.$router.push({name: "QuestionnaireView", query: {questionnaire_type: "FirstVaccinationPrevana", app_name: "prevanavaccination", nb_question: 2, additional_data: {vaccination_number: this.vaccination_number}}});
        else this.$router.push({name: "QuestionnaireView", query: {questionnaire_type: "SecondVaccinationPrevana", app_name: "prevanavaccination", nb_question: 2, additional_data: {vaccination_number: this.vaccination_number}}});
      },

      go_to_appointment() {
        this.loading_rdv = true;
        this.$http.get("/api/vaccination_appointment_info").then(response => {
          if (response.data.status === 8) this.$router.push({name: 'PatientVaccinationNotEligible'});
          else this.$router.push({name: 'PatientVaccinationAppointement'});
        }).finally(() => {
          this.loading_rdv = false;
        })
      }
    }
  }
</script>
